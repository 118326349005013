
export default class IndexedDb {

    constructor(dbName, dbVer){

        this.db = null
        this.dbName = dbName
        this.dbVer = dbVer

        this.upgradeListeners = []
        this.connectListeners = []

    }

    connect(){
        var request = window.indexedDB.open(this.dbName, this.dbVer);

        request.onupgradeneeded = event => {
        
            this.db = event.target.result;
            
            this.upgradeListeners.forEach(callback => {
                callback(event)
            })
        
        }

        request.onerror = () => {
            console.error("Error", request.error);
        };
        
        request.onsuccess = () => {
            this.db = request.result;

            this.connectListeners.forEach(callback => {
                callback(this.db)
            })
        };

    }

    addUpgradeListener(callback){
        this.upgradeListeners.push(callback)
    }

    addConnectListener(callback){
        this.connectListeners.push(callback)
    }

}


