//helpers

export function loadConfig(callback){
    console.log(window.location)
    let url = window.location.origin +'/config.json'
    fetch(url).then((res) => res.json()).then((config) => {callback(config)})
}

export function getIndexByID(ID, array, field){
    if(!array) return null
    if(field===undefined) field="ID"
    const index = array.findIndex(item=>item[field]===ID)
    return index>=0 ? index : null
}

export function getObjectByID(ID, array, field){
    const index = getIndexByID(ID, array, field)
    return index===null? null : array[index]
}

/**
 * Delete item from array using ID or custom field
 * return removed item or null if item not exist
 * @param {string} ID 
 * @param {Array.<Object>} array 
 * @param {string} field 
 */
export function deleteItemByID(ID, array, field){
    const index = getIndexByID(ID, array, field)
    if(index>=0){
        return array.splice(index, 1)
    }
    return null
}
    
export function getBase64ID(){
    var array = new Uint8Array(16);
    window.crypto.getRandomValues(array);
    var b64 = '';
    for (var i = 0; i < array.length; i++) {
        b64 += String.fromCharCode( array[i] );
    }
    return window.btoa( b64 );		 
}

export function getDeviceID(){
    var deviceid = localStorage.getItem("DeviceID");
    if(deviceid === null){
        deviceid = getBase64ID();
        localStorage.setItem("DeviceID", deviceid);
    }
    return deviceid;
}


export function selectKeys(object, value){
    let results = [];
    if (object != null) {
        results = Object.entries(object).filter((v) => v[1] === value).map((e) => e[0]);
    }
    return results;
};

export function decimalColorToHexColor(number) {
    //converts to a integer
    var intnumber = number - 0;
 
    // isolate the colors - really not necessary
    var red, green, blue;
 
    // needed since toString does not zero fill on left
    var template = "#000000";
 
    // in the MS Windows world RGB colors
    // are 0xBBGGRR because of the way Intel chips store bytes
    red = (intnumber&0x000000ff) << 16;
    green = intnumber&0x0000ff00;
    blue = (intnumber&0x00ff0000) >>> 16;
    var rgb = intnumber & 0x00ffffff
 
    // mask out each color and reverse the order
    intnumber = red|green|blue;
 
    // toString converts a number to a hexstring
    var HTMLcolor = rgb.toString(16);
 
    //template adds # for standard HTML #RRGGBB
    HTMLcolor = template.substring(0,7 - HTMLcolor.length) + HTMLcolor;
 
    return HTMLcolor;
} 

export function hexColorToDecimalColor(hexColor){
    let number = 0
    console.log(hexColor)
    let hex = '0xff' + hexColor.substring(1, 7)
    number = parseInt(hex)
    return number
}

export class FormatByteArrayToGuidString {
    //@ Made by Zura Dalakishvili, Tbilisi, Georgia 2016
    //Guid representation created from  byte[] array for Javascript

    // Usage: 
    //  var inst = new Guid(array);
    //   var guidStr = inst.guidString;



    constructor(b){
        this.b = b;

        if (b === null)
        return;
        if (b.length !== 16)
        return;

        this.parts = {
            a: ((b[3]) << 24) | ((b[2]) << 16) | ((b[1]) << 8) | b[0],
            b: (((b[5]) << 8) | b[4]),
            c: (((b[7]) << 8) | b[6]),
            d: b[8],
            e: b[9],
            f: b[10],
            g: b[11],
            h: b[12],
            i: b[13],
            j: b[14],
            k: b[15]
        }

    }

    HexToChar(a) {
        a = a & 0xf;
        return String.fromCharCode(((a > 9) ? a - 10 + 0x61 : a + 0x30));
    }

    //HexsToChars(guidChars, offset, a, b) {
    //    return this.HexsToChars(guidChars, offset, a, b, false);
    //}

    HexsToChars(guidChars, offset, a, b, hex) {
        if (hex) {
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
        }
        guidChars[offset++] = this.HexToChar(a >> 4);
        guidChars[offset++] = this.HexToChar(a);
        if (hex) {
            guidChars[offset++] = ',';
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
        }
        guidChars[offset++] = this.HexToChar(b >> 4);
        guidChars[offset++] = this.HexToChar(b);
        return offset;
    }


    

    //_toGUID(this.b);


    toString = function (format) {
        if (format===undefined || format === null || format.length === 0)
            format = "D";

        var guidChars = [];
        var offset = 0;
        //var strLength;// = 38;
        var dash = true;
        var hex = false;

        if (format.length !== 1) {
            // all acceptable format strings are of length 1
            return null;
        }

        var formatCh = format[0];

        if (formatCh === 'D' || formatCh === 'd') {
            guidChars = new Array(36);
            //strLength = 36;
        } else if (formatCh === 'N' || formatCh === 'n') {
            guidChars = new Array(32);
            //strLength = 32;
            dash = false;
        } else if (formatCh === 'B' || formatCh === 'b') {
            guidChars = new Array(38);
            guidChars[offset++] = '{';
            guidChars[37] = '}';
        } else if (formatCh === 'P' || formatCh === 'p') {
            guidChars = new Array(38);
            guidChars[offset++] = '(';
            guidChars[37] = ')';
        } else if (formatCh === 'X' || formatCh === 'x') {
            guidChars = new Array(68);
            guidChars[offset++] = '{';
            guidChars[67] = '}';
            //strLength = 68;
            dash = false;
            hex = true;
        } else {
            return null;
        }

        if (hex) {
            // {0xdddddddd,0xdddd,0xdddd,{0xdd,0xdd,0xdd,0xdd,0xdd,0xdd,0xdd,0xdd}}
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
            offset = this.HexsToChars(guidChars, offset, this.parts.a >> 24, this.parts.a >> 16);
            offset = this.HexsToChars(guidChars, offset, this.parts.a >> 8, this.parts.a);
            guidChars[offset++] = ',';
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
            offset = this.HexsToChars(guidChars, offset, this.parts.b >> 8, this.parts.b);
            guidChars[offset++] = ',';
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
            offset = this.HexsToChars(guidChars, offset, this.parts.c >> 8, this.parts.c);
            guidChars[offset++] = ',';
            guidChars[offset++] = '{';
            offset = this.HexsToChars(guidChars, offset, this.parts.d, this.parts.e, true);
            guidChars[offset++] = ',';
            offset = this.HexsToChars(guidChars, offset, this.parts.f, this.parts.g, true);
            guidChars[offset++] = ',';
            offset = this.HexsToChars(guidChars, offset, this.parts.h, this.parts.i, true);
            guidChars[offset++] = ',';
            offset = this.HexsToChars(guidChars, offset, this.parts.j, this.parts.k, true);
            guidChars[offset++] = '}';
        } else {
            // [{|(]dddddddd[-]dddd[-]dddd[-]dddd[-]dddddddddddd[}|)]
            offset = this.HexsToChars(guidChars, offset, this.parts.a >> 24, this.parts.a >> 16);
            offset = this.HexsToChars(guidChars, offset, this.parts.a >> 8, this.parts.a);
            if (dash) guidChars[offset++] = '-';
            offset = this.HexsToChars(guidChars, offset, this.parts.b >> 8, this.parts.b);
            if (dash) guidChars[offset++] = '-';
            offset = this.HexsToChars(guidChars, offset, this.parts.c >> 8, this.parts.c);
            if (dash) guidChars[offset++] = '-';
            offset = this.HexsToChars(guidChars, offset, this.parts.d, this.parts.e);
            if (dash) guidChars[offset++] = '-';
            offset = this.HexsToChars(guidChars, offset, this.parts.f, this.parts.g);
            offset = this.HexsToChars(guidChars, offset, this.parts.h, this.parts.i);
            offset = this.HexsToChars(guidChars, offset, this.parts.j, this.parts.k);
        }

        //let str = new String(guidChars)
        let guidStr = guidChars.join('');

        return guidStr; /// new String(guidChars);
    }

    guidString = function () {
        return this.toString('D', null).split(',').join('');
    }

    //return this.guidString();
}

export function base64toGuid(base64Str){
    let arr = base64ToUint8Array(base64Str)
    return new FormatByteArrayToGuidString(arr).toString()
}

export function sortByName(item1, item2){
    const name1 = (item1.Name!==undefined ? item1.Name : item1.name).toLowerCase()
    const name2 = (item2.Name!==undefined ? item2.Name : item2.name).toLowerCase()
    if (name1 > name2) return 1;
    if (name1 === name2) return 0; 
    if (name1 < name2) return -1;
}

export function sortByLogin(item1, item2){
    const login1 = item1.Login.toLowerCase()
    const login2 = item2.Login.toLowerCase()
    if (login1 > login2) return 1;
    if (login1 === login2) return 0; 
    if (login1 < login2) return -1;
}

export function sortByOnlineName(item1, item2){
    if (item1.DeviceID==null && item2.DeviceID) return 1;
    if (item1.DeviceID && item2.DeviceID==null) return -1;
    if ( (item1.DeviceID && item2.DeviceID) || (item1.DeviceID==null && item2.DeviceID==null) ){
        const name1 = item1.Name.toLowerCase()
        const name2 = item2.Name.toLowerCase()
        if (name1 > name2) return 1;
        if (name1 === name2) return 0; 
        if (name1 < name2) return -1;
    }
}

export function base64ToUint8Array(base64string) {
    const str = atob(base64string)
    const arrayBuffer = Uint8Array.from(str, function(c) { return c.charCodeAt(0); });
    return arrayBuffer;
}

export function uint8ArrayToBase64(arrayBuffer) {
    var base64 = btoa(arrayBuffer.reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64;
}