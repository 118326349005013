import React from 'react';

import theme from '../../../theme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default class DevicesList extends React.Component{

    handleItemSelect(itemID){
        console.log('select device '+itemID)
        this.props.onItemSelect(itemID)
    }

    getItemStyle(itemID){
        let selected = itemID === this.props.selectedItemID
        let style = {
            background: selected? theme.palette.background.selectedRow : '',
            cursor: 'pointer'
        }
        return style
    }

    render(){
        console.log(this.props.items)

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            }
        };

        return(
            <>
                <Paper style={styles.paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} style={styles.head}>Устройства:</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.items.map((item) => 
                            <TableRow key={item.ID} 
                                    onClick={()=>{this.handleItemSelect(item.ID)}}
                                    style={this.getItemStyle(item.ID)}>
                                <TableCell component="th" scope="row">
                                    {item.Login}
                                </TableCell>
                                <TableCell>
                                    {item.UserNAme}
                                </TableCell>
                                <TableCell>
                                    {item.versionName}
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                    </Table>
                </Paper>
            </>
        )

    }

}