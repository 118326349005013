import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {Container, Box} from '@material-ui/core'
import {Button, TextField} from '@material-ui/core';

/*
export const KEY_ACTIONS = {
    START_STOP_VOICE_CALL_REPLAY: 0,
    START_STOP_EMERGENCY: 1,       
    SET_STATUS: 2,
    CALL_ALERT: 3,
    SEND_MESAGE: 4,
    ANNOUNCE_BATTARY_CHARGE: 5,   
    NEXT_CHANNEL: 6,
    PREVIOUS_CHANNEL: 7,
}
*/

class EditSipContactDialog_ extends React.Component{

    constructor(props){
        super(props)
        console.log('EditSipContactDialog_')
        console.log(this.props)
        const {sipContact} = this.props;
        console.log('EditSipContactDialog constructor')
        console.log(sipContact)
        this.state = {
            sipContact: {
                SipName: sipContact.SipName,
                SipAddress: sipContact.SipAddress
            },
            errors:{
                SipName: false,
                SipAddress: false
            },
            changed: false,
        }
    }

    handleSave(){
        if(this.validate()){
            debugger
            let sipContact = {...this.state.sipContact}
            //debugger
            this.props.onAction({type: 'SIP_CONTACT_EDITOR_SAVE', sipContact: sipContact, index: this.props.index})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'SIP_CONTACT_EDITOR_CLOSE'})
    }

    handleInput(event){
        let changed = !this.state.changed ? {changed: true} : {}
        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const sipContact = {...this.state.sipContact, [event.target.name]: val}

        this.setState({sipContact: sipContact, ...changed})
    }

    validate(){
        const {t} = this.props

        let errors = this.state.errors
        let sipContact = this.state.sipContact
        let error = false

        if(!sipContact.SipName){
            errors.SipName = t('NameIsEmpty')
            error = true
        }else{
            errors.SipName = false
        }
        
        if(!sipContact.SipAddress){
            errors.SipAddress = t('AddressIsEmpty')
            error = true
        }else{
            errors.SipAddress = false
        }

        this.setState({errors: errors})

        return !error;
    }

    render(){
        const {t} = this.props

        const title = this.props.action==='add'?t('NewSipContact'):t('EditSipContact')

        //const margin = {margin:'16px 0 8px 0'}
        //const margin = {margin:'16px 0 8px 0'}
        /*
        const head = {
            color: theme.palette.color.dialogTitle,
            backgroundColor: theme.palette.background.dialogTitle,
            fontWeight:'bold', 
            margin:5,
            padding:5
        }
        */

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>
            
            <Box textAlign="center">
                <TextField name="SipName"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.SipName?this.state.errors.SipName:t('SipName')}
                    autoComplete="new-password"
                    error={this.state.errors.SipName!==false}
                    value={this.state.sipContact.SipName}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
            <Box textAlign="center">
                <TextField name="SipAddress" margin="normal"
                    style={{width: '100%'}}
                    autoComplete="new-password"
                    label={t('SipAddress')}
                    error={this.state.errors.SipAddress!==false}
                    value={this.state.sipContact.SipAddress}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
        
        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const EditSipContactDialog = withTranslation()(EditSipContactDialog_);