import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../theme';

import {Button, Grid, Typography, Container, AppBar, Toolbar} from '@material-ui/core';

import Logo from './Logo';
//import {SelectLang} from './SelectLang';

class ApplicationBar_ extends React.Component {

    handleLogout(){
        if(this.props.onAction){
            this.props.onAction({type:"LOGOUT"})
        }
    }

    render(){

        const { t } = this.props;

        let backgroundTitleStyle = {
            background:theme.palette.background.dialogTitle,
            color: theme.palette.color.dialogTitle
        }
        let typographyTitleStyle = {
            color: theme.palette.color.dialogTitle, 
            fontWeight: 'bold',
            //padding:'0.5em 0'
        }

        /*
        let linkStyle = {
            margin:'5px 10px',
        }
        */

        return(

            <AppBar position="static" color="secondary">
                <Toolbar variant="dense">
                    <Grid container justify="flex-start" spacing={0} >
                        <Grid xs={3} key="1" item>
                            <Logo></Logo>
                        </Grid>
                        <Grid xs={6} key="2" item>
                            <Container style={{padding:'1em 0'}}>
                                <Typography style={typographyTitleStyle}>
                                    {this.props.children}
                                </Typography>

                                {/*<Link style={linkStyle} to="/monitor">Monitor</Link>*/}

                            </Container>
                        </Grid>
                        <Grid xs={3} key="3" item>
                            <Container align="right" style={{padding:'1em 0'}}>
                                <Typography style={typographyTitleStyle}>                                
                                    {this.props.user.login}
                                    {' '}
                                    {/*
                                    <SelectLang></SelectLang>
                                    */}
                                    <Button style={{...backgroundTitleStyle, margin: '0 0.5em', height: '1.8em', padding: '0 16px'}} 
                                        variant="outlined" onClick={this.handleLogout.bind(this)}>
                                            {t("SignOut")}
                                    </Button>
                                </Typography>
                            </Container>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

        );


    }

}

export const ApplicationBar = withTranslation()(ApplicationBar_);