import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core'
import {Container, Box, Grid} from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
//FormControl InputLabel, Select, MenuItem, TextField
import {Button, OutlinedInput} from '@material-ui/core';
//import PersonIcon from '@material-ui/icons/Person';


class GroupPropertiesDialog_ extends React.Component{

    constructor(props){
        super(props)

        console.log('GroupPropertiesDialog constructor')
        console.log('props')
        console.log(props)
        this.state = {
            properties: props.properties,
            errors:{
                pollingTime: false
            },
            changed: false,
        }

        this.handleInput =  this.handleInput.bind(this)
    }

    handleSave(){
        if(this.validate()){
            let properties = {...this.state.properties}
            this.props.onAction({
                type: 'GROUP_PROPERTIES_DIALOG_SAVE', 
                properties: properties,
                networkID: this.props.networkID,
                groupID: this.props.group.ID,
            })
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'GROUP_PROPETIES_DIALOG_CLOSE'})
    }

    handleInput(event){
        let changed = !this.state.changed ? {changed: true} : {}
        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const properties = {...this.state.properties, [event.target.name]: val}
        this.setState({properties: properties, ...changed})
    }

    validate(){
        const {t} = this.props

        let errors = this.state.errors;
        let error = false

        
        //if(!this.state.properties.pollingTime){
        //    errors.ChannelNum = t('ChannelNumIsEmpty')
        //    error = true
        //}else 
        if(this.state.properties.pollingEnabled && !Number(this.state.properties.pollingTime)){
            errors.pollingTime = t('IsNotNumber')
            error = true
        }else{
            errors.pollingTime = false
        }
/*
        if(!this.state.channel.ChannelName){
            errors.ChannelName = t('NameIsEmpty')
            error = true
        }else{
            errors.ChannelName = false
        }
        this.setState({errors: errors})
        */

        return !error;
    }


    render(){
        const {t} = this.props

        const title = t('Properties')

        const margin = {margin:'16px 0 8px 0'}

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="properties-dialog-title">
        
    <DialogTitle id="properties-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>

            <Box textAlign="center">
                <Grid container style={{width:'100%', border2:'1px solid red'}}>
                    <Typography style={{fontWeight: 'bold'}}>
                        {this.props.group.Name}
                    </Typography>
                </Grid>    
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                {t('LocationSettings')}:
            </Box>
            <Box textAlign="" style={{...margin, width: '100%'}}>
                <Checkbox color="primary" 
                    checked={this.state.properties.pollingEnabled}
                    onChange={this.handleInput}
                    name="pollingEnabled"
                />
                <label>
                    {t('PollingTime')}
                    <OutlinedInput  type="text" name="pollingTime"
                        id={"pollingTime-input"}
                        style={{width: 100, margin:'0 10px', padding:0}}
                        autoComplete="new-password"
                        disabled={!this.state.properties.pollingEnabled}
                        value={this.state.properties.pollingTime}
                        error={this.state.errors["pollingTime"]!==false}
                        onChange={this.handleInput}
                        aria-describedby={"pollingTime-helper-text"}
                    />

                    {this.state.errors["pollingTime"]!==false?
                    <span style={{color:'red', whiteSpace:'nowrap'}} id={"pollingTime-helper-text"}>
                        {this.state.errors["pollingTime"]}
                    </span>
                    :''}
                    
                </label>
            </Box>
        
        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const GroupPropertiesDialog = withTranslation()(GroupPropertiesDialog_);