import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Table, TableBody, TableCell, TableHead, TableRow}  from '@material-ui/core';

//export 
class VersionUptime_ extends React.Component {


    render(){
        const {t} = this.props

        let statistics = this.props.data

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head1: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            head2: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogSubTitle,
            },
        };

        if(!statistics){
            return(<>No data</>)
        }

        return (
<>
            {/*<Box>
                <Box>{t('FleetVersion')}: {statistics.Version}</Box>
                <Box>{t('FleetUptime')}: <Uptime>{statistics.UpTime}</Uptime></Box>
                <Box>{t('OsVersion')}: {statistics.OsVersion}</Box>
                <Box>{t('OsUptime')}: <Uptime>{statistics.OsUpTime}</Uptime></Box>
            </Box>*/}

<Table>
{/*
<TableHead>
    <TableRow>
        <TableCell colSpan={7} style={styles.head1}>{t('Version')}</TableCell>
    </TableRow>
</TableHead>
*/}
<TableHead>
    <TableRow>
        <TableCell style={{...styles.head1, width:250}}>{t('ServerVersion')}</TableCell>
        <TableCell style={{...styles.head1, width:250}}>{t('ServerUptime')}</TableCell>
        <TableCell style={{...styles.head1, width:250}}>{t('Role')}</TableCell>
        {this.props.version==='full'?<>
        <TableCell style={{...styles.head1, width:300}}>{t('OsVersion')}</TableCell>
        <TableCell style={{...styles.head1, width:250}}>{t('OsUptime')}</TableCell>
        </>:''}
        <TableCell style={styles.head1}></TableCell>
    </TableRow>
</TableHead>
<TableBody>
    <TableRow>
        <TableCell>{statistics.Version}</TableCell>
        <TableCell><Uptime>{statistics.UpTime}</Uptime></TableCell>
        <TableCell><ServerRole t={this.props.t}>{statistics.Role}</ServerRole></TableCell>
        {this.props.version==='full'?<>
        <TableCell>{statistics.OsVersion}</TableCell>
        <TableCell><Uptime>{statistics.OsUpTime}</Uptime></TableCell>              
        </>:''}
        <TableCell></TableCell>
    </TableRow> 
</TableBody>

</Table>
</>
        )
    }

}

class VersionUptimeSmall_ extends React.Component {


    render(){
        const {t} = this.props

        let statistics = this.props.data

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head1: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            head2: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogSubTitle,
            },
            cell: {
                borderBottom: 'none', 
                margin: 1, padding: 1, 
                color: 'white',
                fontSize: '1em',
                border2: '1px solid red'
            }
        };

        if(!statistics){
            return(<>No data</>)
        }

        return (
<>
            {/*<Box>
                <Box>{t('FleetVersion')}: {statistics.Version}</Box>
                <Box>{t('FleetUptime')}: <Uptime>{statistics.UpTime}</Uptime></Box>
                <Box>{t('OsVersion')}: {statistics.OsVersion}</Box>
                <Box>{t('OsUptime')}: <Uptime>{statistics.OsUpTime}</Uptime></Box>
            </Box>*/}

<Table style={{margin:1}}>
    <TableBody>
        <TableRow>
            <TableCell style={{...styles.cell, width:'30%', textAlign: 'right'}}></TableCell>
            <TableCell style={{...styles.cell}}>{t('ServerVersion')}: </TableCell>
            <TableCell style={{...styles.cell, width:100}}>{statistics.Version}</TableCell>
       {/*} </TableRow><TableRow>
            <TableCell style={{...styles.cell, width:'50%'}}></TableCell>*/}
            <TableCell style={{...styles.cell}}>{t('ServerUptime')}: </TableCell>
            <TableCell style={{...styles.cell}}><Uptime>{statistics.UpTime}</Uptime></TableCell>
        </TableRow>
    </TableBody>
</Table>
</>
        )
    }

}


function Uptime(props){  
    let uptime = props.children/1000;
    let days = Math.floor(uptime/60/60/24);
    let hours = Math.floor( (uptime - days*60*60*24)/60/60 )
    let minutes = Math.floor( (uptime - days*60*60*24 - hours*60*60 )/60 )
    let seconds = Math.floor( (uptime - days*60*60*24 - hours*60*60 - minutes*60 ) )
    let hh = hours.toString().padStart(2, '0')
    let mm = minutes.toString().padStart(2, '0')
    let ss = seconds.toString().padStart(2, '0')
    return(
       <>{days}:{hh}:{mm}:{ss}</> 
    )
}

function ServerRole(props){
    switch(props.children){
        case 0: return props.t('StandAlone')
        case 1: return props.t('Primary')
        case 2: return props.t('Secondary')
        default: return 'UNknown' 
    }
}


export const VersionUptime = withTranslation()(VersionUptime_);
export const VersionUptimeSmall = withTranslation()(VersionUptimeSmall_);