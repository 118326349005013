

import { getBase64ID,  getDeviceID } from '../../api/helpers';
//getObjectByID, getIndexByID, deleteItemByID,
//import { base64toGuid, base64ToUint8Array, uint8ArrayToBase64 } from '../../api/helpers';

export const FLEET_STATISTICS_FUNCTION_UNKNOWN = 0
export const FLEET_STATISTICS_FUNCTION_OVERVIEW = 1
export const FLEET_STATISTICS_FUNCTION_NETWORKS = 2
export const FLEET_STATISTICS_FUNCTION_DMR_NETWORKS = 3
export const FLEET_STATISTICS_FUNCTION_SERVER = 4

export class ServerApi {

    constructor(serveraddress, login, password, options){
        this.serveraddress = serveraddress
        this.login = login
        this.password = password
        this.userID = null
        this.connectCount = 0


        this.options = {
            onError: null,
            onClose: null,
            onConnect: null,
            onNetworksChanged: null,
            ...options
        }

    }


    clearState(){

    }

    connect(){
        if ("WebSocket" in window) {
            this.isClosed = false
            this.connectCount++
            //alert("ws://" + this.serveraddress)

            let protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

            this.ws = new WebSocket(protocol + "//" + this.serveraddress);
            this.ws.onopen = this.handleOpen.bind(this)
            this.ws.onmessage = this.handleMessage.bind(this)
            this.ws.onerror = this.handleError.bind(this)
            this.ws.onclose = this.handleClose.bind(this)

            //this.initUnreadMessages()
        } else {
            // The browser doesn't support WebSocket
            alert("WebSocket NOT supported by your Browser!");
        }
    }//end connect

    close(){
        this.isClosed = true
        this.ws.close();
    }


    handleOpen() {
        this.connectCount = 1
        //this.initUnreadMessages()
    }

    handleError(event) { 
        console.error('SOCKET_ERROR')
        console.error(event)
        if(this.options.onError){
            this.options.onError(event, this.connectCount)
        }else{
            alert("Socket error: " + JSON.stringify(event, null, 4)); 
        }
    };


    handleClose(event) { 
        console.log('SOCKET_CLOSE ' + event.code)
        console.log(event)

        if(this.isClosed) return //regular logout

        //closed by server or broken connnection
        if(event.code===1005){
            //server halt
            this.clearState()
            window.setTimeout(()=>{this.connect()}, 5000)
        }else if(event.code===1006){
            window.setTimeout(()=>{this.connect()}, 5000)
        }

        if(this.options.onClose){
            this.options.onClose(event, this.connectCount)
        }else{
            alert("Socket closed. Code: "+event.code);	
        }
    };


    handleMessage(event) { 
        var messages = JSON.parse(event.data);
        for(let mi = 0; mi < messages.length; mi++){
            var msg = messages[mi];
            //console.log('handleMessage '+ mi)
            //console.log('MessageID=' + msg.MessageID)
            switch(msg.MessageID){
                case "SERVER_CONFIG":
                    this.VoipPort = msg.VoipPort;
                    this.AudioSampleRate = msg.AudioSampleRate;
                    this.AudioBitRate = msg.AudioBitRate;
                    this.AudioFrameSize = msg.AudioFrameSize;
                    
                    this.bufferSamplesCount = this.AudioSampleRate / 1000 * this.AudioFrameSize;
                    //bufferSamplesCount = this.AudioSampleRate / 1000 * this.AudioFrameSize;
                    //udpPacketBuffer = new ArrayBuffer(12 + this.bufferSamplesCount); // aLaw, muLaw

                    //currentFrame = new Int16Array(this.bufferSamplesCount);
                    //currentBufferIndex = 0;

                    //VoipEndpoint = new IPEndPoint(ServerIPAddr, VoIPPort);
                    //rtpPacket = new RtpPacket { Version = 2, SSRC = ssrc, PayloadType = 106, SequenceNumber = 0, Timestamp = 0 };
                    this.ssrc = new Uint32Array(1);
                    window.crypto.getRandomValues(this.ssrc);
                    var devconf = [{ 
                        MessageID: "DEVICE_CONFIG",  
                        Ssrc: this.ssrc[0], 
                        AppName: "FleetSystemMonitoring", 
                        //VersionName: "5.5", 
                        VersionName: this.options.config.version,
                        VersionCode: 1, 
                        AudioCodec: 1, 
                        VoiceOverTcp : "True", 
                        Password: this.password, 
                        DeviceData: { 
                            SessionID: getBase64ID(), 
                            ID: getDeviceID(), 
                            DeviceDescription: "MANUFACTURER=WLLC;MODEL=APIClientWEB;SERIAL=123456789;OSVERSION=5.0", 
                            Login: this.login, 
                            AvatarHash: "", 
                            VoiceOverTcp: true,
                            StatusID: "AAAAAAAAAAAAAAAAAAAAAA==" 
                        }
                    }];
                    console.log('DEVICE_CONFIG')
                    console.log(devconf)
                    this.ws.send(JSON.stringify(devconf));
                    break;

                case "CONFIG_SERVER_RESPONSE_NACK":
                    console.log('CONFIG_SERVER_RESPONSE_NACK')
                    console.log(msg)
                    if(this.options.onError){
                        this.options.onError(msg.Reason)
                    }else{
                        alert(msg.Reason);
                    }
                    break;

                case "CONFIG_SERVER_RESPONSE_ACK":
                    /*
                    if(this.options.onConnect){
                        this.options.onConnect(); 
                    }
                    */
                    var loginMessage = [{ MessageID: "LOGIN"}];
                    this.ws.send(JSON.stringify(loginMessage));
                    break;

                case "LOGIN_RESPONSE":
                    console.log(msg)
                    if(msg.Response === 0){
                        this.userID = msg.UserID 
    
                        if(this.options.onConnect){
                            this.options.onConnect(); 
                        }

                        /*
                        this.audio = new AudioProcessor(
                            this.AudioSampleRate, 
                            this.bufferSamplesCount, 
                            this.ssrc,
                            this.sendVoicePacket.bind(this)
                        );
                        */
                    }else{
                        let message = '';
                        switch(msg.Response){
                            //case 0: message = 'Successful login'; break;
                            case 1: message = 'Invalid client version'; break;
                            case 2: message = 'Invalid user name or password'; break;
                            case 3: message = 'License is expired'; break;
                            case 4: message = 'Exceeded number of available user connections'; break;
                            case 5: message = 'Server working in demo mode must be restarted'; break;
                            case 6: message = 'Multiple logins are prohibited for this user and ' +
                                'another device is already connected with these credentials'; break;
                            default: 
                                message = "Action not defined for "+ msg.Response + " (LOGIN_RESPONSE)"
                                console.error(message)
                        }
                        this.options.onError({message: message, type: 'login'})
                        //alert('login fail with: '+ message)
                    }
                    break;
                
                case "DEVICE_CONTEXT":
                    console.log('DEVICE_CONTEXT')
                    console.log(msg)
                    if(this.options.onAction){
                        this.options.onAction({type:'SET_LANGUAGE', lang: msg.WebLocale})
                    }
                    //alert(msg.WebLocale)
                    //todo?
                    //this.
                    break;
                    

                case "STATISTICS_RESPONSE":
                    //console.log(msg)
                    this.onStatisticsComplete(msg.Function, msg.Statistics);
                    break;


                default:
                    console.error('Action case not defined for message: ' + msg.MessageID + ', see console log for more details')
                    console.error(msg)
            }
        }
    }


        //Statistics Monitor
        queryStatistics(apiFunction, onComplete){

            //console.log('queryStatistics')
            //console.log('Function:'+apiFunction)

            if(this.isClosed) return;

            //console.log('queryStatistics')
            this.onStatisticsComplete = onComplete
    
            let message = {
                MessageID: 'STATISTICS_REQUEST',
                Function: apiFunction
            }
            //console.log(JSON.stringify([message]))
            if(this.ws) this.ws.send(JSON.stringify([message]));
        }


}