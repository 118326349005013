import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {Paper, Checkbox } from '@material-ui/core';
import { sortByName } from '../../../api/helpers';

export default class AclListEditor_ extends React.Component{

    render(){

        const {t} = this.props;
        
        this.props.items.sort(sortByName)

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            }
        };

        return(
            <>
                <Paper style={styles.paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{...styles.head, width:70}}>{t('Member')}</TableCell>
                                <TableCell style={{...styles.head, width:70}}>{t('RxOnly')}</TableCell>
                                <TableCell style={styles.head}>{this.props.title?this.props.title:t('Name')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.items.map((item, index) => 
                            <TableRow key={item.userID+item.groupID} >
                                
                                <TableCell  align="left" component="th" scope="row">
                                    <Checkbox 
                                        color="primary" 
                                        checked={item.value}
                                        style={{width: 30, height: 30, marginRight: '0.5em'}}
                                        disabled={item.value===null}
                                        onChange={event=>{
                                            this.props.onChange('Users', index, item.groupID, item.userID, event.target.checked)
                                        }}
                                        ></Checkbox>
                                </TableCell>
                                <TableCell  align="left" component="th" scope="row">
                                    <Checkbox 
                                        color="primary" 
                                        checked={item.rxOnly}
                                        style={{width: 30, height: 30, marginRight: '0.5em'}}
                                        disabled={item.rxOnly===null}
                                        onChange={event=>{
                                            this.props.onChange('RxOnlyUsers', index, item.groupID, item.userID, event.target.checked)
                                        }}
                                        ></Checkbox>
                                </TableCell>
                                <TableCell  align="left" component="th" scope="row">
                                    {item.name}
                                </TableCell>
                               
                            </TableRow>
                        )}
                        </TableBody>
                    </Table>
                </Paper>
            </>
        )

    }

}

export const AclListEditor = withTranslation()(AclListEditor_);