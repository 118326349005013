import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';
import {Table, TableBody, TableCell, TableHead, TableRow}  from '@material-ui/core';
import {Box} from '@material-ui/core';
//import { render } from 'react-dom';
//import setSeconds from 'date-fns/fp/setSeconds/index.js';
//import {Paper, Menu, MenuItem, IconButton} from '@material-ui/core';
//import MoreVert from '@material-ui/icons/MoreVert';

//import { VersionUptime } from './VersionUptime'

//export 
class NetworkStatistics_ extends React.Component {

    /*
    constructor(props){
        super(props);
    }
    */

    getNetworkStyle(net){

        let style = {
            background: net.Active? theme.palette.background.active : theme.palette.background.stopped,
        }
        return style
    }

    render(){
        const {t} = this.props

        let statistics = this.props.data
        
        //console.log('Statistics_ this.props')
        //console.log(this.props)

        if(!statistics){
            return(
                <>            
                    No data
                </>

            )
        }else{


            const styles = {
                paper: {
                  width: '100%',
                  overflowX: 'auto',
                },
                head: {
                    color: theme.palette.color.dialogTitle,
                    backgroundColor: theme.palette.background.dialogTitle,
                },
            };


            return(
                <>            
                    
                    <Table>
                        <TableHead>
                                <TableRow>
                                    <TableCell style={styles.head}>{t('Networks')}</TableCell>
                                    <TableCell style={styles.head}>{t('Online')}</TableCell>
                                    <TableCell style={styles.head}>{t('ConnectionsDisconnections')}</TableCell>
                                    <TableCell style={styles.head}>{t('VoiceCalls')}</TableCell>
                                    <TableCell style={styles.head}>{t('VideoCalls')}</TableCell>
                                    <TableCell style={styles.head}>{t('Messages')}</TableCell>
                                    <TableCell style={styles.head}>{t('Emergencies')}</TableCell>
                                    <TableCell style={styles.head}>{t('Locations')}</TableCell>
                                </TableRow>
                        </TableHead>
                        <TableBody>
                            {statistics.FleetNetworks.map((net, id) => 
                                <TableRow key={id+net.Name} 
                                        onClick={()=>{}}
                                        style={this.getNetworkStyle(net)}>
                                    <TableCell component="th" scope="row">
                                    {net.Name}
                                    </TableCell>
                                    <TableCell>
                                        <Box>Dispatchers: {net.DispatchersOnline}</Box>
                                        <Box>Devices: {net.DevicesOnline}</Box>
                                        <Box>DMR: {net.DmrOnline}</Box>
                                    </TableCell>
                                    <TableCell>
                                        <ItemStat itemData={{
                                            Minute: net.Connections.Minute + ' / ' + net.Disconnections.Minute,
                                            Hour: net.Connections.Hour + ' / ' + net.Disconnections.Hour,
                                            Day: net.Connections.Day + ' / ' + net.Disconnections.Day,
                                            Total: net.Connections.Total + ' / ' + net.Disconnections.Total,
                                        }}></ItemStat>
                                    </TableCell>
                                    <TableCell>
                                        <ItemStat itemData={net.VoiceCalls}></ItemStat>
                                    </TableCell>
                                    <TableCell>
                                        <ItemStat itemData={net.VideoCalls}></ItemStat>
                                    </TableCell>

                                    <TableCell>
                                        <ItemStat itemData={net.Messages}></ItemStat>
                                    </TableCell>
                                    <TableCell>
                                        <ItemStat itemData={net.Emergencies}></ItemStat>
                                    </TableCell>
                                    <TableCell>
                                        <ItemStat itemData={net.Locations}></ItemStat>
                                    </TableCell>

                                    {/*}
                                    <TableCell style={{width:40}}>
                                        <IconButton size="small" color="inherit" aria-label="more" component="span"
                                            onClick={event=>this.handleMenuClick(event, net.ID)}>
                                            <MoreVert />
                                        </IconButton>
                                    </TableCell>
                                    */}
                                </TableRow>
                            )}


                        </TableBody>
                    </Table>

                    {/*<VersionUptime data={this.props.data}></VersionUptime>*/}

                </>

            )
        }

    }

}


function ItemStat(props){
   return (
    <>
        <Box>Minute: {props.itemData.Minute}</Box>
        <Box>Hour: {props.itemData.Hour}</Box>
        <Box>Day: {props.itemData.Day}</Box>
        <Box>Total: {props.itemData.Total}</Box>
    </>
   )
}

/*
function Uptime(props){  
    let uptime = props.children/1000;
    let days = Math.floor(uptime/60/60/24);
    let hours = Math.floor( (uptime - days*60*60*24)/60/60 )
    let minutes = Math.floor( (uptime - days*60*60*24 - hours*60*60 )/60 )
    let seconds = Math.floor( (uptime - days*60*60*24 - hours*60*60 - minutes*60 ) )
    let hh = hours.toString().padStart(2, '0')
    let mm = minutes.toString().padStart(2, '0')
    let ss = seconds.toString().padStart(2, '0')
    return(
       <>{days}:{hh}:{mm}:{ss}</> 
    )
}
*/

export const NetworkStatistics = withTranslation()(NetworkStatistics_);