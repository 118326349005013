import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';
import {Table, TableBody, TableCell, TableHead, TableRow, TextField}  from '@material-ui/core';
import {Paper, Grid, IconButton, CircularProgress} from '@material-ui/core';
import { EVENT_STATE, EVENT_TYPE } from '../../api/EventLogger';

import ImagePreview from '../../../components/ImagePreview';
import FilePreview from '../../../components/FilePreview';

import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import BlockIcon from '@material-ui/icons/Block';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import CancelIcon from '@material-ui/icons/Cancel';

import SendIcon from '@material-ui/icons/Send';
import ImageIcon from '@material-ui/icons/Image';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppIcon from '@material-ui/icons/GetApp';

import { lightGreen, grey } from '@material-ui/core/colors';
import { UPLOAD_MAX_FILE_SIZE } from '../../api/ServerApi';
import { getObjectByID } from '../../../api/helpers';
import { formatDateTime } from '../../../api/date';


class Messages_ extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            message: ''
        }

        this.handleMessageChange = this.handleMessageChange.bind(this)
        this.handleSend = this.handleSend.bind(this)

        this.currentdate = 0

        this.fileRef = React.createRef();
        this.imageRef = React.createRef();
    }

    handleSend(){
        
        this.props.onAction({
            type: 'SEND_MESSAGE',
            message: this.state.message,
            userID: this.props.userID,
            deviceID: this.props.deviceID,
            groupID: this.props.groupID,
            targetType: this.props.type,
            networkID: this.props.networkID
        })
        
        this.setState({message: ''})

    }

    handleMessageChange(e){
        
        this.setState({message: e.target.value})
        
    }

    iconState(event){
        if(event.outgoing){
            const style = {
                marginLeft: 5,
                paddingLeft: 5
            }
            switch(event.state){
                case EVENT_STATE.STATE_TEXT_DELIVERED: return <DoneAllIcon style={style} fontSize='small' />
                case EVENT_STATE.STATE_TEXT_INITIATED: return <HourglassEmptyIcon style={style} fontSize='small'/>
                case EVENT_STATE.STATE_TEXT_ACCEPTED: return <DoneIcon style={style} fontSize='small'/>
                case EVENT_STATE.STATE_TEXT_CANCELED: return <BlockIcon style={style} fontSize='small'/>
                default: return ''
            }
        }else{
            const style = {
                marginLeft: 5,
                paddingLeft: 5
            }
            switch(event.state){
                case EVENT_STATE.STATE_TEXT_UNREAD: 
                    if(this.isVisible()){
                        setTimeout(()=>{this.markRead(event.ref_id)}, 5000)
                    }
                    return <AnnouncementOutlinedIcon style={style} fontSize='small'/>
                case EVENT_STATE.STATE_TEXT_READ: return ''
                default: return ''
            }
        }   
        //return ''
    }

    progress(message, isPreview){
        //GetAppIcon
        //return '[' + event.state +']';

        return (
            <>
            {message.file_state===0 || true?
            <IconButton style={{position:'absolute', right:0, bottom: 0, zIndex:1, backgroundColor:'rgba(255,255,255,0.5)', }} 
                size="small" color="primary" aria-label="open" 
                component="span" onClick={event=>this.handleFileLoad(message)}>
                <GetAppIcon fontSize='small'/>
            </IconButton>
            :''}

            {message.file_state===1 || true? 
            <IconButton style={{position:'absolute', right:30, bottom: 0, zIndex:1, backgroundColor:'rgba(255,255,255,0.5)'}} 
                size="small" color="primary" aria-label="cancel" 
                component="span" onClick={event=>this.handleFileCancel(message)}>
                <CancelIcon fontSize='small'/>
            </IconButton>
            :''}

            {message.file_state===1 ? 
            <CircularProgress style={{position:'absolute', left:0, top:0, margin:isPreview?30:5}} />    
            :''}
            </>
        )
    }

    rowStyle(message){
        if(message.outgoing){
            return {
                backgroundColor: lightGreen['A100']
            }
        }
        return {}
    }

    dateRow(event, style){
        const date = new Date(event.datetime)
        const currentdate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()

        if(this.currentdate === currentdate){
            return ''
        } else {
            this.currentdate = currentdate
        }

        return (
            <TableRow>
                <TableCell style={style} colSpan={3} align="center">
                    {formatDateTime(event.datetime, 'long_date')}
                </TableCell>
            </TableRow>
        )
    }

    markRead(jobID){
        if(this.isVisible()){
            const message = getObjectByID(jobID, this.props.messages, 'ref_id')
            if(message && message.state === EVENT_STATE.STATE_TEXT_UNREAD){
                this.props.onAction({
                    type: 'MESSAGE_MARK_READ',
                    jobID: jobID,
                    networkID: this.props.networkID
                })
            }
        }
    }

    isVisible(){
        return this.props.visible
    }

    handleSendFileClick(){       
        const fileInput = this.fileRef.current
        if(fileInput){
            fileInput.click()
        }
    }

    handleSendImageClick(){       
        const fileInput = this.imageRef.current
        if(fileInput){
            fileInput.click()
        }
    }

    handleSendFileChange(){
        const fileInput = this.fileRef.current
        if(fileInput){
            if(fileInput.files){
                const file = fileInput.files[0];
                if(file.size > UPLOAD_MAX_FILE_SIZE){
                    alert('Max file size exceeded: ' + UPLOAD_MAX_FILE_SIZE)
                }else{
                    this.props.onAction({
                        type: 'SEND_FILE',
                        file: file,
                        message: this.state.message,
                        userID: this.props.userID,
                        deviceID: this.props.deviceID,
                        groupID: this.props.groupID,
                        targetType: this.props.type,
                        networkID: this.props.networkID
                    })
                    this.setState({message: ''})
                }
            }
        }
    }

    handleSendImageChange(){
        const fileInput = this.imageRef.current
        if(fileInput){
            if(fileInput.files){
                const file = fileInput.files[0];
                if(file.size > UPLOAD_MAX_FILE_SIZE){
                    alert('Max file size exceeded: ' + UPLOAD_MAX_FILE_SIZE)
                }else{
                    this.props.onAction({
                        type: 'SEND_IMAGE',
                        file: file,
                        message: this.state.message,
                        userID: this.props.userID,
                        deviceID: this.props.deviceID,
                        groupID: this.props.groupID,
                        targetType: this.props.type,
                        networkID: this.props.networkID
                    })
                    this.setState({message: ''})
                }
            }
        }
    }

    messageIcon(message){
        switch(message.type){
            case EVENT_TYPE.TYPE_FILE_PRIVATE:
            case EVENT_TYPE.TYPE_FILE_GROUP:
                return (
                    <IconButton size="small" color="inherit" aria-label="open" 
                        component="span" onClick={event=>this.handleFileOpen(message)}>
                        <AttachFileIcon fontSize='small'/>
                    </IconButton>
                )
            
            case EVENT_TYPE.TYPE_IMAGE_PRIVATE:
            case EVENT_TYPE.TYPE_IMAGE_GROUP:
                return (
                    <IconButton size="small" color="primary" aria-label="open" 
                        component="span" onClick={event=>this.handleFileOpen(message)}>
                        <AttachFileIcon fontSize='small'/>
                    </IconButton>
                )
            default: return ''
        }
    }


    hasPeview(message){
        switch(message.type){
            case EVENT_TYPE.TYPE_IMAGE_PRIVATE:
            case EVENT_TYPE.TYPE_IMAGE_GROUP:
                return true
            default:
                return false
        }
    }

    hasFile(message){
        switch(message.type){
            case EVENT_TYPE.TYPE_FILE_PRIVATE:
            case EVENT_TYPE.TYPE_FILE_GROUP:
                return true
            default:
                return false
        }
    }

    handleFileOpen(message){
        //console.log(message)
        this.props.onAction({
            type: 'FILE_OPEN',
            jobID: message.ref_id,
            networkID: message.network_id
        })
    }

    handleFileLoad(message){
        //console.log(message)
        this.props.onAction({
            type: 'FILE_LOAD',
            jobID: message.ref_id,
            networkID: message.network_id
        })
    }

    handleFileCancel(message){
        //console.log(message)
        this.props.onAction({
            type: 'FILE_CANCEL',
            jobID: message.ref_id,
            networkID: message.network_id
        })
    }

    handlePreviewOpen(message){
        //console.log(message)
        this.props.onAction({
            type: 'PREVIEW_OPEN',
            jobID: message.ref_id,
            networkID: message.network_id
        })
    }

    render(){
        const {t} = this.props 

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            date: {
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                fontWeight: 'bold'
            }
        }

        //console.log(this.props.messages)
        this.currentdate = 0

        return (
            <>
            <Paper style={{...styles.paper, minHeight:200}}>
                <Grid container style={{width:'100%', padding:10}} alignItems="center" spacing={1}>
                    <Grid xs={9} item>
                        <TextField style={{width:'100%'}} value={this.state.message} 
                            variant="outlined"
                            label={t('Message')}
                            onChange={this.handleMessageChange} />
                    </Grid>
                    <Grid xs={3} item>
                        {/*
                        <Button onClick={this.handleSend} color="inherit" variant="contained">{t('Send')}</Button>
                        */}

                        <IconButton color="primary" aria-label="more" title="Send Message"
                            disabled={!this.state.message}
                            onClick={this.handleSend}>
                            <SendIcon />
                        </IconButton>

                        <IconButton aria-label="more" title="Send File"
                            onClick={this.handleSendFileClick.bind(this)}>
                            <DescriptionIcon />
                        </IconButton>

                        <IconButton aria-label="more" title="Send Image"
                            onClick={this.handleSendImageClick.bind(this)}>
                            <ImageIcon />
                        </IconButton>

                        <input type="file" style={{display:'none'}} ref={this.fileRef} 
                            onChange={this.handleSendFileChange.bind(this)} />
                        <input type="file" style={{display:'none'}} ref={this.imageRef} 
                            accept=".gif,.jpg,.jpeg,.png"
                            onChange={this.handleSendImageChange.bind(this)} />

                    </Grid>
                </Grid>

                
                {/*<button onClick={this.handleSendFileClick.bind(this)}>Send file</button>*/}

                <Table size="small">
                    
                    <TableHead>
                        <TableRow>
                            <TableCell style={{...styles.head, width:120}}>{t('Source')}</TableCell>
                            <TableCell style={{...styles.head}}>{t('Message')}</TableCell>
                            <TableCell style={{...styles.head, width:150}}>{/*t('DateTime')*/}</TableCell>          
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
                        {this.props.messages.map(event=>(
                            <React.Fragment key={event.id} >
                                {this.dateRow(event, styles.date)}
                                <TableRow style={this.rowStyle(event)} key={event.id} >
                                    <TableCell>
                                        {event.sender}
                                    </TableCell>
                                    <TableCell>
                                        <Grid container alignItems="center">
                                            {/*
                                            <Grid item>
                                                {this.messageIcon(event)}
                                            </Grid>
                                            */}
                                            
                                            <Grid item style={{marginRight:5}}>
                                                {this.hasPeview(event)?
                                                    <ImagePreview 
                                                        message={event}
                                                        onAction={this.props.onAction}
                                                    ></ImagePreview>
                                                :''}

                                                {this.hasFile(event)?
                                                    <FilePreview 
                                                        message={event}
                                                        onAction={this.props.onAction}
                                                    ></FilePreview>
                                                :''}
                                            </Grid>
                                            
                                            <Grid item>
                                                {event.note}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell style={{color: grey[800], verticalAlign: 'middle'}}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                {formatDateTime(event.datetime, 'short_time')}
                                            </Grid>
                                            <Grid item>
                                                {this.iconState(event)}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                        
                    </TableBody>
                </Table>
            </Paper>
            </>
        )
    }
}

export const Messages = withTranslation()(Messages_);