import React from 'react';
import theme from '../theme';
import PropTypes from 'prop-types';

import { IconButton, CircularProgress} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import GetAppIcon from '@material-ui/icons/GetApp';

export default class ImagePreview extends React.Component {

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        message: PropTypes.object.isRequired,
        onAction: PropTypes.func.isRequired
    };

    static defaultProps = {
        width: 100,
        height: 100
    };

    constructor(props){
        super(props)
        this.state = {
            url: null,
            loading: false,
            hover: false
        }
        this.handleFileOpen = this.handleFileOpen.bind(this)
        this.handleFileLoad = this.handleFileLoad.bind(this)
        this.handleFileCancel = this.handleFileCancel.bind(this)
        this.handleImageLoad = this.handleImageLoad.bind(this)
        this.handleMouseOver = this.handleMouseOver.bind(this)
        this.handleMouseOut = this.handleMouseOut.bind(this)
    }

    componentDidMount(){
        this.props.onAction({
            type: 'PREVIEW_GET',
            jobID: this.props.message.ref_id,
            networkID: this.props.message.network_id,
            onLoad: this.handleImageLoad
        })
        this.setState({loading: true})
    }

    handleImageLoad(url){
        this.setState({url: url, loading: false})
    }

    handleMouseOver(){
        this.setState({hover: true})
    }

    handleMouseOut(){
        this.setState({hover: false})
    }

    handleFileOpen(){
        if(this.props.message.file_state===0||this.props.message.file_state===2){
            this.props.onAction({
                type: 'FILE_OPEN',
                jobID: this.props.message.ref_id,
                networkID: this.props.message.network_id
            })
        }
    }

    handleFileLoad(){
        this.props.onAction({
            type: 'FILE_LOAD',
            jobID: this.props.message.ref_id,
            networkID: this.props.message.network_id
        })
    }

    handleFileCancel(){
        this.props.onAction({
            type: 'FILE_CANCEL',
            jobID: this.props.message.ref_id,
            networkID: this.props.message.network_id
        })
    }

    render(){
        const styles = {
            wrapper: {
                position: 'relative', 
                width:this.props.width+4, 
                height: this.props.height+4,
                border: '2px solid '+ (this.state.hover&&this.state.url?theme.palette.primary.main:'grey')
            },
            image: {
                cursor: 'pointer',
                width: this.props.width,
                height: this.props.height 
            },
            iconLoad: {
                position:'absolute', 
                right:0, 
                bottom: 0, 
                zIndex:1, 
                backgroundColor:'rgba(255,255,255,0.5)'
            },
            progress: {
                position:'absolute', 
                left:0, 
                top:0, 
                margin: (this.props.width-42)/2    
            }
        }

        if(this.state.url){
            return (
                <div style={styles.wrapper}>
                    <img style={styles.image}                     
                        alt=""
                        onClick={this.handleFileOpen}
                        onMouseOver={this.handleMouseOver}
                        onMouseOut={this.handleMouseOut}
                        src={this.state.url}/>

                    {this.props.message.file_state===0?
                    <IconButton style={styles.iconLoad} 
                        size="small" color="primary" aria-label="open" 
                        component="span" onClick={this.handleFileLoad}>
                        <GetAppIcon fontSize='small'/>
                    </IconButton>
                    :''}

                    {this.props.message.file_state===1 || this.props.message.file_state===2? 
                    <IconButton style={styles.iconLoad}
                        size="small" color="primary" aria-label="cancel" 
                        component="span" onClick={this.handleFileCancel}>
                        <CancelIcon fontSize='small'/>
                    </IconButton>
                    :''}

                    {this.props.message.file_state===1 ? 
                    <CircularProgress style={styles.progress} />    
                    :''}
                </div>
            )
        }else{
            return (
            <div style={styles.wrapper}>
                <CircularProgress style={styles.progress} />
            </div>
            )
        }
    }

}