
let instance
let trans // t

export class Settings{

    constructor(){

        if(instance){
            return instance;
        }

        let settings = localStorage.getItem('settings');
        if(settings){
            this.settings = JSON.parse(settings)
        }else{
            this.settings = this.defaultSettings()
        } 

        console.log('Settings constructor')
        console.log(this.settings)


        // Check for the various File API support.
        /*
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            alert('Great success! All the File APIs are supported.');
        } else {
            alert('The File APIs are not fully supported in this browser.');
        }

        if(URL.createObjectURL){

            const octetStreamMime = 'application/octet-stream';
            const textMime = 'text/html';

            var contentType = textMime; //octetStreamMime;

            const data = '12345'
            const blob = new Blob([data], {type: contentType});

            const objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);

            alert('createObjectURL is supported')
        }else{
            alert('createObjectURL is NOT supported')
        }
        */

    }


    defaultSettings(){

        let settings = {
            clearDataDays: 30,
            defaultPollingTime: 30,
            locationValidityTime: 60,   
            showInvalidLocations: true,
            defaultSpeedIn: 'km/h',
            mapLabelColor: '#2966a1',

            clearData: true,
            toneTX: true,
            toneRX: true,
            toneRXStop: true,
            toneBusy: true,
            toneConnect: true,
            toneDisconnect: true,

            autoOpenVideoCall: true,
            pttStyle: 'hold',
            hotKey: 'space'
        }

        return settings
    }

    get(name){
        if(this.settings[name]!==undefined){
            return this.settings[name]
        }else{
            throw new Error('Settings property ' + name + ' is undefined')
        }
    }

    getAll(){
        return this.settings
    }

    save(settings){
        console.log('save')
        console.log(settings)
        this.settings = settings
        localStorage.setItem('settings', JSON.stringify(settings))
    }

}


export function getSettingsInstance(t){
    if(instance){
        return instance
    }else{
        trans = t
        instance = new Settings()
    }
    return instance
}



export function calcSpeed(speed, speedIn){
    switch(speedIn){
        case 'km/h': return speed
        case 'm/s': return speed / 3.6
        case 'mi/h': return speed * 0.621371
        default: console.error('calcSpeed: case for rule ' + speedIn + ' not defined')
    }
}

export function formatSpeed(speed){
    const settings = getSettingsInstance()
    const speedIn = settings.get('defaultSpeedIn')
    return calcSpeed(speed, speedIn).toFixed(1) + '' + trans(speedIn)
}