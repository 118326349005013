import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {Container, Box} from '@material-ui/core'
import {Button, FormControl, Checkbox, TextField, InputLabel, MenuItem, Select } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import GroupIcon from '@material-ui/icons/Group';

import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';

class EditProfileDialog_ extends React.Component{

    constructor(props){
        super(props)

        const {profile} = this.props; 
        this.state = {
            profile: {
                Name: profile.ProfileID ? profile.Name : '',
                Description: profile.Description ? profile.Description : '',
                Profile: profile.ProfileID ? {...this.getProfileDefaults(),  ...JSON.parse(profile.Profile)}: {}
            },
            errors:{
                Name: false,
                Description: false,
                ManDownTimeout: false
            },
            changed: false
        }
        console.log(this.state)
    }

    getProfileDefaults(Profile){
        return {
            Receivers: [],
            ScreenSOS: false,
            ManDown: false,
            EmergencyVoiceCall: false,
            EmergencyGroupId: null,
            ManDownTimeout: 5
        }
    }

    handleSave(){
        if(this.validate()){
            let Profile = JSON.stringify(this.state.profile.Profile);
            let profile = {...this.props.profile, ...this.state.profile, Profile: Profile}
            this.props.onAction({type: 'PROFILE_EDITOR_SAVE', profile: profile})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'PROFILE_EDITOR_CLOSE'})
    }   

    handleInput(event){
        if(!this.state.changed) this.setState({changed: true})

        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const profile = {...this.state.profile, [event.target.name]: val}
        this.setState({profile: profile})
    }

    handleInputProfile(event){
        if(!this.state.changed) this.setState({changed: true})

        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value

        let Profile = {...this.state.profile.Profile, [event.target.name]: val}

        const profile = {...this.state.profile, Profile: Profile}
        this.setState({profile: profile})
    }

    validate(){
        let errors = this.state.errors;
        let error = false
     
        if(!this.state.profile.Name){
            errors.Name = this.props.t('NameIsEmpty')
            error = true
        }else{
            errors.Name = false
        }
        this.setState({errors: errors})

        return !error;
    }

    getReceiverName(ID){
        let receivers = this.props.receivers.filter(item=>item.ID===ID)
        if(receivers.length){
            let icon = this.getReceiverIcon(receivers[0].Type)
             return (<>{icon} {receivers[0].Name}</>)
        }
        return 'undefined '+ID
    }

    getReceiver(ID){
        let receivers = this.props.receivers.filter(item=>item.ID===ID)
        if(receivers.length) return receivers[0]
        return null;
    }

    isReceiverChecked(ID){
        let receivers = this.state.profile.Profile.Receivers.filter(item=>item.ID===ID)
        return receivers.length>0
    }

    handleReceiversChange(event){
        let receivers = []
        console.log(event.target.value)
        for(let i=0; i<event.target.value.length; i++){
            const ID = event.target.value[i]
            const receiver = this.getReceiver(ID)
            if(receiver){
                receivers.push({ID: receiver.ID, Type: receiver.Type})
            }
        }

        let profile = this.state.profile
        profile.Profile.Receivers = receivers;
        this.setState({profile: profile});
    }

    getReceiverType(ID){
        let receiver = this.getReceiver(ID)
        return receiver.Type
    }

    getReceiverIcon(type){
        switch(type){
            case 10: return <PersonOutlineIcon></PersonOutlineIcon>
            case 4: return <GroupIcon></GroupIcon>
            case 3: return <PersonIcon></PersonIcon>
            default: return ''
        }
    }

    render(){
        const {t} = this.props

        const title = this.props.action==='add'?t('NewProfile'):t('EditProfile')

        const margin = {margin:'16px 0 8px 0'}

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>
            <Box textAlign="">
                <TextField name="Name"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.Name?this.state.errors.Name:t('Name')}
                    autoComplete="off"
                    error={this.state.errors.Name!==false}
                    value={this.state.profile.Name}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
            
            <Box textAlign="">
                <TextField name="Description"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.Description?this.state.errors.Description:t('Description')}
                    autoComplete="off"
                    error={this.state.errors.Description!==false}
                    value={this.state.profile.Description}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>

            <Box textAlign="">
            <FormControl style={{...margin, width: '100%'}}>
                <InputLabel htmlFor="select-multiple-chip">{t('Receivers')}</InputLabel>
                <Select
                    style={{width: '100%'}}
                    multiple
                    onChange={this.handleReceiversChange.bind(this)}
                    value={this.state.profile.Profile.Receivers.map(item=>item.ID)}
                    input={<Input id="select-multiple-chip" />}
                    MenuProps={{
                        PaperProps: {
                            style: {
                              maxHeight: '20em',
                              width: '100%',
                            },
                          }
                    }}
                    renderValue={selected => (
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {selected.map(value => (
                            <Chip key={value} label={this.getReceiverName(value)} style={{margin:2}} />
                        ))}
                        </div>
                    )}
                    
                    >
                    {this.props.receivers.map(item => (
                        <MenuItem key={item.ID} value={item.ID} name={item.Name}>
                            {this.getReceiverIcon(item.Type)}
                            <Checkbox checked={this.isReceiverChecked(item.ID)} />
                            <ListItemText primary={item.Name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.profile.Profile.ScreenSOS}
                            onChange={this.handleInputProfile.bind(this)}
                            name="ScreenSOS"
                            />
                            {t('SosButtonOnTheScreen')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.profile.Profile.EmergencyVoiceCall}
                            onChange={this.handleInputProfile.bind(this)}
                            name="EmergencyVoiceCall"
                            />
                            {t('StartVoiceCallToEmergencyGroup')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="">
            <FormControl style={{...margin, width: '100%'}}>
                <InputLabel htmlFor="EmergencyGroupId">{t('EmergencyGroup')}</InputLabel>
                <Select
                    value={this.state.profile.Profile.EmergencyGroupId}
                    onChange={this.handleInputProfile.bind(this)}
                    inputProps={{
                        name: 'EmergencyGroupId',
                        id: 'EmergencyGroupId',
                    }}
                    >
                    <MenuItem key="0" value={null}></MenuItem>
                    {this.props.emergencyGroups.map(
                        item => <MenuItem key={item.ID} value={item.ID}>{item.Name}</MenuItem>
                    )}
                </Select>
            </FormControl>
            </Box>


            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.profile.Profile.ManDown}
                            onChange={this.handleInputProfile.bind(this)}
                            name="ManDown"
                            />
                            {t('ManDown')}
                    </label>
                </FormControl>
            </Box>
            
            <Box textAlign="">
                <TextField name="ManDownTimeout"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.ManDownTimeout?this.state.errors.Description:t('ManDownTimeout')}
                    autoComplete="off"
                    error={this.state.errors.Description!==false}
                    value={this.state.profile.Profile.ManDownTimeout}
                    onChange={this.handleInputProfile.bind(this)}
                />
                {t('TimeoutBeforeSendingEmergencyOnManDownS')}
            </Box>
        
        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const EditProfileDialog = withTranslation()(EditProfileDialog_);