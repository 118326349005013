var q = function(d) {
    function e(a) {
        a = -32768 === a ? -32767 : a;
        var c = ~a >> 8 & 128;
        c || (a *= -1);
        32635 < a && (a = 32635);
        if (256 <= a) {
            var b = k[a >> 8 & 127];
            a = b << 4 | a >> b + 3 & 15
        } else
            a >>= 4;
        return a ^ c ^ 85
    }
    function f(a) {
        var c = 0;
        a ^= 85;
        let x = a & 128 && (a &= -129, 
        c = -1);
        var b = ((a & 240) >> 4) + 4;
        a = 4 != b ? 1 << b | (a & 15) << b - 4 | 1 << b - 5 : a << 1 | 1;
        return -8 * (0 === c ? a : -a)
    }
    function g(a) {
        var c = a >> 8 & 128;
        0 != c && (a = -a);
        32635 < a && (a = 32635);
        a += 132;
        var b = l[a >> 7 & 255];
        return ~(c | b << 4 | a >> b + 3 & 15)
    }
    function h(a) {
        a = ~a;
        var c = a >> 4 & 7;
        c = m[c] + ((a & 15) << c + 3);
        0 != (a & 128) && (c = -c);
        return c
    }
    var k = [1, 1, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7]
      , n = Object.freeze({
        encodeSample: e,
        decodeSample: f,
        encode: function(a) {
            for (var c = new Uint8Array(a.length), b = 0; b < a.length; b++)
                c[b] = e(a[b]);
            return c
        },
        decode: function(a) {
            for (var c = new Int16Array(a.length), b = 0; b < a.length; b++)
                c[b] = f(a[b]);
            return c
        }
    })
      , l = [0, 0, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7]
      , m = [0, 132, 396, 924, 1980, 4092, 8316, 16764]
      , p = Object.freeze({
        encodeSample: g,
        decodeSample: h,
        encode: function(a) {
            for (var c = new Uint8Array(a.length), b = 0; b < a.length; b++)
                c[b] = g(a[b]);
            return c
        },
        decode: function(a) {
            for (var c = new Int16Array(a.length), b = 0; b < a.length; b++)
                c[b] = h(a[b]);
            return c
        }
    });
    d.alaw = n;
    d.mulaw = p;
    return d
}({});

export const alawmulaw = q;
