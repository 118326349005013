import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import Paper from '@material-ui/core/Paper';
import { Grid, Typography, Divider, IconButton } from '@material-ui/core';
//import { getObjectByID } from '../../../api/helpers';

import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';


export default class EmergencyAlerts_ extends React.Component{

    constructor(props){
        super(props)
        const alerts = [...this.props.alerts]
        alerts.reverse()

        this.state = {alerts: alerts}
    }


    componentDidUpdate(prevProps, prevState){
        if(prevProps.alerts !== this.props.alerts){
            const alerts = [...this.props.alerts]
            alerts.reverse()

            this.setState({alerts: alerts})
        }
    }

    handleSendAck(emergency){
        this.props.onAction({
            type: 'SEND_EMERGENCY_ACK',
            emergencyID: emergency.EmergencyID,
        })

    }

    handleCancel(emergency){
        this.props.onAction({
            type: 'CANCEL_EMERGENCY',
            emergencyID: emergency.EmergencyID,
        })
    }

    handleLocate(emergency){
        this.props.onAction({
            type: 'LOCATE_EMERGENCY',
            emergencyID: emergency.EmergencyID,
        })
    }

    render(){
        const {t} = this.props

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'none',
              margin: 'auto',
              padding: theme.spacing(2)
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            gridWrapper: {
                width: '100%',
                paddingBottom: 5
            },
            gridRow: {
            },
            cell: {
                margin: '0 2px',
                textAlign: 'center',
            },
            cellHeader: {
                //background: theme.palette.primary.main,
                color: 'white',
                textAlign: 'center',
                padding: '3px 5px',
                fontWeight: 'bold'
            },
            cellHeader2: {
                //background: theme.palette.primary.main,
                color: 'white',
                textAlign: 'center',
                padding: '3px 5px'
            },
            icon: {
                color: 'white'
            }
        };

        if(this.state.alerts.length){

            return(
                <>
                    <Paper style={styles.paper}>
                    {this.state.alerts.map((alert, index) => {
                        //const network = getObjectByID(alert.NetworkID, this.props.networks)
                        //let user = null
                        //let profile = null
                        //if(network){
                        //    profile = getObjectByID(alert.ProfileID, network.profiles, 'ProfileID')
                        //}
                        return(
                        <>
                        <Grid key={alert.CallID} 
                            style={{...styles.gridWrapper, backgroundColor:'red'}}>

                            <Grid style={styles.gridRow} container alignItems="center">
                                <Grid style={styles.cellHeader}  item xs>
                                    {alert.UserName}
                                </Grid>
                            </Grid>

                            <Grid style={styles.gridRow} container alignItems="center">
                                <Grid style={styles.cellHeader2}  item xs>
                                    SOS
                                </Grid>
                            </Grid>

                            <Grid style={styles.gridRow} container alignItems="center">
                                <Grid style={styles.cell} item xs>
                                    <IconButton onClick={()=>this.handleLocate(alert)}>
                                        <GpsFixedIcon style={styles.icon}/>
                                    </IconButton>
                                </Grid>
                                <Grid style={styles.cell} item xs>
                                    <IconButton onClick={()=>this.handleSendAck(alert)}>
                                        <CheckCircleIcon style={styles.icon}/>
                                    </IconButton>
                                </Grid>
                                <Grid style={styles.cell} item xs>
                                    <IconButton onClick={()=>this.handleCancel(alert)}>
                                        <CancelIcon style={styles.icon}/>
                                    </IconButton>
                                </Grid>
                            </Grid>


                        </Grid>
                        
                        {index+1 < this.state.alerts.length ?
                            <Divider style={{margin:5}}/>
                        :''} 
                        
                        </>
                        )
                    })}
                    </Paper>
                   
                </>
            )

        }else{

            return (
                <Typography variant="h5" color="secondary" 
                    style={{width:'100%', height:'100%'}} align="center">
                    {t('Alerts')}
                </Typography>
            )

        }


    }

}

export const EmergencyAlerts = withTranslation()(EmergencyAlerts_);