import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';
import {Table, TableBody, TableCell, TableRow}  from '@material-ui/core';
import {Paper, Menu, MenuItem, IconButton, Button} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { sortByName } from '../../../api/helpers';
import GroupIcon from '@material-ui/icons/Group';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

import { PTT_CONTROL_TYPES } from '../../api/ServerApi';

export default class GroupsList_ extends React.Component{

    constructor(props){
        super(props);

        this.state = {menuAnchor: null, menuGroup: ''}

        this.pttGroup = null
        this.handlePttUp = this.handlePttUp.bind(this)
    }

    handleGroupSelect(groupID){
        this.props.onGroupSelect(groupID)
    }

    getGroupStyle(groupID){
        let selected = groupID === this.props.selectedGroupID
        let style = {
            background: selected? theme.palette.background.selectedRow : '',
            cursor: 'pointer'
        }
        return style
    }

    formatPriority(priority){
        switch(priority){
            case 0: return this.props.t('Default') 
            case -1: return priority
            default: return '+'+priority
        }
    }

    handleMenuClick(event, group){
        this.setState({menuAnchor: event.currentTarget, menuGroup: group});
        event.stopPropagation();
    }

    handleMenuClose(){
        //this.menuAnchor = null;
        this.setState({menuAnchor: null, menuGroup: null});
        return null;
    }

    handleMenuItemClick(menu){
        const group = this.state.menuGroup
        this.handleMenuClose();
        this.props.onContextMenu(menu, group)
    }

    displayGps(group){
        return Boolean(group.locationRequest)
    }

    getGpsIconColor(group){
        const color = 'disabled'
        return color
    }

    handlePttDown(group){
        document.addEventListener('mouseup', this.handlePttUp)
        this.pttGroup = group

        this.props.onContextMenu('ptt_start', group)
    }

    handlePttUp(){
        console.log('handlePttUp')
        this.props.onContextMenu('ptt_stop', this.pttGroup)

        document.removeEventListener('mouseup', this.handlePttUp)
        this.pttGroup = null
    }

    pttColor(group){
        if(group.PttStatus){
            switch(group.PttStatus.Control){
                case PTT_CONTROL_TYPES.VOICE_GROUP_ENTER: return 'lightblue';
                case PTT_CONTROL_TYPES.VOICE_GROUP_PRESSED: return group.PttStatus.Incoming ? 'lightgreen':'orange';
                case PTT_CONTROL_TYPES.VOICE_GROUP_RELEASED: return 'lightblue';
                case PTT_CONTROL_TYPES.VOICE_GROUP_END: return '';
                case PTT_CONTROL_TYPES.VOICE_GROUP_BEGIN: return '';
                default: console.error('pttColor: case not defined for ptt status'+group.PttStatus.Control)
            }
        }else{
            return ''
        }
    }

    menuIsMuted(){
        //debugger
        return this.state.menuGroup && this.state.menuGroup.SoundIsMuted!==undefined ? this.state.menuGroup.SoundIsMuted : false
    }


    render(){
        const {t} = this.props
        this.props.groups.sort(sortByName)

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            }
        };

        return(
            <>
                <Paper style={styles.paper}>
                    <Table size="small">
                        <TableBody>
                        {this.props.groups.map((group) => 
                            <TableRow key={group.ID} 
                                    onClick={()=>{this.handleGroupSelect(group.ID)}}
                                    style={this.getGroupStyle(group.ID)}>
                                <TableCell style={{width:40, position:'relative'}} >
                                    <GroupIcon color="primary"/>
                                    {group.SoundIsMuted?<VolumeOffIcon fontSize="small" color="error"
                                        style={{position: 'absolute', top: 18, left: 13}} />:''}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {group.Name}
                                </TableCell>
                                <TableCell style={{width:30}}>                                    
                                    {
                                        (this.displayGps(group))?
                                        <GpsFixedIcon color={this.getGpsIconColor(group)}/>
                                        :
                                        ''
                                    }
                                </TableCell>  
                                <TableCell style={{width:30}}>
                                    <Button style={{minWidth:48, background: this.pttColor(group)}}
                                        onMouseDown={event=>this.handlePttDown(group)}
                                        >PTT</Button>
                                </TableCell>
                                <TableCell style={{width:40}}>
                                    <IconButton size="small" color="inherit" aria-label="more" 
                                        component="span"
                                        onClick={event=>this.handleMenuClick(event, group)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                        <Menu anchorEl={this.state.menuAnchor} keepMounted
                            open={Boolean(this.state.menuAnchor)} 
                            onClose={this.handleMenuClose.bind(this)}>
                            
                            <MenuItem onClick={()=>{this.handleMenuItemClick('mute')}}>
                                {this.menuIsMuted() ? t('Unmute') : t('Mute')}</MenuItem>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('messages')}}>{t('Messages')}</MenuItem>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('findonmap')}}>{t('FindOnMap')}</MenuItem>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('properties')}}>{t('Properties')}</MenuItem>
                        </Menu>
                    </Table>
                </Paper>
            </>
        )

    }

}

export const GroupsList = withTranslation()(GroupsList_);