import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';
import {Table, TableBody, TableCell, TableHead, TableRow}  from '@material-ui/core';
import {Paper, Menu, MenuItem, IconButton} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { sortByName } from '../../../api/helpers';
import { PERMISSIONS } from '../../api/ServerApi';



class NetworksList_ extends React.Component{
    
    constructor(props){
        super(props);

        this.state = {menuAnchor: null, menuNetworkID: ''}
    }

    handleNetworkSelect(networkID){
        //console.log('select network '+networkID)
        this.props.onNetworkSelect(networkID)
    }

    getNetworkStyle(networkID){
        let selected = networkID === this.props.selectedNetworkID
        let style = {
            background: selected? theme.palette.background.selectedRow : '',
            cursor: 'pointer'
        }
        return style
    }

    handleMenuClick(event, networkID){
        this.setState({menuAnchor: event.currentTarget, menuNetworkID: networkID});
        event.stopPropagation();
    }

    handleMenuClose(){
        this.menuAnchor = null;
        this.setState({menuAnchor: null, menuNetworkID: null});
        return null;
    }

    handleMenuItemClick(menu){
        const netID = this.state.menuNetworkID
        this.handleMenuClose();
        this.props.onNetworkMenu(menu, netID)
    }

    checkPermission(permission){
        return this.props.onAction({
            type:'CHECK_PERMISSION', 
            networkID: this.state.menuNetworkID, 
            permission: permission 
        })
    }

    render(){
        const {t} = this.props

        this.props.networks.sort(sortByName)

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
        };

        return(
            <>
                <Paper style={styles.paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} style={styles.head}>{t('Networks')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.networks.map((net) => 
                            <TableRow key={net.ID} 
                                    onClick={()=>{this.handleNetworkSelect(net.ID)}}
                                    style={this.getNetworkStyle(net.ID)}>
                                <TableCell component="th" scope="row">
                                  {net.Name}
                                </TableCell>
                                {/*
                                <TableCell>
                                  {net.CommandPort}
                                </TableCell>
                                <TableCell>
                                    {net.devices?net.devices.length:0}
                                </TableCell>
                                 */}
                                <TableCell style={{width:40}}>
                                    <IconButton size="small" color="inherit" aria-label="more" component="span"
                                        
                                        onClick={event=>this.handleMenuClick(event, net.ID)}>
                                        <MoreVert />
                                    </IconButton>
                                    
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                        <Menu anchorEl={this.state.menuAnchor} keepMounted
                            open={Boolean(this.state.menuAnchor)} 
                            onClose={this.handleMenuClose.bind(this)}>
                            <MenuItem 
                                onClick={()=>{this.handleMenuItemClick('edit')}}
                                disabled={!this.checkPermission(PERMISSIONS.OP_MANAGEUSERSGROUPS)}
                            >{t('EditNetwork')}</MenuItem>
                            {/*<MenuItem onClick={()=>{this.handleMenuItemClick('properties')}}>Properties...</MenuItem>*/}
                        </Menu>
                    </Table>
                </Paper>
            </>
        )

    }

}

export const NetworksList = withTranslation()(NetworksList_);