import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Table, TableBody, TableCell, TableHead, TableRow, Typography}  from '@material-ui/core';
import {Box, Grid} from '@material-ui/core';

//import { VersionUptime } from './VersionUptime'

import { formatDateTime } from '../../../api/date'

//export 
class TotalStatistics_ extends React.Component {


    render(){

        const {t} = this.props

        if(!this.props.data){
            return(<>{t('NoData')}</>)
        }

        const net = this.props.data.FleetNetworksSummary
        const hardware = this.props.data.PerformanceCounters
        const dmr = this.props.data.DmrNetworks
        const alarms = this.props.data.Alarms
        //const dmr = undefined

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head1: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            head2: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogSubTitle,
            },
            alarm: {
                color: theme.palette.color.alarm,
                fontWeight: 'bold'
            }
        };

        let connectDisconnect = {
            Minute: net.Connections.Minute + ' / ' + net.Disconnections.Minute,
            Hour: net.Connections.Hour + ' / ' + net.Disconnections.Hour,
            Day: net.Connections.Day + ' / ' + net.Disconnections.Day,
            Total: net.Connections.Total + ' / ' + net.Disconnections.Total,
        }

        return (

            <>
            
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={7} style={styles.head1}>{t('PocActivity')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell style={styles.head2}>{t('OnlineConnections')}</TableCell>
                            <TableCell style={styles.head2}>{t('ConnectionsDisconnections')}</TableCell>
                            <TableCell style={styles.head2}>{t('VoiceCalls')}</TableCell>
                            <TableCell style={styles.head2}>{t('VideoCalls')}</TableCell>
                            <TableCell style={styles.head2}>{t('Messages')}</TableCell>
                            <TableCell style={styles.head2}>{t('Emergencies')}</TableCell>
                            <TableCell style={styles.head2}>{t('Locations')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <Box>{t('Devices')}: {net.DevicesOnline}</Box>
                                <Box>{t('Dispatchers')}: {net.DispatchersOnline}</Box>
                                <Box>{t('DMR')}: {net.DmrOnline}</Box>
                            </TableCell>
                            <TableCell>
                                <ItemStat itemData={connectDisconnect}></ItemStat>
                            </TableCell>
                            <TableCell>
                                <ItemStat itemData={net.VoiceCalls}></ItemStat>
                            </TableCell>
                            <TableCell>
                                <ItemStat itemData={net.VideoCalls}></ItemStat>
                            </TableCell>
                            <TableCell>
                                <ItemStat itemData={net.Messages}></ItemStat>
                            </TableCell>
                            <TableCell>
                                <ItemStat itemData={net.Emergencies}></ItemStat>
                            </TableCell>
                            <TableCell>
                                <ItemStat itemData={net.Locations}></ItemStat>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>

                {dmr && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={8} style={styles.head1}>{t('DmrNetworks')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dmr && dmr.map((dmrItem, id) => 
                                
                                <DmrInfo data={dmrItem} t={t}></DmrInfo>
                                
                                )}
                    </TableBody>
                </Table>
                )}

                               


                <Grid  key="4" container direction="row" spacing={1} style2={{padding:5, border: '1px solid red'}}>
                            
                    <Grid item xs={6} style2={{padding:5, border: '1px solid green'}}>
                    
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={8} style={styles.head1}>{t('Alarms')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={styles.head2}>{t('Time')}</TableCell>
                                    <TableCell style={styles.head2}>{t('AlarmType')}</TableCell>
                                    <TableCell style={styles.head2}>{t('Description')}</TableCell>
                                    <TableCell style={styles.head2}>{t('ObjectType')}</TableCell>
                                    <TableCell style={styles.head2}>{t('ObjectName')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {alarms && alarms.map((alarm, id) => 
                                <TableRow>
                                    <TableCell><DateTimeFormat>{alarm.Time}</DateTimeFormat></TableCell>
                                    <TableCell>{alarm.AlarmType}</TableCell>
                                    <TableCell>{alarm.Description}</TableCell>
                                    <TableCell>{alarm.ObjectType}</TableCell>
                                    <TableCell>{alarm.ObjectName}</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table>

                    </Grid>


                    <Grid item xs={6} style2={{padding:5, border: '1px solid blue'}}>
                    
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2} style={styles.head1}>{t('ServerHardware')}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell style={styles.head2}>{t('CpuUtilization')}</TableCell>
                                    <TableCell style={hardware.ProcessorTotalAlarm?styles.alarm:{}}>{hardware.ProcessorTotal}%</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={styles.head2}>{t('MemoryUtilization')}</TableCell>
                                    <TableCell style={hardware.MemoryTotalAlarm?styles.alarm:{}}>
                                        {Math.round(100*hardware.MemoryUsed/hardware.MemoryTotal)}%</TableCell>
                                </TableRow>
        
                                <TableRow>
                                    <TableCell style={styles.head2}>{t('MemoryUsed')}</TableCell>
                                    <TableCell >{hardware.MemoryUsed} {t('Mb')} / {hardware.MemoryTotal} {t('Mb')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={styles.head2}>{t('Disks')}</TableCell>
                                    <TableCell>
                                        {hardware.DiskCounters.map((disk, id) => 
                                        
                                        <DiskInfo disk={disk}></DiskInfo>
                                        
                                        )}
                                    </TableCell> 
                                </TableRow>

                                <TableRow>
                                    <TableCell style={styles.head2}>{t('Network')}</TableCell>
                                    <TableCell>
                                        {hardware.NetworkAdapterCounters.map((item, id) => 
                                        
                                        <NetworkAdapterInfo data={item}></NetworkAdapterInfo>
                                        
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                                
                        </Table>
                    
                    </Grid>
                </Grid>


                


                {/*<VersionUptime data={this.props.data}></VersionUptime>*/}


            </>

        )
    }

}

function ItemStat(props){
    return (
     <>
         <Box>Minute: {props.itemData.Minute}</Box>
         <Box>Hour: {props.itemData.Hour}</Box>
         <Box>Day: {props.itemData.Day}</Box>
         <Box>Total: {props.itemData.Total}</Box>
     </>
    )
 }

function DiskInfo_(props){
    const alarm = {
        color: theme.palette.color.alarm,
        fontWeight: 'bold'
    }
    const t = props.t
    return (
        <Typography>
            <Typography component="span">{props.disk.Letter} </Typography>
            <Typography component="span">{t('Total')}: {gb(props.disk.TotalSpace)} {t('Gb')} </Typography>
            <Typography component="span" style={props.disk.FreeSpaceAlarm?alarm:{}}>{t('Free')}: {gb(props.disk.FreeSpace)} {t('Gb')} </Typography>
            <Typography component="span" style={props.disk.FreeSpaceAlarm?alarm:{}}>{t('Usage')}: {Math.round(100*(props.disk.TotalSpace-props.disk.FreeSpace)/props.disk.TotalSpace)}% </Typography>
            <Typography component="span" style={props.disk.LoadAlarm?alarm:{}}>{t('Load')}: {props.disk.Load}% </Typography>
        </Typography>
    )
}
const DiskInfo = withTranslation()(DiskInfo_);

function DateTimeFormat(props){
    //return props.children

    //const d = new Date(props.children);

    const d = formatDateTime(props.children)
    //console.log('DateTimeFormat')
    //console.log(props.children)
    //console.log(d)

    return (<>{d}</>)
}

function NetworkAdapterInfo_(props){
    const alarm = {
        color: theme.palette.color.alarm,
        fontWeight: 'bold'
    }
    const t = props.t
    return (
        <Typography>
            <Typography component="span">{props.data.Name} </Typography>
            <Typography component="span">{props.data.Bandwidth} {t('Mbit')} </Typography>
            <Typography component="span" style={props.data.LoadAlarm?alarm:{}}>{t('Load')}: {props.data.Load}% </Typography>
        </Typography>
    )
}
const NetworkAdapterInfo = withTranslation()(NetworkAdapterInfo_);

function DmrInfo(props){
    const {t} = props;
    let styles = {
        online: {width:175, height:'5em', 
            backgroundColor: 'lightgreen', 
            border:'1px solid grey', 
            display:'inline-block',
            textAlign: 'center',
            margin: 5,
            padding: 10
        },
        offline: {width:175, height:'5em', 
            backgroundColor: 'lightgrey', 
            border:'1px solid grey', 
            display:'inline-block',
            textAlign: 'center',
            margin: 5,
            padding: 10
        },
        statusOnline: {},
        statusOffline: {color: 'red'},
    }
    const boxStyle = props.data.Connected ? styles.online : styles.offline
    const status = props.data.Connected ? t('online') : t('offline');
    const statusStyle = props.data.Connected ? styles.statusOnline : styles.statusOffline
    return (
        <div style={boxStyle}>
            <Box style={statusStyle}>{status}</Box>
            <Box>{props.data.Name}</Box>
        </div>
    )
}

function gb(value){
    return Math.round(value/1000)
}

export const TotalStatistics = withTranslation()(TotalStatistics_);