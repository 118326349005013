//import {alawmulaw} from './alawmulaw'

import AudioProcessor from '../../api/AudioProcessor'
import { EVENT_TYPE, EVENT_STATE } from './EventLogger'
//import * as dev from './dev'
import { getSettingsInstance } from './Settings'

import { getFileChunk, getFileMimeType } from '../../api/file'


import { getBase64ID, getObjectByID, getIndexByID, deleteItemByID, getDeviceID } from '../../api/helpers';
import { base64toGuid, base64ToUint8Array, uint8ArrayToBase64 } from '../../api/helpers';

//FLEET_DATA_OP_TYPE
const OP_INITIALIZE = 0
const OP_ADD = 1
const OP_REMOVE = 2
const OP_CHANGE = 3

//FLEET_TYPES
//const TYPE_UNKNOWN = 0
const TYPE_DEVICES = 10
const TYPE_USERS = 11
const TYPE_GROUPS = 12
const TYPE_STATUSES = 13
//const TYPE_PASSWORD = 14
const TYPE_LOCATION_REQUESTS = 15
const TYPE_QUERYAVATAR = 20
const TYPE_AVATARRESPONSE = 21
//const TYPE_QUERYLOGO = 22
//const TYPE_LOGORESPONSE = 23
//const TYPE_MEDIA_CONTROL = 25
const TYPE_EMERGENCY_PROFILES = 30
const TYPE_NETWORKS = 100
const TYPE_NETWORKSETTINGS = 110
//const TYPE_NEWNETWORKSETTINGS = 120
//const TYPE_SERVERSETTINGS = 130
//const TYPE_COMPOUND = 255

export const FILESTORAGE_MAX_FILE_SIZE = 1024*1024*20 //20Mb - limit for indexed db file storage
export const DOWNLOAD_MAX_FILE_SIZE = 1024*1024*100 //100Mb - limit for file download
export const UPLOAD_MAX_FILE_SIZE = 1024*1024*100 //100Mb - limit for file upload

export const PTT_REQUEST_TYPES = {
    PRIVATE_VOICE_CALL_START: 0,// = Start private voice call
    PRIVATE_VOICE_CALL_STOP: 1, //  = Stop private voice call        
    GROUP_VOICE_CALL_START: 2, // = Start group voice call
    GROUP_VOICE_CALL_STOP: 3, // = Stop group voice call
    PRIVATE_VIDEO_CALL_START: 4, // = Start private video call
    PRIVATE_VIDEO_CALL_STOP: 5, // = Stop private video call        
    GROUP_VIDEO_CALL_START: 6, // = Start group video call
    GROUP_VIDEO_CALL_STOP: 7, // = Stop group video call
}


export const PTT_CONTROL_TYPES = {
    VOICE_PRIVATE_BEGIN: 0, //Private voice call initiated by SourceID
    VOICE_PRIVATE_ENTER: 9, //Client enters active call (see notes below)
    VOICE_PRIVATE_PRESSED: 1, //SourceID start sending voice RTP data
    VOICE_PRIVATE_RELEASED: 2, //SourceID stops sending voice RTP data. Parties participating in the call can respond along private call hangtime defined in server settings.
    VOICE_PRIVATE_END: 3, //Private voice call ended
    VOICE_GROUP_BEGIN: 4, //Group voice call initiated by SourceID
    VOICE_GROUP_ENTER: 10, //Client enters active call (see notes below)
    VOICE_GROUP_PRESSED: 5, //SourceID start sending voice RTP data
    VOICE_GROUP_RELEASED: 6, //SourceID stops sending voice RTP data. Parties participating in the call can respond along group call hangtime defined in server settings.
    VOICE_GROUP_END: 7, //Group voice call ended
    VIDEO_PRIVATE_BEGIN: 11, // (VIDEO_PRIVATE_BEGIN)
    VIDEO_PRIVATE_ENTER: 20, // (VIDEO_PRIVATE_ENTER)
    VIDEO_PRIVATE_PRESSED: 12, //(VIDEO_PRIVATE_PRESSED)
    VIDEO_PRIVATE_RELEASED: 13, //(VIDEO_PRIVATE_RELEASED)
    VIDEO_PRIVATE_END: 14, //(VIDEO_PRIVATE_END)
    VIDEO_GROUP_BEGIN: 15, //(VIDEO_GROUP_BEGIN)
    VIDEO_GROUP_ENTER: 21, //(VIDEO_GROUP_ENTER)
    VIDEO_GROUP_PRESSED: 16, //(VIDEO_GROUP_PRESSED)
    VIDEO_GROUP_RELEASED: 17, //(VIDEO_GROUP_RELEASED)
    VIDEO_GROUP_END: 18 //(VIDEO_GROUP_END)
}

export const JOB_STATES = {
    JOB_STATE_FAIL: -10,
    JOB_STATE_NONE: 0,
    JOB_STATE_SENDING: 10,
    JOB_STATE_SENDED: 20,
    JOB_STATE_CONFIRMING: 30,
    JOB_STATE_LOST: 40,
    JOB_STATE_CONFIRMED: 50,
    JOB_STATE_DELIVERED: 60,
    JOB_STATE_DELIVERED_CONFIRMED: 70,
    JOB_STATE_CANCELLED: 80,
    JOB_STATE_CANCELLED_CONFIRMED: 90
}

export const PERMISSIONS = { 
    // 1892549074
    //OP_DEFAULTSET: OP_VOICECALL_PTT | OP_VIDEOCALL_PTT | OP_TEXTMESSAGE | OP_LOCATIONTRACKING | OP_CALLALERT | OP_SHOWEVENTLOG | OP_SHOWSEARCH | OP_SHOWSETTINGS | OP_MONITORING | OP_CALLRECORDINGREPORT | OP_GUARDTOUR | OP_GROUPPATCHES | OP_ALLOWUSERINVENTORYREPORT,
    OP_VOICECALL: 1,                           // 2 ^ 0
    OP_VOICECALL_PTT: 2,                       // 2 ^ 1
    OP_VIDEOCALL: 8,                           // 2 ^ 3
    OP_VIDEOCALL_PTT: 16,                      // 2 ^ 4
    OP_TEXTMESSAGE: 64,                        // 2 ^ 6
    OP_LOCATIONTRACKING: 128,                  // 2 ^ 7
    OP_CALLALERT: 256,                         // 2 ^ 8
    //bits 11, 12 reserved for view mode
    OP_SHOWEVENTLOG: 131072,                   // 2 ^ 17
    OP_SHOWSEARCH: 262144,                     // 2 ^ 18
    OP_SHOWSETTINGS: 524288,                   // 2 ^ 19
    OP_RESTRICTPASSWORDCHANGE: 1048576,        // 2 ^ 20
    OP_MANAGEUSERSGROUPS: 2097152,             // 2 ^ 21
    OP_MONITORING: 4194304,                    // 2 ^ 22
    OP_CALLRECORDINGREPORT: 8388608,           // 2 ^ 23
    OP_MANAGEDEVICESETTINGS: 16777216,         // 2 ^ 24
    OP_RESTRICTLOGOFF: 33554432,               // 2 ^ 25
    OP_RESTRICTQUIT: 67108864,                 // 2 ^ 26
    OP_ALLOWEMERGENCYREPORT: 134217728,        // 2 ^ 27
    OP_GUARDTOUR: 268435456,                   // 2 ^ 28
    OP_GROUPPATCHES: 536870912,                // 2 ^ 29
    OP_ALLOWUSERINVENTORYREPORT: 1073741824,   // 2 ^ 30
    OP_RESTRICTPERIODICLOCATIONS: 4294967296,     // 2 ^ 32
    OP_RESTRICTLOCATIONTRACKREPORT: 8589934592,   // 2 ^ 33
    OP_DYNAMICGROUPS: 17179869184,                // 2 ^ 34
    OP_MANAGEALLDYNAMICGROUPS: 34359738368,       // 2 ^ 35
    OP_ALLOW_PHONE_CALLS: 68719476736,          //2 ^ 36
    OP_ALL: -1,
}

export const KEY_ACTIONS = {
    START_STOP_VOICE_CALL_REPLAY: 0,
    START_STOP_EMERGENCY: 1,       
    SET_STATUS: 2,
    CALL_ALERT: 3,
    SEND_MESSAGE: 4,
    ANNOUNCE_BATTARY_CHARGE: 5,   
    NEXT_CHANNEL: 6,
    PREVIOUS_CHANNEL: 7,
}

//let api

export class ServerApi {

    constructor(serveraddress, login, password, options){
        this.serveraddress = serveraddress
        this.login = login
        this.password = password
        this.userID = null
        this.connectCount = 0

        this.VoipPort = null
        this.AudioSampleRate = null
        this.AudioBitRate = null
        this.AudioFrameSize = null
        this.bufferSamplesCount = 0;
        this.ssrc = 0 

        this.networks = []
        this.calls = []
        this.unreadMessages = []

        this.files = [] //while processing outgoing messages
        this.jobs = [] //while processing incoming messages

        this.emergency = []

        this.mutedGroups = []

        this.activeCallId = ''

        this.ws = null;
        this.audio = null;

        this.pttNetworkID = ''

        this.options = {
            onError: null,
            onClose: null,
            onConnect: null,
            onNetworksChanged: null,
            ...options
        }
        //console.log(this.options)

        this.fileStorage = options.fileStorage
        this.eventLogger = options.eventLogger

        this.onGetnetComplete = null
        this.onGetDeviceSettingsComplete = null
        this.onStatisticsComplete = null

        this.settings = getSettingsInstance()
        //api = this

        //this.handleStreamSuccess = this.handleStreamSuccess.bind(this)
        //this.handleStreamError = this.handleStreamError.bind(this)
    }


    clearState(){
        this.networks = []
        this.calls = []
        if(this.options.onNetworksChanged) {
            this.options.onNetworksChanged(this.networks)
        }
    }

    connect(){
        if ("WebSocket" in window) {
            this.isClosed = false
            this.connectCount++
            //alert("ws://" + this.serveraddress)

            let protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

            this.ws = new WebSocket(protocol+ "//" + this.serveraddress);
            this.ws.onopen = this.handleOpen.bind(this)
            this.ws.onmessage = this.handleMessage.bind(this)
            this.ws.onerror = this.handleError.bind(this)
            this.ws.onclose = this.handleClose.bind(this)

            //this.initUnreadMessages()
        } else {
            // The browser doesn't support WebSocket
            alert("WebSocket NOT supported by your Browser!");
        }
    }//end connect

    close(){
        this.isClosed = true
        this.ws.close();
    }


    handleOpen() {
        this.connectCount = 1
        this.initUnreadMessages()
        this.initGroupsMuted()
    }

    handleError(event) { 
        console.log('SOCKET_ERROR')
        console.log(event)
        if(this.options.onError){
            this.options.onError(event, this.connectCount)
        }else{
            alert("Socket error: " + JSON.stringify(event, null, 4)); 
        }
    };

    handleClose(event) { 
        console.log('SOCKET_CLOSE ' + event.code)
        console.log(event)

        if(this.isClosed) return //regular logout

        //closed by server or broken connnection
        if(event.code===1005){
            //server halt
            this.clearState()
            window.setTimeout(()=>{this.connect()}, 5000)
        }else if(event.code===1006){
            window.setTimeout(()=>{this.connect()}, 5000)
        }

        if(this.options.onClose){
            this.options.onClose(event, this.connectCount)
        }else{
            alert("Socket closed. Code: "+event.code);	
        }
    };

    handleMessage(event) { 
        var messages = JSON.parse(event.data);
        for(let mi = 0; mi < messages.length; mi++){
            var msg = messages[mi];
            //console.log('handleMessage '+ mi)
            //console.log('MessageID=' + msg.MessageID)
            switch(msg.MessageID){
                case "SERVER_CONFIG":
                    this.VoipPort = msg.VoipPort;
                    this.AudioSampleRate = msg.AudioSampleRate;
                    this.AudioBitRate = msg.AudioBitRate;
                    this.AudioFrameSize = msg.AudioFrameSize;
                    
                    this.bufferSamplesCount = this.AudioSampleRate / 1000 * this.AudioFrameSize;
                    //bufferSamplesCount = this.AudioSampleRate / 1000 * this.AudioFrameSize;
                    //udpPacketBuffer = new ArrayBuffer(12 + this.bufferSamplesCount); // aLaw, muLaw

                    //currentFrame = new Int16Array(this.bufferSamplesCount);
                    //currentBufferIndex = 0;

                    //VoipEndpoint = new IPEndPoint(ServerIPAddr, VoIPPort);
                    //rtpPacket = new RtpPacket { Version = 2, SSRC = ssrc, PayloadType = 106, SequenceNumber = 0, Timestamp = 0 };
                    this.ssrc = new Uint32Array(1);
                    window.crypto.getRandomValues(this.ssrc);
                    var devconf = [{ 
                        MessageID: "DEVICE_CONFIG",  
                        Ssrc: this.ssrc[0], 
                        AppName: "FleetConsole", 
                        VersionName: this.options.config.version,//  "5.5", 
                        VersionCode: 1, 
                        AudioCodec: 1, 
                        VoiceOverTcp : "True", 
                        Password: this.password, 
                        DeviceData: { 
                            SessionID: getBase64ID(), 
                            ID: getDeviceID(), 
                            DeviceDescription: "MANUFACTURER=WLLC;MODEL=APIClientWEB;SERIAL=123456789;OSVERSION=5.0", 
                            Login: this.login, 
                            AvatarHash: "", 
                            VoiceOverTcp: true,
                            StatusID: "AAAAAAAAAAAAAAAAAAAAAA==" 
                        }
                    }];
                    console.log('DEVICE_CONFIG')
                    console.log(devconf)
                    this.ws.send(JSON.stringify(devconf));
                    break;

                case "CONFIG_SERVER_RESPONSE_NACK":
                    console.log('CONFIG_SERVER_RESPONSE_NACK')
                    console.log(msg)
                    if(this.options.onError){
                        this.options.onError(msg.Reason)
                    }else{
                        alert(msg.Reason);
                    }
                    break;

                case "CONFIG_SERVER_RESPONSE_ACK":
                    console.log('CONFIG_SERVER_RESPONSE_ACK')
                    console.log(msg)
                    if(this.options.onConnect){
                        this.options.onConnect();
                    }
                    var loginMessage = [{ MessageID: "LOGIN"}];
                    this.ws.send(JSON.stringify(loginMessage));
                    break;

                case "LOGIN_RESPONSE":
                    console.log(msg)
                    if(msg.Response === 0){
                        this.userID = msg.UserID 
    
                        this.audio = new AudioProcessor(
                            this.AudioSampleRate, 
                            this.bufferSamplesCount, 
                            this.ssrc,
                            this.sendVoicePacket.bind(this)
                        );
                    }else{
                        let message = '';
                        switch(msg.Response){
                            //case 0: message = 'Successful login'; break;
                            case 1: message = 'Invalid client version'; break;
                            case 2: message = 'Invalid user name or password'; break;
                            case 3: message = 'License is expired'; break;
                            case 4: message = 'Exceeded number of available user connections'; break;
                            case 5: message = 'Server working in demo mode must be restarted'; break;
                            case 6: message = 'Multiple logins are prohibited for this user and ' +
                                'another device is already connected with these credentials'; break;
                            default: 
                                message = "Action not defined for "+ msg.Response + " (LOGIN_RESPONSE)"
                                console.error(message)
                        }
                        this.options.onError({message: message, type: 'login'})
                        //alert('login fail with: '+ message)
                    }
                    break;

                case "DATAEX":
                    console.log(msg)
                    switch(msg.DataType){
                        
                        case TYPE_NETWORKS:
                            this.processNetworks(msg.DataObjects, msg.Operation);
                            break;

                        case TYPE_GROUPS:
                            this.processGroups(msg.DataObjects, msg.Operation, msg.NetworkID);
                            break;
                        case TYPE_USERS:
                            this.processUsers(msg.DataObjects, msg.Operation, msg.NetworkID);
                            break;
                        case TYPE_DEVICES:
                            this.processDevices(msg.DataObjects, msg.Operation, msg.NetworkID);
                            break;
                        case TYPE_STATUSES:
                            this.processStatuses(msg.DataObjects, msg.Operation, msg.NetworkID);
                            break;
                        case TYPE_EMERGENCY_PROFILES:
                            this.processProfiles(msg.DataObjects, msg.Operation, msg.NetworkID);
                            break;
                        case TYPE_AVATARRESPONSE:
                            this.processAvatars(msg.DataObjects, msg.Operation, msg.NetworkID)
                            break;

                        case TYPE_NETWORKSETTINGS:
                            const serverConfig = msg.DataObjects[0];
                            console.log('onGetnetComplete TYPE_NETWORKSETTINGS')
                            console.log(serverConfig)
                            this.onGetnetComplete(serverConfig);
                            break;
                            
                        case TYPE_LOCATION_REQUESTS:
                            console.log('TYPE_LOCATION_REQUESTS')
                            console.log(msg)
                            this.processLocationRequests(msg.DataObjects, msg.Operation, msg.NetworkID)
                            break;

                        default:
                            console.error('Action case not defined for DataType [DATAEX]: ' + msg.DataType + ', see console log for more details')
                            console.error(msg)
                    }
                    break;

                case "BEGIN_INITIALIZE":
                    console.log('BEGIN_INITIALIZE')
                    console.log(msg)    
                    break;

                case "END_INITIALIZE":
                    console.log('END_INITIALIZE')
                    console.log(msg)    
                    break;    

                case "PTT_CONTROL":
                    console.log('PTT_CONTROL '+ msg.Control)
                    console.log(msg)
                    this.processPTTControl(msg);
                    break;

                case "VOICE_PACKET":
                    //console.log('VOICE_PACKET')
                    //console.log(msg)
                    this.processVoicePacket(msg);
                    break;

                case "DEVICE_CONTEXT":
                    //todo?
                    console.log('DEVICE_CONTEXT')
                    console.log(msg)
                    if(this.options.onAction){
                        this.options.onAction({type:'SET_LANGUAGE', lang: msg.WebLocale})
                    }
                    break;

                case "PING":
                    break;
                
                case "OTAP_SETTINGS_RESPONSE":{
                    console.log('OTAP_SETTINGS_RESPONSE')
                    console.log(msg.Content)
                    const deviceConfig = JSON.parse(msg.Content+'}');
                    this.onGetDeviceSettingsComplete(deviceConfig);
                    break;
                }
                    

                case "STATUS_CHANGE_REQUEST_FOR_DEVICE":{
                    console.log('STATUS_CHANGE_REQUEST_FOR_DEVICE')
                    console.log(msg)
                    const netIndex = getIndexByID(msg.NetworkID, this.networks)
                    if(netIndex!==null){
                        const network = this.networks[netIndex]
                        const deviceIndex = getIndexByID(msg.DeviceID, network.devices)
                        if(deviceIndex!==null){
                            const device = network.devices[deviceIndex]
                            const userIndex = getIndexByID(device.UserID, this.networks[netIndex].users);
                            if(userIndex!==null){
                                if(msg.StatusID!=='AAAAAAAAAAAAAAAAAAAAAA=='){
                                    device.StatusID = msg.StatusID
                                }else{
                                    device.StatusID = null
                                }
                                if(this.options.onNetworksChanged){
                                    this.options.onNetworksChanged(this.networks)
                                }
                            }
                        }
                    }
                    break
                }

                case 'GPS_RESPONSE':
                    console.log('GPS_RESPONSE')
                    console.log(msg)
                /*
                MessageID: "GPS_RESPONSE"
                NetworkID: "r46o+pD7lEKRa+ZDc/JQcQ=="
                RequestID: "AAAAAAAAAAAAAAAAAAAAAA=="
                ResponseType: 0
                */
                    break

                case 'GPS_RESULT_TO_CLIENT':{
                    console.log('GPS_RESULT_TO_CLIENT')
                    console.log(msg)
                    const netIndex = getIndexByID(msg.NetworkID, this.networks)
                    if(netIndex!==null){
                        const deviceIndex = getIndexByID(msg.DeviceID, this.networks[netIndex].devices)
                        if(deviceIndex!==null){
                            const device = this.networks[netIndex].devices[deviceIndex]

                            //msg.Altitude = 120.4
                            //msg.Speed = 3.4

                            device.Latitude = msg.Latitude
                            device.Longitude = msg.Longitude
                            device.Accuracy = msg.Accuracy
                            if(msg.Altitude) device.Altitude = msg.Altitude
                            if(msg.Speed) device.Speed = msg.Speed
                            device.Time = msg.Time
                            
                            device.GpsExpire = new Date().getTime() + this.settings.get('locationValidityTime')*1000


                            if(this.options.onNetworksChanged){
                                this.options.onNetworksChanged(this.networks)
                            }

                        }
                    }
                    
                /*
                Accuracy: 33.284
                DeviceID: "mzGogUkZjtLF9+iiFFrTXg=="
                Flags: 2
                FromName: "sda"
                Latitude: 56.4627967
                Longitude: 84.9903802
                MessageID: "GPS_RESULT_TO_CLIENT"
                NetworkID: "r46o+pD7lEKRa+ZDc/JQcQ=="
                Time: 1578935281407
                */
                    break
                }

                case 'PTT_RESPONSE':{
                    console.log('PTT_RESPONSE')
                    console.log(msg)
                    break;
                }

                case 'STORAGE_JOB_STATE':{
                    console.log('STORAGE_JOB_STATE')
                    console.log(msg)
                    this.processStorageJobState(msg)
                    break;
                }

                case 'STORAGE_JOB_REQUEST':{
                    console.log('STORAGE_JOB_REQUEST')
                    console.log(msg)
                    this.processStorageJobRequest(msg)
                    break
                }

                case 'STORAGE_JOB_CONTENT_REQUEST':{
                    console.log('STORAGE_JOB_CONTENT_REQUEST request')
                    //console.log(msg)
                    this.processStorageJobContentRequest(msg)
                    break
                }

                case 'STORAGE_JOB_CONTENT':{
                    console.log('STORAGE_JOB_CONTENT')
                    //console.log(msg)
                    this.processStorageJobContent(msg)
                    break;
                }

                case 'STORAGE_JOB_INFO': {
                    console.log('STORAGE_JOB_INFO')
                    console.log(msg)
                    this.processStorageJobInfo(msg)
                    break;
                }

                case 'EMERGENCY': {
                    console.log('EMERGENCY')
                    console.log(msg)
                    this.processEmergency(msg)
                    break;
                }

                case 'EMERGENCY_ACK': {
                    console.log('EMERGENCY_ACK')
                    console.log(msg)
                    this.processEmergencyAck(msg)
                    break;
                }
                
                case 'STATISTICS_RESPONSE':{
                    alert('STATISTICS_RESPONSE')
                    console.log('STATISTICS_RESPONSE')
                    console.log(msg)
                    this.onStatisticsComplete(msg)
                    break;
                }



                default:
                    console.error('Action case not defined for message: ' + msg.MessageID + ', see console log for more details')
                    console.error(msg)
            }
        }
    }


    processPTTControl(msg) {

        var control = msg.Control;
        var sourceId = msg.SourceID;
        //var sourceName = msg.SourceName;
        //var targetId = msg.TargetID;
        //var targetName = msg.TargetName;
        var callId = msg.CallID;

        let callIndex = getIndexByID(msg.CallID, this.calls, 'CallID')
        let incoming = msg.SourceID !== getDeviceID()
        let network = getObjectByID(msg.NetworkID, this.networks)
        //debugger
        //let group = getObjectByID(msg.GroupID, network.groups)

        

        //ongoing calls
        let call
        if(callIndex==null){
            call = msg
            
            call.Time = new Date().getTime();
            call.Incoming = incoming
            call.NetworkName = network.Name
            call.TransmitterID = msg.SourceID 
            
            call.Type = call.Control === PTT_CONTROL_TYPES.VOICE_PRIVATE_BEGIN ? 'private' :
                            (call.Control === PTT_CONTROL_TYPES.VOICE_GROUP_BEGIN? 'group' : '')
            
            let mute = false
            //debugger
            if(msg.Type==='group' && incoming){
                let group = getObjectByID(msg.TargetID, network.groups)
                if(group) mute = group.SoundIsMuted
            }
            call.SoundIsMuted = mute


            this.calls.push(call)
            callIndex = this.calls.length-1

        } else {
            call = this.calls[callIndex]
            //all.Time = this.calls[callIndex].Time
            call.Incoming = incoming
            call.TransmitterID = msg.SourceID 
            call.TransmitterName = msg.SourceName

            if(call.Type==='group'){
                call.SourceID = msg.SourceID
                call.SourceName = msg.SourceName
            }
            //call.SoundIsMuted = mute

            call.Control = msg.Control
            this.calls[callIndex] = call
        }

        if(call){
            this.logVoiceEvent(call)
        }

        //ptt status in user list
        if(incoming){
            const device = getObjectByID(msg.SourceID, network.devices)
            if(device){
                const user = getObjectByID(device.UserID, network.users)
                if(user){
                    user.PttStatus = {
                        Control: msg.Control,
                        Incoming: incoming
                    }
                }
                this.options.onNetworksChanged(this.networks)
            }
        }else if(call.Type==='private'){
            const device = getObjectByID(msg.TargetID, network.devices)
            if(device){
                const user = getObjectByID(device.UserID, network.users)
                if(user){
                    user.PttStatus = {
                        Control: msg.Control,
                        Incoming: incoming
                    }
                }
                this.options.onNetworksChanged(this.networks)         
            }  
        }
        //ptt status in group list
        if(call.Type==='group'){
            let group = getObjectByID(msg.TargetID, network.groups)
            if(group){
                //this.calls[callIndex].SoundIsMuted = true
                group.PttStatus = {
                    Control: msg.Control,
                    Incoming: incoming
                }
                this.options.onNetworksChanged(this.networks)
            }
        }

        

        switch (control){
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_BEGIN: {//0
                let pttConfirm = [{
                    MessageID: "PTT_RESPONSE", 
                    NetworkID: msg.NetworkID,
                    Destination: sourceId, 
                    Response: 0, 
                    Type: 0
                }];
                console.log('PTT_RESPONSE confirm private')
                console.log(pttConfirm)
                this.ws.send(JSON.stringify(pttConfirm));
                break;
            }
            
            case PTT_CONTROL_TYPES.VOICE_GROUP_BEGIN: {
                let pttConfirm = [{
                    MessageID: "PTT_RESPONSE", 
                    NetworkID: msg.NetworkID,
                    Destination: sourceId, 
                    Response: 0, 
                    Type: 0
                }];
                console.log('PTT_RESPONSE confirm group')
                console.log(pttConfirm)
                this.ws.send(JSON.stringify(pttConfirm));
                break;
            }

            case PTT_CONTROL_TYPES.VOICE_GROUP_ENTER:
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_ENTER:
                this.activeCallId = callId
                break;
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_PRESSED:
            case PTT_CONTROL_TYPES.VOICE_GROUP_PRESSED:
                break;
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_RELEASED:
            case PTT_CONTROL_TYPES.VOICE_GROUP_RELEASED:
                break;
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_END:
            case PTT_CONTROL_TYPES.VOICE_GROUP_END:
                if (this.activeCallId === callId){
                    this.activeCallId = "";
                }
                break;

            default:
                console.error('Action case not defined for control: ' + control + ', see console log for more details')
                console.error(msg)
        }


        this.options.onPttCall(this.calls, this.activeCallId)

    }


    logVoiceEvent(call){

        console.log('logVoiceEvent')
        console.log(call)

        const eventState = this.voiceStateToEventState(call.Control)

        switch(call.Control){
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_ENTER:
            case PTT_CONTROL_TYPES.VOICE_GROUP_ENTER:{
                
                break
            }

            case PTT_CONTROL_TYPES.VOICE_PRIVATE_BEGIN:
            case PTT_CONTROL_TYPES.VOICE_GROUP_BEGIN:{

                const eventType = (control => {
                    switch(control){
                        case PTT_CONTROL_TYPES.VOICE_PRIVATE_BEGIN:
                            return EVENT_TYPE.TYPE_VOICE_PTT_PRIVATE
                        case PTT_CONTROL_TYPES.VOICE_GROUP_BEGIN:
                            return EVENT_TYPE.TYPE_VOICE_PTT_GROUP
                        default: 
                            return ''
                    }
                })(call.Control)

                const event = {
                    type: eventType,
                    state: eventState, //EVENT_TYPE.STATE_CALL_INITIATED,
                    sender: call.SourceName,
                    recipient: call.TargetName,
                    note: '',
                    ref_id: call.CallID,
                    duration: 0,
                    //ref_device_id: msg.ConversationType===0 && msg.FromDeviceID ? msg.FromDeviceID : '',
                    //ref_user_id: msg.ConversationType===0 && msg.FromUserID ? msg.FromUserID : '',
                    //ref_group_id: msg.ConversationType===1 && msg.GroupID ? msg.GroupID : '',
                    network_id: call.NetworkID
                }
                console.log('processPTTControl log event')
                console.log(event)
                this.options.eventLogger.add(event)

                call.Note = ''
                call.StartTime = null
                call.Tick = 0
                call.Duration = 0

                break;
            }

            case PTT_CONTROL_TYPES.VOICE_PRIVATE_PRESSED:
            case PTT_CONTROL_TYPES.VOICE_GROUP_PRESSED:{

                call.StartTime = new Date().getTime()
                call.Tick ++

                if(call.Tick>1){
                    call.Note += ' +'+call.TransmitterName
                }

                break;
            }

            case PTT_CONTROL_TYPES.VOICE_PRIVATE_RELEASED:
            case PTT_CONTROL_TYPES.VOICE_GROUP_RELEASED:{
                const duration = Math.round((new Date().getTime() - call.StartTime)/1000)
                console.log('calc duration ')
                console.log(call.StartTime)

                call.Duration += duration 
                const event = {
                    duration: call.Duration,
                    note: call.Note
                }
                console.log('update event')
                console.log(event)
                this.options.eventLogger.update(call.CallID, event)
                break;
            }             

            case PTT_CONTROL_TYPES.VOICE_PRIVATE_END:
            case PTT_CONTROL_TYPES.VOICE_GROUP_END:{

                this.options.eventLogger.update(call.CallID, {state: eventState})

                break;
            }

            default: console.error('logVoiceEvent: case not defined for control '+ call.Control)

        }

        
    }



    processNetworks(nets, dataOp){
        if (dataOp === OP_INITIALIZE) this.networks = [];

        for (let i = 0; i < nets.length; i++){
            let net = nets[i];
            switch (dataOp) {
                case OP_INITIALIZE:
                case OP_ADD:
                    this.networks.push(net)
                    break;
                case OP_CHANGE:{
                    const index = getIndexByID(net.ID, this.networks)
                    if(index!==null) this.networks[index] = net;
                    break 
                }
                case OP_REMOVE:{
                    deleteItemByID(net.ID, this.networks)
                    break
                }
                default:
                    const msg = 'Operation case not defined: ' + dataOp + ', see console log for more details'
                    console.error(msg)
            }
        }

        if(this.options.onNetworksChanged){
            this.options.onNetworksChanged(this.networks)
        }
        console.log('NETWORKS CHANGED:')
        console.log(this.networks)

    }

    processGroups(groups, dataOp, networkID){

        let netIndex = this.getNetworkIndexByID(networkID)
        if(netIndex===null) return;

        const net = this.networks[netIndex]

        if (dataOp === OP_INITIALIZE) this.networks[netIndex].groups = [];

        groups.forEach(group => {

            group.NetworkID = networkID
            group.SoundIsMuted = this.getGroupIsMuted(group.ID)
            group.GUID = base64toGuid(group.ID)

            switch (dataOp) {
                case OP_INITIALIZE:
                case OP_ADD:
                    net.groups.push(group)
                    break
                case OP_CHANGE:{
                    const index = getIndexByID(group.ID, net.groups)
                    if(index!==null) net.groups[index] = group;
                    break
                }
                case OP_REMOVE:{
                    deleteItemByID(group.ID, net.groups)
                    break
                }
                default:
                    const msg = 'Operation case not defined: ' + dataOp + ', see console log for more details'
                    console.error(msg)
            }
        })

        if(this.options.onNetworksChanged){
            this.options.onNetworksChanged(this.networks)
        }
        console.log('NETWORKS CHANGED: ' + networkID )
        console.log(this.networks)

    }

    processUsers(users, dataOp, networkID){

        let netIndex = this.getNetworkIndexByID(networkID)
        if(netIndex===null) return;

        const net = this.networks[netIndex]

        if (dataOp === OP_INITIALIZE) net.users = [];

        users.forEach(user => {

            user.NetworkID = networkID

            switch (dataOp) {
                case OP_INITIALIZE:
                case OP_ADD:
                    net.users.push(user)
                    break;
                case OP_CHANGE:{
                    const userIndex = getIndexByID(user.ID, net.users)
                    if(userIndex!==null) net.users[userIndex] = user;
                    break;
                }
                case OP_REMOVE:{
                    deleteItemByID(user.ID, net.users)
                    break;
                }
                default:
                    const msg = 'Operation case not defined: ' + dataOp + ', see console log for more details'
                    console.error(msg)
            }
        })

        if(this.options.onNetworksChanged){
            this.options.onNetworksChanged(this.networks)
        }
        console.log('NETWORKS CHANGED:' + networkID)
        console.log(this.networks)

    }

    processDevices(devices, dataOp, networkID){

        const netIndex = this.getNetworkIndexByID(networkID)
        if(netIndex===null) return;

        const net = this.networks[netIndex]

        if (dataOp === OP_INITIALIZE) net.devices = [];

        devices.forEach(device => {

            device.GUID = base64toGuid(device.ID)

            switch (dataOp) {
                case OP_INITIALIZE:
                case OP_ADD:{
                    net.devices.push(device)
                    break
                }
                case OP_CHANGE:{
                    const index = getIndexByID(device.ID, net.devices)
                    if(index!==null) net.devices[index] = device;
                    break
                }
                case OP_REMOVE:{
                    const index = getIndexByID(device.ID, net.users, 'DeviceID')
                    if(index!==null) net.users[index].DeviceID = null
                    deleteItemByID(device.ID, net.devices)
                    break
                }
                default:
                    let msg = 'Operation case not defined: ' + dataOp + ', see console log for more details'
                    console.error(msg)
            }
        })

        if(this.options.onNetworksChanged){
            this.options.onNetworksChanged(this.networks)
        }
        console.log('NETWORKS CHANGED:' + networkID)
        console.log(this.networks)

    }

    processStatuses(statuses, dataOp, networkID){

        let netIndex = this.getNetworkIndexByID(networkID)
        if(netIndex===null) return;

        const net = this.networks[netIndex]

        if (dataOp === OP_INITIALIZE) net.statuses = [];

        statuses.forEach(status => {
            switch (dataOp) {
                case OP_INITIALIZE:
                case OP_ADD:
                    net.statuses.push(status)
                    break
                case OP_CHANGE:{
                    const index = getIndexByID(status.ID, net.statuses)
                    if(index!==null) net.statuses[index] = status
                    break
                }
                case OP_REMOVE:
                    deleteItemByID(status.ID, net.statuses)
                    break
                default:
                    let msg = 'Operation case not defined: ' + dataOp + ', see console log for more details'
                    console.error(msg)
            }
        })

        if(this.options.onNetworksChanged){
            this.options.onNetworksChanged(this.networks)
        }
        console.log('NETWORKS CHANGED:' + networkID)
        console.log(this.networks)

    }

    processProfiles(profiles, dataOp, networkID){

        let netIndex = this.getNetworkIndexByID(networkID)
        if(netIndex===null) return;

        const net = this.networks[netIndex]

        if (dataOp === OP_INITIALIZE) net.profiles = [];

        profiles.forEach(profile => {
            switch (dataOp) {
                case OP_INITIALIZE:
                case OP_ADD:
                    net.profiles.push(profile)
                    break;
                case OP_CHANGE:{
                    const index = getIndexByID(profile.ID, net.profiles)
                    if(index!==null) net.profiles[index] = profile
                    break
                }
                case OP_REMOVE:
                    deleteItemByID(profile.ID, net.profiles)
                    break;
                default:
                    let msg = 'Operation case not defined: ' + dataOp + ', see console log for more details'
                    console.error(msg)
            }
        })

        if(this.options.onNetworksChanged){
            this.options.onNetworksChanged(this.networks)
        }
        console.log('NETWORKS CHANGED: ' + networkID)
        console.log(this.networks)

    }

    processAvatars(avatars, dataOp, networkID){
        console.log('TYPE_AVATARRESPONSE')
        console.log(avatars)
        const network = getObjectByID(networkID, this.networks)
        
        avatars.forEach(avatar=>{
            const device = getObjectByID(avatar.Hash, network.devices, 'AvatarHash')
            if(device){
                switch (dataOp) {
                    case OP_INITIALIZE:
                    case OP_ADD:
                    case OP_CHANGE:
                        //device.Avatar = avatar.Data
                        localStorage.setItem(avatar.Hash, avatar.Data);
                        if(this.options.onAvatarUpdate){
                            this.options.onAvatarUpdate(avatar.Hash, avatar.Data, networkID)
                            
                        }
                        break;

                    case OP_REMOVE:
                        //delete device.Avatar
                        delete device.AvatarHash
                        localStorage.removeItem(avatar.Hash)
                        if(this.options.onAvatarUpdate){
                            this.options.onAvatarUpdate(avatar.Hash, null, networkID)
                        }
                        break;

                    default:
                        let msg = 'Operation case not defined: ' + dataOp + ', see console log for more details'
                        console.error(msg)
                }
            }

        })
        
        
    }


    processLocationRequests(requests, dataOp, networkID){
        console.log('processLocationRequests')
        let network = getObjectByID(networkID, this.networks)

        requests.forEach(request => {
            
            request.Devices.forEach(deviceGUID => {
                console.log(network)
                const device = getObjectByID(deviceGUID, network.devices, 'GUID')
                if(device){
                    device.locationRequest = {
                        period: request.Period,
                        requestID: request.RequestID
                    }
                }
            })

            request.Groups.forEach(groupGUID => {
                console.log(network)
                const group = getObjectByID(groupGUID, network.groups, 'GUID')
                group.locationRequest = {
                    period: request.Period,
                    requestID: request.RequestID
                }
            })

        })


    }

    /////////////////////////////////
    //EMERGENCY API
    /////////////////////////////////

    processEmergency(msg){

        this.emergency.push(msg)

        console.log('this.options.onEmergency')
        console.log(this.options.onEmergency)
        if(this.options.onEmergency){
            this.options.onEmergency(this.emergency)
            console.log('onEmergency')
            console.log(this.emergency)
        }
        /**
DeviceID: "mzGogUkZjtLF9+iiFFrTXg=="
EmergencyID: "Uv8znuyUxEWrrIMH77fImg=="
MessageID: "EMERGENCY"
NetworkID: "r46o+pD7lEKRa+ZDc/JQcQ=="
ProfileID: "EREREREREREREREREREREQ=="
Reason: 0
UserID: "tSz4jsG0ekCWw8YVcGm2zQ=="
UserName: "sda"
         */
    }

    processEmergencyAck(msg){
        deleteItemByID(msg.EmergencyID, this.emergency, 'EmergencyID')
        this.options.onEmergency([...this.emergency])
    }

    sendEmergencyAck(emergencyID){
        const emergency = getObjectByID(emergencyID, this.emergency, 'EmergencyID')
        let ackMessage = [{
            MessageID: "EMERGENCY_ACK", 
            EmergencyID: emergencyID,
            NetworkID: emergency.NetworkID
        }];
        console.log('send EMERGENCY_ACK')
        console.log(ackMessage)
        this.ws.send(JSON.stringify(ackMessage));
    }

    cancelEmergency(emergencyID){
        deleteItemByID(emergencyID, this.emergency, 'EmergencyID')
        this.options.onEmergency([...this.emergency])
    }


    /////////////////////////////////
    //STORAGE_JOB API
    /////////////////////////////////

    processStorageJobRequest(msg){

        console.log('this.jobs.push')
        console.log(msg)
        msg.inProcess = true
        this.jobs.push(msg)

        if(this.options.eventLogger){
        
            const eventType = ((converationType, jobType) => {
                switch(converationType){
                    case 0:
                        switch(jobType){
                            case 0: return EVENT_TYPE.TYPE_TEXT_PRIVATE
                            case 1: return EVENT_TYPE.TYPE_IMAGE_PRIVATE
                            case 4: return EVENT_TYPE.TYPE_FILE_PRIVATE
                            default: 
                                console.error('processStorageJobRequest: case undefined for jobType='+jobType+'/'+converationType)
                                console.error(msg)
                                return null
                        }
                    case 1:
                        switch(jobType){
                            case 0: return EVENT_TYPE.TYPE_TEXT_GROUP
                            case 1: return EVENT_TYPE.TYPE_IMAGE_GROUP
                            case 4: return EVENT_TYPE.TYPE_FILE_GROUP
                            default: 
                                console.error('processStorageJobRequest: case undefined for jobType='+jobType+'/'+converationType)
                                console.error(msg)
                                return null
                        }
                    default: 
                        console.error('processStorageJobRequest: case undefined for converationType='+converationType)
                        console.error(msg)
                        return null
                }
            })(msg.ConversationType, msg.JobType)

            const event = {
                type: eventType,
                state: this.jobStateToEventState(msg.JobState),
                file_state: 0,
                sender: msg.FromName,
                recipient: msg.ToName,
                note: msg.JobType===0 ? msg.Title : msg.FileName,
                ref_id: msg.JobID,
                ref_device_id: msg.ConversationType===0 && msg.FromDeviceID ? msg.FromDeviceID : '',
                ref_user_id: msg.ConversationType===0 && msg.FromUserID ? msg.FromUserID : '',
                ref_group_id: msg.ConversationType===1 && msg.GroupID ? msg.GroupID : '',
                network_id: msg.NetworkID
            }
            console.log('processStorageJobRequest log event')
            console.log(event)
            
            this.options.eventLogger.add(event)

            this.appendUnreadMessages(event)

            //user|group chat tab are handle new messages
            this.registerTextMessageEvent(event, msg.NetworkID)
        }

        const mimeType = getFileMimeType(msg.FileName)

        msg.previewChunkNum = 0
        msg.fileChunkNum = 0
        //write preview 
        if(msg.JobType===1){
            const fileObject = {
                job_id: msg.JobID,
                name: msg.FileName,
                mime_type: mimeType,
                size: msg.PreviewLen,
                type: 0,
                state: 0,
                network_id: msg.NetworkID
            }
            this.fileStorage.addFile(fileObject, fileID => {
                msg.previewID = fileID
                //const job = getObjectByID(msg.JobID, this.jobs, 'JobID')
                //if(job){

                if(msg.previewChunks){
                    msg.previewChunks.forEach(fileChunk=>{
                        //save cached chunks with empty file_id
                        if(fileChunk.file_id===undefined){
                            fileChunk.file_id = fileID
                            this.fileStorage.addChunk(fileChunk)
                        }
                    })
                }
            })
        }
        //write image or file 
        if(msg.JobType===1 || msg.JobType===4){
            const fileObject = {
                job_id: msg.JobID,
                name: msg.FileName,
                mime_type: mimeType,
                size: msg.DataLen,
                type: 1,
                state: 0,
                network_id: msg.NetworkID
            }
            this.fileStorage.addFile(fileObject, fileID => {
                msg.fileID = fileID
            })
        }

        //if(msg.JobType===0 || msg.JobType===4){
            let confirm = [{
                MessageID: "STORAGE_JOB_STATE", 
                NetworkID: msg.NetworkID,
                States: [{
                    JobID: msg.JobID,
                    JobState: JOB_STATES.JOB_STATE_CONFIRMED
                }]
            }];
            console.log('STORAGE_JOB_STATE confirm 50')
            console.log(confirm)
            this.ws.send(JSON.stringify(confirm));
        //}
/*                    ​
ConversationType: 0
​DataLen: 0
​FileName: ""
​FromDeviceID: "mzGogUkZjtLF9+iiFFrTXg=="
​FromName: "sda"
​FromUserID: "tSz4jsG0ekCWw8YVcGm2zQ=="
​JobID: "D4Ga61bO10SM42pryorwuA=="
​JobState: 20
​JobType: 0
​MessageID: "STORAGE_JOB_REQUEST"
​NetworkID: "r46o+pD7lEKRa+ZDc/JQcQ=="
​PreviewLen: 0
​Sequence: 1
​Time: 1582310713524
​Title: "qerryy"
​ToDeviceID: "8fQdcvSrTpyNeIghqOwtww=="
​ToName: "Administrator"
​ToUserID: "EREREREREREREREREREREQ=="
*/
    }

    processStorageJobState(msg){
        msg.States.forEach(item => {
            this.updateTextMessageState(item.JobID, item.JobState, msg.NetworkID)

            let confirmState = ((state)=>{
                switch(state){
                    case JOB_STATES.JOB_STATE_CONFIRMING: return JOB_STATES.JOB_STATE_CONFIRMED
                    case JOB_STATES.JOB_STATE_DELIVERED: return JOB_STATES.JOB_STATE_DELIVERED_CONFIRMED
                    case JOB_STATES.JOB_STATE_CANCELLED: return JOB_STATES.JOB_STATE_CANCELLED_CONFIRMED
                    default: return null
                }
            })(item.JobState)
            
            if(confirmState){
                let confirm = [{
                    MessageID: "STORAGE_JOB_STATE", 
                    NetworkID: msg.NetworkID,
                    States: [{
                        JobID: item.JobID,
                        JobState: confirmState
                    }]
                }];
                console.log('STORAGE_JOB_STATE confirm '+confirmState)
                console.log(confirm)
                this.ws.send(JSON.stringify(confirm));

                if(confirmState===JOB_STATES.JOB_STATE_DELIVERED_CONFIRMED ||
                   confirmState===JOB_STATES.JOB_STATE_CANCELLED_CONFIRMED
                    ){
                    deleteItemByID(item.JobID, this.jobs, 'JobID')
                    console.log('delete job '+item.JobID)
                    console.log('this.jobs')
                    console.log(this.jobs)
                }
            }

        })
    }

    processStorageJobContentRequest(msg){

        //alert('processStorageJobContentRequest')

        const job = getObjectByID(msg.JobID, this.files, 'jobID')

        const {file, chunkNum} = ((job, type)=>{
            switch(msg.ContentType){
                case 0: return {file: job.thumb, chunkNum: job.previewChunkNum} 
                case 1: return {file: job.file, chunkNum: job.fileChunkNum}
                default: return {file: null, chunkNum: null}
            }
        })(job, msg.ContentType)
        

        if(file){
            //const chunkNum = msg.ContentType===0 ? job.previewChunkNum : job.fileChunkNum
            const chunkSize = 8 * 1024
            const dataFlags = file.size > chunkSize * (chunkNum + 1) ? 0 : 1 
            //console.log('dataFlags: '+ dataFlags +'; '+ file.size + '; ' + chunkSize + '; ' + chunkNum)

            getFileChunk(file, chunkNum, chunkSize, val => {

                //console.log('data str len = ' + val.length)
                const data = btoa(val)
                let response = [{
                    MessageID: "STORAGE_JOB_CONTENT", 
                    JobID: msg.JobID,
                    NetworkID: msg.NetworkID,
                    ContentType: msg.ContentType,
                    DataFlags: dataFlags,
                    Data: data
                }];
                //onsole.log('STORAGE_JOB_CONTENT send chunkNum='+chunkNum)
                //console.log(response)
                this.ws.send(JSON.stringify(response));

            })
            

            if(msg.ContentType===0){
                job.previewChunkNum ++ 
            }else if(msg.ContentType===1){
                job.fileChunkNum ++
            }

        }

    }

    processStorageJobInfo(msg){

        let job = getObjectByID(msg.JobID, this.jobs, 'JobID')
        //if(!job) alert('job is empty!')
        //console.error('job')
        //console.error('job')
        console.log({...job})
        //console.error('msg')
        //console.error(msg)
        //alert('processStorageJobInfo: see console log')
        
        if(job){
            console.log('assign')
            Object.assign(job, msg)
        } else {
            job = msg
            console.log('this.jobs.push')
            console.log(msg)
            this.jobs.push(msg)
        }

        const mimeType = getFileMimeType(job.FileName)
        if(!job.MimeType){
            job.MimeType = mimeType
        }

        job.previewChunks = []
        job.fileChunks = []

        console.error('job')
        console.error({...job})

        const jobID = job.JobID

        //preveiw
        if(job.JobType===1){
            
            const filter = {
                type: 0,
                job_id: jobID
            }
            this.fileStorage.queryFiles(filter, (result)=>{
                if(result && result.length===1){
                    //alert('YES  type 0')
                    console.log('YES type 0')
                    console.log(result)
                }else if(result.length===0){
                    //alert('NO type 0')
                    console.log('NO type 0')
                    console.log(result)

                    const fileObject = {
                        job_id: job.JobID,
                        name: job.FileName,
                        mime_type: job.MimeType,
                        size: job.PreviewLen,
                        type: 0,
                        state: 0,
                        network_id: job.NetworkID
                    }
                    console.error(fileObject)

                    this.fileStorage.addFile(fileObject, fileID => {
                        job.previewID = fileID
                        console.log('fileStorage.addFile = ' + fileID)
                        //const job = getObjectByID(msg.JobID, this.jobs, 'JobID')
                        //if(job){
                            job.previewChunks.forEach(fileChunk=>{
                                console.log('previewChunk add')
                                console.log(fileChunk)
                                if(fileChunk.file_id===undefined){
                                    fileChunk.file_id = fileID
                                    this.fileStorage.addChunk(fileChunk)
                                }
                            })
                        //}
                    })

                }
            })

        }

        //file or image
        if(job.JobType===1 || job.JobType===4){
            
            const filter = {
                type: 1,
                job_id: jobID
            }
            this.fileStorage.queryFiles(filter, (result)=>{
                if(result && result.length===1){
                    //alert('YES  type 1')
                    console.log('YES type 1')
                    console.log(result)
                }else if(result.length===0){
                    //alert('NO type 1')
                    console.log('NO type 1')
                    console.log(result)

                    const fileObject = {
                        job_id: msg.JobID,
                        name: job.FileName,
                        mime_type: getFileMimeType(job.FileName),
                        size: job.DataLen,
                        type: 1,
                        state: 0,
                        network_id: job.NetworkID
                    }
                    console.error(fileObject)

                    this.fileStorage.addFile(fileObject, fileID => {
                        job.fileID = fileID
                        console.log('fileStorage.addFile = ' + fileID)

                        if(job.DataLen < FILESTORAGE_MAX_FILE_SIZE){
                            job.fileChunks.forEach(fileChunk=>{
                                console.log('fileChunk add')
                                console.log(fileChunk)
                                if(fileChunk.file_id===undefined){
                                    fileChunk.file_id = fileID
                                    this.fileStorage.addChunk(fileChunk, ()=>{
                                        if(fileChunk.last){
                                            //alert('this.updateFileState# '+ msg.JobID + '; '+ job.NetworkID)
                                            this.updateFileState(jobID, fileID, 2, job.NetworkID)
                                        }
                                    })
                                }
                            })
                        }
                    })
                    
                }
            })

        }

    }


    processStorageJobContent(msg){
        
        const job = getObjectByID(msg.JobID, this.jobs, 'JobID')
        //console.log('processStorageJobContent')
        //console.log('get job '+msg.JobID)
        //console.log({...job})
        if(!job) return

        let num, fileID
        if(msg.ContentType===0){
            num = job.previewChunkNum
            fileID = job.previewID
            job.previewChunkNum ++
        }else if(msg.ContentType===1){

            if(job.canceled) return

            num = job.fileChunkNum
            fileID = job.fileID
            job.fileChunkNum ++
        }

        const fileChunk = {
            num: num,
            data: msg.Data,
            last: msg.DataFlags
        }
        if(fileID) fileChunk.file_id = fileID

        let chunks = null
        if(job.previewChunks===undefined && msg.ContentType===0){
            chunks = job.previewChunks = []
        }else if(job.fileChunks===undefined && msg.ContentType===1){
            chunks = job.fileChunks = []
        }else if(msg.ContentType===0){
            chunks = job.previewChunks
        }else if(msg.ContentType===1){
            chunks = job.fileChunks
        }
        if(chunks) chunks.push(fileChunk)

        if(fileID && msg.ContentType===0){
            //console.log('fileStorage.addChunk fileChunk')
            //console.log(fileChunk)
            //todo uncomment
            this.fileStorage.addChunk(fileChunk)
        }else if(fileID && msg.ContentType===1){
            //console.log('fileStorage.addChunk fileChunk')
            //console.log(fileChunk)
            if(job.DataLen < FILESTORAGE_MAX_FILE_SIZE){
                this.fileStorage.addChunk(fileChunk, ()=>{
                    if(fileChunk.last){
                        //alert('this.updateFileState '+ msg.JobID + '; '+ msg.NetworkID)
                        this.updateFileState(msg.JobID, fileID, 2, msg.NetworkID)
                    }
                })
            }else{
                //alert('processStorageJobContent: max file size ' + job.DataLen + '; ' + FILESTORAGE_MAX_FILE_SIZE)
            }
            
        }else{
            console.log('empty fileID')
        }


        if(msg.DataFlags===1){
            let onsuccess
            if(msg.ContentType===0 && job.previewOnSuccess){
                onsuccess = job.previewOnSuccess
            }else if(msg.ContentType===1 && job.fileOnSuccess){
                onsuccess = job.fileOnSuccess
            }

            if(onsuccess){
                //alert('job.onsuccess')
                console.log('job.onsuccess')
                console.log({...job})
                //console.log(chunks)

                if(chunks){
                    const arr = chunks.map(item => base64ToUint8Array(item.data))
                    const b = new Blob(arr, {type: job.MimeType, name: job.FileName});//'application/octet-stream'
                    console.log('blob')
                    console.log(b)
                    onsuccess(URL.createObjectURL(b))
                    
                    //clear chunks 
                    //chunks.splice(0, chunks.length)
                    deleteItemByID(job.JobID, this.jobs, 'JobID')
                    console.log('this.jobs')
                    console.log(this.jobs)
                }
                
                //alert('onsuccess')
            }else{
                //unlock data request
                job.inProcess = false
            }
            //todo clear jobs
        }else{
            if(job.DataLen > DOWNLOAD_MAX_FILE_SIZE){
                alert('Max file size exceeded: ' + DOWNLOAD_MAX_FILE_SIZE)
                return
            }
            //get next chunk
            const message = {
                MessageID: 'STORAGE_JOB_CONTENT_REQUEST',
                JobID: msg.JobID,
                ContentType: msg.ContentType,
                NetworkID: msg.NetworkID
            }
            //console.log('STORAGE_JOB_CONTENT_REQUEST next chunk query')
            //console.log(message)
            this.ws.send(JSON.stringify([message]));
        }

    }


    cancelJob(jobID, networkID){
        let job = getObjectByID(jobID, this.jobs, 'JobID')
        if(!job){
            job = {JobID: jobID, NetworkID: networkID}
        }

        if(job){
            job.inProcess = true
            job.canceled = true
            job.fileChunkNum = 0
            job.fileChunks = []
            //clear chunks type=1
            this.updateFileState(jobID, job.fileID, 1, job.NetworkID)
            this.options.fileStorage.deleteChunks(jobID, 1, ()=>{
                console.log('deleteChunks complete')
                //clear file && event state
                this.updateFileState(jobID, job.fileID, 0, job.NetworkID)
                //clear job
                job.canceled = false
                deleteItemByID(job.JobID, this.jobs, 'JobID')
            })
        }

        const file = getObjectByID(jobID, this.files, 'JobID')
        if(file){
            this.queryCancelJob(jobID, networkID)

            //clear chunks type=1
            this.options.fileStorage.deleteChunks(jobID, 1, (fileID)=>{

                this.options.eventLogger.update(jobID, {file_state: 0})
                //clear state

                console.log(file)
                //alert('set file state')
                this.updateFileState(jobID, fileID, 0, job.NetworkID)
                //clear job
                deleteItemByID(job.JobID, this.jobs, 'JobID')
            })

        }
    }

    queryCancelJob(jobID, networkID){
        const message = [{ 
            MessageID: "STORAGE_JOB_CONTROL_REQUEST", 
            JobID: jobID,
            NetworkID: networkID
        }];
        console.log('STORAGE_JOB_CONTROL_REQUEST')
        console.log(message)
        this.ws.send(JSON.stringify(message))
    }


    getNetworkIndexByID(networkID){
        const index = this.networks.findIndex(item=>item.ID===networkID)
        return index>=0 ? index : null;
    }

    getUserIndexByID(userID, networkID){
        let networkIndex = this.getNetworkIndexByID(networkID)
        if(networkIndex===null) return null;

        if(this.networks && this.networks[networkIndex] && this.networks[networkIndex].users){
            for(let i=0; i<this.networks[networkIndex].users.length; i++){   
                let user = this.networks[networkIndex].users[i]
                if(user.ID===userID) return i
            }
        }
        return null
    }

    queryGetNetworkSettings(networkID, onComplete){
        const getnetMessage = [{ MessageID: "SRV_GET_NETWORKSETTINGS", ID: networkID}];
        this.ws.send(JSON.stringify(getnetMessage));
        this.onGetnetComplete = onComplete;
    }

    querySaveNetworkSettings(NetworkSettings, onComplete){
        const saveNetworkMessage = [{ MessageID: "SRV_SET_NETWORKSETTINGS", NetworkSettings: NetworkSettings}]
        console.log('querySaveNetworkSettings')
        console.log(saveNetworkMessage)
        this.ws.send(JSON.stringify(saveNetworkMessage));
    }

    queryGetDeviceSettings(networkID, deviceID, requestID, onComplete){
        const message = [{ 
            MessageID: "OTAP_SETTINGS_REQUEST", 
            NetworkID: networkID,
            RequestID: requestID,
            DeviceID: deviceID
        }];
        console.log('queryGetDeviceSettings')
        console.log(message)
        this.onGetDeviceSettingsComplete = onComplete;
        console.log(JSON.stringify(message));
        this.ws.send(JSON.stringify(message));
    }

    querySaveDeviceSetings(networkID, userID, deviceID, deviceSettings){
        const jobID = getBase64ID()

        debugger
        const time = new Date()
        const utcTime = Date.UTC(
            time.getFullYear(), 
            time.getMonth(),
            time.getDate(),
            time.getHours(),
            time.getMinutes(),
            time.getSeconds() )

        const message = [{ 
            MessageID: "OTAP_JOB_REQUEST", 
            JobID: jobID,
            Time: time.getTime(),
            DispatcherID: this.userID,
            SourceName: this.login,
            Users: [userID],
            DeviceID: deviceID,
            JobType: 0,
            JobState: 0,

            Content: {Settings: JSON.stringify(deviceSettings) },
            ExpirationTime: utcTime,
            NetworkID: networkID
        }];
        console.log('saveDeviceSetings')
        console.log(deviceSettings)
        console.log(message)
        this.ws.send(JSON.stringify(message));
/*
message sample
{
  "MEssageID": "OTAP_JOB_REQUEST",
  "JobID": "2Aht/jqGFEa24MBLjPcMGg==",
  "Time": 1572881170787,
  "DispatcherID": "ylAFjhg6fESBF/uR2C3AAg==",
  "SourceName": "Administrator",
  "Users": [
    "09138564-9bd2-45ad-befd-fe4afc7ee236"
  ],
  "DeviceID": "RMELuvm+ZYEWNsDbYfM0/A==",
  "JobType": 0,
  "JobState": 0,
  "Content": {
    "Settings": "{\r\n  \"AutoLogin\": true,\r\n  \"ShowOnBoot\": true\r\n}"
  },
  "ExpirationTime": 1572881770787,
  "NetworkID": "498e3fc6-ab21-47cf-bde7-2a1098349a3b"
}
*/

    }

    queryGpsDeviceRequest(networkID, deviceID, groupID, period){
        const requestID = getBase64ID()
        const timing = period ? 1 : 0

        //const network = getObjectByID(networkID, this.networks)

        const devices = deviceID? {Devices: [deviceID]} : {}
        const groups = groupID? {Groups: [groupID]} : {}

        const message = [{ 
            MessageID: "GPS_DEVICE_REQUEST", 
            NetworkID: networkID,
            RequestID: requestID,
            Timing: timing,
            Period: period ? period : 0,
            ...devices,
            ...groups
        }];
        console.log('GPS_DEVICE_REQUEST')
        console.log(message)
        this.ws.send(JSON.stringify(message));

        return requestID
/*
        {
            "MessageID": "GPS_DEVICE_REQUEST",
            "NetworkID": "dfg8cLjqAU2iqyASxICVND==",
            "RequestId": "roq8cLjqAU2iqyMWxIEMIA==",
            "Timing": 0,
            "Period": 60,
            "Devices": [
              "lBZVx9bqmytnlNGw1eS3EA=="
            ]
          }

          */
    }

    queryGpsDeviceCancel(networkID, cancelRequestID){
        const requestId = getBase64ID()

        const message = [{ 
            MessageID: "GPS_DEVICE_CANCEL", 
            NetworkID: networkID,
            RequestId: requestId,  
            CancelRequestID: cancelRequestID
        }];
        console.log('gpsDeviceCancel')
        console.log(message)
        this.ws.send(JSON.stringify(message));

        /*
{
  "MessageID": "GPS_DEVICE_CANCEL",
  "RequestId": "ddW7HobvJ0+PkD1Y+by+jQ==",
  "CancelRequestId": "dKeK0ROGQ0CNI9LdOfg6ug==",
}
        */
    }


    queryAvatar(hash, networkID){
        const message = [{ 
            MessageID: "DATAEX", 
            DataType: TYPE_QUERYAVATAR,
            Operation: 0,
            Hash: hash,
            NetworkID: networkID
        }]; 
        console.log('AvatarRequest')
        console.log(message)
        this.ws.send(JSON.stringify(message));
        /*example
        {
  "MessageID": "DATAEX",
  "DataType": 20,
  "Operation": 0,
  "Hash": "ae09c72ffa4396c266d048aa0a9d5e4e"
}
        */
    }


    /** VOICE **/

    /**
     * @param {*} destination ID
     * @param {*} type  'group', 'device'
     */
    startPTT(id, type, networkID){
        this.pttNetworkID = networkID
        var pttrequest = [{
            MessageID: "PTT_REQUEST", 
            NetworkID: networkID,
            Destination: id, 
            Type: type
        }];
        console.log('PTT_REQUEST startPTT')
        console.log(pttrequest)

        this.ws.send(JSON.stringify(pttrequest));
        this.audio.start()
    }
    
    stopPTT(id, type, networkID){
        var pttrequest = [{
            MessageID: "PTT_REQUEST", 
            NetworkID: networkID,
            Destination: id, 
            Type: type
        }];

        console.log('PTT_REQUEST startPTT')
        console.log(pttrequest)

        this.ws.send(JSON.stringify(pttrequest));
        this.audio.stop()
    }

    sendVoicePacket(data){
        const str = uint8ArrayToBase64(data);
        const message = [{ 
            MessageID: "VOICE_PACKET", 
            NetworkID: this.pttNetworkID,  
            Data: str 
        }];
        //console.log('VOICE_PACKET')
        //console.log(message)
        this.ws.send(JSON.stringify(message));
    }

    processVoicePacket(msg) {
        
        var udp = base64ToUint8Array(msg.Data);

        //let arr0 =  udp.subarray(0, 12)//??
        let arr1 =  udp.subarray(12, 28)//CallID
        let callID = uint8ArrayToBase64(arr1)

        //debugger
        let index = getIndexByID(callID, this.calls, 'CallID')
        let call = this.calls[index]
        if(call.SoundIsMuted){
            //alert('muted:'+call.SoundIsMuted.toString())
            console.log('Sound is muted')
            return false;
        }
        

        console.log('processVoicePacket() data len: '+msg.Data.length + ' NetworkID: '+msg.NetworkID)
        //console.log(msg)
        //console.log(msg.Data)
        //console.log(udp)
        this.audio.processAudioFrame(udp);
    };

    voiceStateToEventState(state){
        switch(state){
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_ENTER:
            case PTT_CONTROL_TYPES.VOICE_GROUP_ENTER:
                return EVENT_STATE.STATE_CALL_INITIATED

            case PTT_CONTROL_TYPES.VOICE_GROUP_BEGIN: 
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_BEGIN: 
                return EVENT_STATE.STATE_CALL_INITIATED

            case PTT_CONTROL_TYPES.VOICE_PRIVATE_PRESSED:
            case PTT_CONTROL_TYPES.VOICE_GROUP_PRESSED:    
                return EVENT_STATE.STATE_CALL_ACCEPTED

            case PTT_CONTROL_TYPES.VOICE_PRIVATE_RELEASED:
            case PTT_CONTROL_TYPES.VOICE_GROUP_RELEASED:    
                return EVENT_STATE.STATE_CALL_ACCEPTED

            case PTT_CONTROL_TYPES.VOICE_PRIVATE_END: 
            case PTT_CONTROL_TYPES.VOICE_GROUP_END:
                return EVENT_STATE.STATE_CALL_FINISHED

            default:
                console.error('voiceStateToEventState: case not defined for state '+state)
        }

/*
    STATE_CALL_INITIATED: 1,
    STATE_CALL_ACCEPTED: 2,
    STATE_CALL_DECLINED: 3,
    STATE_CALL_MISSED: 4,
    STATE_CALL_MISSED_ACK: 14,
    STATE_CALL_NOTANSWERED: 5,
    STATE_CALL_FINISHED: 12,
*/

    }


    /** MESSAGES  **/

    querySendTextMessage(textMessage, userID, deviceID, groupID, targetType, networkID){
        const jobID = getBase64ID()

        const network = getObjectByID(networkID, this.networks)
        const user = getObjectByID(this.userID, network.users)

        let target
        switch(targetType){
            case 0: target = getObjectByID(userID, network.users); break
            case 1: target = getObjectByID(groupID, network.groups); break
            default: 
                console.error('querySendTextMessage: case undefined for type='+targetType) 
                console.error(arguments)
        }

        console.log('querySendTextMessage')
        console.log(arguments)
        console.log('target')
        console.log(target)

        const message = { 
            MessageID: "STORAGE_JOB_REQUEST", 
            JobID: jobID,
            Title: textMessage,
            NetworkID: networkID,  
            JobType: 0,
            ConversationType: targetType, //private, 1-for group 
            FromName: user.Name,
            ToName: target.Name
        };

        let eventType
        if(targetType===0){
            message.ToUserID = userID 
            message.ToDeviceID = deviceID
            eventType = EVENT_TYPE.TYPE_TEXT_PRIVATE
        }
        if(targetType===1){
            message.GroupID = groupID
            eventType = EVENT_TYPE.TYPE_TEXT_GROUP
        }

        console.log('STORAGE_JOB_REQUEST (send message)')
        console.log([message])

        this.ws.send(JSON.stringify([message]));

        if(this.options.eventLogger){
            const event = {
                type: eventType,
                state: EVENT_STATE.STATE_TEXT_INITIATED,
                sender: message.FromName,
                recipient: message.ToName,
                note: message.Title,
                ref_id: message.JobID,
                ref_device_id: deviceID,
                ref_user_id: userID,
                ref_group_id: groupID,
                network_id: networkID,
                outgoing: true
            }
            //console.log('querySendTextMessage log event')
            //console.log(event)
            this.options.eventLogger.add(event)

            this.registerTextMessageEvent(event, networkID)
        }

    }

    querySendFile(thumb, file, title, userID, deviceID, groupID, targetType, networkID){
        const jobID = getBase64ID()

        const network = getObjectByID(networkID, this.networks)
        const user = getObjectByID(this.userID, network.users)

        this.files.push({
            jobID: jobID,
            thumb: thumb,
            file: file,
            previewChunkNum: 0,
            fileChunkNum: 0
        })

        let target
        switch(targetType){
            case 0: target = getObjectByID(userID, network.users); break
            case 1: target = getObjectByID(groupID, network.groups); break
            default: 
                console.error('querySendFile: case undefined for type='+targetType) 
                console.error(arguments)
        }

        console.log('querySendFile')
        console.log(arguments)
        console.log('target')
        console.log(target)

        const message = { 
            MessageID: "STORAGE_JOB_REQUEST", 
            JobID: jobID,
            Title: title ? title : file.name,
            NetworkID: networkID,  
            JobType: thumb ? 1 : 4,
            ConversationType: targetType, //0-private, 1-for group 
            FromName: user.Name,
            ToName: target.Name,
            FileName: file.name,
            DataLen: file.size,
            PreviewLen: thumb ? thumb.size : 0
        };

        let eventType
        if(targetType===0){
            message.ToUserID = userID 
            message.ToDeviceID = deviceID
            eventType = thumb ? EVENT_TYPE.TYPE_IMAGE_PRIVATE : EVENT_TYPE.TYPE_FILE_PRIVATE
        }
        if(targetType===1){
            message.GroupID = groupID
            eventType = thumb ? EVENT_TYPE.TYPE_IMAGE_GROUP : EVENT_TYPE.TYPE_FILE_GROUP
        }

        console.log('STORAGE_JOB_REQUEST (send file)')
        console.log([message])

        this.ws.send(JSON.stringify([message]));

        if(this.options.eventLogger){
            const event = {
                type: eventType,
                state: EVENT_STATE.STATE_TEXT_INITIATED,
                file_state: 0,
                sender: message.FromName,
                recipient: message.ToName,
                note: message.Title,
                ref_id: message.JobID,
                ref_device_id: deviceID,
                ref_user_id: userID,
                ref_group_id: groupID,
                network_id: networkID,
                outgoing: true
            }
            //console.log('querySendTextMessage log event')
            //console.log(event)
            this.options.eventLogger.add(event)

            this.registerTextMessageEvent(event, networkID)
        }
         
        return jobID
    }


    queryGetJobData(jobID, type, fileID, networkID, onsuccess){
        let job = getObjectByID(jobID, this.jobs, 'JobID')

        console.log('queryGetJobData0 '+ fileID + '; ' + jobID)
        console.log([...this.jobs])
        console.log({...job})
        console.log(onsuccess)
        if(job && job.canceled) alert('canceled') 

        if(!job){

            //read from output cache
            const file = getObjectByID(jobID, this.files, 'jobID')
            if(file && type===0 && file.thumb){
                console.log('FileReader preview')
                const reader = new FileReader();
                reader.onload = e => { onsuccess(e.target.result) }
                reader.readAsDataURL(file.thumb)
                return
            }else if(file && type===1 && file.file){
                console.log('FileReader file')
                const reader = new FileReader();
                reader.onload = e => { onsuccess(e.target.result) }
                reader.readAsDataURL(file.file)
                return
            }

            job = {
                JobID: jobID,
                NetworkID: networkID,
                inProcess: true
                //onsuccess: onsuccess //return content as file.url object
            }
            console.log('this.jobs.push')
            console.log({...job})
            this.jobs.push(job)

        }else if(job && job.inProcess){
            //job not yet complete
            //add return handler
            console.log('JOB in process...')
            console.log({...job})
            if(type===0 && !job.previewOnSuccess){
                job.previewOnSuccess = onsuccess //return content as file.url object
            }else if(type===1 && !job.fileOnSuccess){
                job.fileOnSuccess = onsuccess //return content as file.url object
            }
            return; //skip current request
        }

        if(type===0){
            job.previewID = fileID
            job.previewChunkNum = 0
            job.fileChunkNum = 0
            job.previewOnSuccess = onsuccess //return content as file.url object
        }else if(type===1){
            job.fileID = fileID
            job.fileChunkNum = 0
            job.fileOnSuccess = onsuccess //return content as file.url object
        }

        console.log('queryGetJobData1')
        console.log({...job})

        if(type===1) this.updateFileState(jobID, fileID, 1, networkID)

        const message = {
            MessageID: 'STORAGE_JOB_CONTENT_REQUEST',
            JobID: jobID,
            ContentType: type,
            NetworkID: networkID
        }
        console.log('STORAGE_JOB_CONTENT_REQUEST query')
        console.log(message)
        this.ws.send(JSON.stringify([message]));

    }

    /**
     * store message to network cache
     */
    registerTextMessageEvent(event, networkID){
        //console.log('registerTextMessageEvent')
        //console.log(event)
        const network = getObjectByID(networkID, this.networks)

        if(network.messages){

            network.messages.forEach(subscribe => {
                //console.log(subscribe)
                const {userID, deviceID, groupID} = subscribe

                if(userID===event.ref_user_id && deviceID===event.ref_device_id && groupID===event.ref_group_id){
                    console.log('register message')
                    console.log(event)
                    if(!event.id) event.id = event.ref_id //surrogate key for react list.key
                    subscribe.items.unshift(event)
                    this.options.onNetworksChanged(this.networks)
                    //console.log('add event subscribe ' + userID + '; '+ deviceID + '; '+groupID)
                }

            })

        }

    }

    updateTextMessageState(jobID, state, networkID){
        //console.log('updateTextMessageState ')
        //console.log(arguments)
        const eventState = this.jobStateToEventState(state)

        const network = getObjectByID(networkID, this.networks)
        if(network && network.messages){
            //console.log(jobID)
            //console.log(network.messages)

            network.messages.forEach(subscribe=>{

                const message = getObjectByID(jobID, subscribe.items, 'ref_id')
                //console.log(message)
                if(message){
                    //console.log('set eventState='+eventState)
                    message.state = eventState
                    this.options.onNetworksChanged(this.networks)
                }

            })

        }

        if(this.options.eventLogger){
            this.options.eventLogger.updateState(jobID, eventState);
        }
    }

    updateFileState(jobID, fileID, state, networkID){
        console.log('updateFileState ')
        console.log(arguments)
        //alert('updateFileState = '+state)
        
        const network = getObjectByID(networkID, this.networks)
        if(network && network.messages){
            network.messages.forEach(subscribe=>{
                const message = getObjectByID(jobID, subscribe.items, 'ref_id')
                console.log(message)
                if(message){
                    message.file_state = state
                    this.options.onNetworksChanged(this.networks)
                }
            })
        }
        if(this.unreadMessages){
            const message = getObjectByID(jobID, this.unreadMessages, 'ref_id')
            if(message){
                message.file_state = state
                this.options.onUnreadMessagesChanged(this.unreadMessages)
            }
        }

        if(this.options.eventLogger){
            this.options.eventLogger.update(jobID, {file_state: state});
        }

        if(this.options.fileStorage){
            if(fileID){
                //alert('set file.state='+state + '; '+fileID)
                this.options.fileStorage.update(fileID, {state: state});
            }else{
                this.options.fileStorage.getFileID(jobID, 1, fileID=>{
                    //alert('get set file.state='+state + '; '+fileID)
                    this.options.fileStorage.update(fileID, {state: state});
                })
            }
        }
    }

    markMessageRead(jobID, networkID){
        deleteItemByID(jobID, this.unreadMessages)

        const network = getObjectByID(networkID, this.networks)
        if(network && network.messages){
            network.messages.forEach(subscribe => {
                const message = getObjectByID(jobID, subscribe.items, 'ref_id')
                if(message) message.state = EVENT_STATE.STATE_TEXT_READ
            })
        }

        //todo
        this.options.eventLogger.updateState(jobID, EVENT_STATE.STATE_TEXT_READ);

        this.options.onUnreadMessagesChanged(this.unreadMessages)
        this.options.onNetworksChanged(this.networks)
    }

    markAllMessagesRead(userID, deviceID, groupID, networkID){
        console.log('markAllMessagesRead')
        console.log(arguments)
        const network = getObjectByID(networkID, this.networks)
        const unreadMessages = [...this.unreadMessages]
        unreadMessages.forEach(message => {
            console.log('message')
            console.log(message)
            if(userID && deviceID){
                if(message.ref_user_id===userID && message.ref_device_id===deviceID){

                    const jobID = message.ref_id

                    deleteItemByID(jobID, this.unreadMessages)

                    if(network.messages){
                        network.messages.forEach(subscribe => {
                            const message = getObjectByID(jobID, subscribe.items, 'ref_id')
                            if(message){
                                message.state = EVENT_STATE.STATE_TEXT_READ
                            }
                        })
                    }

                    //todo
                    this.options.eventLogger.updateState(jobID, EVENT_STATE.STATE_TEXT_READ);

                }
            }
        })

        this.options.onUnreadMessagesChanged(this.unreadMessages)
        this.options.onNetworksChanged(this.networks)
    }


    jobStateToEventState(state){
        switch(state){
            case JOB_STATES.JOB_STATE_SENDED: return EVENT_STATE.STATE_TEXT_UNREAD
            case JOB_STATES.JOB_STATE_CONFIRMING: return EVENT_STATE.STATE_TEXT_ACCEPTED
            case JOB_STATES.JOB_STATE_DELIVERED: return EVENT_STATE.STATE_TEXT_DELIVERED
            case JOB_STATES.JOB_STATE_CANCELLED: return EVENT_STATE.STATE_TEXT_CANCELED
            default: console.error('jobStateToEventState: case undefined for state='+state) 
        }
    }

    subscribeMessages(userID, deviceID, groupID, networkID){

        if(!this.options.eventLogger) return

        console.log('subscribeMessages')
        const network = getObjectByID(networkID, this.networks)

        if(network.messages==null){
            network.messages = []
        }

        const subscribe = network.messages.filter(item =>
            item.userID===userID && item.deviceID===deviceID && item.groupID===groupID
        )

        if(!subscribe.length){

            let subscribe = {
                userID: userID,
                deviceID: deviceID,
                groupID: groupID,
                items: []
            }
            network.messages.push(subscribe)

            this.options.onNetworksChanged(this.networks)

            //todo fill items from event log

            const filter = {}
            if(userID) filter.ref_user_id = userID
            if(userID) filter.type = [
                EVENT_TYPE.TYPE_TEXT_PRIVATE, 
                EVENT_TYPE.TYPE_FILE_PRIVATE, 
                EVENT_TYPE.TYPE_IMAGE_PRIVATE
            ]
            if(deviceID) filter.ref_device_id = deviceID
            if(groupID) filter.ref_group_id = groupID
            if(groupID) filter.type = [
                EVENT_TYPE.TYPE_TEXT_GROUP,
                EVENT_TYPE.TYPE_FILE_GROUP,
                EVENT_TYPE.TYPE_IMAGE_GROUP
            ]
            this.options.eventLogger.queryEvents(filter, messages => {

                console.log('query messages')
                console.log(filter)
                console.log(messages)

                subscribe.items = messages
                this.options.onNetworksChanged(this.networks)
            })
            
            

        }

    }

    unsubscribeMessages(userID, deviceID, groupID, networkID){
        console.log('unsubscribeMessages')

        const network = getObjectByID(networkID, this.networks)

        if(network.messages && network.messages.length){
            const index = network.messages.findIndex(subscribe =>
                subscribe.userID===userID && subscribe.deviceID===deviceID && subscribe.groupID===groupID
            )
            if(index>=0) network.messages.splice(index, 1)
        }
    }


    initUnreadMessages(){
        const filter = {
            type: [EVENT_TYPE.TYPE_TEXT_GROUP, 
                    EVENT_TYPE.TYPE_TEXT_PRIVATE,
                    EVENT_TYPE.TYPE_IMAGE_GROUP,
                    EVENT_TYPE.TYPE_IMAGE_PRIVATE,
                    EVENT_TYPE.TYPE_FILE_GROUP,
                    EVENT_TYPE.TYPE_FILE_PRIVATE
                ],
            state: EVENT_STATE.STATE_TEXT_UNREAD
        }
        
        this.options.eventLogger.queryEvents(filter, messages => {

            console.log('query unread messages')
            console.log(filter)
            console.log(messages)

            this.unreadMessages = messages
            this.options.onUnreadMessagesChanged(messages)
        })
    }

    appendUnreadMessages(message){
        this.unreadMessages.unshift(message)
        this.options.onUnreadMessagesChanged(this.unreadMessages)
    }

    //////////////////////////////////////
    //Groups Muting api
    //////////////////////////////////////
    groupSoundMute(networkID, groupID){
        //alert('api groupSoundMute')
        //console.log(this.networks)
        //debugger

        let networkIndex = getIndexByID(networkID, this.networks)

        let groupIndex = getIndexByID(groupID, this.networks[networkIndex].groups)

        //alert(networkIndex + '; ' +  groupIndex)

        let group = this.networks[networkIndex].groups[groupIndex]
        //console.log(group)
        let isMuted = group.SoundIsMuted!=undefined ? group.SoundIsMuted : false

        //inverse state
        this.networks[networkIndex].groups[groupIndex].SoundIsMuted = !isMuted

        if(!isMuted){
            //add item
            this.mutedGroups.push(groupID)
        }else{
            //remove item
            const index = this.mutedGroups.findIndex(item=>item===groupID)
            this.mutedGroups.splice(index, 1)
        }
        this.saveGroupsMuted()

        this.options.onNetworksChanged(this.networks)
    }

    getGroupIsMuted(groupID){
        //debugger
        const index = this.mutedGroups.findIndex(item=>item===groupID)
        return index!==-1
    }
    
    initGroupsMuted(){
        //debugger
        try {
            let arr = JSON.parse( localStorage.getItem('MutedGroups'))
            if(arr) this.mutedGroups = arr
        }catch(e){}
    }

    saveGroupsMuted(){
        //debugger
        try {
            localStorage.setItem('MutedGroups', JSON.stringify( this.mutedGroups ))
        }catch(e){}
    }


    //////////////////////////////////////
    // Permissions api
    //////////////////////////////////////

    checkPermission (networkID, permission){

        //return true;

        const net = getObjectByID(networkID, this.networks)  
        if(!net) return null;

        //console.log('checkPermission')
        //console.log(networkID)
        //console.log(net)

        console.log('permission =' + permission)
        //debugger
        let allowed
        if(permission>=4294967296){
            permission = permission/4294967296;
            allowed =  net.AllowedOps2 & permission 
        }else{
            allowed =  net.AllowedOps & permission
        }

        //console.log('permission =' + permission)
        //console.log('AllowedOps='+net.AllowedOps)
        //console.log('AllowedOps2='+net.AllowedOps2)
        //console.log('allowed')
        //console.log(allowed)

        return allowed === permission;

    }

}//end class



//helpers
/*
export function getIndexByID(ID, array, field){
    if(!array) return null
    if(field===undefined) field="ID"
    const index = array.findIndex(item=>item[field]===ID)
    return index>=0 ? index : null
}

export function getObjectByID(ID, array, field){
    const index = getIndexByID(ID, array, field)
    return index===null? null : array[index]
}

/**
 * Delete item from array using ID or custom field
 * return removed item or null if item not exist
 * @param {string} ID 
 * @param {Array.<Object>} array 
 * @param {string} field 
 */
/*
export function deleteItemByID(ID, array, field){
    const index = getIndexByID(ID, array, field)
    if(index>=0){
        return array.splice(index, 1)
    }
    return null
}
    
export function getBase64ID(){
    var array = new Uint8Array(16);
    window.crypto.getRandomValues(array);
    var b64 = '';
    for (var i = 0; i < array.length; i++) {
        b64 += String.fromCharCode( array[i] );
    }
    return window.btoa( b64 );		 
}

export function getDeviceID(){
    var deviceid = localStorage.getItem("DeviceID");
    if(deviceid === null){
        deviceid = getBase64ID();
        localStorage.setItem("DeviceID", deviceid);
    }
    return deviceid;
}

export function decimalColorToHexColor(number) {
    //converts to a integer
    var intnumber = number - 0;
 
    // isolate the colors - really not necessary
    var red, green, blue;
 
    // needed since toString does not zero fill on left
    var template = "#000000";
 
    // in the MS Windows world RGB colors
    // are 0xBBGGRR because of the way Intel chips store bytes
    red = (intnumber&0x000000ff) << 16;
    green = intnumber&0x0000ff00;
    blue = (intnumber&0x00ff0000) >>> 16;
    var rgb = intnumber & 0x00ffffff
 
    // mask out each color and reverse the order
    intnumber = red|green|blue;
 
    // toString converts a number to a hexstring
    var HTMLcolor = rgb.toString(16);
 
    //template adds # for standard HTML #RRGGBB
    HTMLcolor = template.substring(0,7 - HTMLcolor.length) + HTMLcolor;
 
    return HTMLcolor;
} 

export function hexColorToDecimalColor(hexColor){
    let number = 0
    console.log(hexColor)
    let hex = '0xff' + hexColor.substring(1, 7)
    number = parseInt(hex)
    return number
}

export class FormatByteArrayToGuidString {
    //@ Made by Zura Dalakishvili, Tbilisi, Georgia 2016
    //Guid representation created from  byte[] array for Javascript

    // Usage: 
    //  var inst = new Guid(array);
    //   var guidStr = inst.guidString;



    constructor(b){
        this.b = b;

        if (b === null)
        return;
        if (b.length !== 16)
        return;

        this.parts = {
            a: ((b[3]) << 24) | ((b[2]) << 16) | ((b[1]) << 8) | b[0],
            b: (((b[5]) << 8) | b[4]),
            c: (((b[7]) << 8) | b[6]),
            d: b[8],
            e: b[9],
            f: b[10],
            g: b[11],
            h: b[12],
            i: b[13],
            j: b[14],
            k: b[15]
        }

    }

    HexToChar(a) {
        a = a & 0xf;
        return String.fromCharCode(((a > 9) ? a - 10 + 0x61 : a + 0x30));
    }

    //HexsToChars(guidChars, offset, a, b) {
    //    return this.HexsToChars(guidChars, offset, a, b, false);
    //}

    HexsToChars(guidChars, offset, a, b, hex) {
        if (hex) {
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
        }
        guidChars[offset++] = this.HexToChar(a >> 4);
        guidChars[offset++] = this.HexToChar(a);
        if (hex) {
            guidChars[offset++] = ',';
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
        }
        guidChars[offset++] = this.HexToChar(b >> 4);
        guidChars[offset++] = this.HexToChar(b);
        return offset;
    }


    

    //_toGUID(this.b);


    toString = function (format) {
        if (format===undefined || format === null || format.length === 0)
            format = "D";

        var guidChars = [];
        var offset = 0;
        //var strLength;// = 38;
        var dash = true;
        var hex = false;

        if (format.length !== 1) {
            // all acceptable format strings are of length 1
            return null;
        }

        var formatCh = format[0];

        if (formatCh === 'D' || formatCh === 'd') {
            guidChars = new Array(36);
            //strLength = 36;
        } else if (formatCh === 'N' || formatCh === 'n') {
            guidChars = new Array(32);
            //strLength = 32;
            dash = false;
        } else if (formatCh === 'B' || formatCh === 'b') {
            guidChars = new Array(38);
            guidChars[offset++] = '{';
            guidChars[37] = '}';
        } else if (formatCh === 'P' || formatCh === 'p') {
            guidChars = new Array(38);
            guidChars[offset++] = '(';
            guidChars[37] = ')';
        } else if (formatCh === 'X' || formatCh === 'x') {
            guidChars = new Array(68);
            guidChars[offset++] = '{';
            guidChars[67] = '}';
            //strLength = 68;
            dash = false;
            hex = true;
        } else {
            return null;
        }

        if (hex) {
            // {0xdddddddd,0xdddd,0xdddd,{0xdd,0xdd,0xdd,0xdd,0xdd,0xdd,0xdd,0xdd}}
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
            offset = this.HexsToChars(guidChars, offset, this.parts.a >> 24, this.parts.a >> 16);
            offset = this.HexsToChars(guidChars, offset, this.parts.a >> 8, this.parts.a);
            guidChars[offset++] = ',';
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
            offset = this.HexsToChars(guidChars, offset, this.parts.b >> 8, this.parts.b);
            guidChars[offset++] = ',';
            guidChars[offset++] = '0';
            guidChars[offset++] = 'x';
            offset = this.HexsToChars(guidChars, offset, this.parts.c >> 8, this.parts.c);
            guidChars[offset++] = ',';
            guidChars[offset++] = '{';
            offset = this.HexsToChars(guidChars, offset, this.parts.d, this.parts.e, true);
            guidChars[offset++] = ',';
            offset = this.HexsToChars(guidChars, offset, this.parts.f, this.parts.g, true);
            guidChars[offset++] = ',';
            offset = this.HexsToChars(guidChars, offset, this.parts.h, this.parts.i, true);
            guidChars[offset++] = ',';
            offset = this.HexsToChars(guidChars, offset, this.parts.j, this.parts.k, true);
            guidChars[offset++] = '}';
        } else {
            // [{|(]dddddddd[-]dddd[-]dddd[-]dddd[-]dddddddddddd[}|)]
            offset = this.HexsToChars(guidChars, offset, this.parts.a >> 24, this.parts.a >> 16);
            offset = this.HexsToChars(guidChars, offset, this.parts.a >> 8, this.parts.a);
            if (dash) guidChars[offset++] = '-';
            offset = this.HexsToChars(guidChars, offset, this.parts.b >> 8, this.parts.b);
            if (dash) guidChars[offset++] = '-';
            offset = this.HexsToChars(guidChars, offset, this.parts.c >> 8, this.parts.c);
            if (dash) guidChars[offset++] = '-';
            offset = this.HexsToChars(guidChars, offset, this.parts.d, this.parts.e);
            if (dash) guidChars[offset++] = '-';
            offset = this.HexsToChars(guidChars, offset, this.parts.f, this.parts.g);
            offset = this.HexsToChars(guidChars, offset, this.parts.h, this.parts.i);
            offset = this.HexsToChars(guidChars, offset, this.parts.j, this.parts.k);
        }

        //let str = new String(guidChars)
        let guidStr = guidChars.join('');

        return guidStr; /// new String(guidChars);
    }

    guidString = function () {
        return this.toString('D', null).split(',').join('');
    }

    //return this.guidString();
}

export function base64toGuid(base64Str){
    let arr = base64ToUint8Array(base64Str)
    return new FormatByteArrayToGuidString(arr).toString()
}

export function sortByName(item1, item2){
    const name1 = (item1.Name!==undefined ? item1.Name : item1.name).toLowerCase()
    const name2 = (item2.Name!==undefined ? item2.Name : item2.name).toLowerCase()
    if (name1 > name2) return 1;
    if (name1 === name2) return 0; 
    if (name1 < name2) return -1;
}

export function sortByLogin(item1, item2){
    const login1 = item1.Login.toLowerCase()
    const login2 = item2.Login.toLowerCase()
    if (login1 > login2) return 1;
    if (login1 === login2) return 0; 
    if (login1 < login2) return -1;
}

export function sortByOnlineName(item1, item2){
    if (item1.DeviceID==null && item2.DeviceID) return 1;
    if (item1.DeviceID && item2.DeviceID==null) return -1;
    if ( (item1.DeviceID && item2.DeviceID) || (item1.DeviceID==null && item2.DeviceID==null) ){
        const name1 = item1.Name.toLowerCase()
        const name2 = item2.Name.toLowerCase()
        if (name1 > name2) return 1;
        if (name1 === name2) return 0; 
        if (name1 < name2) return -1;
    }
}

export function base64ToUint8Array(base64string) {
    const str = atob(base64string)
    const arrayBuffer = Uint8Array.from(str, function(c) { return c.charCodeAt(0); });
    return arrayBuffer;
}

export function uint8ArrayToBase64(arrayBuffer) {
    var base64 = btoa(arrayBuffer.reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64;
}
*/