import { red, grey, yellow, green } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6', // buttons and checkboxes
    },
    secondary: {
      main: grey[700], //app bar
    },
    error: {
      main: red.A400,
    },
    background: {
      default: grey[200],

      appBar: grey[700],

      dialog: 'white',   //Dialog component
      dialogTitle: grey[700], //Dialog component
      dialogSubTitle: grey[500], //Dialog component

      tabbedMenu: grey[700], //TabbedMenu component
      tabbedMenuSelected: grey[900],//TabbedMenu component

      tableRow: 'white',
      selectedRow: yellow[200],
      
      active: green[200],
      stopped: grey[200]
    },
    border: {
      dialog: grey[400]  //Dialog component
    },
    color: {
      dialogTitle: 'white',//Dialog component
      tabbedMenu: 'white',//TabbedMenu component
      alarm: 'red'
    }
  },
  typography: {
    formheader: {
      fontStyle: 'italic',
      fontSize: '20px'
    }
  },
  props: {
    MuiTypography: {
      variantMapping: {
        formheader: 'h4'
      }
    }
  },
  overrides: {
    MuiTableCell: {
      //sizeMedium: {
      //  padding: '10px'
      //},
      sizeSmall: {
        padding: '5px 3px'
      },
      head: {
        fontSize: '1em'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center'
      }
    },
    MuiDialogTitle: {
      root: {
        background: grey[700],
        color: 'white',
        fontWeight: 'bold'
      }
    },
    MuiTab: {
      wrapper: {
        flexDirection: 'row'
      }
    }
  }

});
//MuiTableCell-head
//MuiListItemIcon-root
//MuiDialogActions-root
//MuiDialogTitle-root

export default theme;