import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import { Dialog } from '../../components/Dialog';
import {Box, Button, Typography, Container, LinearProgress} from '@material-ui/core';
import Logo from '../../components/Logo';

//export 
class ConnectionProgress_ extends React.Component {

    render() {

        const { t } = this.props;

        let errorMessage = ''
        if (this.props.apiError) {
            errorMessage = (
                <>
                    <Typography align="center" color="error">
                        {this.props.apiMessage}
                    </Typography>

                    <Box textAlign="center" style={{ marginTop: '1em' }}>
                        <Button variant="contained" color="primary" align="center"
                            onClick={() => this.props.onAction({ type: "LOGOUT" })} >
                            {t('Back')}
                        </Button>
                    </Box>
                </>
            );
        }

        return (
            <Container height="100vh" style={{ paddingTop: '2em' }}>
                <Dialog variant="h6" title={(<Logo></Logo>)}>

                    <LinearProgress style={{marginBottom:10}}></LinearProgress>

                    <Typography variant="h4" component="h1" align="center">
                        {t('Connecting')}...
                    </Typography>
                    

                    {errorMessage}

                </Dialog>
            </Container>
        );        


    }
    
}

export const ConnectionProgress = withTranslation()(ConnectionProgress_);