import React from 'react';

//import Typography from '@material-ui/core/Typography';
//import Container from '@material-ui/core/Container';
//import Box from '@material-ui/core/Box';
//import theme from '../theme';

export default class Logo extends React.Component {

    render(){

        return(
            <img src={process.env.PUBLIC_URL + "/logo.png"} width={180} height={50} alt={this.props.title}/>
        );
/*
text logo sample

        let headerStyle = {
            padding:'0.2em 1em', 
            fontStyle:'italic', 
            color: theme.palette.color.dialogTitle, 
            //backgroundColor: theme.palette.background.dialogTitle,
            fontWeight: 'bold',
            fontSize: '1.8em'
        }

        return(
            <>
            <Box textAlign="left">
                <Typography component="h1" variant="h6" style={headerStyle}>
                    Bee<span style={{color:'#f57c00'}}>Talker</span>
                </Typography>
            </Box>
            </>
        )
*/
    } 
}
//let title = <>Bee<span style={{color:'#f57c00'}}>Talker</span></>;