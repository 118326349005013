import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {Container, Box} from '@material-ui/core'
import {Button, FormControl, Checkbox} from '@material-ui/core';

class EditUserPermissionsDialog_ extends React.Component{

    constructor(props){
        super(props)

        let permissions = this.props.permissions
        let permissionsArr = new Array(32)
        for(let i = 0; i < 32; ++i){
            permissionsArr[i] = (permissions >> i) & 1;
        }

        this.state = {
            changed: false,
            permissions: permissionsArr,
            errors: {}
        }
    }

    handleSave(){
        if(this.validate()){

            let permissions = 0
            let permissionsArr = this.state.permissions // Array(32)


            for(let i = 0; i < 32; ++i){
                permissions += permissionsArr[i] << i;
            }
            //alert('permissions = '+ permissions)

            this.props.onAction({type: 'USER_PERMISSIONS_EDITOR_SAVE', permissions: permissions})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'USER_PERMISSIONS_EDITOR_CLOSE'})
    }   

    handleInput(event){
        let changed = {}
        if(!this.state.changed) changed = {changed: true}

        let arr = event.target.name.split('_')
        let i = arr[1]

        let permissions = this.state.permissions
        permissions[i] = event.target.checked?1:0

        this.setState({permissions: permissions, ...changed})
    }

    validate(){
        //let errors = this.state.errors;
        let error = false
  /*   
        if(!this.state.group.Name){
            errors.Name = 'Name is empty'
            error = true
        }else{
            errors.Name = false
        }
        this.setState({errors: errors})
*/
        return !error;
    }


    render(){
        const {t} = this.props

        const title = t('UserPermissions')

        const margin = {margin:'16px 0 8px 0'}

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>
            
            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[1]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_1"
                            />
                            {t('AllowVoicePTTCalls')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[4]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_4"
                            />
                            {t('AllowVideoPTTCalls')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[6]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_6"
                            />
                            {t('AllowMessages')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[7]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_7"
                            />
                            {t('AllowLocationTracking')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[8]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_8"
                            />
                            {t('AllowCallAlert')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[17]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_17"
                            />
                            {t('ShowEventLog')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[18]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_18"
                            />
                            {t('ShowSearchBox')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[19]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_19"
                            />
                            {t('ShowSettings')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[20]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_20"
                            />
                            {t('RestrictPasswordChange')}
                    </label>
                </FormControl>
            </Box>

            
            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[25]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_25"
                            />
                            {t('RestrictLogoff')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.permissions[26]}
                            onChange={this.handleInput.bind(this)}
                            name="permissions_26"
                            />
                            {t('RestrictQuit')}
                    </label>
                </FormControl>
            </Box>

        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const EditUserPermissionsDialog = withTranslation()(EditUserPermissionsDialog_);