//import i18n from '../i18n'

export function getLang(i18n) {
    let lang = i18n.language
    if (lang.indexOf('-')) {
        lang = lang.split('-')[0]
    }
    let { langs } = i18n.options
    let defaultLang = null
    let existLang = null
    for (let key in langs) {
        if (key === lang) {
            existLang = key
            break
        }
        if (langs[key].default === true) {
            defaultLang = key
        }
    }

    if (!existLang) {
        lang = defaultLang
        i18n.changeLanguage(lang)
    }

    return lang
}