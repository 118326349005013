import { getSettingsInstance } from "./Settings";
import { formatDateTime } from "../../api/date";
import * as dev from '../../api/dev'

/**
 * record structure:
 */
const fields = [
    'id', //autoincrement not reqired
    'type', //EVENT_TYPE enum
    'datetime', 
    'dispatcher', 
    'sender', 
    'recipient', 
    'duration', 
    'note',
    'state', //EVENT_STATE enum
    'file_state', // 0 - transfer not complete, 1 - transfer started, 2 - transfer complete (upload or download); for file events only
    'outgoing', //direction of message or call
    'ref_id', //outer record id for change event state
    'ref_user_id', //indexed,  outer link to referred object
    'ref_group_id', //indexed,  outer link to referred object
    'ref_device_id', //indexed,  outer link to referred object
    'network_id'
]

export const EVENT_TYPE = {
    TYPE_VIDEO_PTT_GROUP: 1,
    TYPE_VIDEO_PTT_PRIVATE: 2,
    TYPE_VIDEO_PTT_EMERGENCYGROUP: 3,
    TYPE_VOICE_PTT_GROUP: 4,
    TYPE_VOICE_PTT_PRIVATE: 5,
    TYPE_VOICE_PTT_EMERGENCYGROUP: 6,
    TYPE_TEXT_GROUP: 7,
    TYPE_TEXT_PRIVATE: 8,
    TYPE_IMAGE_GROUP: 10,
    TYPE_IMAGE_PRIVATE: 11,
    TYPE_FILE_GROUP: 12,
    TYPE_FILE_PRIVATE: 13,
    TYPE_VIDEO_GROUP: 14,
    TYPE_VIDEO_PRIVATE: 15,
    TYPE_VOICEREC_GROUP: 16,
    TYPE_VOICEREC_PRIVATE: 17,
    TYPE_STATUS: 18,
    TYPE_MONITORING_REC: 19,
    TYPE_CALL_ALERT: 20,
    TYPE_EMERGENCY: 21,

    TYPE_UNDEFINED: 0,
    TYPE_LOGIN: 98,
}

export const EVENT_STATE = {
    STATE_UNDEFINED: 0,
    STATE_CALL_INITIATED: 1,
    STATE_CALL_ACCEPTED: 2,
    STATE_CALL_DECLINED: 3,
    STATE_CALL_MISSED: 4,
    STATE_CALL_MISSED_ACK: 14,
    STATE_CALL_NOTANSWERED: 5,
    STATE_CALL_FINISHED: 12,
    STATE_TEXT_INITIATED: 6, //text sent to server
    STATE_TEXT_UNREAD: 7, //text received but not read 
    STATE_TEXT_READ: 8, //text read by user
    STATE_TEXT_DELIVERED: 9, //text delivered to destination device
    STATE_TEXT_CANCELED: 10, //text delivery canceled
    STATE_TEXT_ACCEPTED: 11, //text accepted by server
}


export class EventLogger{

    constructor(indexedDB){

        this.db = null;

        this.indexedDB = indexedDB

        this.indexedDB.addUpgradeListener(this.onUpgradeNeeded.bind(this))
        this.indexedDB.addConnectListener(this.onConnect.bind(this))
        //this.connect();
        
        this.dispatcher = '';

        this.deleteListeners = []

    }

    addDeleteListener(callback){
        this.deleteListeners.push(callback)
    }

    onConnect(db){

        this.db = db

        //clean now
        this.cleanUp()
        //and every 3 hours
        this.cleanUpTimer = setInterval(this.cleanUp.bind(this), 1000*60*60*3)
    }


    onUpgradeNeeded(event){
        //alert('EventLogger onUpgradeNeeded')
        console.log('db EventsLog upgrade needed')

        let transaction = event.target.transaction;
        this.db = event.target.result;

        let eventsLogStore
        if (!this.db.objectStoreNames.contains('EventsLog')) {
            console.log('create EventsLog store')
            eventsLogStore = this.db.createObjectStore("EventsLog", { keyPath : "id", autoIncrement: true  });
        }else{
            console.log('exist store EventsLog')
        }

        if(!eventsLogStore){
            eventsLogStore = transaction.objectStore('EventsLog');
        } 

        

        //let dateIndex
        try {
            //dateIndex = 
            eventsLogStore.index("date_idx");
            console.log('exist index EventsLog.date_idx')
        } catch (error) {
            if(error.name==='NotFoundError'){
                console.log('create EventsLog.date_idx')
                //dateIndex = 
                eventsLogStore.createIndex('date_idx', 'datetime');
            }
        }

        //let typeIndex
        try {
            //typeIndex = 
            eventsLogStore.index("type_idx");
            console.log('exist index EventsLog.type_idx')
        } catch (error) {
            if(error.name==='NotFoundError'){
                console.log('create EventsLog.type_idx')
                //typeIndex = 
                eventsLogStore.createIndex('type_idx', 'type');
            }
        }

        //let stateIndex
        //eventsLogStore.deleteIndex('state_idx')
        try {
            //stateIndex = 
            eventsLogStore.index("state_idx");
            console.log('exist index EventsLog.state_idx')
        } catch (error) {
            if(error.name==='NotFoundError'){
                console.log('create EventsLog.state_idx')
                //stateIndex = 
                eventsLogStore.createIndex('state_idx', 'state');
            }
        }


        //let refIdIndex
        try {
            //refIdIndex = 
            eventsLogStore.index("ref_id_idx");
            console.log('exist index EventsLog.ref_id_idx')
        } catch (error) {
            if(error.name==='NotFoundError'){
                console.log('create EventsLog.ref_id_idx')
                //refIdIndex = 
                eventsLogStore.createIndex('ref_id_idx', 'ref_id');
            }
        }

        //let refDeviceIndex
        try {
            //refDeviceIndex = 
            eventsLogStore.index("ref_device_id_idx");
            console.log('exist index EventsLog.ref_device_id_idx')
        } catch (error) {
            if(error.name==='NotFoundError'){
                console.log('create EventsLog.ref_device_id_idx')
                //refDeviceIndex = 
                eventsLogStore.createIndex('ref_device_id_idx', 'ref_device_id');
            }
        }

        //let refUserIndex
        try {
            //refUserIndex = 
            eventsLogStore.index("ref_user_id_idx");
            console.log('exist index EventsLog.ref_user_id_idx')
        } catch (error) {
            if(error.name==='NotFoundError'){
                console.log('create EventsLog.ref_user_id_idx')
                //refUserIndex = 
                eventsLogStore.createIndex('ref_user_id_idx', 'ref_user_id');
            }
        }

        //let refGroupIndex
        try {
            //refGroupIndex = 
            eventsLogStore.index("ref_group_id_idx");
            console.log('exist index EventsLog.ref_group_id_idx')
        } catch (error) {
            if(error.name==='NotFoundError'){
                console.log('create EventsLog.ref_group_id_idx')
                //refGroupIndex = 
                eventsLogStore.createIndex('ref_group_id_idx', 'ref_group_id');
            }
        }
    
    };

    connected(){
        return Boolean(this.db)
    }

    /**
     * add event to log
     */
    add(event){

        if(!this.connected()) return

        if(event.datetime === undefined){
            event.datetime = new Date().getTime()
        } 

        if(event.dispatcher === undefined){
            event.dispatcher = this.dispatcher
        }

        //console.log('Add Event')
        //console.log(event)
        if(!this.check(event)) return   

        const transaction = this.db.transaction(["EventsLog"],"readwrite");
        transaction.oncomplete = function(event) {
            //console.log("Success");
        };

        transaction.onerror = function(event) {
            //console.log("Error");
        };  
        const log = transaction.objectStore("EventsLog");
        log.add(event);
    }

    check(event){
        //check for required fields
        if(event.type === undefined){
            alert('EventLog: event.type is not defined')
            return false
        }
        if(event.datetime === undefined){
            alert('EventLog: event.datetime is not defined')
            return false
        }
        if(event.sender === undefined){
            alert('EventLog: event.sender is not defined')
            return false
        }

        //console.log(Object.keys(event))
        Object.keys(event).forEach(item => {
            //console.log('item ' + item)
            if(fields.indexOf(item)===-1){
                alert("EventLog: unexpected field "+item)
                return false
            }
        });

        return true
    }

    setDispatcher(name){
        this.dispatcher = name
    }

    updateState(refID, state){
        console.log('updateState')
        const transaction = this.db.transaction(["EventsLog"], "readwrite")
        const eventsLogStore = transaction.objectStore("EventsLog")
        const refIndex = eventsLogStore.index("ref_id_idx")

        if(refID){
            const request = refIndex.get(refID)
            request.onsuccess = function() {
                if (request.result !== undefined) {
                    console.log('request.result')
                    console.log(request.result)
                    let event = request.result
                    event.state = state

                    transaction.oncomplete = function(event) {
                        console.log("Success")
                    }
            
                    transaction.onerror = function(event) {
                        console.log("Error")
                    }
                    //const log = transaction.objectStore("EventsLog");
                    eventsLogStore.put(event)

                }
            }
        }

    }

    update(refID, updatedFields){
        console.log('updateEvent')
        const transaction = this.db.transaction(["EventsLog"], "readwrite")
        const eventsLogStore = transaction.objectStore("EventsLog")
        const refIndex = eventsLogStore.index("ref_id_idx")

        if(refID){
            const request = refIndex.get(refID)
            request.onsuccess = function() {
                if (request.result !== undefined) {
                    console.log('request.result')
                    console.log(request.result)
                    let event = {...request.result, ...updatedFields}

                    transaction.oncomplete = function(event) {
                        console.log("Success")
                    }
            
                    transaction.onerror = function(event) {
                        console.log("Error")
                    }
                    //const log = transaction.objectStore("EventsLog");
                    eventsLogStore.put(event)

                }
            }
        }

    }


    queryEvents(filter, onsuccess, onerror){
        //select events from db
        if(!this.connected()) return

        console.log('queryEvents')
        const transaction = this.db.transaction(["EventsLog"], "readonly");
        const eventsLogStore = transaction.objectStore("EventsLog");
        let cursor
        let useFilter = true
        let useSort = true

        console.log('queryEvents')
        console.log(filter)

        //let request
        let range
        if(filter.dateFrom || filter.dateTo){
            console.log('use date_idx')
            const dateIndex = eventsLogStore.index("date_idx");

            let timeFrom = filter.dateFrom ? filter.dateFrom.getTime() : null
            let timeTo = filter.dateTo ? filter.dateTo.getTime() : null

            if(timeFrom && timeTo && timeFrom<timeTo){
                range = IDBKeyRange.bound(timeFrom, timeTo)    
            }else if(timeFrom && timeTo && timeFrom>timeTo){
                range = IDBKeyRange.bound(timeTo, timeFrom)    
            }else if(timeFrom){
                range = IDBKeyRange.lowerBound(timeFrom)    
            }else if(timeTo){
                range = IDBKeyRange.upperBound(timeTo)  
            }

            cursor = dateIndex.openCursor(range, 'prev')

            useFilter = false
            useSort = false

        }else if(filter.state && !Array.isArray(filter.state)){

            console.log('use state_idx')
            const stateIndex = eventsLogStore.index("state_idx");
            //range = filter.type
            range = IDBKeyRange.only(filter.state)
            cursor = stateIndex.openCursor(range)

            useFilter = true
            useSort = true

        }else if(filter.type && !Array.isArray(filter.type)){

            console.log('use type_idx')
            const typeIndex = eventsLogStore.index("type_idx");
            range = filter.type
            //range = IDBKeyRange.only(filter.type)  
            cursor = typeIndex.openCursor(range)

            useFilter = true
            useSort = true

        }else if(filter.ref_device_id && !Array.isArray(filter.ref_device_id)){

            console.log('use ref_device_id_idx')
            const refDeviceIndex = eventsLogStore.index("ref_device_id_idx");
            range = filter.ref_device_id
            //range = IDBKeyRange.only(filter.type)  
            cursor = refDeviceIndex.openCursor(range)

            useFilter = true
            useSort = true

        }else{
            console.log('use date_idx (default)')
            const dateIndex = eventsLogStore.index("date_idx");
            range = null
            cursor = dateIndex.openCursor(range, 'prev')

            useSort = false
        }
        

        let result = []
        let count = 0
        cursor.onsuccess = event => {
            let cursor = event.target.result;
            if(cursor) {
                //console.log('cursor.value')
                //console.log(cursor.value)
                if(!useFilter || itemFilter(cursor.value, filter)){
                    result.push(cursor.value)
                }
                count ++
                cursor.continue();
            }else{
                //console.log('cursor.complete '+ result.length)
                if(useSort){
                    result = sort(result)
                }
                console.log('count')
                console.log(count)
                onsuccess(result)
            }
        }
       
        cursor.onerror = event => {
            onerror(event)
        }

        /*
        request.onsuccess = ()=>{
            alert('success')
            onsuccess(request.result)
        }
        request.onerror = ()=>{
            alert('error')
            onerror()
        }
        */

    }


    cleanUp(){

        const settings = getSettingsInstance()

        const clearDataDays = settings.get('clearDataDays')
        
        if(clearDataDays>0){

            const date = new Date()
            date.setDate(date.getDate() - clearDataDays)

            //alert(formatDateTime(date.getTime(), 'full_date'))
            console.log('events log cleanup before ' + formatDateTime(date.getTime()))

            const transaction = this.db.transaction(["EventsLog"], "readwrite");
            const eventsLogStore = transaction.objectStore("EventsLog");
            const range = IDBKeyRange.upperBound(date.getTime()) 

            const dateIndex = eventsLogStore.index("date_idx");
            const cursor = dateIndex.openCursor(range)

            cursor.onsuccess = event => {
                let cursor = event.target.result;
                if(cursor) {

                    this.deleteListeners.forEach(callback => {
                        callback(cursor.value)
                    })

                    //console.log('cursor.value')
                    //console.log(cursor.value)
                    eventsLogStore.delete(cursor.value.id)
                    //console.log('delete event ' + cursor.value.id)
                    cursor.continue();
                }else{
                    console.log('cleanup complete')
                }
            }

        }

    }

}

function itemFilter(item, filter){
    //let check = true
    //console.log('itemFilter')
    //console.log(item)
    //console.log(filter)

    if(filter.type){

        if(Array.isArray(filter.type)){
            //console.log('type filter ' + item.type)
            //console.log(filter.type.includes(item.type))
            if(!filter.type.includes(item.type)) return false
        }else{
            if(filter.type!==item.type) return false
        }

    }

    if(filter.ref_user_id && filter.ref_user_id!==item.ref_user_id) return false
    if(filter.ref_device_id && filter.ref_device_id!==item.ref_device_id) return false
    if(filter.ref_group_id && filter.ref_group_id!==item.ref_group_id) return false

    return true
}

function sort(events){
    events.sort((item1, item2) => (item1.datetime < item2.datetime))
    return events
}

export function getEventTypeName(type){
    switch(type){
        case EVENT_TYPE.TYPE_VIDEO_PTT_GROUP: return 'VideoPttGroup'
        case EVENT_TYPE.TYPE_VIDEO_PTT_PRIVATE: return 'VideoPttPrivate'
        case EVENT_TYPE.TYPE_VIDEO_PTT_EMERGENCYGROUP: return 'VideoPttEmergency'
        case EVENT_TYPE.TYPE_VOICE_PTT_GROUP: return 'VoicePttGroup'
        case EVENT_TYPE.TYPE_VOICE_PTT_PRIVATE: return 'VoicePttPrivate'
        case EVENT_TYPE.TYPE_VOICE_PTT_EMERGENCYGROUP: return 'VoicePttEmergency'
        case EVENT_TYPE.TYPE_TEXT_GROUP: return 'MessageGroup'
        case EVENT_TYPE.TYPE_TEXT_PRIVATE: return 'MessagePrivate'
        case EVENT_TYPE.TYPE_IMAGE_GROUP: return 'ImageGroup'
        case EVENT_TYPE.TYPE_IMAGE_PRIVATE: return 'ImagePrivate'
        case EVENT_TYPE.TYPE_FILE_GROUP: return 'FileGroup'
        case EVENT_TYPE.TYPE_FILE_PRIVATE: return 'FilePrivate'
        //case EVENT_TYPE.TYPE_VIDEO_GROUP: 14,
        //case EVENT_TYPE.TYPE_VIDEO_PRIVATE: 15,
        //case EVENT_TYPE.TYPE_VOICEREC_GROUP: 16,
        //case EVENT_TYPE.TYPE_VOICEREC_PRIVATE: 17,
        case EVENT_TYPE.TYPE_STATUS: return 'Status'
        //case EVENT_TYPE.TYPE_MONITORING_REC: 19,
        //case EVENT_TYPE.TYPE_CALL_ALERT: 20,
        case EVENT_TYPE.TYPE_EMERGENCY: return 'MessageEmergency'
    
        case EVENT_TYPE.TYPE_UNDEFINED: return 'Unefined'
        case EVENT_TYPE.TYPE_LOGIN: return "SignIn"
            
        default: 
            dev.alert('Translation for type '+type+' not defined' )
    } 
}