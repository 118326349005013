import i18n from "i18next";
import { initReactI18next } from 'react-i18next/hooks';

//import Backend from 'i18next-xhr-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

//import translationEN from './locales/en/translation.json';
//import translationRU from './locales/ru/translation.json';

//get language default settings
let https = require('https');
let url = "/langs.json";
//console.log(url)
https.get(url,(res) => {
    let body = "";

    res.on("data", (chunk) => {
        body += chunk;
    });

    res.on("end", () => {
        try {
            let langs = JSON.parse(body)
            console.log(langs, i18n)
            //alert('load langs.json')
            // do something with JSON
            init(langs, i18n)

            let event = new CustomEvent("onI18nReady", { });
            document.dispatchEvent(event);
            
        } catch (error) {
            console.error(error.message);
            init(null, i18n)

            let event = new CustomEvent("onI18nReady", { });
            document.dispatchEvent(event);
        };
    });

}).on("error", (error) => {
    console.error(error.message);
});



function init(langs, i18n){

  //list of available languages
  if(!langs){
    langs = require('./locales/langs.json')
  }
  let resources = {}
  //load translations
  let defaultLang
  const langCodes = Object.keys(langs)
  for(let i=0; i<langCodes.length; i++){
    const lng = langCodes[i]
    resources[lng] = {translation: require('./locales/'+lng+'/translation.json')}
    if(langs[lng].default) defaultLang = lng
  }

/*
const resources = {
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    }
};
*/

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    langs: langs, 
    lng: defaultLang,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

}

export default i18n;