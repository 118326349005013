import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';
import {Table, TableBody, TableCell, TableHead, TableRow}  from '@material-ui/core';
import {Paper, IconButton, Menu, MenuItem, ListItemIcon, ListItemText} from '@material-ui/core';

import MoreVert from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import {decimalColorToHexColor, sortByName} from '../../../api/helpers'; 

class StatusesListEditor_ extends React.Component{

    constructor(props){
        super(props);
        this.state = {menuAnchor: null}
    }

    handleItemSelect(itemID){
        this.props.onItemSelect(itemID)
    }

    getItemStyle(itemID){
        let selected = itemID === this.props.selectedItemID
        let style = {
            background: selected? theme.palette.background.selectedRow : '',
            cursor: 'pointer'
        }
        return style
    }

    handleMenuClick(event, itemID){
        this.setState({menuAnchor: event.currentTarget, menuItemID: itemID});
        event.stopPropagation();
    }

    handleMenuClose(){
        this.menuAnchor = null;
        this.setState({menuAnchor: null});
        return null;
    }

    handleMenuItemClick(action){
        const itemID = this.state.menuItemID
        this.handleMenuClose();
        this.props.onAction({type: action, statusID: itemID})
    }


    render(){
        const {t} = this.props

        this.props.items.sort(sortByName)

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            addIcon: {
                color: 'white'
            }
        };

        return(
            <>
                <Paper style={styles.paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.head}>{t('Name')}</TableCell>
                                <TableCell style={styles.head}>{t('Color')}</TableCell>
                                <TableCell style={{...styles.head, width:40}}>
                                    <IconButton size="small" color="primary" aria-label="add status" component="span"
                                        style={styles.addIcon}
                                        onClick={()=>{this.handleMenuItemClick('STATUS_EDITOR_OPEN_ADD')}}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.items.map((item) => 
                            <TableRow key={item.ID} 
                                    onClick={()=>{this.handleItemSelect(item.ID)}}
                                    style={this.getItemStyle(item.ID)}>
                                <TableCell component="th" scope="row">
                                    {item.Name}
                                </TableCell>
                                <TableCell style={{backgroundColor:decimalColorToHexColor(item.Color)}}>
                                    {/*item.Color*/} {decimalColorToHexColor(item.Color)}
                                </TableCell>
                                <TableCell style={{width:40}}>
                                    <IconButton size="small" color="inherit" aria-label="add" component="span"
                                        onClick={event=>this.handleMenuClick(event, item.ID)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                        <Menu anchorEl={this.state.menuAnchor} keepMounted
                            open={Boolean(this.state.menuAnchor)} onClose={this.handleMenuClose.bind(this)}>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('STATUS_EDITOR_OPEN_EDIT')}}>
                                <ListItemIcon color="primary"><EditIcon/></ListItemIcon> 
                                <ListItemText primary={t('EditStatus')} />
                            </MenuItem>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('STATUS_EDITOR_DELETE')}}>
                                <ListItemIcon><DeleteForeverIcon/></ListItemIcon>
                                <ListItemText primary={t('DeleteStatus')} />
                            </MenuItem>
                        </Menu>
                    </Table>
                </Paper>
            </>
        )

    }

}

export const StatusesListEditor = withTranslation()(StatusesListEditor_);