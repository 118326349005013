import React from 'react';
import './FleetStatistics.css';

import { withTranslation } from 'react-i18next/hooks';

import { getLang } from '../functions/lang'
import { isString } from 'util';

import * as dev from '../api/dev'

import { ServerApi } from './api/ServerApi';

import { Container } from '@material-ui/core';

import {ConnectionProgress} from '../pages/ConnectionProgress';
import { Login } from '../pages/Login';
import { ServerMonitor } from './pages/ServerMonitor'

import { FLEET_STATISTICS_FUNCTION_OVERVIEW, FLEET_STATISTICS_FUNCTION_NETWORKS, 
    FLEET_STATISTICS_FUNCTION_DMR_NETWORKS, FLEET_STATISTICS_FUNCTION_SERVER } from './api/ServerApi'
import { TrackChangesOutlined } from '@material-ui/icons';


//export default 
class FleetStatistics_ extends React.Component {


    constructor(props) {
        super(props);

        //const {t} = props 

        let login = localStorage.getItem('login');
        let password = sessionStorage.getItem('password');
        let serveraddress = localStorage.getItem('serveraddress')

        this.state = {
            isLogged: false,
            isConnecting: false, //this.isConnecting(),
            authUser: {
                login: isString(login) ? login : '',
                password: isString(password) ? password : '',
                serveraddress: isString(serveraddress) ? serveraddress : ''
            },
            apiError: false,
            apiMessage: '',
            page: 'login',
            statisticsOverview: null,
            statisticsNetworks: null,
            statisticsDmr: null,
            statisticsServer: null,
        }

        this.api = null

        this.handleAppAction = this.handleAppAction.bind(this)
    }

    componentDidMount(){
        this.setTitle()
    }
    componentDidUpdate(){
        this.setTitle()
    }
    setTitle(){
        document.title = this.props.config.title + ' System Monitoring'
    }

    /*
    getLang(i18n) {
        let lang = this.props.i18n.language
        if (lang.indexOf('-')) {
            lang = lang.split('-')[0]
        }
        let { langs } = this.props.i18n.options
        let defaultLang = null
        let existLang = null
        for (let key in langs) {
            if (key === lang) {
                existLang = key
                break
            }
            if (langs[key].default === true) {
                defaultLang = key
            }
        }

        if (!existLang) {
            lang = defaultLang
            this.props.i18n.changeLanguage(lang)
        }

        return lang
    }
    */

    login(login, password, serveraddress) {
        if (login && password && serveraddress) {
            localStorage.setItem("login", login);
            sessionStorage.setItem("password", password);
            localStorage.setItem("serveraddress", serveraddress);

            this.setState({ isConnecting: true, page: 'connecting' });
            this.startApi(serveraddress, login, password, this.props.config);

            //this.eventLogger.setDispatcher(login)
            //this.eventLogger.add({ type: EVENT_TYPE.TYPE_LOGIN, sender: login, note: '' })
        }
    }

    startApi(serveraddress, login, password, config) {
        
        let options = {
            //settings: this.settings,
            //eventLogger: this.eventLogger,
            //fileStorage: this.fileStorage,
            onError: this.handleApiError.bind(this),
            onClose: this.handleApiClose.bind(this),
            onConnect: this.handleApiConnect.bind(this),
            onAction: this.handleAppAction.bind(this),
            config: config
            //onNetworksChanged: this.handleNetworksChanged.bind(this),
            //onAvatarUpdate: this.handleAvatarUpdate.bind(this),
            //onPttCall: this.handlePttCall.bind(this),
            //onUnreadMessagesChanged: this.handleUnreadMessagesChanged.bind(this),
            //onEmergency: this.handleEmergency.bind(this)
        }
        this.api = new ServerApi(serveraddress, login, password, options)
        this.api.connect();
    }


    //login form callback
    handleLogin(login, password, serveraddress) {
        this.setState({
            authUser: {
                login: login,
                password: password,
                serveraddress: serveraddress
            }
        })
        this.login(login, password, serveraddress);
    }

    handleApiError(err, connectCount) {
        console.log('handleApiError error')
        console.log(err)
        if (isString(err)) {
            this.setState({ apiError: true, apiMessage: err })
        } else {
            if (err.type === 'login') {
                this.setState({ apiError: true, apiMessage: err.message, page: 'login', isConnecting: true })
            } else {
                let errorStr = JSON.stringify(err, ["message", "arguments", "type", "name"]);
                this.setState({ apiError: true, apiMessage: 'An error occured: ' + errorStr + ' ' + connectCount })
                console.log(err)
            }
        }
    }

    handleApiClose(evt, connectCount) {
        //console.log(evt)
        switch (evt.code) {
            case 1006:
                this.setState({ apiError: true, apiMessage: 'Try to Connect: ' + connectCount })
                break;
            case 1005:
                //No Status Received - regular close connection
                if (this.state.page !== 'login') {
                    this.setState({ 
                        apiError: true, 
                        apiMessage: 'Try to Connect: ' + connectCount,
                        isConnecting: true
                    })
                }
                break;

            default:
                this.setState({ 
                    apiError: true, 
                    apiMessage: 'handleApiClose:' + evt.code + '; ' + JSON.stringify(evt) 
                })
        }
    }

    //on message CONFIG_SERVER_RESPONSE_ACK
    handleApiConnect() {
        this.setState({ 
            isLogged: true, 
            isConnecting: false, 
            page: 'console',
            apiError: false, 
            apiMessage: ''
        })
    }

    handleAppAction(action) {
        //console.log('App.action')
        //console.log(action)
        switch (action.type) {

            case 'SET_LANGUAGE':
                const lang = action.lang;
                this.props.i18n.changeLanguage(lang);
                break;

            case 'LOGOUT':
                this.setState({
                    isLogged: false,
                    isConnecting: false,
                    page: 'login',
                    apiError: false,
                    apiMessage: ''
                })
                this.api.close();
                break;

            case 'STATISTICS_REQUEST':{
                //console.log('action')
                //console.log(action)
                this.api.queryStatistics(action.function, (apiFunction, statistics) => {
                    //alert('set state statistics')
                    //console.log('set state statistics')
                    //console.log(statistics)
                    statistics = this.statHook(statistics, apiFunction)
                    //console.log('apiFunction')
                    //console.log(apiFunction)
                    switch(apiFunction){
                        case FLEET_STATISTICS_FUNCTION_OVERVIEW: 
                            //console.log('statisticsOverview'); 
                            this.setState({statisticsOverview: statistics}); 
                            break
                        case FLEET_STATISTICS_FUNCTION_NETWORKS: 
                            //console.log('statisticsNetworks'); 
                            this.setState({statisticsNetworks: statistics}); 
                            break
                        case FLEET_STATISTICS_FUNCTION_DMR_NETWORKS: 
                            //console.log('statisticsDmr'); 
                            this.setState({statisticsDmr: statistics}); 
                            break
                        case FLEET_STATISTICS_FUNCTION_SERVER: 
                            //console.log('statisticsServer'); 
                            this.setState({statisticsServer: statistics}); 
                            break
                        default: console.error('Unknown switch case for apiFunction = '+apiFunction)
                    }
                });
                break;
            }

            default: dev.alert('App action [' + action.type + '] not defined')

        }

    }

    statHook(statistics, apiFunction){
  /*      
        if (apiFunction==1){
            console.log(statistics)
            if(statistics.PerformanceCounters.DiskCounters.length){
                statistics.PerformanceCounters.DiskCounters.push (statistics.PerformanceCounters.DiskCounters[0])
            }
            
            if(statistics.DmrNetworks.length){
            statistics.DmrNetworks[1].Connected = true
                statistics.DmrNetworks.push ( statistics.DmrNetworks[0])
                statistics.DmrNetworks.push ( statistics.DmrNetworks[1])
                statistics.DmrNetworks.push ( statistics.DmrNetworks[1])
                statistics.DmrNetworks.push ( statistics.DmrNetworks[0])
                statistics.DmrNetworks.push ( statistics.DmrNetworks[2])
                statistics.DmrNetworks.push ( statistics.DmrNetworks[1])
            }
        }
*/
        
        /*
        statistics.DmrNetworks= [
            {
              "Name": "Caltta Network",
              "Connected": false,
              "DmrOnline": 0,
              "Connections": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "VoiceCalls": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "Emergencies": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "Locations": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "Messages": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              }
            },
            {
              "Name": "Epmak Network",
              "Connected": false,
              "DmrOnline": 0,
              "Connections": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "VoiceCalls": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "Emergencies": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "Locations": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "Messages": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              }
            },
            {
              "Name": "Hytera Network 1",
              "Connected": false,
              "DmrOnline": 0,
              "Connections": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "VoiceCalls": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "Emergencies": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "Locations": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              },
              "Messages": {
                "Total": 0,
                "Minute": 0,
                "Hour": 0,
                "Day": 0
              }
            }
          ]
          */
        return statistics;
    }


    render() {

        //const { t, i18n } = this.props;
        //console.log('this.state')
        //console.log(this.state)

        if (this.state.isConnecting) {
            return (
                <ConnectionProgress apiError={this.state.apiError} 
                        apiMessage={this.state.apiMessage} 
                        onAction={this.handleAppAction} />
            )
        } else if (this.state.page === 'login'){
            return (
                <Container height="100vh" style={{ paddingTop: '2em' }}>
                    <Login onLogin={this.handleLogin.bind(this)}
                        login={this.state.authUser.login}
                        password={this.state.authUser.password}
                        serveraddress={this.state.authUser.serveraddress}
                        lang={getLang(this.props.i18n)}
                    />
                </Container>
            );
        } else {
            
            return(
                <ServerMonitor
                        user={this.state.authUser}
                        onAction={this.handleAppAction}
                        statusError={this.state.apiError}
                        statusMessage={this.state.apiMessage}
  
                        statisticsOverview={this.state.statisticsOverview}
                        statisticsNetworks={this.state.statisticsNetworks}
                        statisticsDmr={this.state.statisticsDmr}
                        statisticsServer={this.state.statisticsServer}
                    />
            )
        }
    }

}


export const FleetStatistics = withTranslation()(FleetStatistics_);