import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import theme from '../theme';

export class Dialog extends React.Component{

    render() {
        //alert(theme.palette.dialogTitle)
        const styles = {
            headerStyle: {
                padding:'0.5em 1em', 
                fontStyle:'italic', 
                color: theme.palette.color.dialogTitle, 
                backgroundColor: theme.palette.background.dialogTitle,
                fontWeight: 'bold',
                lineHeight: '1em'
            },
            contentStyle: { 
                backgroundColor: 'white', 
                padding:'1em', 
                border: '1px solid '+ theme.palette.border.dialog  
            },
            paper: {
                //width: '100%',
                overflowX: 'auto',
            }
        }

        let title = '';
        if(this.props.title){
            title = (
                <Box textAlign={this.props.align?this.props.align:'center'}>
                    <Typography component="h1" variant={this.props.variant?this.props.variant:'h4'} style={styles.headerStyle}>
                        {this.props.title}
                    </Typography>
                </Box>
            );
        }

        return (
            <Container maxWidth={this.props.width?this.props.width:'xs'}>
                <Paper style={styles.paper}>
                    {title}
                    <Typography component="div" style={styles.contentStyle}>
                        {this.props.children}
                    </Typography>
                </Paper>
            </Container>
        );

    }

}

