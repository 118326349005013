import React from 'react';
import theme from '../theme';
import PropTypes from 'prop-types';

import { IconButton, CircularProgress} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';

export default class FilePreview extends React.Component {

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        message: PropTypes.object.isRequired,
        onAction: PropTypes.func.isRequired
    };

    static defaultProps = {
        width: 50,
        height: 50
    };

    constructor(props){
        super(props)
        this.state = {
            hover: false
        }
        
        this.handleFileOpen = this.handleFileOpen.bind(this)
        this.handleFileLoad = this.handleFileLoad.bind(this)
        this.handleFileCancel = this.handleFileCancel.bind(this)
        this.handleMouseOver = this.handleMouseOver.bind(this)
        this.handleMouseOut = this.handleMouseOut.bind(this)
    }

    handleMouseOver(){
        this.setState({hover: true})
    }

    handleMouseOut(){
        this.setState({hover: false})
    }

    handleFileOpen(){
        if(this.props.message.file_state===0 || this.props.message.file_state===2){
            this.props.onAction({
                type: 'FILE_OPEN',
                jobID: this.props.message.ref_id,
                networkID: this.props.message.network_id
            })
        }
    }

    handleFileLoad(event){
        this.props.onAction({
            type: 'FILE_LOAD',
            jobID: this.props.message.ref_id,
            networkID: this.props.message.network_id
        })
        event.stopPropagation();
    }

    handleFileCancel(event){
        this.props.onAction({
            type: 'FILE_CANCEL',
            jobID: this.props.message.ref_id,
            networkID: this.props.message.network_id
        })
        event.stopPropagation();
    }

    render(){
        //console.log('render FilePreview')
        //console.log(this.props)

        const styles = {
            wrapper: {
                position: 'relative', 
                width: this.props.width+4, 
                height: this.props.height+4,
                border: '2px solid '+ (this.state.hover?theme.palette.primary.main:'grey')
            },
            fileIcon: {
                width: this.props.width,
                height: this.props.height,
                position:'absolute', 
                cursor: 'pointer',
                left:0, 
                top:0
            },
            iconLoad: {
                position:'absolute', 
                right:0, 
                bottom: 0, 
                zIndex:1, 
                backgroundColor:'rgba(255,255,255,0.5)'
            },
            progress: {
                position:'absolute', 
                left:0, 
                top:0, 
                margin: (this.props.width-42)/2    
            }
        }

        const style = {}
        if(this.props.onClick) style.cursor = 'pointer'
        style.border = '2px solid '+ (this.state.hover?theme.palette.primary.main:'grey')
        return (
            <div style={styles.wrapper} onClick={this.handleFileOpen}>
                <div>
                    <DescriptionIcon style={styles.fileIcon} color="disabled"
                        onMouseOver={this.handleMouseOver}
                        onMouseOut={this.handleMouseOut}
                        onClick22={this.handleFileOpen}
                    />
                </div>

                {this.props.message.file_state===0 ?
                <IconButton style={styles.iconLoad}
                    size="small" color="primary" aria-label="open" 
                    component="span" onClick={this.handleFileLoad}>
                    <GetAppIcon fontSize='small'/>
                </IconButton>
                :''}

                {this.props.message.file_state===1 || this.props.message.file_state===2 ? 
                <IconButton style={styles.iconLoad}
                    size="small" color="primary" aria-label="cancel" 
                    component="span" onClick={this.handleFileCancel}>
                    <CancelIcon fontSize='small'/>
                </IconButton>
                :''}

                {this.props.message.file_state===1 ? 
                <CircularProgress style={styles.progress} />    
                :''}
            </div>
        )
    }


}