import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Button, ButtonGroup} from '@material-ui/core';

class TabbedMenu_ extends React.Component{

  handleClick(tab){
    if(this.props.onClick) this.props.onClick(tab)
  }

  buttonStyle(name){
    const buttonStyle = {
      minWidth: '100px',
      height: '2.0em',
      background: theme.palette.background.tabbedMenu,
      color: theme.palette.color.tabbedMenu
    } 
    if(this.props.selected===name){
      return {
        ...buttonStyle,
        background: theme.palette.background.tabbedMenuSelected
      }
    }else{
      return buttonStyle
    }

  }

  render(){
    const {t} = this.props

    return (
      <ButtonGroup size="small">
        <Button style={this.buttonStyle('overview')} onClick={()=>this.handleClick('overview')}>{t('Overview')}</Button>
        <Button style={this.buttonStyle('networks')} onClick={()=>this.handleClick('networks')}>{t('Networks')}</Button>
        <Button style={this.buttonStyle('dmr')} onClick={()=>this.handleClick('dmr')}>{t('DmrNetworks')}</Button>
        <Button style={this.buttonStyle('server')} onClick={()=>this.handleClick('server')}>{t('Server')}</Button>
      </ButtonGroup>
    );
  }
}

export const TabbedMenu = withTranslation()(TabbedMenu_);