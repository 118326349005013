import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

//import theme from '../../theme';

import {Dialog, DialogActions, DialogContent, DialogTitle, Typography, OutlinedInput} from '@material-ui/core'
import {Box} from '@material-ui/core'
import {Button, Checkbox, MenuItem, Select} from '@material-ui/core';

import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class SettingsDialog_ extends React.Component {

    constructor(props){
        super(props)

        //debugger
        let {settings} = this.props;

        this.state = {
            settings: settings,
            //enableds: this.initEnableds(settings),
            errors:{
                clearDataDays: false,
                defaultPollingTime: false, 
                locationValidityTime: false
            },
            panelsExpanded: {
                locationSettings: true,
                ptt: true,
                soundNotifications: true,
                eventLog: true,
                localization: true
            },
            changed: false,
            tab: 0
        }

        this.togglePanel = this.togglePanel.bind(this)
        this.handleInput = this.handleInput.bind(this)
        //this.handleTabChange = this.handleTabChange.bind(this)
    }

    togglePanel(event, expanded, panel){
        this.setState(state => {
            state.panelsExpanded[panel] = expanded
            return state
        })
    }

    handleSave(){
        const isValid = this.validate()
        if(this.state.changed && isValid){
            let settings = {...this.props.settings, ...this.state.settings}

            this.props.onAction({
                type: 'SETTINGS_EDITOR_SAVE', 
                settings: settings
            })
        }else if(this.state.changed && !isValid){
            alert(this.props.t('CheckFormForErrors'))
        }else if(!this.state.changed){
            this.props.onAction({type: 'SETTINGS_DIALOG_CLOSE'})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'SETTINGS_DIALOG_CLOSE'})
    }

    handleInput(event){
        const changed = !this.state.changed ? {changed: true} : {}
        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value 
       
        console.log(event.target.name + ' val='+val)

        const settings = {...this.state.settings, [event.target.name]: val}
        this.setState({settings: settings, ...changed})
    }

    checkBox(label, name){
        const margin = {}
        return(
<Box textAlign="" style={{...margin, width: '100%'}}>
    <label>
        <Checkbox color="primary" 
            checked={this.state.settings[name]}
            onChange={this.handleInput}
            name={name}
        />
        {label}
    </label>
</Box>
        );
    }

    select(label, name, items){
        const margin = {}
        return(
<Box textAlign="" style={{...margin, width: '100%'}}>
    <label>{label}
    <Select
        style={{margin:'3px 10px'}}
        value={this.state.settings[name]}
        onChange={this.handleInput}
        inputProps={{name: name, id: name}}
        >
            {items.map(item=>(<MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>) )}
    </Select>
    </label>
</Box>
        );
    }

    inputBox(label, name){
        const margin = {}
        return(
<Box textAlign="" style={{...margin, width: '100%'}}>
    <label>
        {label}
            <OutlinedInput  type="text" name={name}
                id={name+"-input"}
                style={{width: 100, margin:'0 10px', padding:0}}
                autoComplete="new-password"
                value={this.state.settings[name]}
                error={this.state.errors[name]!==false}
                onChange={this.handleInput}
                aria-describedby={name+"-helper-text"}
            />

        {this.state.errors[name]!==false?
        <span style={{color:'red', whiteSpace:'nowrap'}} id={name+"-helper-text"}>
            {this.state.errors[name]}
        </span>
        :''}
        
    </label>
</Box>
        );
    }

    textBox(label, name){
        const margin = {}
        return(
<Box textAlign="" style={{...margin, width: '100%'}}>
    <label>
        {label}
        <OutlinedInput  type="text" name={name}
            style={{width: 300, margin:'0 10px', padding:0}}
            autoComplete="new-password"
            value={this.state.settings[name]||''}
            onChange={this.handleInput}
        />
    </label>    
</Box>
        );
    }


    validate(){
        const {t} = this.props

        let errors = this.state.errors;
        let error = false
        if(this.state.settings.LowBatteryLevel && isNaN(this.state.settings.LowBatteryLevel)){
            errors.LowBatteryLevel = t('IsNotNumber')
            error = true
        }else{
            errors.LowBatteryLevel = false
        }

        if(this.state.settings.PttHardwareKey && isNaN(this.state.settings.PttHardwareKey)){
            errors.PttHardwareKey = t('IsNotNumber')
            error = true
        }else{
            errors.PttHardwareKey = false
        }

        if(this.state.settings.DeleteEventsOlderDays && isNaN(this.state.settings.DeleteEventsOlderDays)){
            errors.DeleteEventsOlderDays = t('IsNotNumber')
            error = true
        }else{
            errors.DeleteEventsOlderDays = false
        }
        

        this.setState({errors: errors})

        return !error;
    }

    languageItems(){
        const {i18n} = this.props
        return Object.keys(i18n.options.langs).map(
            code => [code, i18n.options.langs[code].name]
        )
    }

    speedItems(){
        const { t } = this.props
        return [
            ['km/h', t('KilometersPerHour')],
            ['m/s', t('MetersPerSecond')],
            ['mi/h', t('MilesPerHour')]
        ]
    }

    render(){
        const {t} = this.props

        const title = t('Settings')

        //const margin = {}//{margin: 0}

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent style={{minWidth:450}}>

        <form>
        
            <ExpansionPanel 
                expanded={this.state.panelsExpanded.locationSettings} 
                onChange={(event, expanded) => this.togglePanel(event, expanded, 'locationSettings')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('LocationSettings')}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'block'}}>
                    {this.inputBox(t('LocationValidityTime'), 'locationValidityTime')}
                    {this.checkBox(t('ShowInvalidLocations'), 'showInvalidLocations')}
                    {this.inputBox(t('DefaultPollingTime'), 'defaultPollingTime')}

                    {this.select(t('ShowSpeedIn'), 'defaultSpeedIn', this.speedItems())}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                expanded={this.state.panelsExpanded.ptt} 
                onChange={(event, expanded) => this.togglePanel(event, expanded, 'ptt')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('PTT')}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails  style={{display: 'block'}}>

                    {this.select(t('PttCallStyle'), 'pttStyle', [['hold', 'hold'], ['press', 'press']])}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel 
                expanded={this.state.panelsExpanded.soundNotifications} 
                onChange={(event, expanded) => this.togglePanel(event, expanded, 'soundNotifications')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('SoundNotifications')}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'block'}}>
                    {this.checkBox(t('ToneOnPttCallStart'), 'toneTX')}
                    {this.checkBox(t('ToneOnIncomingPttCallStart'), 'toneRX')}
                    {this.checkBox(t('ToneOnIncomingPttCallStop'), 'toneRXStop')}
                    {this.checkBox(t('PttBusyTone'), 'toneBusy')}
                    {this.checkBox(t('ConnectTone'), 'toneConnect')}
                    {this.checkBox(t('DisconnectTone'), 'toneDisconnect')}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                expanded={this.state.panelsExpanded.eventLog} 
                onChange={(event, expanded) => this.togglePanel(event, expanded, 'eventLog')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('EventLog')}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'block'}}>

                    {this.checkBox(t('ClearData'), 'clearData')}
                    {this.inputBox(t('ClearDataOlder'), 'clearDataDays')}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                expanded={this.state.panelsExpanded.localization} 
                onChange={(event, expanded) => this.togglePanel(event, expanded, 'localization')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('Localization')}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails  style={{display: 'block'}}>

                    {this.select(t('Language'), 'language', this.languageItems())}

                </ExpansionPanelDetails>
            </ExpansionPanel>

        </form>

    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }

}


export const SettingsDialog = withTranslation()(SettingsDialog_);