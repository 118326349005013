import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {Container, Box} from '@material-ui/core'
import {Button, FormControl, TextField } from '@material-ui/core';

import { SketchPicker } from 'react-color';
import {decimalColorToHexColor, hexColorToDecimalColor} from '../../../api/helpers'; 

class EditStatusDialog_ extends React.Component{

    constructor(props){
        super(props)

        const {status} = this.props; 
        this.state = {
            status: {
                Name: status.ID ? status.Name : '',
                Color: status.ID ? status.Color : -1,
            },
            errors:{
                Name: false,
                Color: false
            },
            changed: false,
            colorPicker: {
                open: false,
                color: ''
            }

        }
    }

    handleSave(){
        if(this.validate()){
            let status = {...this.props.status, ...this.state.status}
            this.props.onAction({type: 'STATUS_EDITOR_SAVE', status: status})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'STATUS_EDITOR_CLOSE'})
    }   

    handleInput(event){
        if(!this.state.changed) this.setState({changed: true})

        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const status = {...this.state.status, [event.target.name]: val}
        this.setState({status: status})
    }

    validate(){
        let errors = this.state.errors;
        let error = false
     
        if(!this.state.status.Name){
            errors.Name = this.props.t('NameIsEmpty')
            error = true
        }else{
            errors.Name = false
        }
        this.setState({errors: errors})

        return !error;
    }


    render(){
        const {t} = this.props

        const title = this.props.action==='add'?t('NewStatus'):t('EditStatus')

        const margin = {margin:'16px 0 8px 0'}

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>
            <Box textAlign="">
                <TextField name="Name"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.Name?this.state.errors.Name:t('Name')}
                    autoComplete="off"
                    error={this.state.errors.Name!==false}
                    value={this.state.status.Name}
                    onChange={this.handleInput.bind(this)}
                />

            </Box>
            
            <Box textAlign="">
                <FormControl style={{...margin, width: '100%'}}>
                    <TextField name="Name" margin="normal"
                        style={{backgroundColor: decimalColorToHexColor(this.state.status.Color),
                            cursor: 'pointer'}}
                        label={this.state.errors.Color?this.state.errors.Color:t('Color')}
                        readonly={true}
                        value={decimalColorToHexColor(this.state.status.Color)}
                        onClick={ ()=>{this.setState({colorPicker:{
                            open: true, color: decimalColorToHexColor(this.state.status.Color)
                        }} ) } } 
                    ></TextField>
                </FormControl>

                <Dialog open={this.state.colorPicker.open}>
                    <DialogTitle id="edit-dialog-title">{t('SelectColor')}</DialogTitle>
                    <DialogContent>
                        <SketchPicker name="Color" 
                            color={this.state.colorPicker.color}
                            onChangeComplete={(color)=>{
                                this.setState({
                                    colorPicker:{open: true, color: color.hex}
                                } ) 
                            }}
                            ></SketchPicker>
                    </DialogContent>
                    <DialogActions>
                        <Button  variant="contained" color="primary"
                            onClick={()=>{
                                let color = hexColorToDecimalColor(this.state.colorPicker.color)
                                const status = {...this.state.status, Color: color}
                                this.setState({status: status, colorPicker:{open: false, color: ''}})
                            }}>
                            {t('Apply')}
                        </Button>
                        <Button variant="contained" color="inherit"
                                onClick={ ()=>{this.setState({colorPicker:{open: false, color: ''} }) } } 
                                >
                            {t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
    
        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const EditStatusDialog = withTranslation()(EditStatusDialog_);