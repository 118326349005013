import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import Button from '@material-ui/core/Button';
import {Grid, Typography, Container, Box} from '@material-ui/core';

import {ApplicationBar} from '../../../components/ApplicationBar';

//import Logo from '../../components/Logo';

import {TabbedMenu} from '../../../components/TabbedMenu';

import {UsersListEditor} from './UsersListEditor';
import {GroupsListEditor} from './GroupsListEditor';
import {StatusesListEditor} from './StatusesListEditor';
import {ProfilesListEditor} from './ProfilesListEditor';

import DevicesList from './../ServerConsole/DevicesList';
import {Dialog} from '../../../components/Dialog';
import {AclListEditor} from './AclListEditor';

import {EditUserDialog} from './EditUserDialog';
import {EditGroupDialog} from './EditGroupDialog';
import {EditStatusDialog} from './EditStatusDialog';
import {EditProfileDialog} from './EditProfileDialog';
//import {SelectLang} from '../../components/SelectLang';

import {getBase64ID, getIndexByID, base64toGuid, sortByName} from '../../../api/helpers'; 


class NetworkEditor_ extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            networkSettings: this.props.networkSettings,
            currentNetorkID: '',
            currentUserID: '',
            currentGroupID: '',
            currentProfileID: '',
            currentStatusID: '',
            currentDeviceID: '',
            selectedTab: 'users',
            userEditor: {
                action: '', //add|edit
                userID: '',
                user: {}
            },
            groupEditor: {
                action: '', //add|edit
                groupID: '',
                group: {}
            },
            statusEditor: {
                action: '', //add|edit
                statusID: '',
                status: {}
            },
            profileEditor: {
                action: '', //add|edit
                statusID: '',
                profile: {}
            }
        }
    }

    componentDidMount(){
        
    }
    componentDidUpdate(){
        if(this.props.networkSettings && !this.state.networkSettings){
            this.setState({networkSettings: this.props.networkSettings})
            console.log('Open network editor')
            console.log(this.props.networkSettings)
        }
    }

    handleNetworkSelect(networkID){
        this.setState({currentNetorkID: networkID})
    }

    handleUserSelect(userID){
        this.setState({currentUserID: userID})
    }

    handleGroupSelect(groupID){
        this.setState({currentGroupID: groupID})
    }

    handleProfileSelect(profileID){
        this.setState({currentProfileID: profileID})
    }

    handleStatusSelect(statusID){
        this.setState({currentStatusID: statusID})
    }

    handleDeviceSelect(statusID){
        this.setState({currentStatusID: statusID})
    }


    getNetwork(networkID){
        for(let i=0; i<this.props.networks.length; i++){
            if(this.props.networks[i].ID === networkID) return this.props.networks[i];
        }
        return null
    }
    

    getUserAcls(userID){
        if(!this.state.networkSettings) return []

        let acls = [], acl;

        let checked = false;
        let rxOnlyChecked = false;
        for(let i=0; i<this.state.networkSettings.Groups.length; i++){
            
            let group = this.state.networkSettings.Groups[i]
            //console.log('group')
            //console.log(group)
            if(userID){
                let users = group.Users.filter(item => item===userID)
                let rxUsers = group.RxOnlyUsers.filter(item => item===userID)
                checked = users.length>0 ? true : false;
                rxOnlyChecked = rxUsers.length>0 ? true : false;
                acl = {groupID: group.ID, userID: userID, value: checked, rxOnly: rxOnlyChecked, name: group.Name}
            }else{
                acl = {groupID: group.ID, userID:'', value: null, rxOnly: null, name: group.Name}
            }      
            acls.push(acl);
        }
        return acls
    }

    getGroupAcls(groupID){
        if(!this.state.networkSettings) return []

        let acls = [], acl;

        let groups = this.state.networkSettings.Groups.filter(item => item.ID===groupID)
        let group =  groups.length>0 ? groups[0] : null;

        let checked = false;
        let rxOnlyChecked = false;
        for(let i=0; i<this.state.networkSettings.Users.length; i++){
            let user = this.state.networkSettings.Users[i]
            //console.log('user')
            //console.log(user)
            if(group){
                let users = group.Users.filter(item => item===user.ID)
                let rxUsers = group.RxOnlyUsers.filter(item => item===user.ID)
                checked = users.length>0?true:false;
                rxOnlyChecked = rxUsers.length>0 ? true : false;
                acl = {groupID: group.ID, userID: user.ID, value: checked, rxOnly: rxOnlyChecked, name: user.Login +  ' ' +  user.Name}
            }else{
                acl = {groupID: '', userID: user.ID, value: null, rxOnly: null, name: user.Login +  ' ' +  user.Name}
            }      
            acls.push(acl);      
        }
        return acls
    }   

/*
    handleLogout(){
        if(this.props.onLogout){
            this.props.onLogout()
        }
    }
    */

    handleTabClick(tab){
        this.setState({selectedTab: tab});
    }

    handleButtonSave(){
        this.props.onAction({type:'NETWORK_EDITOR_SAVE', networkSettings: this.state.networkSettings})
    }

    handleButtonCancel(){
        this.props.onAction({type:'NETWORK_EDITOR_CLOSE'})
    }

    //acl editor
    handleUserAclChange(aclName, index, groupID, userID, checked){
        //clone object
        //console.log('handleUserAclChange '+aclName+ '; checked'+checked)
        let networkSettings = {...this.state.networkSettings};
        for(let i=0; i<networkSettings.Groups.length; i++){
            let group = networkSettings.Groups[i]
            if(group.ID===groupID){
                if(checked){
                    //add user
                    //console.log('raddemove user ' + userID)
                    group[aclName].push(userID)
/*
                    if(aclName=='Users'){
                        group.Users.push(userID)
                    }else if(aclName=='RxOnlyUsers'){
                        group.RxOnlyUsers.push(userID)
                    }*/
                }else{
                    //remove user
                    //console.log('remove user ' + userID)
                    for(let j=0; j<group.Users.length; j++){
                        if(group[aclName][j]===userID){
                            //group.Users.splice(j, 1)

                            group[aclName].splice(j, 1)
                            break 
                        }
                    }
                }
                break
            }
        }

        this.setState({networkSettings: networkSettings})

    }

    handleAction(action){
        this.props.onAction(action)
    }
    
    handleUserEditorAction(action){

        switch(action.type){
            case 'USER_EDITOR_SAVE': {
                    let user = {...action.user}
                    let networkSettings = {...this.state.networkSettings}
                    if(!user.ID){
                        user.ID = getBase64ID()
                    }
                    let userIndex = getIndexByID(user.ID, networkSettings.Users)
                    if(userIndex===null){
                        networkSettings.Users.push(user);
                    }else{
                        networkSettings.Users[userIndex] = user
                    }
                    this.setState({networkSettings: networkSettings, userEditor: {action:'', userID:'', user:{}}})
                }
                break;

            case 'USER_EDITOR_CLOSE':
                this.setState({userEditor: {action:'', userID:'', user:{}}})
                break;

            case 'USER_EDITOR_OPEN_EDIT': {
                    let users = this.state.networkSettings.Users.filter(item => item.ID===action.userID)
                    let user = users[0]
                    this.setState({userEditor: {
                        action: 'edit', //add|edit
                        userID: action.userID,
                        user: user
                    }})
                }
                break
            case 'USER_EDITOR_OPEN_ADD': 
                this.setState({userEditor: {
                    action: 'add', //add|edit
                    userID: action.userID,
                    user: {}
                }})
                break
            case 'USER_EDITOR_DELETE': {
                    let networkSettings = {...this.state.networkSettings}
                    const userIndex = getIndexByID(action.userID, networkSettings.Users)
                    if(userIndex!==null){
                        networkSettings.Users.splice(userIndex, 1)
                        this.setState({networkSettings: networkSettings})   
                    }
                }
                break;
            case 'USER_EDITOR_INPUT':{
                    const {event, userID} = action;
                    let networkSettings = {...this.state.networkSettings}
                    const userIndex = getIndexByID(userID, networkSettings.Users)
                    if(userIndex!==null){
                        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
                        const user = {...networkSettings.Users[userIndex], [event.target.name]: val}
                        networkSettings.Users[userIndex] = user
                        this.setState({networkSettings: networkSettings})
                    }
                }
                break;

            default:
                alert(action.type)
        }
    }

    handleGroupEditorAction(action){

        switch(action.type){
            case 'GROUP_EDITOR_SAVE': {
                    let group = {...action.group}
                    let networkSettings = {...this.state.networkSettings}
                    //if(!group.ID){
                    //    group.ID = getBase64ID()
                    //}
                    let groupIndex = getIndexByID(group.ID, networkSettings.Groups)
                    if(groupIndex===null){
                        if(group.Users===undefined){
                            //group.Users = []
                            //group.DMRGroupID = 0,​
                            //group.DMRNetworkID = "AAAAAAAAAAAAAAAAAAAAAA=="
                            //group.DMRSlot = 0
                        }
                        networkSettings.Groups.push(group);
                    }else{
                        networkSettings.Groups[groupIndex] = group
                    }
                    this.setState({networkSettings: networkSettings, groupEditor: {action:'', groupID:'', group:{}}})
                }
                break;

            case 'GROUP_EDITOR_CLOSE':
                this.setState({groupEditor: {action:'', groupID:'', group:{}}})
                break;

            case 'GROUP_EDITOR_OPEN_EDIT': {
                    let groups = this.state.networkSettings.Groups.filter(item => item.ID===action.groupID)
                    let group = groups[0]
                    this.setState({groupEditor: {
                        action: 'edit', //add|edit
                        groupID: action.groupID,
                        group: group
                    }})
                }
                break
            case 'GROUP_EDITOR_OPEN_ADD': 
                this.setState({groupEditor: {
                    action: 'add', //add|edit
                    groupID: action.groupID,
                    group: { //group defaults
                        ID: getBase64ID(),
                        Users: [],
                        Dispatchers: [],
                        Devices: [],
                        DMRGroupID: 0, 
                        DMRNetworkID: "AAAAAAAAAAAAAAAAAAAAAA==",
                        DMRSlot: 0,
                        Priority: 0,
                        AllCall: 0,
                        Broadcast: 0,
                        Emergency: 0,
                    }
                }})
                break
            case 'GROUP_EDITOR_DELETE': {
                    let networkSettings = {...this.state.networkSettings}
                    const groupIndex = getIndexByID(action.groupID, networkSettings.Groups)
                    if(groupIndex!==null){
                        networkSettings.Groups.splice(groupIndex, 1)
                        this.setState({networkSettings: networkSettings})   
                    }
                }
                break;

            case 'GROUP_EDITOR_INPUT':{
                    const {event, groupID} = action;
                    let networkSettings = {...this.state.networkSettings}
                    const groupIndex = getIndexByID(groupID, networkSettings.Groups)
                    if(groupIndex!==null){
                        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
                        const group = {...networkSettings.Groups[groupIndex], [event.target.name]: val}
                        networkSettings.Groups[groupIndex] = group
                        this.setState({networkSettings: networkSettings})
                    }
                }
                break;
            default:
                alert(action.type)
        }
    }


    handleStatusEditorAction(action){

        switch(action.type){
            case 'STATUS_EDITOR_SAVE': {
                    let status = {...action.status}
                    let networkSettings = {...this.state.networkSettings}
                    if(!status.ID){
                        status.ID = getBase64ID()
                    }
                    let statusIndex = getIndexByID(status.ID, networkSettings.Statuses)
                    if(statusIndex===null){
                        networkSettings.Statuses.push(status);
                    }else{
                        networkSettings.Statuses[statusIndex] = status
                    }
                    this.setState({networkSettings: networkSettings, statusEditor: {action:'', statusID:'', status:{}}})
                }
                break;

            case 'STATUS_EDITOR_CLOSE':
                this.setState({statusEditor: {action:'', statusID:'', status:{}}})
                break;

            case 'STATUS_EDITOR_OPEN_EDIT': {
                    let statuses = this.state.networkSettings.Statuses.filter(item => item.ID===action.statusID)
                    let status = statuses[0]
                    this.setState({statusEditor: {
                        action: 'edit', //add|edit
                        statusID: action.statusID,
                        status: status
                    }})
                }
                break
            case 'STATUS_EDITOR_OPEN_ADD': 
                this.setState({statusEditor: {
                    action: 'add', //add|edit
                    statusID: action.statusID,
                    status: {}
                }})
                break
            case 'STATUS_EDITOR_DELETE': {
                    let networkSettings = {...this.state.networkSettings}
                    const statusIndex = getIndexByID(action.statusID, networkSettings.Statuses)
                    if(statusIndex!==null){
                        networkSettings.Statuses.splice(statusIndex, 1)
                        this.setState({networkSettings: networkSettings})   
                    }
                }
                break;

            case 'STATUS_EDITOR_INPUT':{
                    const {event, statusID} = action;
                    let networkSettings = {...this.state.networkSettings}
                    const statusIndex = getIndexByID(statusID, networkSettings.Statuses)
                    if(statusIndex!==null){
                        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
                        const status = {...networkSettings.Statuss[statusIndex], [event.target.name]: val}
                        networkSettings.Statuses[statusIndex] = status
                        this.setState({networkSettings: networkSettings})
                    }
                }
                break;

            default:
                alert(action.type)
        }
    }

    handleProfileEditorAction(action){

        switch(action.type){
            case 'PROFILE_EDITOR_SAVE': {
                let profile = {...action.profile}
                let networkSettings = {...this.state.networkSettings}
                if(!profile.ProfileID){
                    profile.ProfileID = getBase64ID()
                }
                let profileIndex = getIndexByID(profile.ProfileID, networkSettings.Profiles, "ProfileID")
                if(profileIndex===null){
                    networkSettings.Profiles.push(profile);
                }else{
                    networkSettings.Profiles[profileIndex] = profile
                }
                this.setState({networkSettings: networkSettings, profileEditor: {action:'', profileID:'', profile:{}}})
                break;
            }
                

            case 'PROFILE_EDITOR_CLOSE':
                this.setState({profileEditor: {action:'', profileID:'', profile:{}}})
                break;

            case 'PROFILE_EDITOR_OPEN_EDIT': {
                let profiles = this.state.networkSettings.Profiles.filter(item => item.ProfileID===action.profileID)
                let profile = profiles[0]

                this.setState({profileEditor: {
                    action: 'edit', //add|edit
                    profileID: action.profileID,
                    profile: profile,
                    emergencyGroups: this.getProfileEmergencyGroups(),
                    receivers: this.getProfileReceivers()
                }})
                break
            }
                
            case 'PROFILE_EDITOR_OPEN_ADD': 
                this.setState({profileEditor: {
                    action: 'add', //add|edit
                    profileID: action.profileID,
                    profile: {},
                    emergencyGroups: this.getProfileEmergencyGroups(),
                    receivers: this.getProfileReceivers()
                }})
                break
                
            case 'PROFILE_EDITOR_DELETE': {
                let networkSettings = {...this.state.networkSettings}
                const profileIndex = getIndexByID(action.profileID, networkSettings.Profiles, "ProfileID")
                if(profileIndex!==null){
                    networkSettings.Profiles.splice(profileIndex, 1)
                    this.setState({networkSettings: networkSettings})   
                }
                break
            }

            case 'PROFILE_EDITOR_INPUT':{
                const {event, profileID} = action;
                let networkSettings = {...this.state.networkSettings}
                const profileIndex = getIndexByID(profileID, networkSettings.Profiles, "ProfileID")
                if(profileIndex!==null){
                    const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
                    const profile = {...networkSettings.Profiles[profileIndex], [event.target.name]: val}
                    networkSettings.Profiles[profileIndex] = profile
                    this.setState({networkSettings: networkSettings})
                }
                break;
            }
                
            default:
                alert(action.type)
        }
    }

    getProfileEmergencyGroups(){
        let emergencyGroups = this.state.networkSettings.Groups.filter(
            item => item.Emergency  
        ).sort(sortByName);
        return emergencyGroups
    }

    getProfileReceivers(){
        let receivers = [
            ...this.state.networkSettings.NetworkDispatchers.map(item=>{
                return {ID: base64toGuid(item.ID), Name:item.Name, Type:10}
            }),
            ...this.state.networkSettings.Groups.map(item=>{
                return {ID: base64toGuid(item.ID), Name:item.Name, Type:4}
            }),
            ...this.state.networkSettings.Users.map(item=>{
                return {ID: base64toGuid(item.ID), Name:item.Name, Type:3}
            }),
        ];      

        //console.log(receivers);
        return receivers;
    }

    render(){
        const {t} = this.props

        let statusMessage = ''
        if(this.props.statusMessage){
            statusMessage = (
            <Typography align="center" color={this.props.statusError ? "error" : "default"}>
                {this.props.statusMessage}
            </Typography>
            );
        }

        //current network
        let net = this.getNetwork(this.props.networkID)
        //networkSettings
        let network = this.state.networkSettings
        let users = network && network.Users ? network.Users: [];
        let groups = network && network.Groups ? network.Groups: [];
        let profiles = network && network.Profiles ? network.Profiles: [];
        let statuses = network && network.Statuses ? network.Statuses: [];
        let devices = network && network.Devices ? network.Devices: [];

        let list = ''
        switch(this.state.selectedTab){
            case 'users': 
                const userAcls = this.getUserAcls(this.state.currentUserID); 
                const userDialog = this.state.userEditor.action?(
                    <EditUserDialog open={this.state.userEditor.action!==''}
                        {...this.state.userEditor} 
                        profiles={this.state.networkSettings.Profiles}
                        onAction={this.handleUserEditorAction.bind(this)}/>
                ):'';

                list = (
                    <Grid justify="flex-start" container style={{minHeight:'30vh'}}>
                        <Grid xs={6} key="1" item style={{}}>
                            <UsersListEditor users={users}
                                    selectedUserID={this.state.currentUserID}
                                    onUserSelect={this.handleUserSelect.bind(this)}
                                    onAction={this.handleUserEditorAction.bind(this)} />
                            {userDialog}
                        </Grid>        
                        <Grid xs={6} key="2" item style={{paddingLeft:'1em'}}>
                            <AclListEditor items={userAcls} title={t('GroupName')} 
                                onChange={this.handleUserAclChange.bind(this)} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'groups':
                const groupAcls = this.getGroupAcls(this.state.currentGroupID); 
                const groupDialog = this.state.groupEditor.action?(
                    <EditGroupDialog open={this.state.groupEditor.action!==''}
                        {...this.state.groupEditor} 
                        onAction={this.handleGroupEditorAction.bind(this)}/>
                ):'';

                list = (
                    <Grid justify="flex-start" container style={{minHeight:'30vh'}}>

                        <Grid xs={6} key="1" item style={{}}>
                            <GroupsListEditor groups={groups} 
                                selectedGroupID={this.state.currentGroupID}
                                onItemSelect={this.handleGroupSelect.bind(this)}
                                onAction={this.handleGroupEditorAction.bind(this)} /> 
                            {groupDialog}
                        </Grid>
                        <Grid xs={6} key="2" item style={{paddingLeft:'1em'}}>
                            <AclListEditor items={groupAcls} title={t('UserName')}
                                onChange={this.handleUserAclChange.bind(this)} />
                        </Grid>
                    </Grid>               
                );
                break;
            case 'statuses':

                const statusDialog = this.state.statusEditor.action?(
                    <EditStatusDialog open={this.state.statusEditor.action!==''}
                        {...this.state.statusEditor} 
                        onAction={this.handleStatusEditorAction.bind(this)}/>
                ):'';
                //console.log(statuses)
                list = (
                    <>
                    <StatusesListEditor items={statuses} 
                        selectedItemID={this.state.currentStatusID}
                        onItemSelect={this.handleStatusSelect.bind(this)}
                        onAction={this.handleStatusEditorAction.bind(this)} />  
                    {statusDialog}
                    </>
                )
                break;
            case 'devices':
                list = (
                    <DevicesList items={devices} 
                        selectedItemID={this.state.currentDeviceID}
                        onItemSelect={this.handleDeviceSelect.bind(this)}/>  
                )
                break;
            case 'profiles':
                const profileDialog = this.state.profileEditor.action?(
                    <EditProfileDialog open={this.state.profileEditor.action!==''}
                        {...this.state.profileEditor}  
                        onAction={this.handleProfileEditorAction.bind(this)}/>
                ):'';

                list = (
                    <>
                    <ProfilesListEditor items={profiles} 
                        selectedItemID={this.state.currentProfileID}
                        onItemSelect={this.handleProfileSelect.bind(this)}
                        onAction={this.handleProfileEditorAction.bind(this)} />  
                    {profileDialog}
                    </>
                )   
                break
            default:
                alert('Tab action not defined!')
        }

           
        return (
            <Grid item xs={12} style={{height:'100vh'}}>

                <ApplicationBar user={this.props.user}
                    onAction={this.handleAction.bind(this)}>
                    {network ? network.Name : ''}
                </ApplicationBar>

                <Grid container justify="flex-start" spacing={0} style={{height:'83%', borderTop:'1px solid '+theme.palette.border.dialog}} >
                    <Grid xs={12} key="2" item style={{}}>
                        <Container height="100vh" style={{padding:0, paddingTop: '1em'}}>

                            <Dialog width="xl" variant="h6" align="left" title={net.Name} style={{}}>
                                <Box textAlign="left" style={{paddingBottom: '1em'}}>
                                    <TabbedMenu 
                                        selected={this.state.selectedTab} 
                                        onClick={this.handleTabClick.bind(this)}
                                        usersEnabled={true}
                                        groupsEnabled={true}
                                        statusesEnabled={true}
                                        profilesEnabled={true}

                                    ></TabbedMenu>
                                </Box>
                                <Box textAlign="left" style={{paddingBottom: '1em'}}>
                                    {list}
                                </Box>
                                <Box textAlign="center" style={{}}>
                                    <Button variant="contained" color="primary" 
                                        onClick={this.handleButtonSave.bind(this)} 
                                        style={{margin: '0 0.4em'}}>{t('Apply')}</Button>
                                    <Button variant="contained" color="inherit"
                                        onClick={this.handleButtonCancel.bind(this)} 
                                        style={{margin: '0 0.4em'}} >{t('Cancel')}</Button>
                                </Box>
                            </Dialog>

                        </Container>
                    </Grid>
                </Grid>
                <Grid xs={12} key="2" item style={{height:'3em'}}>
                    {statusMessage}{/*Server Status*/}
                </Grid>
            </Grid>
        );
    }
}

export const NetworkEditor = withTranslation()(NetworkEditor_);