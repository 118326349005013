import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

//import { getLang } from '../functions/lang'

import { Dialog } from '../components/Dialog';
import {Container, Box, Button } from '@material-ui/core';
import Logo from '../components/Logo';
import { Navigate } from "react-router-dom";

//export 
class MainMenu_ extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            lang: this.props.lang, // this.props.i18n.language
        }

        //console.log('this.props')
        //console.log(this.props)

        this.handleMonitoringMenu = this.handleMonitoringMenu.bind(this)
        this.handleDispatcherMenu = this.handleDispatcherMenu.bind(this)
    }

    componentDidMount(){
        this.setTitle()
    }
    componentDidUpdate(){
        this.setTitle()
    }
    setTitle(){
        document.title = this.props.config.title + ' Control Panel'
    }

    handleMonitoringMenu(){
        this.setState({navigate:'/monitoring'})
    }

    handleDispatcherMenu(){
        this.setState({navigate:'/dispatcher'})
    }

    handleLangChange(e){
        const lang = e.target.value
        this.props.i18n.changeLanguage(lang);
        this.setState({lang: lang})
    }

    render() {
        //const { t, i18n } = this.props;

        if(this.state.navigate){
            return (
<Navigate to={this.state.navigate}></Navigate>
            )
        }

        //const lang = getLang(i18n)
        
        //const margin = {margin:'16px 0 8px 0'}

        return (
            <>
            <Container height="100vh" style={{ paddingTop: '2em' }}>
            <Dialog variant="h6" title={(<Logo></Logo>)}>
                    
                        <Box style={{margin: 10}} textAlign="center">
                            <Button variant="outlined" size="large" onClick={this.handleDispatcherMenu}>Dispatcher</Button>
                        </Box>
                        <Box style={{margin: 10}} textAlign="center">
                            <Button variant="outlined" size="large" onClick={this.handleMonitoringMenu}>System Monitoring</Button>
                        </Box>

                        {/*
                        <Box textAlign="center">
                        <FormControl style={{...margin, minWidth: 170}}>
                            <InputLabel htmlFor="Lang">{t("Language")}</InputLabel>
                            <Select
                                style={{textAlign: 'left'}}
                                value={lang}
                                onChange={this.handleLangChange.bind(this)}
                                inputProps={{name: 'lang', id: 'lang'}}
                                >
                                {Object.keys(i18n.options.langs).map(
                                    code => <MenuItem key={code} value={code}>{i18n.options.langs[code].name}</MenuItem>
                                )}
                            </Select>

                        </FormControl>
                        </Box>
                        */}                    

            </Dialog>
            </Container>
            </>
        );
    }

}

export const MainMenu = withTranslation()(MainMenu_);