import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import Paper from '@material-ui/core/Paper';
import { Grid, Typography, Divider } from '@material-ui/core';
import { PTT_CONTROL_TYPES } from '../../api/ServerApi';

import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { formatDateTime } from '../../../api/date';



export default class OngoingCalls_ extends React.Component{

    constructor(props){
        super(props)
        this.calls = [...this.props.calls]
        this.calls.reverse()
    }


    componentDidUpdate(){
        this.calls = [...this.props.calls]
        this.calls.reverse()
    }

    background(call){
        switch(call.Control){
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_END: return '';
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_ENTER: return 'lightblue';
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_PRESSED: return call.Incoming?'lightgreen':'orange';
            case PTT_CONTROL_TYPES.VOICE_PRIVATE_RELEASED: return 'lightblue';

            case PTT_CONTROL_TYPES.VOICE_GROUP_END: return '';
            case PTT_CONTROL_TYPES.VOICE_GROUP_ENTER: return 'lightblue';
            case PTT_CONTROL_TYPES.VOICE_GROUP_PRESSED: return call.Incoming?'lightgreen':'orange';
            case PTT_CONTROL_TYPES.VOICE_GROUP_RELEASED: return 'lightblue';
            default: return '';
        }
    }

    iconArrowSource(call){
        if(call.Control === PTT_CONTROL_TYPES.VOICE_GROUP_PRESSED){
            return <ArrowBackIcon style={{color: 'red'}}></ArrowBackIcon>
        }else if(call.Control === PTT_CONTROL_TYPES.VOICE_PRIVATE_PRESSED){
            if(call.SourceID === call.TransmitterID){
                return <ArrowBackIcon style={{color: 'red'}}></ArrowBackIcon>
            } else {
                return <ArrowForwardIcon style={{color: 'green'}}></ArrowForwardIcon>
            }
        }else{
            return <ArrowBackIcon style={{visibility: 'hidden'}}></ArrowBackIcon>
        }
    }

    iconArrowTarget(call){
        if(call.Control === PTT_CONTROL_TYPES.VOICE_GROUP_PRESSED){
            return <ArrowForwardIcon style={{color: 'green'}}></ArrowForwardIcon>
        }else if(call.Control === PTT_CONTROL_TYPES.VOICE_PRIVATE_PRESSED){
            if(call.TargetID === call.TransmitterID){
                return <ArrowBackIcon style={{color: 'red'}}></ArrowBackIcon>
            }else{
                return <ArrowForwardIcon style={{color: 'green'}}></ArrowForwardIcon>
            }
        }else{
            return <ArrowBackIcon style={{visibility: 'hidden'}}></ArrowBackIcon>
        }
    }

    iconTarget(call){
        if(call.Type === 'group'){
            return <GroupIcon color="primary"/>
        }else{
            return <PersonIcon color="primary"/>
        }
    }

    render(){
        const {t} = this.props

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'none',
              margin: 'auto',
              padding: theme.spacing(2)
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            gridWrapper: {
                width: '100%',
                paddingBottom: 5
            },
            gridRow: {
            },
            cell: {
                margin: '0 2px' 
            },
            cellHeader: {
                background: theme.palette.primary.main,
                color: 'white',
                padding: '3px 5px'
            }
        };

        if(this.calls.length){
            
        return(
            <>
                <Paper style={styles.paper}>
                {this.calls.map((call, index) => 
                    <>
                    <Grid key={call.CallID} style={{...styles.gridWrapper, backgroundColor:this.background(call)}}>
                        <Grid style={styles.gridRow} container alignItems="center">
                            <Grid style={styles.cell} item>
                                {this.iconArrowSource(call)}
                            </Grid>
                            <Grid style={styles.cell} item >
                                <PersonIcon color="primary"/>
                            </Grid>
                            <Grid style={styles.cell} item>
                                {call.SourceName}
                            </Grid>
                        </Grid>
                        <Grid style={styles.gridRow} container alignItems="center">
                            <Grid style={styles.cell} item >
                                {this.iconArrowTarget(call)}
                            </Grid>
                            <Grid style={styles.cell} item>
                                {this.iconTarget(call)}
                            </Grid>
                            <Grid style={styles.cell} item>
                                {call.TargetName}
                            </Grid>
                        </Grid>
                        <Grid style={styles.gridRow} container alignItems="center">
                            <Grid style={styles.cellHeader} item xs>
                                {formatDateTime(call.Time, 'time')}
                                {" :: "}
                                {call.NetworkName}
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    {index+1 < this.calls.length ?
                        <Divider style={{margin:5}}/>
                    :''} 
                    
                    </>
                )}
                </Paper>
               
            </>
        )
        }else{
            return (
                <Typography variant="h5" color="secondary" style={{width:'100%', height:'100%'}} align="center">
                    {t('OngoingCalls')}
                </Typography>
            );

        }

    }

}


export const OngoingCalls = withTranslation()(OngoingCalls_);