import React from 'react';
import './FleetConsole.css';

import { withTranslation } from 'react-i18next/hooks';

import { getLang } from '../functions/lang'

import { Container } from '@material-ui/core';

//import {Routes, Route, Outlet } from "react-router-dom";
//import { BrowserRouter } from "react-router-dom";

import { ServerApi, PTT_REQUEST_TYPES } from './api/ServerApi';
import { getBase64ID, getObjectByID } from '../api/helpers';
import { FILESTORAGE_MAX_FILE_SIZE } from './api/ServerApi';
//import { Dialog } from './components/Dialog';
//import Logo from './components/Logo';
import { isString } from 'util';


import {ConnectionProgress} from '../pages/ConnectionProgress';
import { Login } from '../pages/Login';
import { ServerConsole } from './pages/ServerConsole/ServerConsole';
//import { NetworkEditor } from './pages/NetworkEditor/NetworkEditor';
//import { ServerMonitor } from './pages/ServerMonitor';
//import { EditDeviceSettingsDialog } from './pages/DeviceSettings/EditDeviceSettingsDialog';
//import { UserPropertiesDialog } from './pages/ServerConsole/UserPropertiesDialog';
//import { GroupPropertiesDialog } from './pages/ServerConsole/GroupPropertiesDialog';

import { EventLogger, EVENT_TYPE } from './api/EventLogger';

//import { SettingsDialog } from './pages/SettingsDialog/SettingsDialog';
import { getSettingsInstance } from './api/Settings';

import * as dev from '../api/dev'
import FileStorage from './api/FileStorage';
import IndexedDb from '../api/IndexedDb';
import { parseFile, thumbnailify } from '../api/file';
//import { appendFile } from 'fs';


//export default 
class FleetConsole_ extends React.Component {

    constructor(props) {
        super(props);

        const {t} = props 

        let login = localStorage.getItem('login');
        let password = sessionStorage.getItem('password');
        let serveraddress = localStorage.getItem('serveraddress')

        this.state = {
            vendorName: 'WalkieFleet',
            isLogged: false,
            isConnecting: false, //this.isConnecting(),
            authUser: {
                login: isString(login) ? login : '',
                password: isString(password) ? password : '',
                serveraddress: isString(serveraddress) ? serveraddress : ''
            },
            apiError: false,
            apiMessage: '',
            networks: [],
            page: 'login',
            networkEditor: {
                networkID: '',
                networkSettings: null
            },
            deviceSettingsEditor: {
                networkID: '',
                deviceID: '',
                deviceSettings: null
            },
            userPropertiesDialog: {
                networkID: '',
                userID: '',
                deviceID: '',
                avatar: null,
                properties: null
            },
            groupPropertiesDialog: {
                networkID: '',
                groupID: '',
                properties: null
            },
            settingsDialog: null,
            calls: [],
            unreadMessages: [],
            emergency: [],
            
        };

        //this.login();
        //console.log('new EventLogger')

        this.indexedDB = new IndexedDb('walkiefleet', 15)
        this.eventLogger = new EventLogger(this.indexedDB)
        this.fileStorage = new FileStorage(this.indexedDB)
        this.eventLogger.addDeleteListener(this.handleDeleteEvent.bind(this))
        this.indexedDB.connect()

        this.api = null

        //this.settings = new Settings()
        this.settings = getSettingsInstance(t)

        this.handleAppAction = this.handleAppAction.bind(this)
    }

    componentDidMount(){
        this.setTitle()
    }
    componentDidUpdate(){
        this.setTitle()
    }
    setTitle(){
        document.title = this.props.config.title + ' Dispatcher'
    }

    /*
    isConnecting(){
      let user = this.getAuthUser();
      return user.login && user.password && user.serveraddress;
    }
    */

    /*
    getLang() {
        let lang = this.props.i18n.language
        if (lang.indexOf('-')) {
            lang = lang.split('-')[0]
        }
        let { langs } = this.props.i18n.options
        let defaultLang = null
        let existLang = null
        for (let key in langs) {
            if (key === lang) {
                existLang = key
                break
            }
            if (langs[key].default === true) {
                defaultLang = key
            }
        }

        if (!existLang) {
            lang = defaultLang
            this.props.i18n.changeLanguage(lang)
        }

        return lang
    }
    */

    login(login, password, serveraddress) {
        if (login && password && serveraddress) {
            localStorage.setItem("login", login);
            sessionStorage.setItem("password", password);
            localStorage.setItem("serveraddress", serveraddress);

            this.setState({ isConnecting: true, page: 'connecting' });
            this.startApi(serveraddress, login, password, this.props.config);

            this.eventLogger.setDispatcher(login)
            this.eventLogger.add({ type: EVENT_TYPE.TYPE_LOGIN, sender: login, note: '' })
        }
    }

    startApi(serveraddress, login, password, config) {
        
        let options = {
            //settings: this.settings,
            eventLogger: this.eventLogger,
            fileStorage: this.fileStorage,
            onError: this.handleApiError.bind(this),
            onClose: this.handleApiClose.bind(this),
            onConnect: this.handleApiConnect.bind(this),
            onNetworksChanged: this.handleNetworksChanged.bind(this),
            onAvatarUpdate: this.handleAvatarUpdate.bind(this),
            onPttCall: this.handlePttCall.bind(this),
            onUnreadMessagesChanged: this.handleUnreadMessagesChanged.bind(this),
            onEmergency: this.handleEmergency.bind(this),
            onAction: this.handleAppAction.bind(this),
            config: config
        }
        this.api = new ServerApi(serveraddress, login, password, options)
        this.api.connect();
    }


    //login form callback
    handleLogin(login, password, serveraddress) {
        this.setState({
            authUser: {
                login: login,
                password: password,
                serveraddress: serveraddress
            }
        })
        this.login(login, password, serveraddress);
    }

    handleApiError(err, connectCount) {
        console.log('handleApiError error')
        console.log(err)
        if (isString(err)) {
            this.setState({ apiError: true, apiMessage: err })
        } else {
            if (err.type === 'login') {
                this.setState({ apiError: true, apiMessage: err.message, page: 'login', isConnecting: true })
            } else {
                let errorStr = JSON.stringify(err, ["message", "arguments", "type", "name"]);
                this.setState({ apiError: true, apiMessage: 'An error occured: ' + errorStr + ' ' + connectCount })
                console.log(err)
            }
        }
    }

    handleApiClose(evt, connectCount) {
        //console.log(evt)
        switch (evt.code) {
            case 1006:
                this.setState({ apiError: true, apiMessage: 'Try to Connect: ' + connectCount })
                break;
            case 1005:
                //No Status Received - regular close connection
                if (this.state.page !== 'login') {
                    this.setState({ 
                        apiError: true, 
                        apiMessage: 'Try to Connect: ' + connectCount,
                        isConnecting: true
                    })
                }
                break;

            default:
                this.setState({ 
                    apiError: true, 
                    apiMessage: 'handleApiClose:' + evt.code + '; ' + JSON.stringify(evt) 
                })
        }
    }

    //on message CONFIG_SERVER_RESPONSE_ACK
    handleApiConnect() {
        this.setState({ 
            isLogged: true, 
            isConnecting: false, 
            page: 'console',
            apiError: false, 
            apiMessage: ''
        })
    }

    handleNetworksChanged(nets) {
        //console.log('handleNetworksChanged')
        //console.log(nets)
        this.setState({ networks: nets })
    }

    handleDeleteEvent(event){
        //console.log('onDeleteEvent')
        //console.log(event)
        switch(event.type){
            case EVENT_TYPE.TYPE_FILE_GROUP:
            case EVENT_TYPE.TYPE_FILE_PRIVATE:
            case EVENT_TYPE.TYPE_IMAGE_GROUP:
            case EVENT_TYPE.TYPE_IMAGE_PRIVATE:
                this.fileStorage.delete(event.ref_id)
                break
            default: console.error('Unknown switch case for event.type = '+event.type)
        }
    }

    handleAppAction(action) {
        const { i18n } = this.props
        //console.log('App.action')
        //console.log(action)
        switch (action.type) {
            case 'NETWORK_EDITOR_SHOW':
                this.setState({
                    page: 'networkeditor',
                    networkEditor: { networkID: action.networkID, networkSettings: null }
                });
                this.api.queryGetNetworkSettings(action.networkID, (networkSettings) => {
                    if (this.state.networkEditor && this.state.networkEditor.networkID) {
                        this.setState({
                            networkEditor: {
                                networkID: action.networkID,
                                networkSettings: networkSettings
                            }
                        })
                    }
                })
                break;

            case 'NETWORK_EDITOR_SAVE':
                this.api.querySaveNetworkSettings(action.networkSettings);
                this.setState({ networkEditor: null, page: '' })
                break;

            case 'NETWORK_EDITOR_CLOSE':
                this.setState({ networkEditor: null, page: '' })
                break;

            case 'SET_LANGUAGE':
                const lang = action.lang;
                this.props.i18n.changeLanguage(lang);
                break;

            case 'LOGOUT':
                this.setState({
                    isLogged: false,
                    isConnecting: false,
                    page: 'login',
                    apiError: false,
                    apiMessage: ''
                })
                this.api.close();
                break;

            case 'LANGUAGE_CHANGE':
                //todo???
                break;

            case 'DEVICESETTINGS_EDITOR_SHOW':
                const { networkID, deviceID, userID } = action
                const requestID = getBase64ID()
                this.api.queryGetDeviceSettings(networkID, deviceID, requestID, deviceSettings => {
                    this.setState({
                        deviceSettingsEditor: {
                            networkID: networkID,
                            deviceID: deviceID,
                            userID: userID,
                            deviceSettings: deviceSettings
                        }
                    });
                });
                break;

            case 'DEVICE_SETTINGS_EDITOR_CLOSE':
                this.setState({
                    deviceSettingsEditor: {
                        networkID: '',
                        deviceID: '',
                        deviceSettings: null
                    }
                });
                break;

            case 'DEVICE_SETTINGS_EDITOR_SAVE': {
                const { networkID, userID, deviceID, deviceSettings } = action
                this.api.querySaveDeviceSetings(networkID, userID, deviceID, deviceSettings)
                this.setState({
                    deviceSettingsEditor: {
                        networkID: '',
                        deviceID: '',
                        deviceSettings: null
                    }
                });
                break;
            }


            case 'GPS_DEVICE_REQUEST': {
                const { networkID, deviceID, groupID } = action
                this.api.queryGpsDeviceRequest(networkID, deviceID, groupID)
                break;
            }


            case 'USER_PROPERTIES_DIALOG_SHOW': {
                const { networkID, deviceID, userID } = action
                const network = getObjectByID(networkID, this.state.networks)
                const device = getObjectByID(deviceID, network.devices)
                let avatar = null

                if (device && device.AvatarHash) {
                    avatar = localStorage.getItem(device.AvatarHash)
                }

                if (device && device.AvatarHash && !avatar) {
                    this.api.queryAvatar(device.AvatarHash, networkID)
                }

                let pollingTime = this.settings.get('defaultPollingTime'), pollingEnabled = false
                if (device && device.locationRequest) {
                    pollingEnabled = true
                    pollingTime = device.locationRequest.period
                }

                this.setState({
                    userPropertiesDialog: {
                        networkID: networkID,
                        deviceID: deviceID,
                        userID: userID,
                        avatar: avatar,
                        properties: {
                            pollingTime: pollingTime,
                            pollingEnabled: pollingEnabled,
                            profileID: ''
                        }
                    }
                });
                break
            }

            case 'USER_PROPETIES_DIALOG_CLOSE': {
                this.setState({
                    userPropertiesDialog: {
                        networkID: '',
                        deviceID: '',
                        userID: '',
                        profileID: null,
                        properties: null
                    }
                });
                break
            }

            case 'USER_PROPERTIES_DIALOG_SAVE': {
                const { networkID, deviceID, properties } = action //userID,

                //console.log('USER_PROPERTIES_DIALOG_SAVE')
                //console.log(action)
                //console.log('networkID=' + networkID)
                let networks = this.state.networks
                //console.log(networks)
                let network = getObjectByID(networkID, networks)
                //console.log(network)
                let device = getObjectByID(deviceID, network.devices)
                //console.log(device)

                if(device){
                    if (properties.pollingEnabled && properties.pollingTime) {
                        let requestID = this.api.queryGpsDeviceRequest(networkID, deviceID, null, properties.pollingTime)
                        device.locationRequest = {
                            period: properties.pollingTime,
                            requestID: requestID
                        }
                    } else {
                        if (device.locationRequest) {
                            this.api.queryGpsDeviceCancel(networkID, device.locationRequest.requestID)
                            delete device.locationRequest
                        }
                    }
                }

                this.setState({
                    networks: networks,
                    userPropertiesDialog: {
                        networkID: '',
                        deviceID: '',
                        userID: '',
                        profileID: null,
                        properties: null
                    }
                })

                break;
            }

            case 'GROUP_PROPERTIES_DIALOG_SHOW': {
                const { networkID, groupID } = action
                const network = getObjectByID(networkID, this.state.networks)
                const group = getObjectByID(groupID, network.groups)

                let pollingTime = this.settings.get('defaultPollingTime'), pollingEnabled = false
                if (group.locationRequest) {
                    pollingEnabled = true
                    pollingTime = group.locationRequest.period
                }

                this.setState({
                    groupPropertiesDialog: {
                        networkID: networkID,
                        groupID: groupID,
                        properties: {
                            pollingTime: pollingTime,
                            pollingEnabled: pollingEnabled,
                        }
                    }
                });
                break
            }

            case 'GROUP_PROPETIES_DIALOG_CLOSE': {
                this.setState({
                    groupPropertiesDialog: {
                        networkID: '',
                        groupID: '',
                        properties: null
                    }
                });
                break
            }

            case 'GROUP_PROPERTIES_DIALOG_SAVE': {
                const { networkID, groupID, properties } = action

                //console.log('GROUP_PROPERTIES_DIALOG_SAVE')
                //console.log(action)
                //console.log('networkID=' + networkID)
                let networks = this.state.networks
                //console.log(networks)
                let network = getObjectByID(networkID, networks)
                //console.log(network)
                let group = getObjectByID(groupID, network.groups)
                //console.log(group)

                if (properties.pollingEnabled && properties.pollingTime) {
                    let requestID = this.api.queryGpsDeviceRequest(networkID, null, groupID, properties.pollingTime)
                    group.locationRequest = {
                        period: properties.pollingTime,
                        requestID: requestID
                    }
                } else {
                    if (group.locationRequest) {
                        this.api.queryGpsDeviceCancel(networkID, group.locationRequest.requestID)
                        delete group.locationRequest
                    }
                }
                this.setState({
                    networks: networks,
                    groupPropertiesDialog: {
                        networkID: '',
                        groupID: '',
                        properties: null
                    }
                })

                break
            }

            case 'GROUP_SOUND_MUTE': {
                const { networkID, groupID, properties } = action
                this.api.groupSoundMute(networkID, groupID)
                //alert('sound mute '+networkID+ ' '+groupID)
                break
            }

            case 'SETTINGS_DIALOG_SHOW': {
                let settings = this.settings.getAll()
                settings.language = getLang(i18n)

                this.setState({
                    settingsDialog: {
                        settings: settings
                    }
                })
                break
            }

            //case 'SETTINGS_EDITOR_CLOSE':
            case 'SETTINGS_DIALOG_CLOSE':
                this.setState({
                    settingsDialog: null
                })
                break

            case 'SETTINGS_EDITOR_SAVE': {
                let { settings } = action
                //console.log('settings')
                //console.log(settings)

                if (getLang(i18n) !== settings.language) {
                    this.props.i18n.changeLanguage(settings.language);
                }
                delete settings.language

                this.settings.save(settings)

                this.setState({
                    settingsDialog: null
                })
                break
            }

            case 'PTT_START': {
                const { deviceID, groupID, networkID } = action
                //console.log(action)
                if (deviceID) {
                    this.api.startPTT(deviceID, PTT_REQUEST_TYPES.PRIVATE_VOICE_CALL_START, networkID)
                } else if (groupID) {
                    this.api.startPTT(groupID, PTT_REQUEST_TYPES.GROUP_VOICE_CALL_START, networkID)
                }
                break
            }

            case 'PTT_STOP': {
                const { deviceID, groupID, networkID } = action
                if (deviceID) {
                    this.api.stopPTT(deviceID, PTT_REQUEST_TYPES.PRIVATE_VOICE_CALL_STOP, networkID)
                } else if (groupID) {
                    this.api.stopPTT(groupID, PTT_REQUEST_TYPES.GROUP_VOICE_CALL_STOP, networkID)
                }

                break
            }


            case 'SEND_MESSAGE': {
                //console.log('action')
                //console.log(action)
                const { message, userID, deviceID, groupID, targetType, networkID } = action
                this.api.querySendTextMessage(message, userID, deviceID, groupID, targetType, networkID)
                break
            }

            case 'SEND_IMAGE':{
                const { file, message, userID, deviceID, groupID, targetType, networkID } = action

                thumbnailify(file, 100, (blob)=>{

                    //console.log('thumb blob')
                    //console.log(blob)

                    const jobID = this.api.querySendFile(blob, file, message, userID, deviceID, groupID, targetType, networkID)

                    //save thumb
                    const thumbObject = {
                        job_id: jobID,
                        name: file.name,
                        size: blob.size,
                        mime_type: file.type,
                        type: 0,
                        state: 0,
                        network_id: networkID
                    }

                    this.fileStorage.addFile(thumbObject, fileID => {
                        let num = 0
                        parseFile(blob, (val)=>{
                            const data = btoa(val)
                            const fileChunk = {
                                file_id: fileID,
                                num: num,
                                data: data
                            }
                            //console.log('fileChunk')
                            //console.log(fileChunk)
                            this.fileStorage.addChunk(fileChunk)
                            num++
                        })
                    })

                    //save image
                    const fileObject = {
                        job_id: jobID,
                        name: file.name,
                        size: file.size,
                        mime_type: file.type,
                        type: 1,
                        state: 0,
                        network_id: networkID
                    }

                    this.fileStorage.addFile(fileObject, fileID => {
                        let num = 0
                        let totalBytes = 0

                        if(file.size < FILESTORAGE_MAX_FILE_SIZE ){
                            parseFile(file, (val)=>{
                                const data = btoa(val)
                                totalBytes += val.length
                                const fileChunk = {
                                    file_id: fileID,
                                    num: num,
                                    data: data
                                }
                                //console.log('fileChunk')
                                //console.log(fileChunk)
                                this.fileStorage.addChunk(fileChunk)
                                num++

                                if(totalBytes >= file.size){
                                    alert('save last chunk')
                                    //console.log('save last chunk')
                                    //todo update status
                                }
                            })
                        }
                    })

                })

                break
            }

            case 'SEND_FILE': {
                //console.log('action')
                //console.log(action)
                const { file, message, userID, deviceID, groupID, targetType, networkID } = action
                const jobID = this.api.querySendFile(null, file, message, userID, deviceID, groupID, targetType, networkID)
                
                const fileObject = {
                    job_id: jobID,
                    name: file.name,
                    size: file.size,
                    mime_type: file.type,
                    type: 1,
                    network_id: networkID
                }
                this.fileStorage.addFile(fileObject, fileID => {

                    if(file.size < FILESTORAGE_MAX_FILE_SIZE ){
                        let num = 0
                        parseFile(file, (val)=>{
                            const data = btoa(val)
                            const fileChunk = {
                                file_id: fileID,
                                num: num,
                                data: data
                            }
                            //console.log('fileChunk')
                            //console.log(fileChunk)
                            this.fileStorage.addChunk(fileChunk)
                            num++
                        })
                    }

                })

                break
            }

            case 'SUBSCRIBE_MESSAGES': {
                const userID = action.userID ? action.userID : ''
                const deviceID = action.deviceID ? action.deviceID : ''
                const groupID = action.groupID ? action.groupID : ''
                const networkID = action.networkID
                this.api.subscribeMessages(userID, deviceID, groupID, networkID)
                break
            }

            case 'UNSUBSCRIBE_MESSAGES': {
                const userID = action.userID ? action.userID : ''
                const deviceID = action.deviceID ? action.deviceID : ''
                const groupID = action.groupID ? action.groupID : ''
                const networkID = action.networkID
                this.api.unsubscribeMessages(userID, deviceID, groupID, networkID)
                break
            }

            case 'MESSAGE_MARK_READ': {
                const { jobID, networkID } = action
                this.api.markMessageRead(jobID, networkID)
                break
            }

            case 'MESSAGE_MARK_ALL_READ': {
                const { userID, deviceID, groupID, networkID } = action
                this.api.markAllMessagesRead(userID, deviceID, groupID, networkID)
                break
            }

            case 'FILE_OPEN':{
                const { jobID, networkID } = action
                const onLoad = url => {
                    try{
                        if(url){
                            const w = window.open(url)
                            w.onload = ()=>{
                                URL.revokeObjectURL(url)
                            }
                        }
                    }catch(e){

                    }
                }
                //const onLoad = url => {alert('window.open');}
                //console.log('onLoad')
                //console.log(onLoad)
                //onLoad()

                let job = getObjectByID(jobID, this.api.jobs, 'JobID')
                if(job){
                    //alert('job '+ job.inProcess)
                }
                
                this.fileStorage.readFile(jobID, 1, networkID, onLoad, this.handleFileError.bind(this))
                break
            }

            case 'FILE_LOAD':{
                const { jobID, networkID } = action
                //const onLoad = url => {window.open(url)}
                //const onLoad = url => {alert('window.open');}
                //console.log('onLoad')
                //console.log(onLoad)
                //onLoad()
                this.fileStorage.readFile(jobID, 1, networkID, null, this.handleFileError.bind(this))
                break
            }

            case 'FILE_CANCEL':{
                const { jobID, networkID } = action
                this.api.cancelJob(jobID, networkID)
                break
            }

            case 'PREVIEW_OPEN':{
                const { jobID, networkID } = action
                const onLoad = url => {window.open(url)}
                this.fileStorage.readFile(jobID, 0, networkID, onLoad, this.handleFileError.bind(this))
                break
            }

            case 'PREVIEW_GET':{
                const { jobID, networkID, onLoad } = action
                //console.log('PREVIEW_GET '+ jobID)
                this.fileStorage.readFile(jobID, 0, networkID, onLoad, this.handleFileError.bind(this))
                break
            }


            //emergency actions
            case 'CANCEL_EMERGENCY':{
                this.api.cancelEmergency(action.emergencyID)
                break
            }
            case 'SEND_EMERGENCY_ACK':{
                this.api.sendEmergencyAck(action.emergencyID)
                break
            }
            case 'LOCATE_EMERGENCY':{
                const emergency = getObjectByID(action.emergencyID, this.state.emergency, 'EmergencyID')
                if(emergency){
                    this.api.queryGpsDeviceRequest(emergency.NetworkID, emergency.DeviceID)
                }
                break
            }

            case 'STATISTICS_REQUEST':{
                this.api.queryStatistics(statistics => {
                    alert('set state statistics')
                    this.setState({
                        statistics: statistics
                    });
                });
                break;
            }

            case 'CHECK_PERMISSION':{
                //console.log('action');
                //console.log(action);
                const ret = this.api.checkPermission(action.networkID, action.permission);
                //console.log('ret');
                //console.log(ret);
                return ret;
                //break;
            }

            default: dev.alert('App action [' + action.type + '] not defined')

        }

    }

    handleFileError(jobID, type, errorCode, fileID, networkID, onsuccess){
        switch(errorCode){
            case 1: {//empty data
                console.error('handleFileError queryEmptyData ' + jobID + '; type='+type)
                this.api.queryGetJobData(jobID, type, fileID, networkID, onsuccess)
                break
            }
            case 2: {// file is absent (not yet uploaded)
                console.error('handleFileError queryAbsentFile ' + jobID + '; type='+type)
                this.api.queryGetJobData(jobID, type, fileID, networkID, onsuccess)
                break
            }
            default:
                console.error('handleFileError: unexpected errorCode ' + errorCode)
        }
    }

    handleAvatarUpdate(hash, avatar, networkID) {
        if (this.state.userPropertiesDialog && this.state.userPropertiesDialog.deviceID) {
            const network = getObjectByID(networkID, this.state.networks)
            const device = getObjectByID(this.state.userPropertiesDialog.deviceID, network.devices)
            if (device && device.AvatarHash === hash) {
                this.setState({
                    userPropertiesDialog: { ...this.state.userPropertiesDialog, avatar: avatar }
                })
            }
        }
    }

    handlePttCall(calls, activeCallID) {
        this.setState({ calls: calls })
        this.forceUpdate()
    }

    handleUnreadMessagesChanged(messages) {
        //console.log('handleUnreadMessagesChanged')
        //console.log(messages)
        this.setState({ unreadMessages: messages })
    }

    handleEmergency(emergency){
        //console.log('handleEmergency')
        //console.log(emergency)
        this.setState({ emergency: emergency })
    }

    render() {

        const { i18n } = this.props;

        if (this.state.isConnecting) {
            return (
                <ConnectionProgress apiError={this.state.apiError} 
                        apiMessage={this.state.apiMessage} 
                        onAction={this.handleAppAction} />
            )
        } else if (this.state.page === 'login'){
            return (
                <Container height="100vh" style={{ paddingTop: '2em' }}>
                    <Login onLogin={this.handleLogin.bind(this)}
                        login={this.state.authUser.login}
                        password={this.state.authUser.password}
                        serveraddress={this.state.authUser.serveraddress}
                        lang={getLang(i18n)}
                    />
                </Container>
            );
        } else {
            
            return(
                <ServerConsole
                        settings={this.settings}
                        networks={this.state.networks}
                        user={this.state.authUser}
                        onAction={this.handleAppAction}
                        statusError={this.state.apiError}
                        statusMessage={this.state.apiMessage}
                        eventLogger={this.eventLogger}
                        calls={this.state.calls}
                        unreadMessages={this.state.unreadMessages}
                        emergency={this.state.emergency}

                        settingsDialog={this.state.settingsDialog}
                        deviceSettingsEditor={this.state.deviceSettingsEditor}
                        userPropertiesDialog={this.state.userPropertiesDialog}
                        groupPropertiesDialog={this.state.groupPropertiesDialog}
                        networkEditor={this.state.networkEditor}
                    />
            )
            
/*
            switch (this.state.page) {
                
                case 'login':
                    return (
                        <Container height="100vh" style={{ paddingTop: '2em' }}>
                            <Login onLogin={this.handleLogin.bind(this)}
                                login={this.state.authUser.login}
                                password={this.state.authUser.password}
                                serveraddress={this.state.authUser.serveraddress}
                                lang={getLang(i18n)}
                            />
                        </Container>
                    );
                    

                case 'networkeditor':
                    //onLogout={this.handleLogout.bind(this)}
                    return (
                        <NetworkEditor
                            networks={this.state.networks}
                            networkID={this.state.networkEditor.networkID}
                            networkSettings={this.state.networkEditor.networkSettings}
                            user={this.state.authUser}

                            onAction={this.handleAppAction}
                            statusError={this.state.apiError}
                            statusMessage={this.state.apiMessage}></NetworkEditor>
                    );

                case 'console':
                default:
                    //console.log('render ServerConsole')
                    //console.log(this.state.emergency)
                    return (
                        <ServerConsole
                            settings={this.settings}
                            networks={this.state.networks}
                            user={this.state.authUser}
                            onAction={this.handleAppAction}
                            statusError={this.state.apiError}
                            statusMessage={this.state.apiMessage}
                            eventLogger={this.eventLogger}
                            calls={this.state.calls}
                            unreadMessages={this.state.unreadMessages}
                            emergency={this.state.emergency}

                            settingsDialog={this.state.settingsDialog}
                            deviceSettingsEditor={this.state.deviceSettingsEditor}
                            userPropertiesDialog={this.state.userPropertiesDialog}
                            groupPropertiesDialog={this.state.groupPropertiesDialog}
                        />
                    );
            }
            */
        }
    }
}

export const FleetConsole = withTranslation()(FleetConsole_);