import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {Paper, Menu, MenuItem, IconButton, Button} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';

import { sortByOnlineName, getIndexByID, decimalColorToHexColor } from '../../../api/helpers';
import {  PTT_CONTROL_TYPES } from '../../api/ServerApi';
import { formatSpeed } from '../../api/Settings';
import { formatDateTime } from '../../../api/date';

import { PERMISSIONS } from '../../api/ServerApi';

//import {startPTT, stopPTT} from '../../api/ServerApi';


class UsersList_ extends React.Component{

    constructor(props){
        super(props);

        this.state = {menuAnchor: null, menuUser: ''}
        
        this.pttUser = null
        //this.pttButton = null

        this.handlePttUp = this.handlePttUp.bind(this)
    }

    componentDidUpdate(){
        let needRenderInterval = false
        this.props.users.forEach(user => {
            if(user.Latitude && user.Longitude){
                let expired = this.getGpsExpired(user)
                needRenderInterval |= !expired
            }
        });
        if(needRenderInterval && !this.interval){
            this.interval = setInterval(() => {
                this.setState({time: new Date().getTime()})
            }, 10000);
        }else if(this.interval && !needRenderInterval){
                clearInterval(this.interval)
        }
    }

    componentWillUnmount(){
        if(this.interval){
            clearInterval(this.interval)
        } 
    }

    handleUserSelect(userID, deviceID){
        this.props.onUserSelect(userID, deviceID)
    }

    getItemStyle(userID, deviceID){
        let selected = userID === this.props.selectedUserID && deviceID === this.props.selectedDeviceID 
        let style = {
            background: selected? theme.palette.background.selectedRow : '',
            cursor: 'pointer'
        }
        return style
    }

    formatPriority(priority){
        switch(priority){
            case 0: return this.props.t('Default') 
            default: return priority
        }
    }

    formatLocked(locked){
        switch(locked){
            case true: return this.props.t('locked')
            case false: 
            default: return ''
        }
    }

    handleMenuClick(event, user){
        this.setState({menuAnchor: event.currentTarget, menuUser: user});
        event.stopPropagation();
    }

    handleMenuClose(){
        //this.menuAnchor = null;
        this.setState({menuAnchor: null, menuUser: null});
        return null;
    }

    handleMenuItemClick(menu){
        const user = this.state.menuUser
        this.handleMenuClose();
        this.props.onContextMenu(menu, user)
    }

    getGpsExpired(user){
        let expired = false
        if(user.Latitude && user.Longitude){
            expired = new Date().getTime() > user.GpsExpire 
        }
        return expired
    }

    getGpsIconColor(user){
        let color = 'primary'
        const expired = this.getGpsExpired(user)
        if(expired){
            color = 'error'
        }else if(user.LocationRequest){
            color = 'disabled'
        }
        return color
    }

    displayGps(user){
        const expired = this.getGpsExpired(user)
        const hasGps = user.Latitude!==undefined && user.Longitude!==undefined
        const showInvalid = this.props.settings.get('showInvalidLocations')
        return hasGps && (!expired || showInvalid)
    }

    handlePttDown(user){
        document.addEventListener('mouseup', this.handlePttUp)
        this.pttUser = user

        this.props.onContextMenu('ptt_start', user)
    }

    handlePttUp(){
        this.props.onContextMenu('ptt_stop', this.pttUser)

        document.removeEventListener('mouseup', this.handlePttUp)
        this.pttUser = null
    }

    pttColor(user){
        if(user.PttStatus){
            switch(user.PttStatus.Control){
                case PTT_CONTROL_TYPES.VOICE_PRIVATE_ENTER: return 'lightblue'
                case PTT_CONTROL_TYPES.VOICE_PRIVATE_PRESSED: return user.PttStatus.Incoming?'lightgreen':'orange';
                case PTT_CONTROL_TYPES.VOICE_PRIVATE_RELEASED: return 'lightblue';
                case PTT_CONTROL_TYPES.VOICE_PRIVATE_END: return '';
                case PTT_CONTROL_TYPES.VOICE_PRIVATE_BEGIN: return '';
                default: console.error('UsersList.js: Unknown ptt status '+user.PttStatus.Control)
            }
            //return 'orange'
        }else{
            return ''
        }
    }

    checkPermission(permission){
        return this.props.onAction({
            type:'CHECK_PERMISSION', 
            networkID: this.props.networkID, 
            permission: permission 
        })
    }

    render(){
        const {t} = this.props

        this.props.users.sort(sortByOnlineName)

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            }
        };

        const users = this.props.users.map((user)=>{
            user.StatusName = null
            user.StatusColor = null
            if(user.StatusID!==undefined){
                const statusIndex = getIndexByID(user.StatusID, this.props.statuses)
                if(statusIndex!==null){
                    const status = this.props.statuses[statusIndex]
                    user.StatusName = status.Name
                    user.StatusColor = decimalColorToHexColor(status.Color) 
                }
            }
            return user
        })

        return(
            <>
                <Paper style={styles.paper}>
                    <Table size="small">
                        <TableBody>
                        {users.map((user) => 
                            <TableRow key={user.ID + user.DeviceID} 
                                    onClick={()=>{this.handleUserSelect(user.ID, user.DeviceID)}}
                                    style={this.getItemStyle(user.ID, user.DeviceID)}>
                                <TableCell style={{width:40, position:'relative'}} >
                                    <PersonIcon color={user.DeviceID?"primary":"disabled"}/>
                                    {user.Locked?<LockIcon fontSize="inherit" color="inherit"
                                        style={{position:'absolute', top:16, left: 20}} />:''}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {user.Name}
                                </TableCell>
                                {
                                    user.StatusID && user.StatusID!=='AAAAAAAAAAAAAAAAAAAAAA=='?
                                    <TableCell style={{width:30, backgroundColor:user.StatusColor}}
                                        title={user.StatusName}>
                                        <ErrorOutlineIcon  />
                                    </TableCell>
                                    :
                                    <TableCell style={{width:30}}></TableCell>
                                } 
                                <TableCell style={{width:30}} 
                                    title={user.Latitude && user.Longitude?(
                                        t('lon')+':'+user.Longitude.toFixed(5) + 
                                        ', '+t('lat')+':' + user.Latitude.toFixed(5) + 
                                        (user.Altitude? ', ' + t('alt') + ':' + user.Altitude.toFixed(1):'') +
                                        (user.Speed? ', '+ t('speed') + ':' + formatSpeed(user.Speed):'')+
                                        ', '+t('time')+': '+ formatDateTime(user.Time, 'time')
                                        ):''}>
                                    {
                                        (this.displayGps(user))?
                                        <GpsFixedIcon color={this.getGpsIconColor(user)} />
                                        :
                                        ''
                                    }
                                </TableCell>   
                                <TableCell style={{width:30}}>
                                    <Button style={{minWidth:48, background: this.pttColor(user)}}
                                        onMouseDown={event=>this.handlePttDown(user)}
                                        disabled={!Boolean(user.DeviceID)}>PTT</Button>
                                </TableCell>
                                <TableCell style={{width:30}}>
                                    <IconButton size="small" color="inherit" aria-label="more" 
                                        component="span"
                                        onClick={event=>this.handleMenuClick(event, user)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                        <Menu anchorEl={this.state.menuAnchor} keepMounted
                            open={Boolean(this.state.menuAnchor)} 
                            onClose={this.handleMenuClose.bind(this)}>

                            {this.state.menuUser && this.state.menuUser.DeviceID ?
                                <MenuItem 
                                    disabled={!this.checkPermission(PERMISSIONS.OP_MANAGEDEVICESETTINGS)}
                                    onClick={()=>{this.handleMenuItemClick('editdevicesettings')}}>
                                    {t('DeviceSettings')}
                                </MenuItem>
                                :''}

                            <MenuItem onClick={()=>{this.handleMenuItemClick('messages')}}>{t('Messages')}</MenuItem>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('findonmap')}}>{t('FindOnMap')}</MenuItem>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('properties')}}>{t('Properties')}</MenuItem>
                        </Menu>
                    </Table>
                </Paper>
            </>
        )

    }

}

export const UsersList = withTranslation()(UsersList_);