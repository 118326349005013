import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core'
import {Container, Box, IconButton} from '@material-ui/core'
import {Button, FormControl, Checkbox, TextField, InputLabel, MenuItem, Select } from '@material-ui/core';
import {MoreVert} from '@material-ui/icons';
import {EditUserPermissionsDialog} from './EditUserPermissionsDialog';

class EditUserDialog_ extends React.Component{

    constructor(props){
        super(props)

        const {user} = this.props; 
        this.state = {
            user: {
                Login: user.ID ? user.Login : '',
                Name: user.ID ? user.Name : '',
                Password: user.ID ? user.Password : '',
                Priority: user.ID ? user.Priority : 0,
                ProfileID: user.ID ? user.ProfileID: 'EREREREREREREREREREREQ==',
                Permissions: user.ID ? user.Permissions: -1,
                LocationPeriod: user.ID ? user.LocationPeriod: '',
                Locked: user.ID ? user.Locked: false 
            },
            errors:{
                Login: false, 
                Name: false,
                Password: false,
                Priority: false,
                ProfileID: false,
                Permissions: false,
                LocationPeriod: false,
                Locked: false
            },
            permissionsDialogOpen: false,
            changed: false,
            permissionsType: user.ID && user.Permissions>=0 ? 0 : -1
        }
    }

    handleSave(){
        if(this.validate()){
            let user = {...this.props.user, ...this.state.user}
            this.props.onAction({type: 'USER_EDITOR_SAVE', user: user})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'USER_EDITOR_CLOSE'})
    }

    handleInput(event){
        let changed = !this.state.changed ? {changed: true} : {}
        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const user = {...this.state.user, [event.target.name]: val}
        this.setState({user: user, ...changed})
    }

    handlePermissionsTypeChange(event){
        let permissions = {}
        if(event.target.value===0 && this.state.user.Permissions===-1){
            permissions = {Permissions: 13500882}
        }

        const user = {...this.state.user, ...permissions}
        this.setState({permissionsType: event.target.value, user: user})

        //this.setState({permissionsType: event.target.value, ...permissions})
    }

    handleAction(action){
        switch(action.type){
            case 'USER_PERMISSIONS_EDITOR_OPEN':
                this.setState({permissionsDialogOpen: true})
                break;

            case 'USER_PERMISSIONS_EDITOR_CLOSE':
                this.setState({permissionsDialogOpen: false})
                break;

            case 'USER_PERMISSIONS_EDITOR_SAVE':
                let changed = !this.state.changed ? {changed: true} : {}

                this.setState({permissionsDialogOpen: false})
                const user = {...this.state.user, Permissions: action.permissions}
                this.setState({user: user, ...changed})
                break;

            default: alert('EditUserDialog handleAction: udefined case')
        }
    }

    validate(){
        const {t} = this.props

        let errors = this.state.errors;
        let error = false
        if(!this.state.user.Login){
            errors.Login = t('LoginIsEmpty')
            error = true
        }else{
            errors.Login = false
        }
        if(!this.state.user.Name){
            errors.Name = t('NameIsEmpty')
            error = true
        }else{
            errors.Name = false
        }

        if(this.state.user.LocationPeriod && !Number(this.state.user.LocationPeriod)){
            errors.LocationPeriod = t('IsNotNumber')
            error = true
        }else if(this.state.user.LocationPeriod && !Number.isInteger(Number(this.state.user.LocationPeriod))){
            errors.LocationPeriod = t('IsNotInteger')
            error = true
        }else{
            errors.LocationPeriod = false
        }

        this.setState({errors: errors})

        return !error;
    }

    render(){
        const {t} = this.props

        console.log('this.state.user');
        console.log(this.state.user);

        const title = this.props.action==='add'?t('NewUser'):t('EditUser')

        const margin = {margin:'16px 0 8px 0'}

        let userPermissionsDialog = '';
        if(this.state.permissionsDialogOpen){
            userPermissionsDialog = (
                <EditUserPermissionsDialog
                    open={this.state.permissionsDialogOpen}
                    permissions={this.state.user.Permissions}
                    onAction={this.handleAction.bind(this)}
                    />
            );
        }


        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>
            <Box textAlign="center">
                <TextField name="Login"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.Login?this.state.errors.Login:t('Login')}
                    autoComplete="new-password"
                    error={this.state.errors.Login!==false}
                    value={this.state.user.Login}
                    onChange={this.handleInput.bind(this)}
                />

            </Box>
            <Box textAlign="center">
                <TextField name="Name"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.Name?this.state.errors.Name:t('Name')}
                    autoComplete="new-password"
                    error={this.state.errors.Name!==false}
                    value={this.state.user.Name}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
            <Box textAlign="center">
                <TextField type="password" name="Password" margin="normal"
                    style={{width: '100%'}}
                    autoComplete="new-password"
                    label={t('Password')}
                    value={this.state.user.Password}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
            

            <Box textAlign="left">
            <FormControl style={{...margin, width: '100%'}}>
                <InputLabel htmlFor="Priority">{t('Priority')}</InputLabel>
                <Select
                    value={this.state.user.Priority}
                    onChange={this.handleInput.bind(this)}
                    inputProps={{
                        name: 'Priority',
                        id: 'Priority',
                    }}
                    >
                    <MenuItem key="14" value={14}>+14</MenuItem>
                    <MenuItem key="13" value={13}>+13</MenuItem>
                    <MenuItem key="12" value={12}>+12</MenuItem>
                    <MenuItem key="11" value={11}>+11</MenuItem>
                    <MenuItem key="10" value={10}>+10</MenuItem>
                    <MenuItem key="9" value={9}>+9</MenuItem>
                    <MenuItem key="8" value={8}>+8</MenuItem>
                    <MenuItem key="7" value={7}>+7</MenuItem>
                    <MenuItem key="6" value={6}>+6</MenuItem>
                    <MenuItem key="5" value={5}>+5</MenuItem>
                    <MenuItem key="4" value={4}>+4</MenuItem>
                    <MenuItem key="3" value={3}>+3</MenuItem>
                    <MenuItem key="2" value={2}>+2</MenuItem>
                    <MenuItem key="1" value={1}>+1</MenuItem>
                    <MenuItem key="0" value={0}>{t('Default')}</MenuItem>
                    <MenuItem key="-1" value={-1}>-1</MenuItem>
                </Select>
            </FormControl>
            </Box>

            <Box textAlign="left">
            <FormControl style={{...margin, width: '100%'}}>
                <InputLabel htmlFor="ProfileID">{t('Profile')}</InputLabel>
                <Select
                    value={this.state.user.ProfileID}
                    onChange={this.handleInput.bind(this)}
                    inputProps={{name: 'ProfileID', id: 'ProfileID'}}
                    >
                    {this.props.profiles.map(profile=>
                        <MenuItem key={profile.ProfileID} value={profile.ProfileID}>{profile.Name}</MenuItem>
                    )}
                </Select>
            </FormControl>
            </Box>

            <Box textAlign="center">
                <Grid container>
                    <Grid item xs={11} align="left">

                        <FormControl style={{...margin, width: '100%'}}>
                            <InputLabel htmlFor="permissionsType">{t('Permissions')}</InputLabel>
                            <Select style2={{width: '100%'}}
                                value={this.state.permissionsType}
                                onChange={this.handlePermissionsTypeChange.bind(this)}
                                
                                inputProps={{
                                    name: 'permissionsType',
                                    id: 'permissionsType',
                                }}
                                >
                                <MenuItem key="-1" value={-1}>{t('InheritedFromNetwork')}</MenuItem>
                                <MenuItem key="0" value={0}>{t('Custom')}</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={1} style={{verticalAlign:'bottom', paddingTop:33}}>
                        <IconButton disabled={this.state.permissionsType===-1} 
                            size="small" color="inherit" aria-label="more permissions" component="span"
                            style={{width2: 16, height2: 16}}
                            onClick={()=>{this.handleAction({type: 'USER_PERMISSIONS_EDITOR_OPEN'})}}>
                            <MoreVert />
                        </IconButton>
                    </Grid>
                </Grid>

                {userPermissionsDialog}
            
            </Box>

            <Box textAlign="center">
                <TextField name="LocationPeriod"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.LocationPeriod?this.state.errors.LocationPeriod:t('LocationPeriod')}
                    autoComplete="new-password"
                    error={this.state.errors.LocationPeriod!==false}
                    value={this.state.user.LocationPeriod}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>

            <Box textAlign="center" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.user.Locked}
                            onChange={this.handleInput.bind(this)}
                            name="Locked"
                            />
                            {t('Locked')}
                    </label>
                </FormControl>
            </Box>
        
        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const EditUserDialog = withTranslation()(EditUserDialog_);