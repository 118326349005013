import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';

import './index.css';
//import {App} from './App';
import {AppRouter} from './AppRouter';
import * as serviceWorker from './serviceWorker';

import CssBaseline from '@material-ui/core/CssBaseline'; 
import { ThemeProvider } from '@material-ui/styles'; 
import theme from './theme';

document.addEventListener("onI18nReady", function(e) {
    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRouter />
        </ThemeProvider>    
    , 
    document.getElementById('root')
);
  });

  /*
ReactDOM.render(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRouter />
        </ThemeProvider>    
    , 
    document.getElementById('root')
);
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
