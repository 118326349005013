import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import {Paper} from '@material-ui/core';
import { EventList } from './EventList';
import { EventFilter } from './EventFilter';


class EventPanel_ extends React.Component {

    constructor() {
        super()

        const time = new Date()
        const date = new Date(time.getFullYear(), time.getMonth(), time.getDate(), 0, 0 )

        this.state = {
            filter: {
                dateFrom: date,
                dateTo: null
            },
            events: []
        }

        this.handleFilterChange = this.handleFilterChange.bind(this)

        this.handleQueryComplete = this.handleQueryComplete.bind(this)
        this.handeQueryError = this.handeQueryError.bind(this)
    } 
    
    componentDidMount(){
        this.handleFilterChange(this.state.filter)
    }

    handleFilterChange(filter){
        this.setState({filter: filter})
        console.log('filter:')
        console.log(filter)

        this.props.eventLogger.queryEvents(filter, this.handleQueryComplete, this.handeQueryError)
    }

    handleQueryComplete(events){
        //alert('query complete')
        console.log('handleQueryComplete')
        console.log(events)
        this.setState({events: events})
    }

    handeQueryError(){
        alert('query error')
    }


    render(){

        return(
            <Paper>
                <EventFilter onChange={this.handleFilterChange} filter={this.state.filter}/>
                <EventList events={this.state.events} />
            </Paper>
        );
            //eventLogger={this.props.eventLogger} filter={this.state.filter}
    }

}

export const EventPanel = withTranslation()(EventPanel_);