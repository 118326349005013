import React from 'react'
import './App.css'

import { withTranslation } from 'react-i18next/hooks'

import { getLang } from './functions/lang'
 
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom"

import { FleetConsole } from './FleetConsole'
import { FleetStatistics } from './FleetStatistics'
import { MainMenu } from './pages/MainMenu'
import { loadConfig } from './api/helpers'

//export default 
class AppRouter_ extends React.Component {

    constructor(props){
        super(props)
    
        this.state = {
            config: {
                title: 'WwwalkieFleet'
            }
        }
    }

    componentDidMount(){
        loadConfig(this.onloadConfig.bind(this))
    }

    onloadConfig(config){
        this.setState({config: config})
    }

    render(){

        const lang = getLang(this.props.i18n)

        return(
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<><Outlet></Outlet></>} >
                        <Route path="" element={<MainMenu lang={lang} config={this.state.config}/>} />

                        <Route path="dispatcher" element={<FleetConsole config={this.state.config}/>} />

                        <Route path="monitoring" element={<FleetStatistics config={this.state.config}/>} />
                        
                        <Route path="*" element={<MainMenu lang={lang} config={this.state.config}/>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        )

     }

}


export const AppRouter = withTranslation()(AppRouter_);   