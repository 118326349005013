import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Table, TableHead, TableBody, TableCell, TableRow} from '@material-ui/core';
import {Paper} from '@material-ui/core';
import { getEventTypeName } from '../../api/EventLogger';
import { formatDateTime } from '../../../api/date';

class EventList_ extends React.Component {

    render(){
        const {t} = this.props

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            }
        };

        return(
            <Paper style={styles.paper}>
                <Table size="small">
                    
                    <TableHead>
                        <TableRow>
                            <TableCell style={styles.head}>{t('EventType')}</TableCell>
                            <TableCell style={styles.head}>{t('DateTime')}</TableCell>
                            <TableCell style={styles.head}>{t('Dispatcher')}</TableCell>
                            <TableCell style={styles.head}>{t('Source')}</TableCell>
                            <TableCell style={styles.head}>{t('Destination')}</TableCell>
                            <TableCell style={styles.head}>{t('Duration')}</TableCell>
                            <TableCell style={styles.head}>{t('Description')}</TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
                        {this.props.events.map(event=>(
                            <TableRow key={event.id} >
                                <TableCell>
                                    {t(getEventTypeName(event.type))}
                                </TableCell>
                                <TableCell>
                                    {formatDateTime(event.datetime, 'full_date')}
                                </TableCell>
                                <TableCell>
                                    {event.dispatcher}
                                </TableCell>
                                <TableCell>
                                    {event.sender}
                                </TableCell>
                                <TableCell>
                                    {event.recipient}
                                </TableCell>
                                <TableCell>
                                    {event.duration}
                                </TableCell>
                                <TableCell>
                                    {event.note}
                                </TableCell>
                            </TableRow>
                        ))}
                        
                    </TableBody>
                </Table>
            </Paper>
        );

    }

}

export const EventList = withTranslation()(EventList_);