import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Grid, Typography, Container, Paper, Tabs, Tab} from '@material-ui/core';

import {ApplicationBar} from '../../../components/ApplicationBar';
//import {PttInformer} from '../../components/PttInformer'
//import Logo from '../../components/Logo';
import {TabbedMenu} from '../../../components/TabbedMenu';

import {NetworksList} from './NetworksList';
import {UsersList} from './UsersList';
import {GroupsList} from './GroupsList';
//import {ProfilesList} from './ProfilesList';
//mport DevicesList from './DevicesList';
//import {StatusesList} from './StatusesList';
//import {SelectLang} from '../../components/SelectLang';

import {Menu, MenuItem, Button, IconButton} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';

//import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";

import {getObjectByID, getDeviceID} from '../../../api/helpers';

import config from '../../../config';

import { TabPanel } from '../../../components/TabPanel'
import { MapPanel } from './MapPanel';
import { Messages } from './Messages';
import { EventPanel } from './EventPanel';

import { OngoingCalls } from './OngoingCalls';
import { UnreadMessages } from './UnreadMessages';
import { EmergencyAlerts } from './EmergencyAlerts';

import { SettingsDialog } from '../SettingsDialog/SettingsDialog';
import { UserPropertiesDialog } from './UserPropertiesDialog';
import { GroupPropertiesDialog } from './GroupPropertiesDialog';
import { EditDeviceSettingsDialog } from '../DeviceSettings/EditDeviceSettingsDialog';

import { NetworkEditor } from '../NetworkEditor/NetworkEditor';

class ServerConsole_ extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            currentNetworkID: '',
            currentUserID: '',
            currentGroupID: '',
            currentProfileID: '',
            currentStatusID: '',
            currentDeviceID: '',
            selectedTab: 'users', //tab object type  users|groups
            expandMessageArea: false,
            viewAnchor: null,
            toolsAnchor: null,
            tabs: [], //tab panel 
            tab: null
        }

        this.handleExpandClick = this.handleExpandClick.bind(this)
        this.handleNetworkSelect = this.handleNetworkSelect.bind(this)
        this.handleNetworkMenu = this.handleNetworkMenu.bind(this)
        this.handleViewMenu = this.handleViewMenu.bind(this)
        this.handleViewClose = this.handleViewClose.bind(this)
        this.handleToolsMenu = this.handleToolsMenu.bind(this)
        this.handleToolsClose = this.handleToolsClose.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
        this.handleUserMenu = this.handleUserMenu.bind(this)
        this.handleUserSelect = this.handleUserSelect.bind(this)
        this.handleGroupMenu = this.handleGroupMenu.bind(this)
        this.handleGroupSelect = this.handleGroupSelect.bind(this)
        this.handleAction = this.handleAction.bind(this)
        this.handleTabClick = this.handleTabClick.bind(this)
        this.handleSettingsMenu = this.handleSettingsMenu.bind(this)
        this.handleUnreadMessagesMenu = this.handleUnreadMessagesMenu.bind(this)
    }

    componentDidUpdate(){
        if(!this.state.currentNetworkID && this.props.networks && this.props.networks.length){
            this.setState({currentNetworkID: this.props.networks[0].ID})
        }
    }

    handleNetworkSelect(networkID){
        this.setState({currentNetworkID: networkID})
    }

    handleUserSelect(userID, deviceID){
        this.setState({currentUserID: userID, currentDeviceID: deviceID})
    }

    handleGroupSelect(groupID){
        this.setState({currentGroupID: groupID})
    }

    handleProfileSelect(profileID){
        this.setState({currentProfileID: profileID})
    }

    handleStatusSelect(statusID){
        this.setState({currentStatusID: statusID})
    }

    handleDeviceSelect(statusID){
        this.setState({currentStatusID: statusID})
    }

    handleNetworkMenu(action, networkID){
        switch(action){
            case 'edit':
                this.props.onAction({type:'NETWORK_EDITOR_SHOW', networkID: networkID})
                break;
            default: 
                alert('undefined case '+action)
        }
    }

    handleUserMenu(action, user){
        //const {t} = this.props
        switch(action){
            case 'editdevicesettings':
                this.props.onAction({
                    type:'DEVICESETTINGS_EDITOR_SHOW', 
                    networkID: this.state.currentNetworkID,
                    deviceID: user.DeviceID,
                    userID: user.ID
                })
                break;

            case 'findonmap':
                this.props.onAction({
                    type:'GPS_DEVICE_REQUEST', 
                    networkID: this.state.currentNetworkID,
                    deviceID: user.DeviceID
                })
                break;

            case 'properties':{
                this.setState({userPropertiesDialog: {ID: user.ID, deviceID: user.DeviceID}})
                this.props.onAction({
                    type:"USER_PROPERTIES_DIALOG_SHOW",
                    networkID: this.state.currentNetworkID,
                    deviceID: user.DeviceID,
                    userID: user.ID
                })
                break
            }

            case 'ptt_start':{
                console.log('ptt_start')
                console.log(user)
                this.props.onAction({
                    type: 'PTT_START',
                    deviceID: user.DeviceID,
                    networkID: user.NetworkID
                })
                break
            }

            case 'ptt_stop':{
                console.log('ptt_stop')
                this.props.onAction({
                    type: 'PTT_STOP',
                    deviceID: user.DeviceID,
                    networkID: user.NetworkID
                })
                break
            }

            case 'messages':{
                console.log('user')
                console.log(user)
                this.appendTab({
                    name: 'messages', 
                    label: user.Name, 
                    id: user.ID+user.DeviceID,
                    userID: user.ID,
                    deviceID: user.DeviceID, 
                    groupID: '',
                    type: 0, 
                    networkID: user.NetworkID
                })

                break
            }

            default: 
                alert('handleUserMenu action not defined: '+action)
        }
    }

    handleGroupMenu(action, group){
        switch(action){

            case 'findonmap':
                this.props.onAction({
                    type:'GPS_DEVICE_REQUEST', 
                    networkID: this.state.currentNetworkID,
                    groupID: group.ID,
                    //userID: user.ID
                })
                break;

            case 'properties':{
                this.setState({groupPropertiesDialog: {ID: group.ID}})
                this.props.onAction({
                    type:"GROUP_PROPERTIES_DIALOG_SHOW",
                    networkID: this.state.currentNetworkID,
                    groupID: group.ID
                })
                break;
            }
            
            case 'ptt_start':{
                console.log('ptt_start')
                console.log(group)
                this.props.onAction({
                    type: 'PTT_START',
                    groupID: group.ID,
                    networkID: group.NetworkID
                })
                break
            }

            case 'ptt_stop':{
                console.log('ptt_stop')
                this.props.onAction({
                    type: 'PTT_STOP',
                    groupID: group.ID,
                    networkID: group.NetworkID
                })
                break
            }

            case 'messages':{
                console.log('group')
                console.log(group)
                this.appendTab({
                    name: 'messages', 
                    label: group.Name, 
                    id: group.ID,
                    userID: '',
                    deviceID: '', 
                    groupID: group.ID,
                    type: 1, 
                    networkID: group.NetworkID
                })
                break
            }

            case 'mute':
                //console.log('group')
                //console.log(group)
                this.props.onAction({
                    type: 'GROUP_SOUND_MUTE',
                    groupID: group.ID,
                    networkID: group.NetworkID
                })
                break;

            default: 
                alert('handleGroupMenu action not defined: '+action)
        }
    }

    handleUnreadMessagesMenu(action, message){
        switch(action){
            case 'messages':{
                console.log('message')
                console.log(message)
                this.appendTab({
                    name: 'messages', 
                    label: message.ref_group_id ?  message.recipient : message.sender, 
                    id: message.ref_group_id ? message.ref_group_id : message.ref_user_id + message.ref_device_id,
                    userID: message.ref_user_id,
                    deviceID: message.ref_device_id, 
                    groupID: message.ref_group_id,
                    type: message.ref_group_id ? 1 : 0, 
                    networkID: message.network_id
                })
                break
            }

            case 'markread':{
                console.log(message)
                this.props.onAction({
                    type: 'MESSAGE_MARK_READ',
                    jobID: message.ref_id,
                    networkID: message.network_id
                })
                break
            }

            case 'markallread':{
                console.log('markallread')
                console.log(message)
                this.props.onAction({
                    type: 'MESSAGE_MARK_ALL_READ',
                    jobID: message.ref_id,
                    userID: message.ref_user_id,
                    deviceID: message.ref_device_id,
                    groupID: message.ref_group_id,
                    networkID: message.network_id
                })
                break
            }

            default: 
                alert('handleUnreadMessagesMenu action not defined: '+action)
        }
    }

    getNetwork(){
        return getObjectByID(this.state.currentNetworkID, this.props.networks)
    }

    handleLogout(){
        if(this.props.onLogout){
            this.props.onLogout()
        }
    }

    handleAction(action){
        this.props.onAction(action)
    }

    handleTabClick(tab){
        this.setState({selectedTab: tab});
    }

    handleExpandClick(){
        this.setState({expandMessageArea: !this.state.expandMessageArea});
    }

    handleViewMenu(event){
        this.setState({viewAnchor: event.currentTarget});
    }

    handleViewClose(){
        //this.menuAnchor = null;
        this.setState({viewAnchor: null});
        return null;
    }

    handleToolsMenu(event){
        this.setState({toolsAnchor: event.currentTarget});
    }

    handleToolsClose(){
        //this.menuAnchor = null;
        this.setState({toolsAnchor: null});
        return null;
    }

    handleSettingsMenu(){
        this.handleToolsClose();

        this.props.onAction({type: 'SETTINGS_DIALOG_SHOW'})
    }
    
    handleTabChange(event, newValue){
        //alert('handleTabChange ' + newValue)
        this.setState({tab: newValue})
    }

    appendTab(tab){
        let tabs = this.state.tabs
        let tabIndex = 0

        console.log('appendTab')
        console.log(tab)

        //if(tabs===null) tabs = [];
        let findIndex
        if(tab.id){
            findIndex = tabs.findIndex(item => item.name===tab.name && item.id===tab.id)
        }else{
            findIndex = tabs.findIndex(item => item.name===tab.name)
        }
        if(findIndex<0){
            tabs.push(tab)
            tabIndex = tabs.length-1
        }else{
            tabIndex = findIndex
        }

        if(tab.name==='messages'){
            this.props.onAction({
                type:'SUBSCRIBE_MESSAGES',
                userID: tab.userID,
                deviceID: tab.deviceID,
                groupID: tab.groupID,
                networkID: tab.networkID
            })
        }

        //console.log(tabs)
        this.setState({tabs: tabs, tab: tabIndex})
    }

    removeTab(name, id){
        let tabIndex = this.state.tab
        let tabs = this.state.tabs

        let findIndex
        if(id){
            findIndex = tabs.findIndex(item => item.name===name && item.id===id)
        }else{
            findIndex = tabs.findIndex(item => item.name===name)
        }
        if(findIndex>=0){
            const removedTabs = tabs.splice(findIndex, 1)

            console.log('removeTab')
            console.log(removedTabs)

            if(tabIndex>=tabs.length) tabIndex = tabs.length-1

            if(name==='messages' && removedTabs.length){
                const tab = removedTabs[0]
                this.props.onAction({
                    type: 'UNSUBSCRIBE_MESSAGES',
                    userID: tab.userID,
                    deviceID: tab.deviceID,
                    groupID: tab.groupID,
                    networkID: tab.networkID
                })
            }
        }
        this.setState({tabs: tabs, tab: tabIndex})
    }

    renderTabs(){
        //const {t} = this.props

        if(this.state.tabs.length){
            return (
                <>
                <Paper square>
                    <Tabs value={this.state.tab} 
                        variant="scrollable"
                        onChange={this.handleTabChange} 
                        indicatorColor="primary">

                        {this.state.tabs.map(item =>
                            <Tab key={item.name} 
                                label={(<>{item.label} <IconButton onClick={(e)=>{
                                        this.removeTab(item.name, item.id); e.stopPropagation();
                                    }}><CloseIcon fontSize="small"/></IconButton></>)} 
                                id={"tab-"+item.name} aria-controls={"tabpanel-"+item.name} />)}
                    </Tabs>
                </Paper>

                {this.state.tabs.map((item, index) => 
                    <TabPanel style={{height:'83%'}} key={item.name} value={this.state.tab} index={index}>
                        {this.renderPanel(item, index===this.state.tab)}
                    </TabPanel>
                )}

                </>
            );
        }else{
            return '';
        }
        
    }

    renderPanel(panel, visible){
        //console.log('render panel')
        //console.log(panel)

        switch(panel.name){
            case 'map': {

                //const network = this.getNetwork()
                
                const markers = []

                this.props.networks.forEach(network=>{
                    if(network.devices){
                        network.devices.forEach(device=>{

                            if(device.UserID && device.Longitude && device.Latitude){
                                //const user = getObjectByID(device.UserID, network.users)
                                
                                const marker = {
                                    id: device.ID,
                                    lat: device.Latitude,
                                    lng: device.Longitude,
                                    alt: device.Altitude,
                                    speed: device.Speed,
                                    accuracy: device.Accuracy,
                                    time: device.Time,
                                    label: device.UserName,
                                    title: device.UserName + (device.Speed? ', '+device.Speed+' km/h':'')
                                }
                                if(device.ID===this.state.currentDeviceID && device.UserID===this.state.currentUserID){
                                    markers.unshift(marker)    
                                }else{
                                    markers.push(marker)
                                }
                            }

                        })
                    }

                })

                /*
                if(this.state.selectedTab==='users' && this.state.currentDeviceID && this.state.currentUserID){
                    const deviceIndex = getIndexByID(this.state.currentDeviceID, network.devices)
                    const device = network.devices[deviceIndex]
                    const userIndex = getIndexByID(this.state.currentUserID, network.users)
                    const user = network.users[userIndex]
                    //console.log('map device')
                    //console.log(device)
                    if(device && device.Longitude && device.Latitude){
                        markers.push({
                            id: device.ID,
                            lat: device.Latitude,
                            lng: device.Longitude,
                            label: user.Name
                        })
                    }
                }
                */
                

                //return (<Paper style={{height:'100%'}}><Container style={{padding: 10}}>Google Map <p><input type="text"/></p></Container></Paper>);
                //let key = 'AIzaSyAHM68Q8KHoame5hn-sTUsoPkoVLo_QyVc'
                //console.log('config')
                //console.log(config)


                let url = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places'

                //if(key) 
                url += '&key='+config.GOOGLE_MAP_API_KEY
                console.log('INIT GOOGLE MAP')
                console.log(url)
                console.log(markers)

                return (<MapPanel 
                            t={this.props.t}
                            googleMapURL={url}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `100%` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            markers = {markers}
                             />)
            }

            case 'eventlog':
                return (<EventPanel eventLogger={this.props.eventLogger}/>);

            case 'messages':{
                const network = getObjectByID(panel.networkID, this.props.networks)
                
                let messages = []
                if(network && network.messages){
                    let subscribes = network.messages.filter(item =>
                        item.userID===panel.userID && item.deviceID===panel.deviceID && item.groupID===panel.groupID
                    )
                    if(subscribes.length){
                        messages = subscribes[0].items
                    }
                }
                return (<Messages messages={messages} 
                            visible={visible}
                            id={panel.id} 
                            userID={panel.userID}
                            deviceID={panel.deviceID}
                            groupID={panel.groupID}
                            networkID={panel.networkID} 
                            type={panel.type}
                            onAction={this.handleAction}/>);
            }

            case 'other':
                return (<Paper style={{height:'100%'}}><Container style={{padding: 10}}>Other <p><input type="text"/></p></Container></Paper>);

            default:
                return ''
        }
    }


    render(){

        const {t} = this.props

        if(this.state.navigate){
            return (
<Navigate to={this.state.navigate}></Navigate>
            )
        }


        let statusMessage = ''
        if(this.props.statusMessage){
            statusMessage = (
            <Typography align="center" color={this.props.statusError ? "error" : "default"}>
                {this.props.statusMessage}
            </Typography>
            );
        }

        //current network
        let network = this.getNetwork()
        let users = network && network.users ? network.users: [];
        let groups = network && network.groups ? network.groups: [];
        //let profiles = network && network.profiles ? network.profiles: [];
        let statuses = network && network.statuses ? network.statuses: [];
        let devices = network && network.devices ? network.devices: [];
        //console.log('render ServerConsole ' + this.state.currentNetworkID)
        //console.log(network)
        /*
        let backgroundTitleStyle = {
            background:theme.palette.background.dialogTitle,
            color: theme.palette.color.dialogTitle
        }
        let typographyTitleStyle = {
            color: theme.palette.color.dialogTitle, 
            fontWeight: 'bold',
            //padding:'0.5em 0'
        }
        */

        let list = ''
        //console.log('select tab '+this.state.selectedTab)
        switch(this.state.selectedTab){
            case 'users':

                let mergedUsers = []
                users.forEach(user=>{
                    const dev = devices.filter(device=>device.UserID===user.ID) 
                    if(dev.length===0){
                        mergedUsers.push({...user, DeviceID: null})
                    }else{
                        dev.forEach(device=>{
                            mergedUsers.push({
                                ...user, 
                                DeviceID: device.ID, 
                                StatusID: device.StatusID,
                                Latitude: device.Latitude,
                                Longitude: device.Longitude,
                                Altitude: device.Altitude,
                                Speed: device.Speed,
                                Time: device.Time,
                                GpsExpire: device.GpsExpire,
                                LocationRequest: Boolean(device.locationRequest)
                            })
                        })
                    }
                })


                //filter own
                //todo remove comment 
                //alert(getDeviceID())
                //console.log()
                mergedUsers = mergedUsers.filter(item => item.DeviceID!==getDeviceID())

                list = (
                    <UsersList 
                            settings={this.props.settings}
                            networkID={this.state.currentNetworkID}
                            users={mergedUsers} 
                            statuses={statuses}
                            selectedUserID={this.state.currentUserID}
                            selectedDeviceID={this.state.currentDeviceID}
                            onUserSelect={this.handleUserSelect}
                            onContextMenu={this.handleUserMenu} 
                            onAction={this.props.onAction}/>
                );
                break;
            case 'groups':
                list = (
                    <GroupsList groups={groups} 
                        selectedGroupID={this.state.currentGroupID}
                        onGroupSelect={this.handleGroupSelect}
                        onContextMenu={this.handleGroupMenu}/>                   
                );
                break;
            /*    
            case 'statuses':
                list = (
                    <StatusesList items={statuses} 
                        selectedItemID={this.state.currentStatusID}
                        onItemSelect={this.handleStatusSelect.bind(this)}/>  
                )
                break;
            case 'devices':
                list = (
                    <DevicesList items={devices} 
                        selectedItemID={this.state.currentDeviceID}
                        onItemSelect={this.handleDeviceSelect.bind(this)}/>  
                )
                break;
            case 'profiles':
                list = (
                    <ProfilesList items={profiles} 
                        selectedItemID={this.state.currentProfileID}
                        onItemSelect={this.handleProfileSelect.bind(this)}/>  
                )
                break
            */    
            default:
                alert('Tab action not defined!')
        }


        let deviceSettingsEditor = null
        if (this.props.deviceSettingsEditor.deviceID) {
            //const networkID = this.state.deviceSettingsEditor.networkID
            //const networkIndex = getIndexByID(networkID, this.state.networks)
            //const network = this.state.networks[networkIndex]
            const { deviceSettings, networkID, deviceID, userID } = this.props.deviceSettingsEditor
            const network = getObjectByID(networkID, this.props.networks)
            const groups = network ? network.groups : null
            deviceSettingsEditor = (
                <EditDeviceSettingsDialog
                    open={true}
                    deviceSettings={deviceSettings}
                    networks={this.props.networks}
                    networkID={networkID}
                    deviceID={deviceID}
                    userID={userID}
                    groups={groups}
                    onAction={this.props.onAction}
                />
            )
        }

        let userPropertiesDialog = null
        if (this.props.userPropertiesDialog.userID) {
            const { properties, networkID, deviceID, userID, avatar } = this.props.userPropertiesDialog
            const network = getObjectByID(networkID, this.props.networks)
            const user = getObjectByID(userID, network.users)
            const device = getObjectByID(deviceID, network.devices)
            console.log('device')
            console.log(device)
            userPropertiesDialog = (
                <UserPropertiesDialog
                    open={true}
                    properties={properties}
                    networkID={networkID}
                    device={device}
                    user={user}
                    avatar={avatar}
                    profiles={network.profiles}
                    onAction={this.props.onAction}
                />
            )
        }

        let groupPropertiesDialog = null
        if (this.props.groupPropertiesDialog.groupID) {
            const { properties, networkID, groupID } = this.props.groupPropertiesDialog
            const network = getObjectByID(networkID, this.props.networks)
            const group = getObjectByID(groupID, network.groups)
            console.log('group')
            console.log(group)
            groupPropertiesDialog = (
                <GroupPropertiesDialog
                    open={true}
                    properties={properties}
                    networkID={networkID}
                    group={group}
                    onAction={this.props.onAction}
                />
            )
        }

        if(this.props.networkEditor && this.props.networkEditor.networkID){
            console.log('this.props.networkEditor')
            console.log(this.props.networkEditor)
            return (
                <NetworkEditor
                    networks={this.props.networks}
                    networkID={this.props.networkEditor.networkID}
                    networkSettings={this.props.networkEditor.networkSettings}
                    user={this.props.user}

                    onAction={this.props.onAction}
                    statusError={this.props.apiError}
                    statusMessage={this.props.apiMessage}></NetworkEditor>
            );
        }


        let settingsDialog = null
        if (this.props.settingsDialog) {
            settingsDialog = (
                <SettingsDialog
                    open={true}
                    settings={this.props.settingsDialog.settings}
                    onAction={this.props.onAction}
                />
            );
        }



        let backgroundTitleStyle = {
            background: theme.palette.background.dialogTitle,
            color: theme.palette.color.dialogTitle
        }
           
        return (
            <>
            <Grid item xs={12} style={{height:'100vh'}}>
                
                <ApplicationBar user={this.props.user}
                    onAction={this.handleAction}>
                    
                    <Button style={{...backgroundTitleStyle, margin: '0 0.5em', height: '1.8em', padding: '0 16px'}} 
                             variant="outlined" onClick={this.handleViewMenu}
                            >{t('View')} <ExpandMoreIcon style={{marginLeft:5}}/></Button>
                    <Menu anchorEl={this.state.viewAnchor} keepMounted
                            open={Boolean(this.state.viewAnchor)} 
                            onClose={this.handleViewClose}
                            >
                        <MenuItem onClick={()=>{this.appendTab({name:'map', label:t('Map')}); this.handleViewClose();}}>{t('Map')}</MenuItem>
                        <MenuItem onClick={()=>{this.appendTab({name:'eventlog', label: t('EventLog')}); this.handleViewClose();}}>{t('EventLog')}</MenuItem>
                    </Menu>

                    <Button style={{...backgroundTitleStyle, margin: '0 0.5em', height: '1.8em', padding: '0 16px'}} 
                             variant="outlined" onClick={this.handleToolsMenu}
                            >{t('Tools')} <ExpandMoreIcon style={{marginLeft:5}}/></Button>
                    <Menu anchorEl={this.state.toolsAnchor} keepMounted
                            open={Boolean(this.state.toolsAnchor)} 
                            onClose={this.handleToolsClose}
                            >
                        <MenuItem onClick={this.handleSettingsMenu}>{t('Settings')}</MenuItem>
                    </Menu>
                    

                </ApplicationBar>
                
                <Grid container justify="flex-start" spacing={0} 
                    style={{height:'83%', 
                        paddingTop: 5,
                        borderTop:'1px solid '+theme.palette.border.dialog}} >
                    <Grid xs={3} key="1" item style={{padding:5, border2:'1px solid red'}}>

                        <Grid container direction2="column" alignItems="flex-start"
                            spacing={1} style={{border2:'1px solid darkblue', overflow:'auto', maxHeight: '83vh'}}>

                            <Container style={{padding:0}}>
                                <NetworksList networks={this.props.networks} 
                                    selectedNetworkID={this.state.currentNetworkID}
                                    onNetworkSelect={this.handleNetworkSelect}
                                    onNetworkMenu={this.handleNetworkMenu} 
                                    onAction={this.props.onAction}
                                />
                            </Container>

                            {this.state.currentNetworkID?
                            <Container
                                style={{padding:0, paddingTop:20}}>
                                <TabbedMenu 
                                    selected={this.state.selectedTab} 
                                    onClick={this.handleTabClick}
                                    usersEnabled={true}
                                    groupsEnabled={true}
                                    />
                                {list}
                            </Container>
                            :''}

                        </Grid>

                    </Grid>
                    <Grid xs={9} key="2" item style={{padding:5}}>

                        <Grid container direction="row" spacing={1} 
                            style={{height:this.state.expandMessageArea?'50vh':'20vh', border2:'1px solid blue'}}>

                            <Grid xs={4} key="1" item style={{height:"100%"}}>
                                <Container fixed  
                                    style={{padding:5, height:'100%', 
                                        border:'1px solid lightgray',
                                        overflowY:'auto'}}> 
                                    <OngoingCalls networks={this.props.networks} calls={this.props.calls}/>
                                </Container>
                            </Grid>
                            <Grid xs={4} key="2" item style={{height:"100%"}}>
                                <Container fixed 
                                    style={{padding:5, height:'100%', 
                                        border:'1px solid lightgray',
                                        overflowY:'auto'}}>
                                    <UnreadMessages messages={this.props.unreadMessages}
                                        networks={this.props.networks}
                                        onAction={this.handleAction}
                                        onContextMenu={this.handleUnreadMessagesMenu} />
                                </Container>
                            </Grid>
                            <Grid xs={4} key="3" item style={{height:"100%"}}>
                                <Container fixed 
                                    style={{padding:5, height:'100%', 
                                        border:'1px solid lightgray',
                                        overflowY:'auto'}}>
                                    <EmergencyAlerts 
                                        alerts={this.props.emergency}
                                        networks={this.props.networks}
                                        onAction={this.handleAction}
                                        />
                                </Container>
                            </Grid>

                        </Grid>

                        <Grid container direction="row" spacing={1} 
                            style={{padding:5, border2:'1px solid blue'}}>
                            <Container align="center">
                                <Button size="small" color="inherit" aria-label="expand" component="span"
                                            style={{width: '100%'}}
                                            onClick={this.handleExpandClick}>                                       
                                    {this.state.expandMessageArea?<ExpandLessIcon/>:<ExpandMoreIcon/>}
                                </Button>
                            </Container>
                        </Grid>

                        <Grid container direction="row" spacing={1} 
                            style={{height:this.state.expandMessageArea?'30vh':'60vh', padding:5, border2:'1px solid blue'}}>
    
                            <Container fixed style={{padding:5, height:'100%', border:'1px solid lightgray'}}>
                                {this.renderTabs()}
                            </Container>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid xs={12} key="2" item style={{border2: '1px solid green', height:'3em'}}>
                    {/*server startus*/} {statusMessage}
                </Grid>
            </Grid>

            {deviceSettingsEditor}
            {userPropertiesDialog}
            {groupPropertiesDialog}
            {settingsDialog}

            </>
        );
    }
}

export const ServerConsole = withTranslation()(ServerConsole_);