import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import { Dialog } from '../../components/Dialog';
import {Box, Button, TextField } from '@material-ui/core';
import Logo from '../../components/Logo';

//export 
class Login_ extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            login: this.props.login,
            password: this.props.password,
            serveraddress: this.props.serveraddress,
            loginMessage: '',
            loginError: false,
            passwordMessage: '',
            passwordError: false,
            serveraddressMessage: '',
            serveraddressError: false,
            lang: this.props.lang, // this.props.i18n.language
        }
        
    }

    formSubmit(e){

        if(this.validateForm()){
            this.props.onLogin(this.state.login, this.state.password, this.state.serveraddress);
        }

        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    handleInput(e){
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value})
    }

    validateForm(){
        const { t } = this.props;
        
        if(!this.state.login){
            this.setState({loginMessage: t('LoginIsEmpty'), loginError: true})
        }else{
            this.setState({loginMessage: '', loginError: false})
        }
        /*
        if(!this.state.password){
            this.setState({passwordMessage: 'Password is empty', passwordError: true})
        }else{
            this.setState({passwordMessage: '', passwordError: false})
        }
        */
        if(!this.state.serveraddress){
            this.setState({serveraddressMessage: '', serveraddressError: true})
        }else{
            this.setState({serveraddressMessage: '', serveraddressError: false})
        }
        return true;
    }

    handleLangChange(e){
        const lang = e.target.value
        this.props.i18n.changeLanguage(lang);
        this.setState({lang: lang})
    }

    render() {
        const { t } = this.props;
        
        //const margin = {margin:'16px 0 8px 0'}

        return (
            <>

            <Dialog variant="h6" title={(<Logo></Logo>)}>
                    <form onSubmit={this.formSubmit.bind(this)}>
                        <Box textAlign="center">
                            <TextField name="login"  margin="normal"
                                label={this.state.loginMessage?this.state.loginMessage:t("Login")}
                                error={this.state.loginError}
                                value={this.state.login}
                                onChange={this.handleInput.bind(this)}
                            />
                        </Box>
                        <Box textAlign="center">
                            <TextField type="password" name="password" margin="normal"
                                autoComplete="current-password"
                                label={this.state.passwordMessage?this.state.passwordMessage:t("Password")}
                                error={this.state.passwordError}
                                value={this.state.password}
                                onChange={this.handleInput.bind(this)}
                            />
                        </Box>
                        <Box textAlign="center">
                            <TextField name="serveraddress" margin="normal"
                                label={this.state.serveraddressMessage?this.state.serveraddressMessage:t("Server-port")}
                                error={this.state.serveraddressError}
                                value={this.state.serveraddress}
                                onChange={this.handleInput.bind(this)}
                            />
                        </Box>

                        {/*
                        <Box textAlign="center">
                        <FormControl style={{...margin, minWidth: 170}}>
                            <InputLabel htmlFor="Lang">{t("Language")}</InputLabel>
                            <Select
                                style={{textAlign: 'left'}}
                                value={this.state.lang}
                                onChange={this.handleLangChange.bind(this)}
                                inputProps={{name: 'lang', id: 'lang'}}
                                >
                                {Object.keys(i18n.options.langs).map(
                                    code => <MenuItem key={code} value={code}>{i18n.options.langs[code].name}</MenuItem>
                                )}
                            </Select>

                        </FormControl>
                        </Box>
                        */}

                        <Box textAlign="center" style={{marginTop:'1em'}}>
                            <Button type="submit" variant="contained" color="primary">
                                {t("SignIn")}
                            </Button>
                        </Box>
                    </form>

            </Dialog>
            </>
        );
    }

}

export const Login = withTranslation()(Login_);