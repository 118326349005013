import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {Container, Box} from '@material-ui/core'
import {Button, TextField } from '@material-ui/core';

class EditChannelDialog_ extends React.Component{

    constructor(props){
        super(props)

        const {channel} = this.props; 
        console.log('EditChannelDialog constructor')
        console.log(channel)
        this.state = {
            channel: {
                ChannelNum: channel.ChannelNum,
                ChannelName: channel.ChannelName || '',
            },
            errors:{
                ChannelNum: false,
                ChannelName: false, 
            },
            changed: false,
        }
    }

    handleSave(){
        if(this.validate()){
            let channel = {...this.props.channel, ...this.state.channel}
            channel.ChannelNum = Number(channel.ChannelNum)
            this.props.onAction({type: 'CHANNEL_EDITOR_SAVE', channel: channel, index: this.props.index})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'CHANNEL_EDITOR_CLOSE'})
    }

    handleInput(event){
        let changed = !this.state.changed ? {changed: true} : {}
        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const channel = {...this.state.channel, [event.target.name]: val}
        this.setState({channel: channel, ...changed})
    }

    validate(){
        const {t} = this.props

        let errors = this.state.errors;
        let error = false

        if(!this.state.channel.ChannelNum){
            errors.ChannelNum = t('ChannelNumIsEmpty')
            error = true
        }else if(!Number(this.state.channel.ChannelNum)){
            errors.ChannelNum = t('IsNotNumber')
            error = true
        }else{
            errors.ChannelNum = false
        }

        if(!this.state.channel.ChannelName){
            errors.ChannelName = t('NameIsEmpty')
            error = true
        }else{
            errors.ChannelName = false
        }
        this.setState({errors: errors})

        return !error;
    }

    render(){
        const {t} = this.props

        const title = this.props.action==='add'?t('NewChannel'):t('EditChannel')

        //const margin = {margin:'16px 0 8px 0'}

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>
            
            <Box textAlign="center">
                <TextField name="ChannelNum"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.ChannelNum?this.state.errors.ChannelNum:t('ChannelNum')}
                    autoComplete="new-password"
                    error={this.state.errors.ChannelNum!==false}
                    value={this.state.channel.ChannelNum}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
            <Box textAlign="center">
                <TextField name="ChannelName" margin="normal"
                    style={{width: '100%'}}
                    autoComplete="new-password"
                    label={t('ChannelName')}
                    error={this.state.errors.ChannelName!==false}
                    value={this.state.channel.ChannelName}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
        
        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const EditChannelDialog = withTranslation()(EditChannelDialog_);