import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {Container, Box} from '@material-ui/core'
import {Button, FormControl, Checkbox, TextField, InputLabel, MenuItem, Select } from '@material-ui/core';

export default class EditGroupDialog_ extends React.Component{

    constructor(props){
        super(props)

        const {group} = this.props; 
        this.state = {
            group: {
                Name: group.ID ? group.Name : '',
                Priority: group.ID ? group.Priority : 0,
                LocationPeriod: group.ID ? group.LocationPeriod: '',
                Emergency: group.ID ? group.Emergency: false,
                AllCall: group.ID ? group.AllCall: false,
                Broadcast: group.ID ? group.Broadcast: false 
            },
            errors:{
                Name: false,
                LocationPeriod: false
            },
            changed: false
        }
    }

    handleSave(){
        if(this.validate()){
            let group = {...this.props.group, ...this.state.group}
            this.props.onAction({type: 'GROUP_EDITOR_SAVE', group: group})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'GROUP_EDITOR_CLOSE'})
    }

    handleInput(event){
        if(!this.state.changed) this.setState({changed: true})

        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const group = {...this.state.group, [event.target.name]: val}
        this.setState({group: group})
    }

    validate(){
        let errors = this.state.errors;
        let error = false
     
        if(!this.state.group.Name){
            errors.Name = this.props.t('NameIsEmpty')
            error = true
        }else{
            errors.Name = false
        }
        this.setState({errors: errors})


        if(this.state.group.LocationPeriod && !Number(this.state.group.LocationPeriod)){
            errors.LocationPeriod = this.props.t('IsNotNumber')
            error = true
        }else if(this.state.group.LocationPeriod && !Number.isInteger(Number(this.state.group.LocationPeriod))){
            errors.LocationPeriod = this.props.t('IsNotInteger')
            error = true
        }else{
            errors.LocationPeriod = false
        }

        return !error;
    }


    render(){
        const {t} = this.props

        const title = this.props.action==='add'?t('NewGroup'):t('EditGroup')

        const margin = {margin:'16px 0 8px 0'}

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>
            <Box textAlign="">
                <TextField name="Name"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.Name?this.state.errors.Name:t('Name')}
                    autoComplete="off"
                    error={this.state.errors.Name!==false}
                    value={this.state.group.Name}
                    onChange={this.handleInput.bind(this)}
                />

            </Box>
            
            <Box textAlign="">
            <FormControl style={{...margin, width: '100%'}}>
                <InputLabel htmlFor="Priority">{t('Priority')}</InputLabel>
                <Select
                    value={this.state.group.Priority}
                    onChange={this.handleInput.bind(this)}
                    inputProps={{
                        name: 'Priority',
                        id: 'Priority',
                    }}
                    >
                    <MenuItem key="5" value={5}>+5</MenuItem>
                    <MenuItem key="4" value={4}>+4</MenuItem>
                    <MenuItem key="3" value={3}>+3</MenuItem>
                    <MenuItem key="2" value={2}>+2</MenuItem>
                    <MenuItem key="1" value={1}>+1</MenuItem>
                    <MenuItem key="0" value={0}>{t('Default')}</MenuItem>
                    <MenuItem key="-1" value={-1}>-1</MenuItem>
                </Select>
            </FormControl>
            </Box>

            <Box textAlign="center">
                <TextField name="LocationPeriod"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.LocationPeriod?this.state.errors.LocationPeriod:t('LocationPeriod')}
                    autoComplete="new-password"
                    error={this.state.errors.LocationPeriod!==false}
                    value={this.state.group.LocationPeriod}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.group.Emergency}
                            onChange={this.handleInput.bind(this)}
                            name="Emergency"
                            />
                            {t('Emergency')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.group.AllCall}
                            onChange={this.handleInput.bind(this)}
                            name="AllCall"
                            />
                            {t('AllCall')}
                    </label>
                </FormControl>
            </Box>

            <Box textAlign="" style={{...margin, width: '100%'}}>
                <FormControl>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.group.Broadcast}
                            onChange={this.handleInput.bind(this)}
                            name="Broadcast"
                            />
                            {t('Broadcast')}
                    </label>
                </FormControl>
            </Box>
        
        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const EditGroupDialog = withTranslation()(EditGroupDialog_);