import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {Container, Box} from '@material-ui/core'
import {Button, TextField } from '@material-ui/core';

class EditAccountDialog_ extends React.Component{

    constructor(props){
        super(props)

        const {account} = this.props; 
        this.state = {
            account: {
                Login: account.ID ? account.Login : '',
                Password: account.ID ? account.Password : '',
                ServerAddress: account.ID ? account.ServerAddress : '',
            },
            errors:{
                Login: false, 
                Password: false,
                ServerAddress: false,
            },
            changed: false,
        }
    }

    handleSave(){
        if(this.validate()){
            let account = {...this.props.account, ...this.state.account}
            this.props.onAction({type: 'ACCOUNT_EDITOR_SAVE', account: account})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'ACCOUNT_EDITOR_CLOSE'})
    }

    handleInput(event){
        let changed = !this.state.changed ? {changed: true} : {}
        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const account = {...this.state.account, [event.target.name]: val}
        this.setState({account: account, ...changed})
    }

    validate(){
        const {t} = this.props

        let errors = this.state.errors;
        let error = false
        if(!this.state.account.Login){
            errors.Login = t('LoginIsEmpty')
            error = true
        }else{
            errors.Login = false
        }
        if(!this.state.account.ServerAddress){
            errors.ServerAddress = t('ServerAddressIsEmpty')
            error = true
        }else{
            errors.ServerAddress = false
        }
        this.setState({errors: errors})

        return !error;
    }

    render(){
        const {t} = this.props

        const title = this.props.action==='add'?t('NewAccount'):t('EditAccount')

        //const margin = {margin:'16px 0 8px 0'}

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>
            <Box textAlign="center">
                <TextField name="Login"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.Login?this.state.errors.Login:t('Login')}
                    autoComplete="new-password"
                    error={this.state.errors.Login!==false}
                    value={this.state.account.Login}
                    onChange={this.handleInput.bind(this)}
                />

            </Box>
            <Box textAlign="center">
                <TextField type="password" name="Password" margin="normal"
                    style={{width: '100%'}}
                    autoComplete="new-password"
                    label={t('Password')}
                    value={this.state.account.Password}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
            <Box textAlign="center">
                <TextField name="ServerAddress"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.ServerAddress?this.state.errors.ServerAddress:t('ServerAddress')}
                    autoComplete="new-password"
                    error={this.state.errors.ServerAddress!==false}
                    value={this.state.account.ServerAddress}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
        
        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const EditAccountDialog = withTranslation()(EditAccountDialog_);