export function parseFile(file, callback) {
    const fileSize   = file.size;
    const chunkSize  = 8 * 1024; // bytes
    let offset     = 0;

    const readEventHandler = function(evt) {
        if (evt.target.error == null) {
            offset += evt.target.result.length;
            callback(evt.target.result); // callback for handling read chunk
        } else {
            console.log("Read error: " + evt.target.error);
            return;
        }
        if (offset >= fileSize) {
            console.log("Done reading file");
            callback('');
            return;
        }

        // of to the next chunk
        chunkReaderBlock(offset, chunkSize, file);
    }

    const chunkReaderBlock = function(_offset, length, _file) {
        var r = new FileReader();
        let blob = _file.slice(_offset, length + _offset);

        r.onload = readEventHandler;
        r.readAsBinaryString(blob);
    }

    // now let's start the read with the first block
    chunkReaderBlock(offset, chunkSize, file);
}


export function getFileChunk(file, chunkNum, chunkSize, callback){
    let offset = chunkNum * chunkSize

    const readEventHandler = function(evt) {
        if (evt.target.error == null) {
            offset += evt.target.result.length;
            callback(evt.target.result); // callback for handling read chunk
        } else {
            console.log("getFileChunk: Read error: " + evt.target.error);
            return;
        }
    }

    const chunkReaderBlock = function(_offset, length, _file) {
        //console.log('getFileChunk.chunkReaderBlock ' + _offset + ', ' + length)
        var r = new FileReader()
        let blob = _file.slice(_offset, length + _offset)

        r.onload = readEventHandler
        r.readAsBinaryString(blob)
    }

    chunkReaderBlock(offset, chunkSize, file)

}



export function thumbnailify(file, targetSize, callback) {//base64Image
    console.log('thumbnailify')
    console.log(file)

    var img = new Image();
  
    img.onerror = e => {
        alert('Unsupported image format')
    }
    img.onload = e => {
        console.log('img.onload')
        var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");
    
        canvas.width = canvas.height = targetSize;
    
        ctx.drawImage(
            img,
            width > height ? (width - height) / 2 : 0,
            height > width ? (height - width) / 2 : 0,
            width > height ? height : width,
            width > height ? height : width,
            0, 0,
            targetSize, targetSize
        );
    
        const type = 'image/jpeg' // || file.type
        canvas.toBlob(blob => {
            console.log('blob')
            console.log(blob)
            callback(blob);
        }, type)
        
    };
  

    var reader = new FileReader();
    reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(img);
    reader.readAsDataURL(file);

  };
  
  /*
  var sourceImage = document.getElementById("source-image"),
          thumbnail = document.getElementById("thumbnail");
  
  thumbnailify(sourceImage.src, 100, function(base64Thumbnail) {
      thumbnail.src = base64Thumbnail;
  });
  */



const mime = require('./mime');
/*
console.log(mime)
alert(mime.getType('txt'))                    // ⇨ 'text/plain'
alert(mime.getExtension('text/plain'));        // ⇨ 'txt'
alert(mime.getType(''));
alert(getFileMimeType('image.jpg'))
alert(getFileMimeType('file'))
*/

export function getFileExt(fileName){
    return fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
}

export function getFileMimeType(fileName, defaultType = 'application/octet-stream'){
    const ext = getFileExt(fileName)
    const mimeType = mime.getType(ext)
    return mimeType || defaultType
}

