import React , { useState } from 'react';
//import { withTranslation } from 'react-i18next/hooks';

import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"

import {Paper} from '@material-ui/core';
import config from '../../../config';
import { getObjectByID } from '../../../api/helpers';
import { formatSpeed } from '../../api/Settings';
import { formatDateTime } from '../../../api/date';

export const MapPanel = withScriptjs(withGoogleMap((props) => {
    let defaultCenter
    let defaultZoom
    let center 
    let zoom
    let ref

    const [currentMarkerID, setMarker] = useState(null);
    const [infoVisibility, setInfoVisibility] = useState(false);
    const [tick, setTick] = useState(0);

    let centerMoved = function(v){
        let c = this.getCenter()
        let center = {lat: c.lat(), lng: c.lng(), zoom: this.getZoom() }
        localStorage.setItem('MAP_DEFAULT_CENTER', JSON.stringify(center))
    }

    if(props.markers && props.markers.length){
        const marker = props.markers[0]
        center = {lat: marker.lat, lng: marker.lng}
        zoom = 16

        let currentMarker = null
        if(currentMarkerID){
            currentMarker = getObjectByID(currentMarkerID, props.markers, 'id')
        }

        return (
            <Paper>
                <GoogleMap
                    defaultZoom={8}
                    defaultCenter={defaultCenter}
                    center={center}
                    zoom={zoom}
                >
                    {props.markers.map(marker=>
                        <Marker key={marker.id} 
                            onClick={()=>{
                                setMarker(marker.id)
                                setInfoVisibility(true)
                                setTick(tick + 1) // force update
                            }}
                            title={marker.title} 
                            label={marker.label} 
                            position={{lat: marker.lat, lng: marker.lng}}>
                        </Marker>
                    )}

                    {infoVisibility && currentMarker?
                        <MarkerInfo 
                            t={props.t}
                            tick={tick}
                            marker={currentMarker} 
                            visible={infoVisibility}
                            onCloseClick={setInfoVisibility} />
                    :''}
   
                </GoogleMap>
            </Paper>
        )
    }else{
        defaultZoom = 8
        defaultCenter = config.MAP_DEFAULT_CENTER ///{lat: -34.397, lng: 150.644}
        try{
            let defaultCenter2 = JSON.parse( localStorage.getItem('MAP_DEFAULT_CENTER'))
            if(defaultCenter2){
                defaultCenter = defaultCenter2
                if(defaultCenter2.zoom) defaultZoom  = defaultCenter2.zoom
            }
            //console.log('GET MAP_DEFAULT_CENTER');
            //console.log(defaultCenter2)
        }catch(e){}


        return (
            <Paper>
                <GoogleMap
                    defaultZoom={defaultZoom}
                    defaultCenter={defaultCenter}
                    onDragEnd={centerMoved}
                ></GoogleMap>
            </Paper>
        )
    }

}))


const MarkerInfo = (props)=>{

    const {marker, visible, onCloseClick} = props

    const styles = {
        h3: { },
        p: {
            margin: '2px',
            whiteSpace: 'nowrap' 
        }
    }

    const t = props.t
    
    //  alert('MarkerInfo')
    //console.log('MarkerInfo props')
    //console.log(props)
    return (
        <InfoWindow
            tick={props.tick}
            position={{lat: marker.lat, lng: marker.lng}}
            onCloseClick={()=>{onCloseClick(false)}}
            visible={visible}>
                <div style={{}}>
                    <h3 style={styles.h3}>{marker.label}</h3>
                    <p style={styles.p}>{t('Latitude')}: {marker.lat.toFixed(5)}</p>
                    <p style={styles.p}>{t('Longitude')}: {marker.lng.toFixed(5)}</p>
                    
                    {marker.speed?
                    <p style={styles.p}>{t('Speed')}: {formatSpeed(marker.speed)}</p>
                    :''}
                    {marker.alt?
                    <p style={styles.p}>{t('Altitude')}: {marker.alt.toFixed(1)+t('m')}</p>
                    :''}
                    {marker.accuracy?
                    <p style={styles.p}>{t('Accuracy')}: {marker.accuracy.toFixed(1)+t('m')}</p>
                    :''}
                    <p style={styles.p}>{t('Time')}: {formatDateTime(marker.time, 'time')}</p>
                </div>  
        </InfoWindow>
    )

}

//export const MapPanel = withGoogleMap()(MapPanel_);

//withTranslation()