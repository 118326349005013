import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem} from '@material-ui/core'
import {Container, Box} from '@material-ui/core'
import {Button, TextField, InputLabel, FormControl} from '@material-ui/core';

import {decimalColorToHexColor, base64toGuid} from '../../../api/helpers'; 

import {KEY_ACTIONS, PERMISSIONS} from '../../api/ServerApi'

import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';

/*
export const KEY_ACTIONS = {
    START_STOP_VOICE_CALL_REPLAY: 0,
    START_STOP_EMERGENCY: 1,       
    SET_STATUS: 2,
    CALL_ALERT: 3,
    SEND_MESAGE: 4,
    ANNOUNCE_BATTARY_CHARGE: 5,   
    NEXT_CHANNEL: 6,
    PREVIOUS_CHANNEL: 7,
}
*/

class EditKeyMappingDialog_ extends React.Component{

    constructor(props){
        super(props)
        console.log('EditKeyMappingDialog_')
        console.log(this.props)
        const {keyMapping} = this.props;
        console.log('EditKeyMappingDialog constructor')
        console.log(keyMapping)
        this.state = {
            keyMapping: {
                KeyCode: keyMapping.KeyCode,
                KeyIntent: keyMapping.KeyIntent,
                KeyCommand: keyMapping.KeyCommand,
                KeyStatusId: keyMapping.KeyStatusId,
                KeyReceiverId: keyMapping.KeyReceiverId,
                KeyReceiverType: keyMapping.KeyReceiverType,
                KeyMessage: keyMapping.KeyMessage,
                KeyName: keyMapping.KeyName || ''
            },
            errors:{
                KeyCode: false,
                KeyName: false, 
                KeyIntent: false, 
                KeyCommand: false,
                KeyMessage: false,
                KeyStatusId: false
            },
            changed: false,
        }
    }

    handleSave(){
        if(this.validate()){
            debugger
            let keyMapping = {...this.state.keyMapping}
            keyMapping.KeyCode = Number(keyMapping.KeyCode)

            if(keyMapping.KeyCommand===KEY_ACTIONS.CALL_ALERT){
                delete keyMapping.KeyStatusId
                delete keyMapping.KeyMessage
            }else if(keyMapping.KeyCommand===KEY_ACTIONS.SET_STATUS){
                delete keyMapping.KeyReceiverId
                delete keyMapping.KeyReceiverType
                delete keyMapping.KeyMessage
            }else if(keyMapping.KeyCommand===KEY_ACTIONS.SEND_MESSAGE){
                delete keyMapping.KeyStatusId
            }else{
                delete keyMapping.KeyStatusId
                delete keyMapping.KeyReceiverId
                delete keyMapping.KeyReceiverType
                delete keyMapping.KeyMessage
            }
            //debugger
            this.props.onAction({type: 'KEY_MAPPING_EDITOR_SAVE', keyMapping: keyMapping, index: this.props.index})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'KEY_MAPPING_EDITOR_CLOSE'})
    }

    handleInput(event){
        let changed = !this.state.changed ? {changed: true} : {}
        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const keyMapping = {...this.state.keyMapping, [event.target.name]: val}

        debugger
        if(event.target.name==="KeyReceiverId" && val){
            let receivers = this.getReceivers(true);
            let items = receivers.filter((v)=> v.ID===val )
            console.log(items)
            if(val && items.length) keyMapping.KeyReceiverType = items[0].type
        }

        this.setState({keyMapping: keyMapping, ...changed})
    }

    validate(){
        const {t} = this.props

        let errors = this.state.errors
        let keyMapping = this.state.keyMapping
        let error = false

        if(!keyMapping.KeyCode){
            errors.KeyCode = t('KeyMappingCodeIsEmpty')
            error = true
        }else if(!Number(keyMapping.KeyCode)){
            errors.KeyCode = t('IsNotNumber')
            error = true
        }else{
            errors.KeyCode = false
        }

        if(!keyMapping.KeyName){
            errors.KeyName = t('NameIsEmpty')
            error = true
        }else{
            errors.KeyName = false
        }
        this.setState({errors: errors})

        if(keyMapping.KeyCommand===KEY_ACTIONS.SET_STATUS){
            debugger
            if(!keyMapping.KeyStatusId){
                errors.KeyStatusId = t('ChooseStatus')
                error = true
            }else{
                errors.KeyStatusId = false
            }

        }

        return !error;
    }

    getStatuses(){

        let statuses = []

        for(let i=0; i<this.props.networks.length; i++ ){
            
            let network = this.props.networks[i];
            if(network.statuses){
                for(let j=0; j<network.statuses.length; j++){
                    if(j===0) statuses.push({networkName: network.Name})
                    let status = {...network.statuses[j]}
                    //debugger
                    //console.log(status)
                    status.ID = base64toGuid(status.ID)
                    statuses.push(status)
                }
            }

        }

        return statuses
    }

    getReceivers(withGroups){
        let receivers = []
        debugger
        for(let i=0; i<this.props.networks.length; i++ ){
            
            let network = this.props.networks[i];

            let p = this.checkPermission(PERMISSIONS.OP_MANAGEUSERSGROUPS, network.ID )
            if(!p) continue

            console.log(network)

            if((withGroups && network.groups) || network.users) {
                receivers.push({networkName: network.Name})
            }

            if(withGroups && network.groups){
                for(let j=0; j<network.groups.length; j++){
                    let group = {...network.groups[j]}
                    group.type = 4
                    //debugger
                    //console.log(status)
                    group.ID = base64toGuid(group.ID)
                    receivers.push(group)
                }
            }

            if(network.users){
                for(let j=0; j<network.users.length; j++){
                    //if(j==0) receivers.push({networkName: network.Name})
                    let user = {...network.users[j]}
                    user.type = 3
                    //debugger
                    //console.log(status)
                    user.ID = base64toGuid(user.ID)
                    receivers.push(user)
                }
            }

        }

        return receivers
    }

    checkPermission(permission, networkID){
        return this.props.onAction({
            type:'CHECK_PERMISSION', 
            networkID: networkID, 
            permission: permission 
        })
    }

    render(){
        const {t} = this.props

        const title = this.props.action==='add'?t('NewKeyMapping'):t('EditKeyMApping')

        const margin = {margin:'16px 0 8px 0'}
        //const margin = {margin:'16px 0 8px 0'}

        const head = {
            color: theme.palette.color.dialogTitle,
            backgroundColor: theme.palette.background.dialogTitle,
            fontWeight:'bold', 
            margin:5,
            padding:5
        }

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <form>
        <Container maxWidth="lg" style={{minWidth:300}}>
            
            <Box textAlign="center">
                <TextField name="KeyName"  margin="normal"
                    style={{width: '100%'}}
                    label={this.state.errors.KeyName?this.state.errors.KeyName:t('KeyName')}
                    autoComplete="new-password"
                    error={this.state.errors.KeyName!==false}
                    value={this.state.keyMapping.KeyName}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
            <Box textAlign="center">
                <TextField name="KeyCode" margin="normal"
                    style={{width: '100%'}}
                    autoComplete="new-password"
                    label={t('KeyCode')}
                    error={this.state.errors.KeyCode!==false}
                    value={this.state.keyMapping.KeyCode}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
            <Box textAlign="center">
                <TextField name="KeyIntent" margin="normal"
                    style={{width: '100%'}}
                    autoComplete="new-password"
                    label={t('KeyIntent')}
                    error={this.state.errors.KeyIntent!==false}
                    value={this.state.keyMapping.KeyIntent}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>
            <Box>
                <FormControl style={{...margin, width: '100%'}}>
                <InputLabel htmlFor="Priority">{t('KeyAction')}: {this.state.keyMapping.KeyCommand}</InputLabel>
                <Select
                    style={{width: '100%'}}
                    value={this.state.keyMapping.KeyCommand}
                    onChange={this.handleInput.bind(this)}
                    label={t('KeyAction')}
                    inputProps={{name: 'KeyCommand', id: 'KeyCommand'}}
                    >
                    {/*console.log(Object.entries(KEY_ACTIONS))*/}
                    {Object.entries(KEY_ACTIONS).map( (k, v) => 
                        <MenuItem key={k[1]} value={k[1]}>{t(k[0])}</MenuItem>
                    )
                    }
                </Select>
                </FormControl>
            </Box>

            {(this.state.keyMapping.KeyCommand===KEY_ACTIONS.SET_STATUS)?(

            <Box>
                <FormControl style={{...margin, width: '100%'}}>
                <InputLabel error={this.state.errors.KeyStatusId!==false} htmlFor="KeyStatusId">{this.state.errors.KeyStatusId?this.state.errors.KeyStatusId:t('Status')}</InputLabel>
                <Select
                    style={{width: '100%'}}
                    value={this.state.keyMapping.KeyStatusId}
                    onChange={this.handleInput.bind(this)}
                    label_={this.state.errors.KeyStatusId?this.state.errors.KeyStatusId:t('Status')}
                    error={this.state.errors.KeyStatusId!==false}
                    inputProps={{name: 'KeyStatusId', id: 'KeyStatusId'}}
                    >
                    {console.log(this.getStatuses())}
                    {this.getStatuses().map( (v, k) => 
                        v.networkName ? (<Box style={head}>{v.networkName}</Box>):
                        (<MenuItem style={{backgroundColor:decimalColorToHexColor(v.Color)}} 
                        key={k} value={v.ID}>{v.Name}</MenuItem>)
                    )
                    }
                </Select>
                </FormControl>
            </Box>

            ):''}


            {(this.state.keyMapping.KeyCommand===KEY_ACTIONS.CALL_ALERT)?(

            <Box>
                <FormControl style={{...margin, width: '100%'}}>
                <InputLabel htmlFor="KeyReceiverId">{t('Receiver')}</InputLabel>
                <Select
                    style={{width: '100%' }}
                    value={this.state.keyMapping.KeyReceiverId}
                    onChange={this.handleInput.bind(this)}
                    label={t('Receiver')}
                    inputProps={{name: 'KeyReceiverId', id: 'KeyReceiverId'}}
                    >
                    {console.log(this.getReceivers())}
                    {this.getReceivers().map( (v, k) => 
                        v.networkName ? (<Box style={head}>{v.networkName}</Box>):
                        (<MenuItem key={k} value={v.ID}>{v.Login}</MenuItem>)
                    )
                    }
                </Select>
                </FormControl>
            </Box>

            ):''}

            
            {(this.state.keyMapping.KeyCommand===KEY_ACTIONS.SEND_MESSAGE)?(
            <>
            <Box textAlign="center">
                <TextField name="KeyMessage" margin="normal"
                    style={{width: '100%'}}
                    autoComplete="new-password"
                    label={t('KeyMessage')}
                    error={this.state.errors.KeyMessage!==false}
                    value={this.state.keyMapping.KeyMessage}
                    onChange={this.handleInput.bind(this)}
                />
            </Box>

            <Box>
            <FormControl style={{...margin, width: '100%'}}>
            <InputLabel htmlFor="KeyReceiverId">{t('Receiver')}</InputLabel>
            <Select
                style={{width: '100%' }}
                value={this.state.keyMapping.KeyReceiverId}
                onChange={this.handleInput.bind(this)}
                label={t('Receiver')}
                inputProps={{name: 'KeyReceiverId', id: 'KeyReceiverId'}}
                >
                {console.log(this.getReceivers(true))}
                {this.getReceivers(true).map( (v, k) => 
                    v.networkName ? (<Box style={head}>{v.networkName}</Box>):
                    (v.Login ?
                    (<MenuItem key={k} value={v.ID}><PersonIcon style={{marginRight:5}} color="disabled"/>{v.Login}</MenuItem>):
                    (<MenuItem key={k} value={v.ID}><GroupIcon  style={{marginRight:5}} color="disabled"/>{v.Name}</MenuItem>)
                    )
                )
                }
            </Select>
            </FormControl>
            </Box>
            </>
            ):''}

        
        </Container>
        </form>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }
}

export const EditKeyMappingDialog = withTranslation()(EditKeyMappingDialog_);