import i18n from '../i18n'

/**
 * formats:
 * 'short_date': 13.02.2020 
 * 'full_date': 04.03.2019 17:38:02
 * 'short_time': 12:04
 * 'time': 13:45:23
 * 
 * @param {number} time unix timestamp
 * @param {string} format 'short_date' | 'full_date' | 'short_time' | 'time'
 */
export function formatDateTime(time, format='full_date'){
    if(time){
        const options = getFormat(format)
        let date = new Date(time)
        let ret = date.toLocaleString(i18n.language, options)
        return ret
    }
    return ''
}

function getFormat(format){
    switch(format){
        case 'short_date':
            return {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }
        case 'full_date':
            return {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }
        case 'long_date':
            return {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            }
        case 'short_time':
            return {
                hour: 'numeric',
                minute: 'numeric'
            }
        case 'time':
            return {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }
        default:
            return null
    }
}