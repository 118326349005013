import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import { Select,  } from '@material-ui/core';
import {Grid, Typography} from '@material-ui/core';
import {MenuItem, Button} from '@material-ui/core';

import RefreshIcon from '@material-ui/icons//Refresh';


import { Navigate } from "react-router-dom";

//import Logo from '../../components/Logo';

import { ApplicationBar } from '../../../components/ApplicationBar';
import { TotalStatistics } from './TotalStatistics'
import { NetworkStatistics } from './NetworksStatistics'
import { DmrStatistics } from './DmrStatistics'
import {PerformanceMonitor} from './PerformanceMonitor'
import { TabbedMenu } from './TabbedMenu'

import { FLEET_STATISTICS_FUNCTION_UNKNOWN, FLEET_STATISTICS_FUNCTION_OVERVIEW, FLEET_STATISTICS_FUNCTION_NETWORKS, 
        FLEET_STATISTICS_FUNCTION_DMR_NETWORKS, FLEET_STATISTICS_FUNCTION_SERVER } from '../../api/ServerApi'
import { VersionUptimeSmall } from './VersionUptime';

//export 
class ServerMonitor_ extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            currentNetworkID: '',
            currentUserID: '',
            currentGroupID: '',
            currentProfileID: '',
            currentStatusID: '',
            currentDeviceID: '',
            selectedTab: 'overview', //tab object type  users|groups
            //apiFunction: FLEET_STATISTICS_FUNCTION_OVERVIEW,
            expandMessageArea: false,
            viewAnchor: null,
            toolsAnchor: null,
            tabs: [], //tab panel 
            tab: null,
            refreshRate: 1
        }

        this.timer = null

        this.handleViewMenu = this.handleViewMenu.bind(this)
        this.handleViewClose = this.handleViewClose.bind(this)
        this.handleToolsMenu = this.handleToolsMenu.bind(this)
        this.handleToolsClose = this.handleToolsClose.bind(this)
        this.handleConsoleMenu = this.handleConsoleMenu.bind(this)
        this.handleRefresh = this.handleRefresh.bind(this)
        this.handleAction = this.handleAction.bind(this)
        this.onCangeRefreshRate = this.onCangeRefreshRate.bind(this)

        this.handleTabClick = this.handleTabClick.bind(this)
    }

    handleAction(action){
        this.props.onAction(action)
    }

    handleViewMenu(event){
        this.setState({viewAnchor: event.currentTarget});
    }

    handleViewClose(){
        //this.menuAnchor = null;
        this.setState({viewAnchor: null});
        return null;
    }

    handleToolsMenu(event){
        this.setState({toolsAnchor: event.currentTarget});
    }

    handleToolsClose(){
        //this.menuAnchor = null;
        this.setState({toolsAnchor: null});
        return null;
    }

    handleConsoleMenu(){
        //let navigate = usexgate () 
        //alert('console');
        this.setState({navigate:'/'})
        //window.location = "/monitor";
    }

    handleRefresh(){
        //alert('api request');
        this.props.onAction(this.query())
    }

    componentDidMount(){
        //alert('api request');
        this.props.onAction(this.query())
        this.onCangeRefreshRate()
    }

    componentWillUnmount(){
        clearInterval(this.timer)
    }

    onCangeRefreshRate(event){
        //console.log(event)
        let rate = this.state.refreshRate
        if(event){
            rate = event.target.value
            this.setState({refreshRate: rate})  
        } 
        if(this.timer){
            clearInterval(this.timer)
            this.timer = null
        }
        if(rate){
            this.timer = setInterval(()=>{this.props.onAction(this.query())}, rate*1000)
        }
    }

    query(tab){
        if(!tab) tab = this.state.selectedTab
        let q = {
            type: 'STATISTICS_REQUEST',
            function: this.apiFunction(tab)
        }
        //console.log('query')
        //console.log(tab)
        //console.log(q)
        return q
    }

    handleTabClick(tab){
        this.setState({selectedTab: tab});
        this.props.onAction(this.query(tab))
    }

    apiFunction(tab){
        let apiFunction
        switch(tab){
            case 'overview': apiFunction = FLEET_STATISTICS_FUNCTION_OVERVIEW; break
            case 'networks': apiFunction = FLEET_STATISTICS_FUNCTION_NETWORKS; break
            case 'dmr': apiFunction = FLEET_STATISTICS_FUNCTION_DMR_NETWORKS; break
            case 'server': apiFunction = FLEET_STATISTICS_FUNCTION_SERVER; break
            default : apiFunction = FLEET_STATISTICS_FUNCTION_UNKNOWN
        }
        return apiFunction
    }

    render(){

        const {t} = this.props

        if(this.state.navigate){
            return (
<Navigate to={this.state.navigate}></Navigate>
            )
        }

        let statusMessage = ''
        if(this.props.statusMessage){
            statusMessage = (
            <Typography align="center" color={this.props.statusError ? "error" : "default"}>
                {this.props.statusMessage}
            </Typography>
            );
        }

        /*
        let backgroundTitleStyle = {
            background: theme.palette.background.dialogTitle,
            color: theme.palette.color.dialogTitle
        }
        */

        let uptime = null
        switch(this.state.selectedTab){
            case 'overview': uptime = this.props.statisticsOverview; break;
            case 'networks': uptime = this.props.statisticsNetworks; break;
            case 'dmr': uptime = this.props.statisticsDmr; break;
            case 'server': uptime = this.props.statisticsServer; break;
            default: uptime = null;
        }
                            

        return (
            <>
            <Grid item xs={12} style={{height:'100vh'}}>
                
                <ApplicationBar user={this.props.user}
                    onAction={this.handleAction}>
                    
                    {/*
                    <Button style={{...backgroundTitleStyle, margin: '0 0.5em', height: '1.8em', padding: '0 16px'}} 
                             variant="outlined" onClick={this.handleViewMenu}
                            >{t('View')} <ExpandMoreIcon style={{marginLeft:5}}/></Button>
                    <Menu anchorEl={this.state.viewAnchor} keepMounted
                            open={Boolean(this.state.viewAnchor)} 
                            onClose={this.handleViewClose}
                            >
                        <MenuItem onClick={()=>{this.appendTab({name:'map', label:t('Map')}); this.handleViewClose();}}>{t('Map')}</MenuItem>
                        <MenuItem onClick={()=>{this.appendTab({name:'eventlog', label: t('EventLog')}); this.handleViewClose();}}>{t('EventLog')}</MenuItem>
                    </Menu>
                    */}
                    {/*
                    <Button style={{...backgroundTitleStyle, margin: '0 0.5em', height: '1.8em', padding: '0 16px'}} 
                             variant="outlined" onClick={this.handleToolsMenu}
                            >{t('Tools')} <ExpandMoreIcon style={{marginLeft:5}}/></Button>
                    <Menu anchorEl={this.state.toolsAnchor} keepMounted
                            open={Boolean(this.state.toolsAnchor)} 
                            onClose={this.handleToolsClose}
                            >
                        <MenuItem onClick={this.handleSettingsMenu}>{t('Settings')}</MenuItem>
                        <MenuItem onClick={this.handleConsoleMenu}>{t('FleetConsole')}</MenuItem>
                    </Menu>
                    */}
                    <VersionUptimeSmall data={uptime}></VersionUptimeSmall>
                    

                </ApplicationBar>
                
                <Grid container justify="flex-start" spacing={0} 
                    style={{height:'83%', 
                        paddingTop: 5,
                        borderTop:'1px solid '+theme.palette.border.dialog}} >

                    <Grid xs={12} key="2" item style={{padding:5}}>

                        <Grid  key="1" container direction="row" spacing={1} style={{ padding:5, border2:'1px solid blue'}}>
                            <Grid xs={12} item>
                                <TabbedMenu selected={this.state.selectedTab} onClick={this.handleTabClick}></TabbedMenu>  
                            </Grid>
                        </Grid>
                        <Grid  key="2" container direction="row" spacing={1} 
                            style={{height2:this.state.expandMessageArea?'30vh':'60vh', padding:5, border2:'1px solid blue'}}>
    
                            <Grid xs={12} item>
                            {this.state.selectedTab==='overview' ? <TotalStatistics data={this.props.statisticsOverview}></TotalStatistics> : ''}
                            {this.state.selectedTab==='networks' ? <NetworkStatistics data={this.props.statisticsNetworks}></NetworkStatistics> : ''}
                            {this.state.selectedTab==='dmr' ?<DmrStatistics data={this.props.statisticsDmr}></DmrStatistics> : ''}
                            {this.state.selectedTab==='server' ?<PerformanceMonitor data={this.props.statisticsServer}></PerformanceMonitor> : ''}
                            </Grid>

                        </Grid>

                        <Grid  key="4" container direction="row" spacing={1}  item style={{padding:5, border2: '1px solid red'}}>
                            
                            <Grid item style={{margin:5}} ><Typography>{t('RefreshRate')}: </Typography></Grid>
                            <Grid item>
                                <Select value={this.state.refreshRate}
                                        onChange={this.onCangeRefreshRate}
                                        inputProps={{
                                                        name: 'RefreshRate',
                                                        id: 'RefreshRate',
                                                    }}
                                                    >
                                                    <MenuItem key="1" value={1}>1</MenuItem>
                                                    <MenuItem key="2" value={2}>2</MenuItem>
                                                    <MenuItem key="5" value={5}>5</MenuItem>
                                                    <MenuItem key="15" value={15}>15</MenuItem>
                                                    <MenuItem key="30" value={10}>30</MenuItem>
                                                    <MenuItem key="0" value={0}>{t('DontRefresh')}</MenuItem>
                                </Select>
                            </Grid>
                            { this.state.refreshRate===0 && <Grid item><Button onClick={this.handleRefresh}><RefreshIcon/></Button></Grid> }
                        </Grid>
                        
                    </Grid>
                </Grid>
                

                <Grid xs={12} key="5" item style={{border2: '1px solid green', height:'3em'}}>
                    {/*server startus*/} {statusMessage}
                </Grid>

                


            </Grid>
            </>
        );



    }

}

function gb(value){
    return Math.round(value/1000)
}

export const ServerMonitor = withTranslation()(ServerMonitor_);