import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';
import {Table, TableBody, TableCell, TableHead, TableRow}  from '@material-ui/core';
import {Paper, IconButton, Menu, MenuItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {FormControl, Checkbox, Select} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { sortByName } from '../../../api/helpers';

class GroupsListEditor_ extends React.Component{

    constructor(props){
        super(props);
        this.state = {menuAnchor: null}
    }

    handleItemSelect(groupID){
        this.props.onItemSelect(groupID)
    }

    getRowStyle(groupID){
        let selected = groupID === this.props.selectedGroupID
        let style = {
            background: selected? theme.palette.background.selectedRow : '',
            cursor: 'pointer'
        }
        return style
    }
    handleMenuClick(event, itemID){
        this.setState({menuAnchor: event.currentTarget, menuItemID: itemID});
        event.stopPropagation();
    }

    handleMenuClose(){
        this.menuAnchor = null;
        this.setState({menuAnchor: null});
        return null;
    }

    handleMenuItemClick(action){
        const itemID = this.state.menuItemID
        this.handleMenuClose();
        this.props.onAction({type: action, groupID: itemID})
    }

    render(){
        const {t} = this.props 
        this.props.groups.sort(sortByName)

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            addIcon: {
                color: 'white'
            }
        };

        return(
            <>
                <Paper style={styles.paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.head}>{t('Name')}</TableCell>
                                <TableCell style={styles.head}>{t('Priority')}</TableCell>

                                <TableCell style={styles.head} align="center">{t('Emer')}.</TableCell>
                                <TableCell style={styles.head} align="center">{t('AllC')}.</TableCell>
                                <TableCell style={styles.head} align="center">{t('Broad')}.</TableCell>

                                <TableCell style={{...styles.head, width:40}}>
                                    <IconButton size="small" color="primary" aria-label="add group" component="span"
                                        style={styles.addIcon}
                                        onClick={()=>{this.handleMenuItemClick('GROUP_EDITOR_OPEN_ADD')}}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.groups.map((group) => 
                            <TableRow key={group.ID} 
                                    onClick={()=>{this.handleItemSelect(group.ID)}}
                                    style={this.getRowStyle(group.ID)}>
                                <TableCell component="th" scope="row">
                                  {group.Name}
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={group.Priority}
                                        onChange={event=>{
                                            this.props.onAction({type: 'GROUP_EDITOR_INPUT', event: event, groupID: group.ID})
                                        }}
                                        variant="standard"
                                        inputProps={{
                                            name: 'Priority',
                                            id: 'Priority',
                                        }}
                                        >
                                        <MenuItem key="5" value={5}>+5</MenuItem>
                                        <MenuItem key="4" value={4}>+4</MenuItem>
                                        <MenuItem key="3" value={3}>+3</MenuItem>
                                        <MenuItem key="2" value={2}>+2</MenuItem>
                                        <MenuItem key="1" value={1}>+1</MenuItem>
                                        <MenuItem key="0" value={0}>{t('Default')}</MenuItem>
                                        <MenuItem key="-1" value={-1}>-1</MenuItem>
                                    </Select>
                                </TableCell>

                                <TableCell>
                                    <FormControl>
                                        <label>
                                            <Checkbox color="primary" 
                                                style={{width: 30, height: 30, marginRight: '0.5em'}}
                                                checked={Boolean(group.Emergency)}
                                                onChange={event=>{
                                                    this.props.onAction({type: 'GROUP_EDITOR_INPUT', event: event, groupID: group.ID})
                                                }}
                                                name="Emergency"
                                                />
                                        </label>
                                    </FormControl>
                                </TableCell>

                                <TableCell>
                                    <FormControl>
                                        <label>
                                            <Checkbox color="primary" 
                                                style={{width: 30, height: 30, marginRight: '0.5em'}}
                                                checked={Boolean(group.AllCall)}
                                                onChange={event=>{
                                                    this.props.onAction({type: 'GROUP_EDITOR_INPUT', event: event, groupID: group.ID})
                                                }}
                                                name="AllCall"
                                                />
                                        </label>
                                    </FormControl>
                                </TableCell>

                                <TableCell>
                                    <FormControl>
                                        <label>
                                            <Checkbox color="primary" 
                                                style={{width: 30, height: 30, marginRight: '0.5em'}}
                                                checked={Boolean(group.Broadcast)}
                                                onChange={event=>{
                                                    this.props.onAction({type: 'GROUP_EDITOR_INPUT', event: event, groupID: group.ID})
                                                }}
                                                name="Broadcast"
                                                />
                                        </label>
                                    </FormControl>
                                </TableCell>

                                <TableCell style={{width:40}}>
                                    <IconButton size="small" color="inherit" aria-label="add" component="span"
                                        onClick={event=>this.handleMenuClick(event, group.ID)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                        <Menu anchorEl={this.state.menuAnchor} keepMounted
                            open={Boolean(this.state.menuAnchor)} onClose={this.handleMenuClose.bind(this)}>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('GROUP_EDITOR_OPEN_EDIT')}}>
                                <ListItemIcon color="primary"><EditIcon/></ListItemIcon> 
                                <ListItemText primary={t('EditGroup')+'...'} />
                            </MenuItem>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('GROUP_EDITOR_DELETE')}}>
                                <ListItemIcon><DeleteForeverIcon/></ListItemIcon>
                                <ListItemText primary={t('DeleteGroup')} />
                            </MenuItem>
                        </Menu>
                    </Table>
                </Paper>
            </>
        )

    }

}

export const GroupsListEditor = withTranslation()(GroupsListEditor_);