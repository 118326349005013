import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import ImagePreview from '../../../components/ImagePreview';
import FilePreview from '../../../components/FilePreview';

import { Menu, MenuItem, Paper, Grid, Typography, Divider, IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

import PersonIcon from '@material-ui/icons/Person';
//import GroupIcon from '@material-ui/icons/Group';
import { getObjectByID } from '../../../api/helpers';
import { formatDateTime } from '../../../api/date';
import { EVENT_TYPE } from '../../api/EventLogger';


export default class UnreadMessages_ extends React.Component{

    constructor(props){
        super(props);

        this.state = {menuAnchor: null, menuMessage: ''}

    }

    handleMenuClick(event, message){
        this.setState({menuAnchor: event.currentTarget, menuMessage: message});
        event.stopPropagation();
    }

    handleMenuClose(){
        //this.menuAnchor = null;
        this.setState({menuAnchor: null, menuMessage: null});
        return null;
    }

    handleMenuItemClick(menu){
        const message = this.state.menuMessage
        this.handleMenuClose();
        if(this.props.onContextMenu){
            this.props.onContextMenu(menu, message)
        }
    }

    getNetworkName(networkID){
        const network = getObjectByID(networkID, this.props.networks)
        if(network){
            return network.Name
        }
        return ''
    }

    hasPeview(message){
        switch(message.type){
            case EVENT_TYPE.TYPE_IMAGE_PRIVATE:
            case EVENT_TYPE.TYPE_IMAGE_GROUP:
                return true
            default:
                return false
        }
    }

    hasFile(message){
        switch(message.type){
            case EVENT_TYPE.TYPE_FILE_PRIVATE:
            case EVENT_TYPE.TYPE_FILE_GROUP:
                return true
            default:
                return false
        }
    }


    handleFileOpen(message){
        //console.log(message)
        this.props.onAction({
            type: 'FILE_OPEN',
            jobID: message.ref_id,
            networkID: message.network_id
        })
    }

    handleFileLoad(message){
        //console.log(message)
        this.props.onAction({
            type: 'FILE_LOAD',
            jobID: message.ref_id,
            networkID: message.network_id
        })
    }

    handleFileCancel(message){
        //console.log(message)
        this.props.onAction({
            type: 'FILE_CANCEL',
            jobID: message.ref_id,
            networkID: message.network_id
        })
    }

    render(){
        const {t} = this.props

        //console.log(this.props.items)

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'none',
              margin: 'auto',
              padding: theme.spacing(2)
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            gridWrapper: {
                width: '100%',
                //border: '1px solid red'
                paddingBottom: 5
            },
            gridRow: {
                //border: '1px solid blue'
            },
            cell: {
                //border: '1px solid grey',
                margin: '0 2px' 
            },
            cellHeader: {
                background: theme.palette.primary.main,
                color: 'white',
                padding: '3px 5px'
            }
        };

        //console.log('UnreadMessages render')
        //console.log(this.props)
        //console.log(this.props.messages)
        if(this.props.messages && this.props.messages.length){
            
        return(
            <>
                <Paper style={styles.paper}>
                {this.props.messages.map((message, index) => 
                    <>
                    <Grid item xs={12} sm container alignItems="center" key={message.ref_id} style={{}}>

                        <Grid item xs container>

                            <Grid style={styles.gridRow} container alignItems="center">
                                <Grid style={{...styles.cell, height:24}} item >
                                    <PersonIcon color="primary"/>
                                </Grid>
                                <Grid style={styles.cell} item xs>
                                    {message.sender}
                                    {" > "}
                                    {message.recipient}
                                </Grid>
                                <Grid style={styles.cell} item>
                                    {formatDateTime(message.datetime, 'short_time')}
                                </Grid>
                            </Grid>
                            <Grid style={styles.gridRow} container xs alignItems="center">
                                {this.hasPeview(message)?
                                    <Grid style={styles.cell} item>
                                        <ImagePreview 
                                            width={50}
                                            height={50}
                                            message={message}
                                            onAction={this.props.onAction}
                                        ></ImagePreview>
                                        #{message.state}-{message.file_state}-{message.type}
                                    </Grid>    
                                :''}
                                {this.hasFile(message)?
                                    <Grid style={styles.cell} item>
                                        <FilePreview 
                                            width={50}
                                            height={50}
                                            message={message}
                                            onAction={this.props.onAction}
                                        ></FilePreview>
                                        #{message.state}-{message.file_state}-{message.type}
                                    </Grid>    
                                :''}
                                <Grid style={styles.cell} item xs>
                                    {message.note}
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item style={{width:20}}>
                            <IconButton size="small" color="inherit" aria-label="more" 
                                component="span"
                                onClick={event=>this.handleMenuClick(event, message)}>
                                <MoreVert />
                            </IconButton>
                        </Grid>

                    </Grid>
                    
                    {index+1 < this.props.messages.length ?
                        <Divider style={{margin:5}}/>
                    :''}
                    
                    </>
                )}
                    <Menu anchorEl={this.state.menuAnchor} keepMounted
                        open={Boolean(this.state.menuAnchor)} 
                        onClose={this.handleMenuClose.bind(this)}>
                        <MenuItem onClick={()=>{this.handleMenuItemClick('messages')}}>{t('Messages')}</MenuItem>
                        <MenuItem onClick={()=>{this.handleMenuItemClick('markread')}}>{t('MarkRead')}</MenuItem>
                        <MenuItem onClick={()=>{this.handleMenuItemClick('markallread')}}>{t('MarkAllRead')}</MenuItem>
                    </Menu>
                </Paper>
               
            </>
        )
        }else{
            return (
                <Typography variant="h5" color="secondary" style={{width:'100%', height:'100%'}} align="center">
                    {t('UnreadMessages')}
                </Typography>
            );

        }

    }

}


export const UnreadMessages = withTranslation()(UnreadMessages_);
