import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../theme';

import {Button, ButtonGroup} from '@material-ui/core';

class TabbedMenu_ extends React.Component{

  handleClick(tab){
    if(this.props.onClick) 
    this.props.onClick(tab)
  }

  buttonStyle(name){
    const buttonStyle = {
      minWidth: '100px',
      height: '2.0em',
      background: theme.palette.background.tabbedMenu,
      color: theme.palette.color.tabbedMenu
    } 
    if(this.props.selected===name){
      return {
        ...buttonStyle,
        background: theme.palette.background.tabbedMenuSelected
      }
    }else{
      return buttonStyle
    }

  }

  render(){
    const {t} = this.props

    const buttonUsers = this.props.usersEnabled?(
      <Button style={this.buttonStyle('users')} 
        onClick={()=>this.handleClick('users')}>{t('Users')}</Button>
    ):'';
    const buttonGroups = this.props.groupsEnabled?(
      <Button style={this.buttonStyle('groups')} 
        onClick={()=>this.handleClick('groups')}>{t('Groups')}</Button>
    ):'';
    const buttonStatuses = this.props.statusesEnabled?(
      <Button style={this.buttonStyle('statuses')} 
        onClick={()=>this.handleClick('statuses')}>{t('Statuses')}</Button>
    ):'';
    const buttonDevices = this.props.devicesEnabled?(
      <Button style={this.buttonStyle('devices')} 
        onClick={()=>this.handleClick('devices')}>{t('Devices')}</Button>
    ):'';
    const buttonProfiles = this.props.profilesEnabled?(
      <Button style={this.buttonStyle('profiles')} 
        onClick={()=>this.handleClick('profiles')}>{t('Profiles')}</Button>
    ):'';

    return (
      <ButtonGroup size="small">
        {buttonUsers}
        {buttonGroups}
        {buttonStatuses}
        {buttonDevices}
        {buttonProfiles}
      </ButtonGroup>
    );
  }
}

export const TabbedMenu = withTranslation()(TabbedMenu_);