import {alawmulaw} from './alawmulaw'
import SoundBuffer from './SoundBuffer';
import Resampler from './Resampler';
import { base64toGuid , uint8ArrayToBase64 } from './helpers';

export default class AudioProcessor{


    constructor(sampleRate, bufferSamplesCount, ssrc, sendPacketCallback){

        this.sampleRate = sampleRate
        this.bufferSamplesCount = bufferSamplesCount
        this.sendPacketCallback = sendPacketCallback

        //alert('bufferSamplesCount=' + bufferSamplesCount)

        this.audioContext = null;
        this.microphoneStream = null;
        this.soundBuffer = null;
        this.microphoneOn = false; //true if packets recieve

        this.audioProcessorNode = null;
        this.currentBufferIndex = 0;

        this.currentFrame = new Int16Array(this.bufferSamplesCount);
        this.sequenceNumber = 0;

        this.chunkSize = 2048;

        this.udpPacketBuffer = new ArrayBuffer(12 + this.bufferSamplesCount);

        this.ssrc = ssrc //new Uint32Array(1);
        //window.crypto.getRandomValues(this.ssrc);


        this.queue = new PacketQueue()

        if(!this.hasGetUserMedia()){
            throw Error('Audio devices not supported in your browser')
        } 

        this.init()

        this.mixTimer = setInterval( this.channelMixer.bind(this), 50 )

        //this.stats = setInterval(()=>{console.log(this.queue)}, 10000 )

    }

    hasGetUserMedia() {
        // Note: Opera builds are unprefixed.
        return Boolean(navigator.getUserMedia || 
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || 
                navigator.msGetUserMedia);
    }

    init(){

        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        const audioContextOptions = isFirefox? null : { sampleRate: this.sampleRate }
        this.audioContext = new AudioContext(audioContextOptions);
        
        if(this.audioContext.sampleRate !== this.sampleRate){
            console.log('init resampler '+ this.audioContext.sampleRate + ' => ' + this.sampleRate)
            this.resampler = new Resampler(this.audioContext.sampleRate, this.sampleRate, 1, this.chunkSize)
        }
    
        if (!navigator.getUserMedia)
            navigator.getUserMedia = //navigator.mediaDevices.getUserMedia ||
                                    navigator.getUserMedia || 
                                    navigator.webkitGetUserMedia || 
                                    navigator.mozGetUserMedia || 
                                    navigator.msGetUserMedia;                                   
    
        if(false && navigator.mediaDevices.getUserMedia){
            //alert('navigator.mediaDevices.getUserMedia')
            navigator.mediaDevices.getUserMedia({audio:true}, 
                this.startStream.bind(this),
                function(e) {alert('Error capturing audio.')}
            );
        }else if(navigator.getUserMedia){
            navigator.getUserMedia({audio:true}, 
                this.startStream.bind(this),
                function(e) {alert('Error capturing audio.')}
            );
        } else { alert('getUserMedia not supported in this browser.'); }
    
        this.soundBuffer = new SoundBuffer(this.audioContext, this.sampleRate, 10, 3, false);
        //this.sampleRate
        return true;

    }

    startStream(stream){

        try{
            this.microphoneStream = this.audioContext.createMediaStreamSource(stream);
        }catch(e) {
            alert('createMediaStreamSource: '+ e)
            return false
        }
        
        this.audioProcessorNode = this.audioContext.createScriptProcessor(this.chunkSize, 1, 1);
        this.audioProcessorNode.onaudioprocess = this.processAudioNode.bind(this)

    }


    processAudioNode(data){
        let leftChannel

        //console.log('processAudioNode')
        //console.log(data.inputBuffer.getChannelData(0).length)

        if(this.resampler){
            //console.log('resampler')
            leftChannel = this.resampler.resample(data.inputBuffer.getChannelData(0));
        }else{
            leftChannel = data.inputBuffer.getChannelData(0);
        }
        //console.log('processAudioNode')
        //console.log(this.chunkSize)
        //console.log(leftChannel.length)
        //console.log(data.inputBuffer.getChannelData(0).length)

        let index = 0;
        while (index < leftChannel.length) {
            while (this.currentBufferIndex < this.bufferSamplesCount && index < this.chunkSize) {
                this.currentFrame[this.currentBufferIndex] = leftChannel[index] * 32767;
                index++;
                this.currentBufferIndex++;
            }

            if (this.currentBufferIndex === this.bufferSamplesCount) {
                this.currentBufferIndex = 0;
                this.processMicrophoneFrame();
            }
        }

/*
        leftChannel.forEach(item => {
            this.currentFrame[this.currentBufferIndex] = item * 32767;
            this.currentBufferIndex++;

            if (this.currentBufferIndex === this.bufferSamplesCount) {
                this.currentBufferIndex = 0;
                this.processMicrophoneFrame();
            }
        })

        */
    
    }

    processMicrophoneFrame() {

        //if(!this.microphoneOn) return;

        //console.log('processMicrophoneFrame')

        let udpPacket = new Uint8Array(this.udpPacketBuffer);
        // header
        udpPacket[0] = 2 << 6; // udp version = 2
        udpPacket[1] = 106; // voice call
        this.sequenceNumber++;
        udpPacket[2] = (this.sequenceNumber >> 8) & 0xff;
        udpPacket[3] = this.sequenceNumber & 0xff;
    
        udpPacket[8] = ((this.ssrc >> 0x18) & 0xff);
        udpPacket[9] = ((this.ssrc >> 0x10) & 0xff);
        udpPacket[10] = ((this.ssrc >> 8) & 0xff);
        udpPacket[11] = (this.ssrc & 0xff);
    
        var alaw = alawmulaw.alaw.encode(this.currentFrame);
        udpPacket.set(alaw, 12);
    
        if(this.sendPacketCallback) this.sendPacketCallback(udpPacket)
    }


    start(){
        //console.log('AudioProcessor start')
        this.microphoneOn = true
        this.audioContext.resume();
        if(this.microphoneStream){
            this.microphoneStream.connect(this.audioProcessorNode);
            this.audioProcessorNode.connect(this.audioContext.destination);
        }
    }
    
    stop(){
        //console.log('AudioProcessor stop')
        this.microphoneOn = false
        if(this.microphoneStream){
            this.microphoneStream.disconnect(this.audioProcessorNode);
            this.audioProcessorNode.disconnect(this.audioContext.destination);
        }
    }
    

    processAudioFrame(data){
        //console.log('processAudioFrame')
        //console.log(data)
        
        const info = this.getVoicePacketInfo(data, true)
        //console.log(info)

        var alaw = new Uint8Array(data.buffer, 12+16);
        var pcm = alawmulaw.alaw.decode(alaw);     

        var audioFrame = new Float32Array(this.bufferSamplesCount);
        
        //debugger
        //let audioFrame = pcm.map(item=>1.0 * item / 32767)
        //console.log(audioFrame) 
        
        for(let i = 0; i < this.bufferSamplesCount; i++){
            audioFrame[i] = 1.0 * pcm[i] / 32767;
        }

        this.queue.put(audioFrame, info)

        //console.log('audioFrame')
        //console.log(audioFrame)
        //this.soundBuffer.addChunk(audioFrame);
        
    }


    getVoicePacketInfo(data, extractGuid){
        //console.log(data.buffer)
        let packetHeader = data.slice(0,12)
    
        //console.log('getVoicePacketInfo')
        //console.log(packetHeader)
        
        const x = (packetHeader[8] << 0x18) + 
                    (packetHeader[9] << 0x10) +
                    (packetHeader[10] << 8) + 
                    packetHeader[11] 
        const ssrc = new Uint32Array(1)
        ssrc[0] = x

        const packetInfo = {
            'version': packetHeader[0] >> 6,
            'type': packetHeader[1],
            'number': (packetHeader[2] << 8) + packetHeader[3],
            'ssrc': ssrc
        }
        
        if(extractGuid){
            let guidArr, id, guid
            guidArr = data.slice(12, 28)
            //console.log(guidArr)
            id = uint8ArrayToBase64(guidArr)
            guid = base64toGuid(id)
            packetInfo.id = id
            packetInfo.guid = guid
        }

        //console.log(packetInfo)
        return packetInfo
    }


    channelMixer(){
        //console.log(this)
        //let stat = this.queue.getStat()

        const audioFrame = this.queue.get()
        if(audioFrame){
            //console.log('channelMixer audioFrame')
            //console.log(audioFrame)
            //console.log(this.queue.getStat())
            this.soundBuffer.addChunk(audioFrame);
        }
        //alert('channelMixer '+ stat)
    }

}

class PacketQueue{

    constructor(){

        this.queue = {}

    }

    getStat(){
        let countChannels = Object.keys(this.queue).length
        let total = Object.keys(this.queue).reduce((sum, key)=>sum + this.queue[key].length, 0)

        return {channels: countChannels, packets: total}
    }

    //put packet to queue
    put(packet, info){

        if(this.queue[info.id]===undefined){
            this.queue[info.id] = []
        }

        this.queue[info.id].push(packet)

        //console.log('put')
        //console.log(this.getStat())
    }

    //merge packets
    get(){
        const packets = []

        Object.keys(this.queue).forEach(key=>{
            if(this.queue[key].length){
                packets.push(this.queue[key].shift())
            }
        })

        if(packets.length){
            //console.log('get')
            //console.log(this.getStat())
        }

        const frame = this.merge(packets)
        return frame
    }

    merge(packets){
        let out
        packets.forEach(packet => {
            if(out === undefined){
                out = packet
                return
            }

            out = out.map((item, index) => this.mix(item, packet[index]))

        })
        return out
    }

    mix(a, b){
        const MAX_VAL_SQUARE = 1073741824
        const sample = (a + b) / (1 + (a * b) / MAX_VAL_SQUARE);
        return sample
    }

}