import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Table, TableBody, TableCell, TableHead, TableRow}  from '@material-ui/core';

import { VersionUptime } from './VersionUptime'

//export 
class PerformanceMonitor_ extends React.Component {


    render(){
        const {t} = this.props

        if(!this.props.data) return (<>No data</>)

        let stat = this.props.data.PerformanceCounters
        let networks = this.props.data.FleetNetworks
        console.log('stat')
        console.log(this.props.data)
        console.log(stat)

        if(!stat) return (<>No data</>)
/*
        DiskCounters: Array [ {…} ]
​​
MemoryTotal: 7948
​​
MemoryUsed: 7058
​​
MemoryWF: 73
​​
NetworkAdapterCounters: Array [ {…}, {…} ]
​​
ProcessorTotal: 0
​​
ProcessorWF: 0
*/

        const styles = {
            paper: {
            width: '100%',
            overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            alarm: {
                color: theme.palette.color.alarm,
                fontWeight: 'bold'
            }
        };

        const styleAlarm = function(alarm, w){
            let style = {}
            if(alarm){
                style = {...styles.alarm}
            }
            if(w){
                style.width = w
            }
            return style;
        }

        return (
            <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} style={styles.head}>{t('Memory')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{width:300}}>{t('MemoryUtilization')}</TableCell>
                        <TableCell style={styleAlarm(stat.MemoryTotalAlarm)}>{Math.round(100*stat.MemoryUsed/stat.MemoryTotal)}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t('MemoryTotal')}</TableCell>
                        <TableCell>{stat.MemoryTotal} {t('Mb')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t('MemoryUsed')}</TableCell>
                        <TableCell style={styleAlarm(stat.MemoryTotalAlarm)}>{stat.MemoryUsed} {t('Mb')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t('MemoryWF')}</TableCell>
                        <TableCell>{stat.MemoryWF} {t('Mb')}</TableCell>
                    </TableRow>
                </TableBody>

                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} style={styles.head}>{t('CPU')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                     <TableRow>
                        <TableCell>{t('CpuUtilization')}</TableCell>
                        <TableCell style={styleAlarm(stat.ProcessorTotalAlarm)}>{stat.ProcessorTotal}%</TableCell>
                    </TableRow>
                    {/**
                    <TableRow>
                        <TableCell>{t('СpuWF')}</TableCell>
                        <TableCell>{stat.ProcessorWF}%</TableCell>
                    </TableRow>
                     */}
                </TableBody>
            </Table>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={1} style={styles.head}>{t('Drive')}</TableCell>
                        <TableCell colSpan={1} style={styles.head}>{t('Total')}</TableCell>
                        <TableCell colSpan={1} style={styles.head}>{t('Free')}</TableCell>
                        <TableCell colSpan={1} style={styles.head}>{t('Usage')}</TableCell>
                        <TableCell colSpan={2} style={styles.head}>{t('Load')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stat.DiskCounters.map((disk, id) => 
                    <TableRow key={id}>
                        <TableCell style={{width:100}}>{disk.Letter}</TableCell>
                        <TableCell style={{width:100}}>{gb(disk.TotalSpace)}{t('Gb')}</TableCell>
                        <TableCell style={styleAlarm(disk.FreeSpaceAlarm, 100)}>{gb(disk.FreeSpace)}{t('Gb')}</TableCell>
                        <TableCell style={styleAlarm(disk.FreeSpaceAlarm, 100)}>{Math.round(100*(disk.TotalSpace-disk.FreeSpace)/disk.TotalSpace)}%</TableCell>
                        <TableCell style={styleAlarm(disk.LoadAlarm, 100)}>{disk.Load}%</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>


            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{...styles.head, width:400}}>{t('NetworkAdapters')}</TableCell>
                        <TableCell style={{...styles.head, width:120}}>{t('Bandwidth')}</TableCell>
                        <TableCell style={{...styles.head, width:120}}>{t('Load')}</TableCell>
                        <TableCell style={{...styles.head, width:120}}>{t('Received')}</TableCell>
                        <TableCell style={{...styles.head}}>{t('Sent')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stat.NetworkAdapterCounters.map((item, id) => 
                    <TableRow key={id}>
                        <TableCell>{item.Name}</TableCell>
                        <TableCell>{item.Bandwidth} {t('Mbit')}</TableCell>
                        <TableCell>{item.Load} %</TableCell>
                        <TableCell>{item.Received} {t('Mbit')}</TableCell>
                        <TableCell>{item.Sent} {t('Mbit')}</TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{...styles.head, width:400}}>{t('Networks')}</TableCell>
                        <TableCell style={{...styles.head, width:120}}>{t('UsersCount')}</TableCell>
                        <TableCell style={{...styles.head, width:120}}>{t('GroupsCount')}</TableCell>
                        <TableCell style={{...styles.head, width:120}}>{t('DispatchersCount')}</TableCell>
                        <TableCell style={{...styles.head}}>{t('DatabaseSize')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {networks.map((net, id) => 
                    <TableRow key={id}>
                        <TableCell>{net.Name}</TableCell>
                        <TableCell>{net.UsersCount}</TableCell>
                        <TableCell>{net.GroupsCount}</TableCell>
                        <TableCell>{net.DispatchersCount}</TableCell>
                        <TableCell style={styleAlarm(net.DatabaseSizeAlarm)}>{net.DatabaseSize} {t('Mb')}</TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>

            <VersionUptime data={this.props.data} version='full'></VersionUptime>

            </>
        )
    }

}

function gb(value){
    return Math.round(value/1000)
}

export const PerformanceMonitor = withTranslation()(PerformanceMonitor_);