
import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import { Box } from '@material-ui/core'; //Grid, Paper,

import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';


class EventFilter_ extends React.Component {

    constructor() {
        super()

        //const time = new Date()
        //const date = new Date(time.getFullYear(), time.getMonth(), time.getDate(), 0, 0 )

        //this.state = {
        //    dateFrom: date,
        //    dateTo: null
        //}

        this.handleDateFromChange = this.handleDateFromChange.bind(this)
        this.handleDateToChange = this.handleDateToChange.bind(this)
    }   

    handleDateFromChange(date){
        let filter = {...this.props.filter}
        filter.dateFrom = date
        console.log('handleDateFromChange')
        console.log(date)

        //this.setState({dateFrom: date})
        if(this.props.onChange) this.props.onChange(filter)//this.getFilter()
        
    }

    handleDateToChange(date){
        let filter = {...this.props.filter}
        //this.setState({dateTo: date})
        filter.dateTo = date
        console.log('handleDateToChange')
        console.log(date)

        if(this.props.onChange) this.props.onChange(filter)//
        
    }

    getFilter(){
        return this.state
    }

    render(){
        const {t, i18n} = this.props

        let dateFormat

        switch(i18n.language){
            case 'ru':
                dateFormat = "dd.MM.yyyy HH:mm"
                break

            case 'en':
            default:
                dateFormat = "MM/dd/yyyy HH:mm"
        }

        return(
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box style={{margin: '0 16px'}}>
                <KeyboardDateTimePicker
                        style={{marginRight: 16}}
                        margin="normal"
                        id="dateFrom"
                        name="dateFrom"
                        label={t('From')}
                        cancelLabel={t('Cancel')}
                        okLabel={t('Apply')}
                        format={dateFormat}
                        value={this.props.filter.dateFrom}
                        onChange={this.handleDateFromChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />

                <KeyboardDateTimePicker
                        margin="normal"
                        id="dateTo"
                        name="dateTo"
                        label={t("To")}
                        cancelLabel={t('Cancel')}
                        okLabel={t('Apply')}
                        format={dateFormat}
                        value={this.props.filter.dateTo}
                        onChange={this.handleDateToChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />                        
            </Box>
            </MuiPickersUtilsProvider>
        );

    }

}

export const EventFilter = withTranslation()(EventFilter_);

