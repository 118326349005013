import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Table, TableBody, TableCell, TableHead, TableRow}  from '@material-ui/core';
import {Paper, IconButton, Menu, MenuItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Select} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { sortByLogin } from '../../../api/helpers';

class UsersListEditor_ extends React.Component{

    constructor(props){
        super(props);
        this.state = {menuAnchor: null}
    }

    handleUserSelect(userID){
        this.props.onUserSelect(userID)
    }

    getUserStyle(userID){
        let selected = userID === this.props.selectedUserID
        let style = {
            background: selected? theme.palette.background.selectedRow : '',
            cursor: 'pointer'
        }
        return style
    }

    handleMenuClick(event, itemID){
        this.setState({menuAnchor: event.currentTarget, menuItemID: itemID});
        event.stopPropagation();
    }

    handleMenuClose(){
        this.menuAnchor = null;
        this.setState({menuAnchor: null});
        return null;
    }

    handleMenuItemClick(action){
        const itemID = this.state.menuItemID
        this.handleMenuClose();
        this.props.onAction({type: action, userID: itemID})
    }

    render(){
        const {t} = this.props

        this.props.users.sort(sortByLogin)
        //console.log(this.props.users)

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            addIcon: {
                color: 'white'
            }
        };

        return(
            <>
                <Paper style={styles.paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.head}>{t('Login')}</TableCell>
                                <TableCell style={styles.head}>{t('Name')}</TableCell>
                                <TableCell style={styles.head}>{t('Priority')}</TableCell>
                                <TableCell style={{...styles.head, width:40}}>
                                    <IconButton size="small" color="primary" aria-label="more" component="span"
                                        style={styles.addIcon}
                                        onClick={()=>{this.handleMenuItemClick('USER_EDITOR_OPEN_ADD')}}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.users.map((user) => 
                            <TableRow key={user.ID} 
                                    onClick={()=>{this.handleUserSelect(user.ID)}}
                                    style={this.getUserStyle(user.ID)}>
                                <TableCell component="th" scope="row">
                                    {user.Login}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {user.Name}
                                </TableCell>
                                <TableCell style={{width:50, whiteSpace:'nowrap'}}>
                                    <Select
                                        value={user.Priority}
                                        onChange={event=>{
                                            this.props.onAction({type: 'USER_EDITOR_INPUT', event: event, userID: user.ID})
                                        }}
                                        inputProps={{
                                            name: 'Priority',
                                            id: 'Priority',
                                        }}
                                        >
                                        <MenuItem key="14" value={14}>+14</MenuItem>
                                        <MenuItem key="13" value={13}>+13</MenuItem>
                                        <MenuItem key="12" value={12}>+12</MenuItem>
                                        <MenuItem key="11" value={11}>+11</MenuItem>
                                        <MenuItem key="10" value={10}>+10</MenuItem>
                                        <MenuItem key="9" value={9}>+9</MenuItem>
                                        <MenuItem key="8" value={8}>+8</MenuItem>
                                        <MenuItem key="7" value={7}>+7</MenuItem>
                                        <MenuItem key="6" value={6}>+6</MenuItem>
                                        <MenuItem key="5" value={5}>+5</MenuItem>
                                        <MenuItem key="4" value={4}>+4</MenuItem>
                                        <MenuItem key="3" value={3}>+3</MenuItem>
                                        <MenuItem key="2" value={2}>+2</MenuItem>
                                        <MenuItem key="1" value={1}>+1</MenuItem>
                                        <MenuItem key="0" value={0}>{t('Default')}</MenuItem>
                                        <MenuItem key="-1" value={-1}>-1</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell style={{width:40}}>
                                    <IconButton size="small" color="inherit" aria-label="add" component="span"
                                        onClick={event=>this.handleMenuClick(event, user.ID)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                        <Menu anchorEl={this.state.menuAnchor} keepMounted
                            open={Boolean(this.state.menuAnchor)} onClose={this.handleMenuClose.bind(this)}>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('USER_EDITOR_OPEN_EDIT')}}>
                                <ListItemIcon color="primary"><EditIcon/></ListItemIcon> 
                                <ListItemText primary={t('EditUser')+'...'} />
                            </MenuItem>
                            <MenuItem onClick={()=>{this.handleMenuItemClick('USER_EDITOR_DELETE')}}>
                                <ListItemIcon><DeleteForeverIcon/></ListItemIcon>
                                <ListItemText primary={t('DeleteUser')} />
                            </MenuItem>
                        </Menu>
                    </Table>
                </Paper>
            </>
        )

    }

}

export const UsersListEditor = withTranslation()(UsersListEditor_);