import React from 'react';
import { withTranslation } from 'react-i18next/hooks';

import theme from '../../../theme';

import {Dialog, DialogActions, DialogContent, DialogTitle, Typography, Slider, OutlinedInput} from '@material-ui/core'
import {Box} from '@material-ui/core'
import {Button, Checkbox, MenuItem, Select} from '@material-ui/core';

import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {Tabs, Tab} from '@material-ui/core'
import {Table, TableBody, TableCell, TableHead, TableRow}  from '@material-ui/core';
import {Paper, IconButton, Menu, ListItemIcon, ListItemText} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { EditAccountDialog } from './EditAccountDialog';
import { EditChannelDialog } from './EditChannelDialog';
import { EditKeyMappingDialog } from './EditKeyMappingDialog';
import { EditSipContactDialog } from './EditSipContactDialog';

import { KEY_ACTIONS } from '../../api/ServerApi';

import { selectKeys } from '../../../api/helpers'

import { getBase64ID, getIndexByID, base64toGuid } from '../../../api/helpers';
import {TabPanel} from '../../../components/TabPanel'

class EditDeviceSettingsDialog_ extends React.Component {

    constructor(props){
        super(props)

        //debugger
        let {deviceSettings} = this.props; 
        if(deviceSettings.DeviceAccounts===undefined){
            deviceSettings.DeviceAccounts = []
        }
        if(deviceSettings.DeviceChannels===undefined){
            deviceSettings.DeviceChannels = []
        }

        this.state = {
            deviceSettings: deviceSettings,
            enableds: this.initEnableds(deviceSettings),
            errors:{
                PttHardwareKey: false, 
                LowBatteryLevel: false,
                DeleteEventsOlderDays: false
            },
            panelsExpanded: {
                startupSettings: true,
                soundSettings: true,
                vibrateOn: true,
                callSettings: true,
                other: true,
                video: true,
                location: true
            },
            accountEditor:{
                action: '', //add|edit
                accountID: '',
                account: {}
            },
            channelEditor:{
                index: null,
                action: '', //add|edit
                channel: {}
            },
            keyMappingEditor:{
                index: null,
                action: '', //add|edit
                keyMapping: {}
            },
            sipContactEditor:{
                index: null,
                action: '', //add|edit
                sipContact: {}
            },
            changed: false,
            tab: 0
        }

        this.togglePanel = this.togglePanel.bind(this)
        this.handleInput = this.handleInput.bind(this)
        this.handleEnableds = this.handleEnableds.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
    }

    initEnableds(deviceSettings){
        return Object.fromEntries(Object.keys(deviceSettings).map(item=>[item, false]))
    }

    handleSave(){

        const isValid = this.validate()
        if(this.state.changed && isValid){
            //debugger
            let deviceSettings = {...this.props.deviceSettings, ...this.state.deviceSettings}

            let arrSettings = Object.entries(deviceSettings);

            arrSettings = arrSettings.filter(item => this.state.enableds[item[0]])
            deviceSettings = Object.fromEntries(arrSettings)

            if(deviceSettings.LowBatteryLevel){
                deviceSettings.LowBatteryLevel = Number(deviceSettings.LowBatteryLevel)
            }
            if(deviceSettings.PttHardwareKey){
                deviceSettings.PttHardwareKey = Number(deviceSettings.PttHardwareKey)
            }

            //debugger
            this.props.onAction({
                type: 'DEVICE_SETTINGS_EDITOR_SAVE', 
                deviceSettings: deviceSettings,
                userID: this.props.userID,
                networkID: this.props.networkID,
                deviceID: this.props.deviceID
            })
        }else if(this.state.changed && !isValid){
            alert(this.props.t('CheckFormForErrors'))
        }else if(!this.state.changed){
            this.props.onAction({type: 'DEVICE_SETTINGS_EDITOR_CLOSE'})
        }
    }

    handleClose(){
        if(this.state.changed){
            if(!window.confirm(this.props.t('CloseFormConfirm'))) return; 
        }
        this.props.onAction({type: 'DEVICE_SETTINGS_EDITOR_CLOSE'})
    }

    handleInput(event){
        const changed = !this.state.changed ? {changed: true} : {}
        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value 
        //console.log(event.target.name + ' val='+val)
        const deviceSettings = {...this.state.deviceSettings, [event.target.name]: val}
        this.setState({deviceSettings: deviceSettings, ...changed})
    }

    handleEnableds(event){
        //debugger
        let changed = !this.state.changed ? {changed: true} : {}
        const val =  event.target.type==='checkbox' ? event.target.checked : event.target.value
        const enableds = {...this.state.enableds, [event.target.name]: val}
        const deviceSettings = {...this.state.deviceSettings}

        if(event.target.name==='EmergencyVolume' && val){
            deviceSettings.SetEmergencyVolume = true
            enableds.SetEmergencyVolume = true
        }
        if(event.target.name==='NotificationVolume' && val){
            deviceSettings.SetNotificationVolume = true
            enableds.SetNotificationVolume = true
        }


        //if(event.target.name==='DeviceAccounts'){
        //    enableds.DefaultUser = enableds.DeviceAccounts
        //}

        this.setState({deviceSettings: deviceSettings, enableds: enableds, ...changed})
    }

    togglePanel(event, expanded, panel){
        this.setState(state => {
            state.panelsExpanded[panel] = expanded
            return state
        })
    }

    checkBox(label, name){
        const margin = {}
        return(
<Box textAlign="" style={{...margin, width: '100%'}}>
    <Checkbox color="primary" 
        checked={this.state.enableds[name]}
        onChange={this.handleEnableds}
        name={name}
    />
    <label>
        <Checkbox color="primary" 
            disabled={!this.state.enableds[name]}
            checked={this.state.deviceSettings[name]}
            onChange={this.handleInput}
            name={name}
        />
        {label}
    </label>
</Box>
        );
    }

    voiceSlider(label, name, max=20){
        const margin = {}
        return(
<Box textAlign="" style={{...margin, width: '100%'}}>
    <Checkbox color="primary" 
        checked={this.state.enableds[name]}
        onChange={this.handleEnableds}
        name={name}
    />
    <label>
        {label}
    </label>
    <Slider
        style={{width:'40%', margin:'5px 15px', padding: 0}}
        disabled={!this.state.enableds[name]}
        value={this.state.deviceSettings[name]}
        onChange={(event, value)=>{
            this.handleInput({target:{name: name, value: value}})
        }}        
        step={1}
        min={0}
        max={max}
    />
</Box>
        );
    }

    select(label, name, items){
        const margin = {}
        return(
<Box textAlign="" style={{...margin, width: '100%'}}>
    <Checkbox color="primary" 
        checked={this.state.enableds[name]}
        onChange={this.handleEnableds}
        name={name}
    />
    <label>{label}
    <Select
        style={{margin:'3px 10px'}}
        disabled={!this.state.enableds[name]}
        value={this.state.deviceSettings[name]}
        onChange={this.handleInput.bind(this)}
        inputProps={{name: name, id: name}}
        >
            {items.map(item=>(<MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>) )}
    </Select>
    </label>
</Box>
        );
    }

    inputBox(label, name){
        const margin = {}
        return(
<Box textAlign="" style={{...margin, width: '100%'}}>
    <Checkbox color="primary" 
        checked={this.state.enableds[name]}
        onChange={this.handleEnableds}
        name={name}
    />
    <label>
        {label}
            <OutlinedInput  type="text" name={name}
                id={name+"-input"}
                style={{width: 100, margin:'0 10px', padding:0}}
                autoComplete="new-password"
                disabled={!this.state.enableds[name]}
                value={this.state.deviceSettings[name]}
                error={this.state.errors[name]!==false}
                onChange={this.handleInput}
                aria-describedby={name+"-helper-text"}
            />

        {this.state.errors[name]!==false?
        <span style={{color:'red', whiteSpace:'nowrap'}} id={name+"-helper-text"}>
            {this.state.errors[name]}
        </span>
        :''}
        
    </label>
</Box>
        );
    }

    textBox(label, name){
        const margin = {}
        return(
<Box textAlign="" style={{...margin, width: '100%'}}>
    <Checkbox color="primary" 
        checked={this.state.enableds[name]}
        onChange={this.handleEnableds}
        name={name}
    />
    <label>
        {label}
        <OutlinedInput  type="text" name={name}
            style={{width: 300, margin:'0 10px', padding:0}}
            autoComplete="new-password"
            disabled={!this.state.enableds[name]}
            value={this.state.deviceSettings[name]||''}
            onChange={this.handleInput}
        />
    </label>    
</Box>
        );
    }

    getGroupsSelectItems(){
        if(!this.props.groups) return []
        let items = this.props.groups.map(item=>[item.Name, item.Name])
        items.unshift(['Undefined', this.props.t('Undefined')])
        return items
    }

    validate(){
        const {t} = this.props

        let errors = this.state.errors;
        let error = false
        if(this.state.deviceSettings.LowBatteryLevel && isNaN(this.state.deviceSettings.LowBatteryLevel)){
            errors.LowBatteryLevel = t('IsNotNumber')
            error = true
        }else{
            errors.LowBatteryLevel = false
        }

        if(this.state.deviceSettings.PttHardwareKey && isNaN(this.state.deviceSettings.PttHardwareKey)){
            errors.PttHardwareKey = t('IsNotNumber')
            error = true
        }else{
            errors.PttHardwareKey = false
        }

        if(this.state.deviceSettings.DeleteEventsOlderDays && isNaN(this.state.deviceSettings.DeleteEventsOlderDays)){
            errors.DeleteEventsOlderDays = t('IsNotNumber')
            error = true
        }else{
            errors.DeleteEventsOlderDays = false
        }
        

        this.setState({errors: errors})

        return !error;
    }

    handleTabChange(event, newValue){
        this.setState({tab: newValue})
    }


    tabGeneral(){

        const {t} = this.props

        return(

<form>
    
    <ExpansionPanel 
        expanded={this.state.panelsExpanded.startupSettings} 
        onChange={(event, expanded) => this.togglePanel(event, expanded, 'startupSettings')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('StartupSettings')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>

            {this.checkBox(t('StartAutomatically'), 'AutoStart')}
            {this.checkBox(t('LoginAutomatically'), 'AutoLogin')}
            {this.checkBox(t('ShowOnDeviceBoot'), 'ShowOnBoot')}
            {this.checkBox(t('SmallScreen'), 'SmallScreen')}

        </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel
        expanded={this.state.panelsExpanded.soundSettings} 
        onChange={(event, expanded) => this.togglePanel(event, expanded, 'soundSettings')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('SoundSettings')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails  style={{display: 'block'}}>

            {this.checkBox(t('PttPressNotification'), 'PttPressSound')}
            {this.checkBox(t('TxStartNotification'), 'TxSound')}
            {this.checkBox(t('IncomingPttCallStartNotification'), 'RxSound')}
            {this.checkBox(t('IncomingPttCallStopNotification'), 'TxStopSound')}
            {this.checkBox(t('PttBusyTone'), 'BusyTone')}
            {this.checkBox(t('ConnectTone'), 'ConnectTone')}
            {this.checkBox(t('DisconnectTone'), 'DisconnectTone')}
            {this.voiceSlider(t('VoiceBoostLevel'), 'BoostLevel')}
            {this.checkBox(t('ExclusiveMicUsagee'), 'KeepMicOpen')}

        </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel
        expanded={this.state.panelsExpanded.vibrateOn} 
        onChange={(event, expanded) => this.togglePanel(event, expanded, 'vibrateOn')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('VibrateOn')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>

            {this.checkBox(t('IncomingPttCall'), 'VibroPtt')}
            {this.checkBox(t('IncomingMessage'), 'VibroText')}

        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel
        expanded={this.state.panelsExpanded.callSettings} 
        onChange={(event, expanded) => this.togglePanel(event, expanded, 'callSettings')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('CallSettings')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>

            {this.checkBox(t('PopupOnIncomingPttCall'), 'PttPopupOnCall')}
            {this.checkBox(t('KeepScreenOnForPttWindow'), 'PttKeepScreenOn')}
            {this.select(t('PttCallStyle'), 'PttStyle', 
                [
                    ['hold', t('PttWhileHoldingButton')],
                    ['press', t('PressButtonToStartStopPtt')],
                ]
            )}
            {this.checkBox(t('LockHardwarePtt'), 'PttLockHardwarePtt')}     
            {this.inputBox(t('HardwarePttKeyCode'), 'PttHardwareKey')}
            {this.checkBox(t('EnableHeadsetButtons'), 'EnableHeadsetButtonsInBackground')}
            {this.select(t('DefaultGroup'), 'DefaultGroup', this.getGroupsSelectItems() )}
            {this.checkBox(t('SetDefaultGroupSelected'), 'DefaultGroupSelected')} 
            {this.checkBox(t('SetFixedEmergencyVolume'), 'SetEmergencyVolume')}
            {this.voiceSlider(t('EmergencyVolume'), 'EmergencyVolume', 100)}
            {this.checkBox(t('SetFixedNotificationVolume'), 'SetNotificationVolume')}
            {this.voiceSlider(t('NotificationVolume'), 'NotificationVolume', 100)}
        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel
        expanded={this.state.panelsExpanded.location} 
        onChange={(event, expanded) => this.togglePanel(event, expanded, 'location')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('Location')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>

            {this.select(t('LocationService'), 'LocationService', 
                [
                    ['server', t('ServerDefined')],
                    ['google', 'Google Map'],
                    ['openStreet', 'OpenStreetMap'],
                ]
            )}

        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel
        expanded={this.state.panelsExpanded.video} 
        onChange={(event, expanded) => this.togglePanel(event, expanded, 'video')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('Video')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>

            {this.select(t('VideoResolution'), 'VideoResolution', 
                [
                    ['1', '320 x 240'],
                    ['2', '640 x 480'],
                ]
            )}
            {this.checkBox(t('FlipRearCamera'), 'FlipRearCamera')}

        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel
        expanded={this.state.panelsExpanded.other} 
        onChange={(event, expanded) => this.togglePanel(event, expanded, 'other')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('Other')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>

            {this.checkBox(t('AllowRemoteMonitoring'), 'RemoteMonitoring')}
            {this.inputBox(t('LowBattaryLevel'), 'LowBatteryLevel')}
            {this.textBox(t('LowBattaryLevelAnnouncement'), 'LowBatteryAnnouncement')}
            {this.checkBox(t('PreventCpuSleep'), 'PreventCpuSleep')}
            {this.checkBox(t('ContinuousServerPing'), 'PingServer')}
            {this.checkBox(t('DisableTts'), 'DisableTts')}
            {this.textBox(t('TtsLanguage'), 'TtsLang')}
            {this.inputBox(t('DeleteEventsOlderDays'), 'DeleteEventsOlder')}

        </ExpansionPanelDetails>
    </ExpansionPanel>

</form>

        )
    }


    handleAccountMenuItemClick(action){
        const itemID = this.state.menuItemID
        this.handleAccountMenuClose();
        this.handleAccountEditorAction({type: action, ID: itemID})
    }

    handleAccountMenuClick(event, itemID){
        this.setState({accountMenuAnchor: event.currentTarget, menuItemID: itemID});
        event.stopPropagation();
    }

    handleAccountMenuClose(){
        this.setState({accountMenuAnchor: null});
        return null;
    }

    handleAccountEditorAction(action){

        switch(action.type){
            case 'ACCOUNT_EDITOR_SAVE': {
                let account = {...action.account}
                let deviceSettings = {...this.state.deviceSettings}
                //if(!account.ID){
                //    account.ID = base64toGuid(getBase64ID())
                //}
                let accountIndex = getIndexByID(account.ID, deviceSettings.DeviceAccounts)
                if(accountIndex===null){
                    deviceSettings.DeviceAccounts.push(account);
                }else{
                    deviceSettings.DeviceAccounts[accountIndex] = account
                }
                this.setState({
                    changed: true,
                    deviceSettings: deviceSettings, 
                    accountEditor: {action:'', ID:'', account:{}}})
                break;
            }
                

            case 'ACCOUNT_EDITOR_CLOSE':
                this.setState({accountEditor: {action:'', ID:'', account:{}}})
                break;

            case 'ACCOUNT_EDITOR_OPEN_EDIT': {
                let accounts = this.state.deviceSettings.DeviceAccounts.filter(item => item.ID===action.ID)
                let account = accounts[0]
                this.setState({accountEditor: {
                    action: 'edit', //add|edit
                    ID: action.ID,
                    account: account
                }})
                break
            }
                
            case 'ACCOUNT_EDITOR_OPEN_ADD': 
                this.setState({accountEditor: {
                    action: 'add', //add|edit
                    ID: action.ID,
                    account: {
                        ID: base64toGuid(getBase64ID())
                    }
                }})
                break
            case 'ACCOUNT_EDITOR_DELETE': {
                let deviceSettings = {...this.state.deviceSettings}
                const accountIndex = getIndexByID(action.ID, deviceSettings.DeviceAccounts)
                if(accountIndex!==null){
                    deviceSettings.DeviceAccounts.splice(accountIndex, 1)
                    this.setState({
                        changed: true,
                        deviceSettings: deviceSettings
                    })
                }
                break
            }      

            default:
                alert(action.type)
        }
    }

    tabAccounts(){
        const {t} = this.props

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
              minHeight: 300
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            addIcon: {
                color: 'white'
            }
        };

        return(
            <>
            
            {this.state.accountEditor.action?(
                <EditAccountDialog open={this.state.accountEditor.action!==''}
                    {...this.state.accountEditor} 
                    onAction={this.handleAccountEditorAction.bind(this)}/>
            ):''}

            <Paper style={styles.paper}>

                <Box textAlign="" style={{ width: '100%'}}>
                    <label>
                        <Checkbox color="primary" 
                            checked={this.state.enableds.DeviceAccounts}
                            onChange={this.handleEnableds}
                            name="DeviceAccounts"
                        />
                        {t('UpdateAccounts')}
                    </label>
                </Box>
                

                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.head}>{t('Login')}</TableCell>
                                <TableCell style={styles.head}>{t('ServerAddress')}</TableCell>
                                <TableCell style={{...styles.head, width:40}}>
                                    <IconButton size="small" color="primary" 
                                        disabled={!this.state.enableds.DeviceAccounts}
                                        aria-label="more" component="span"
                                        style={styles.addIcon}
                                        onClick={()=>{this.handleAccountMenuItemClick('ACCOUNT_EDITOR_OPEN_ADD')}}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.deviceSettings.DeviceAccounts.map((user) => 
                            <TableRow key={user.ID}>
                                <TableCell component="th" scope="row">
                                    {user.Login}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {user.ServerAddress}
                                </TableCell>
                                <TableCell style={{width:40}}>
                                    <IconButton size="small" color="inherit" 
                                        disabled={!this.state.enableds.DeviceAccounts}
                                        aria-label="add" component="span"
                                        onClick={event=>this.handleAccountMenuClick(event, user.ID)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                        <Menu anchorEl={this.state.accountMenuAnchor} keepMounted
                            open={Boolean(this.state.accountMenuAnchor)} onClose={this.handleAccountMenuClose.bind(this)}>
                            <MenuItem onClick={()=>{this.handleAccountMenuItemClick('ACCOUNT_EDITOR_OPEN_EDIT')}}>
                                <ListItemIcon color="primary"><EditIcon/></ListItemIcon> 
                                <ListItemText primary={t('EditAccount')+'...'} />
                            </MenuItem>
                            <MenuItem onClick={()=>{this.handleAccountMenuItemClick('ACCOUNT_EDITOR_DELETE')}}>
                                <ListItemIcon><DeleteForeverIcon/></ListItemIcon>
                                <ListItemText primary={t('DeleteAccount')} />
                            </MenuItem>
                        </Menu>
                    </Table>
                    {this.select('Default account', 'DefaultUser', this.getDefaultUserItems())}
                </Paper>            
            </> 
        )
    }

    getDefaultUserItems(){

        return this.state.deviceSettings.DeviceAccounts.map(
            item => [item.ID, item.Login + '(' + item.ServerAddress + ')']
        )

        //return []
    }

    handleChannelMenuItemClick(action){
        const itemID = this.state.menuItemID
        this.handleChannelMenuClose();
        this.handleChannelEditorAction({type: action, index: itemID})
    }

    handleChannelMenuClick(event, index){
        this.setState({channelMenuAnchor: event.currentTarget, menuItemID: index});
        event.stopPropagation();
    }

    handleChannelMenuClose(){
        this.setState({channelMenuAnchor: null, menuItemID: null});
        return null;
    }

    handleChannelEditorAction(action){

        switch(action.type){
            case 'CHANNEL_EDITOR_SAVE': {
                let channel = {...action.channel}
                let deviceSettings = {...this.state.deviceSettings}
                let channelIndex = action.index //getIndexByID(account.ID, deviceSettings.DeviceAccounts)
                if(channelIndex===null){
                    deviceSettings.DeviceChannels.push(channel);
                }else{
                    deviceSettings.DeviceChannels[channelIndex] = channel
                }
                deviceSettings.DeviceChannels = this.reindexChannels(deviceSettings.DeviceChannels, channelIndex, channel.ChannelNum)
                this.setState({
                    changed: true,
                    deviceSettings: deviceSettings, 
                    channelEditor: {action: '', index: null, channel: {}}
                })
                break;
            }
                

            case 'CHANNEL_EDITOR_CLOSE':
                this.setState({channelEditor: {action: '', index: null, account: {}}})
                break;

            case 'CHANNEL_EDITOR_OPEN_EDIT': {
                //let channels = this.state.deviceSettings.DeviceChannels.filter(item => item.ID===action.ID)
                let channel = this.state.deviceSettings.DeviceChannels[action.index]
                this.setState({channelEditor: {
                    action: 'edit', //add|edit
                    index: action.index,
                    channel: channel
                }})
                break
            }
                
            case 'CHANNEL_EDITOR_OPEN_ADD': {
                let channels = this.state.deviceSettings.DeviceChannels
                let channelNum = 1
                let channelIndex = 0
                if(channels.length){
                    channelNum = channels[channels.length-1].ChannelNum+1
                    channelIndex = channels.length
                }

                this.setState({channelEditor: {
                    action: 'add', //add|edit
                    index: channelIndex,
                    channel: {
                        ChannelNum: channelNum,
                        ChannelName: ''
                    }
                }})
                break
            }

            case 'CHANNEL_EDITOR_DELETE': {
                let deviceSettings = {...this.state.deviceSettings}
                const channelIndex = action.index //getIndexByID(action.ID, deviceSettings.DeviceChannels)
                if(channelIndex!==null){
                    deviceSettings.DeviceChannels.splice(channelIndex, 1)
                    this.setState({
                        changed: true,
                        deviceSettings: deviceSettings
                    })
                }
                break
            }


            default:
                alert(action.type)
        }
    }


    reindexChannels(channels, channelIndex, channelNum){
        //let currentNum = channelNum
        channels = channels.map(
            (item, index) => {
                if(channelIndex!==index && channelNum===item.ChannelNum){
                    //currentNum = 
                    item.ChannelNum = item.ChannelNum+1
                }
                return item
            }
        )
        channels = channels.sort((item1, item2)=>item1.ChannelNum-item2.ChannelNum)
        return channels
    }

    tabChannels(){

        const {t} = this.props

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
              minHeight: 300
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            addIcon: {
                color: 'white'
            }
        };

        return(
            <>
            
            {this.state.channelEditor.action?(
                <EditChannelDialog open={this.state.channelEditor.action!==''}
                    {...this.state.channelEditor} 
                    onAction={this.handleChannelEditorAction.bind(this)}/>
            ):''}

            <Paper style={styles.paper}>

                <label>
                    <Checkbox color="primary" 
                        checked={this.state.enableds.DeviceChannels}
                        onChange={this.handleEnableds}
                        name="DeviceChannels"
                    />
                    {t('UpdateChannels')}
                </label>

                    <Table size="small" disabled={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.head}>{t('ChannelNum')}</TableCell>
                                <TableCell style={styles.head}>{t('ChannelName')}</TableCell>
                                <TableCell style={{...styles.head, width:40}}>
                                    <IconButton size="small" color="primary" 
                                        disabled={!this.state.enableds.DeviceChannels}
                                        aria-label="more" component="span"
                                        style={styles.addIcon}
                                        onClick={()=>{this.handleChannelMenuItemClick('CHANNEL_EDITOR_OPEN_ADD')}}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.deviceSettings.DeviceChannels.map((channel, index) => 
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {channel.ChannelNum}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {channel.ChannelName}
                                </TableCell>
                                <TableCell style={{width:40}}>
                                    <IconButton size="small" color="inherit" 
                                        disabled={!this.state.enableds.DeviceChannels}
                                        aria-label="add" component="span"
                                        onClick={event=>this.handleChannelMenuClick(event, index)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                        <Menu anchorEl={this.state.channelMenuAnchor} keepMounted
                            open={Boolean(this.state.channelMenuAnchor)} onClose={this.handleChannelMenuClose.bind(this)}>
                            <MenuItem onClick={()=>{this.handleChannelMenuItemClick('CHANNEL_EDITOR_OPEN_EDIT')}}>
                                <ListItemIcon color="primary"><EditIcon/></ListItemIcon> 
                                <ListItemText primary={t('EditChannel')+'...'} />
                            </MenuItem>
                            <MenuItem onClick={()=>{this.handleChannelMenuItemClick('CHANNEL_EDITOR_DELETE')}}>
                                <ListItemIcon><DeleteForeverIcon/></ListItemIcon>
                                <ListItemText primary={t('DeleteChannel')} />
                            </MenuItem>
                        </Menu>
                    </Table>
                </Paper>            
            </>
        )
    }


    handleKeyMappingMenuItemClick(action){
        const itemID = this.state.menuItemID
        this.handleKeyMappingMenuClose();
        this.handleKeyMappingEditorAction({type: action, index: itemID})
    }

    handleKeyMappingMenuClick(event, index){
        this.setState({keyMappingMenuAnchor: event.currentTarget, menuItemID: index});
        event.stopPropagation();
    }

    handleKeyMappingMenuClose(){
        this.setState({keyMappingMenuAnchor: null, menuItemID: null});
        return null;
    }

    handleKeyMappingEditorAction(action){
        
        switch(action.type){
            case 'KEY_MAPPING_EDITOR_SAVE': {
                let keyMapping = {...action.keyMapping}
                let deviceSettings = {...this.state.deviceSettings}
                let index = action.index //getIndexByID(account.ID, deviceSettings.DeviceAccounts)
                //debugger
                console.log('keyMapping')
                console.log(keyMapping)
                console.log('deviceSettings')
                console.log(deviceSettings)
                console.log('index')
                console.log(index)

                if(deviceSettings.CustomKeys===undefined) deviceSettings.CustomKeys = []

                if(index===null){
                    deviceSettings.CustomKeys.push(keyMapping);
                }else{
                    deviceSettings.CustomKeys[index] = keyMapping
                }
                //deviceSettings.KeyMapping = this.reindexKeyMapping(deviceSettings.KeyMapping, index, keyMapping.KeyName)
                this.setState({
                    changed: true,
                    deviceSettings: deviceSettings, 
                    keyMappingEditor: {action: '', index: null, keyMapping: {}}
                })
                break;
            }
                

            case 'KEY_MAPPING_EDITOR_CLOSE':
                this.setState({keyMappingEditor: {action: '', index: null, account: {}}})
                break;

            case 'KEY_MAPPING_EDITOR_OPEN_EDIT': {
                //let channels = this.state.deviceSettings.DeviceChannels.filter(item => item.ID===action.ID)
                let keyMapping = this.state.deviceSettings.CustomKeys[action.index]
                console.log('keyMapping')
                console.log(keyMapping)
                this.setState({keyMappingEditor: {
                    action: 'edit', //add|edit
                    index: action.index,
                    keyMapping: keyMapping
                }})
                break
            }
                
            case 'KEY_MAPPING_EDITOR_OPEN_ADD': {
                let CustomKeys = this.state.deviceSettings.CustomKeys
                //let channelNum = 1
                let keyMappingIndex = 0
                if(CustomKeys && CustomKeys.length){
                    //channelNum = KeyMapping[channels.length-1].ChannelNum+1
                    keyMappingIndex = CustomKeys.length
                }

                this.setState({keyMappingEditor: {
                    action: 'add', //add|edit
                    index: keyMappingIndex,
                    keyMapping: {
                        KeyName:'',
                        KeyCode:'',
                        KeyIntent:'',
                        KeyCommand:''    
                    }
                }})
                break
            }

            case 'KEY_MAPPING_EDITOR_DELETE': {
                let deviceSettings = {...this.state.deviceSettings}
                const keyMappingIndex = action.index //getIndexByID(action.ID, deviceSettings.DeviceChannels)
                if(keyMappingIndex!==null){
                    deviceSettings.CustomKeys.splice(keyMappingIndex, 1)
                    this.setState({
                        changed: true,
                        deviceSettings: deviceSettings
                    })
                }
                break
            }

            case 'CHECK_PERMISSION':
                return this.props.onAction(action);
              
            default:
                alert(action.type)
        }
    }
 

    
    keyAction(command){
        let actions = selectKeys(KEY_ACTIONS, command)
        if(actions.length) return actions[0]
        else return ''
    }

    tabKeyMapping(){

        const {t} = this.props

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
              minHeight: 300
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            addIcon: {
                color: 'white'
            }
        };

        return(
            <>
            
            {this.state.keyMappingEditor.action?(
                <EditKeyMappingDialog open={this.state.keyMappingEditor.action!==''}
                    {...this.state.keyMappingEditor} 
                    networks={this.props.networks}
                    onAction={this.handleKeyMappingEditorAction.bind(this)}/>
            ):''}

            <Paper style={styles.paper}>

                <label>
                    <Checkbox color="primary" 
                        checked={this.state.enableds.CustomKeys}
                        onChange={this.handleEnableds}
                        name="CustomKeys"
                    />
                    {t('UpdateKeyMapping')}
                </label>

                    <Table size="small" disabled={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.head}>{t('KeyName')}</TableCell>
                                <TableCell style={styles.head}>{t('KeyAction')}</TableCell>
                                <TableCell style={{...styles.head, width:40}}>
                                    <IconButton size="small" color="primary" 
                                        disabled={!this.state.enableds.CustomKeys}
                                        aria-label="more" component="span"
                                        style={styles.addIcon}
                                        onClick={()=>{this.handleKeyMappingMenuItemClick('KEY_MAPPING_EDITOR_OPEN_ADD')}}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.deviceSettings.CustomKeys ? this.state.deviceSettings.CustomKeys.map((key, index) => 
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {key.KeyName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {key.KeyCommand}:
                                    {t(this.keyAction(key.KeyCommand))}
                                </TableCell>
                                <TableCell style={{width:40}}>
                                    <IconButton size="small" color="inherit" 
                                        disabled={!this.state.enableds.CustomKeys}
                                        aria-label="add" component="span"
                                        onClick={event=>this.handleKeyMappingMenuClick(event, index)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ):''}
                        </TableBody>
                        <Menu anchorEl={this.state.keyMappingMenuAnchor} keepMounted
                            open={Boolean(this.state.keyMappingMenuAnchor)} 
                            onClose={this.handleKeyMappingMenuClose.bind(this)}>
                            <MenuItem onClick={()=>{this.handleKeyMappingMenuItemClick('KEY_MAPPING_EDITOR_OPEN_EDIT')}}>
                                <ListItemIcon color="primary"><EditIcon/></ListItemIcon> 
                                <ListItemText primary={t('EditKeyMapping')+'...'} />
                            </MenuItem>
                            <MenuItem onClick={()=>{this.handleKeyMappingMenuItemClick('KEY_MAPPING_EDITOR_DELETE')}}>
                                <ListItemIcon><DeleteForeverIcon/></ListItemIcon>
                                <ListItemText primary={t('DeleteKeyMapping')} />
                            </MenuItem>
                        </Menu>
                    </Table>
                </Paper>            
            </>
        )
    }

    tabSipContacts(){

        const {t} = this.props

        const styles = {
            paper: {
              width: '100%',
              overflowX: 'auto',
              minHeight: 300
            },
            head: {
                color: theme.palette.color.dialogTitle,
                backgroundColor: theme.palette.background.dialogTitle,
            },
            addIcon: {
                color: 'white'
            }
        };

        //debugger 

        return(
            <>
            
            {this.state.sipContactEditor.action?(
                <EditSipContactDialog open={this.state.sipContactEditor.action!==''}
                    {...this.state.sipContactEditor} 
                    networks={this.props.networks}
                    onAction={this.handleSipContactEditorAction.bind(this)}/>
            ):''}

            <Paper style={styles.paper}>

                <label>
                    <Checkbox color="primary" 
                        checked={this.state.enableds.SipContacts}
                        onChange={this.handleEnableds}
                        name="SipContacts"
                    />
                    {t('UpdateSipContacts')}
                </label>

                    <Table size="small" disabled={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.head}>{t('SipName')}</TableCell>
                                <TableCell style={styles.head}>{t('SipAddress')}</TableCell>
                                <TableCell style={{...styles.head, width:40}}>
                                    <IconButton size="small" color="primary" 
                                        disabled={!this.state.enableds.SipContacts}
                                        aria-label="more" component="span"
                                        style={styles.addIcon}
                                        onClick={()=>{this.handleSipContactMenuItemClick('SIP_CONTACT_EDITOR_OPEN_ADD')}}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.deviceSettings.SipContacts ? this.state.deviceSettings.SipContacts.map((key, index) => 
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {key.SipName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {key.SipAddress}
                                </TableCell>
                                <TableCell style={{width:40}}>
                                    <IconButton size="small" color="inherit" 
                                        disabled={!this.state.enableds.SipContacts}
                                        aria-label="add" component="span"
                                        onClick={event=>this.handleSipContactMenuClick(event, index)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ):''}
                        </TableBody>
                        <Menu anchorEl={this.state.sipContactMenuAnchor} keepMounted
                            open={Boolean(this.state.sipContactMenuAnchor)} 
                            onClose={this.handleSipContactMenuClose.bind(this)}>
                            <MenuItem onClick={()=>{this.handleSipContactMenuItemClick('SIP_CONTACT_EDITOR_OPEN_EDIT')}}>
                                <ListItemIcon color="primary"><EditIcon/></ListItemIcon> 
                                <ListItemText primary={t('EditSipContact')+'...'} />
                            </MenuItem>
                            <MenuItem onClick={()=>{this.handleSipContactMenuItemClick('SIP_CONTACT_EDITOR_DELETE')}}>
                                <ListItemIcon><DeleteForeverIcon/></ListItemIcon>
                                <ListItemText primary={t('DeleteSipContact')} />
                            </MenuItem>
                        </Menu>
                    </Table>
                </Paper>
            </>
        )
    }

       
    handleSipContactMenuItemClick(action){
        //debugger
        const itemID = this.state.menuItemID
        this.handleSipContactMenuClose();
        this.handleSipContactEditorAction({type: action, index: itemID})
    }

    handleSipContactMenuClick(event, index){
        //debugger
        this.setState({sipContactMenuAnchor: event.currentTarget, menuItemID: index});
        event.stopPropagation();
    }

    handleSipContactMenuClose(){
        this.setState({sipContactMenuAnchor: null, menuItemID: null});
        return null;
    }

    handleSipContactEditorAction(action){
        //debugger
        switch(action.type){
            case 'SIP_CONTACT_EDITOR_SAVE': {
                let sipContact = {...action.sipContact}
                let deviceSettings = {...this.state.deviceSettings}
                let index = action.index //getIndexByID(account.ID, deviceSettings.DeviceAccounts)
                //debugger
                console.log('sipContact')
                console.log(sipContact)
                console.log('deviceSettings')
                console.log(deviceSettings)
                console.log('index')
                console.log(index)

                if(deviceSettings.SipContacts===undefined) deviceSettings.SipContacts = []

                if(index===null){
                    deviceSettings.SipContacts.push(sipContact);
                }else{
                    deviceSettings.SipContacts[index] = sipContact
                }
                this.setState({
                    changed: true,
                    deviceSettings: deviceSettings, 
                    sipContactEditor: {action: '', index: null, sipContact: {}}
                })
                break;
            }
                

            case 'SIP_CONTACT_EDITOR_CLOSE':
                this.setState({sipContactEditor: {action: '', index: null, account: {}}})
                break;

            case 'SIP_CONTACT_EDITOR_OPEN_EDIT': {
                //let channels = this.state.deviceSettings.DeviceChannels.filter(item => item.ID===action.ID)
                let SipContact = this.state.deviceSettings.SipContacts[action.index]
                //console.log('SipContact')
                //console.log(SipContact)
                this.setState({sipContactEditor: {
                    action: 'edit', //add|edit
                    index: action.index,
                    sipContact: SipContact
                }})
                break
            }
                
            case 'SIP_CONTACT_EDITOR_OPEN_ADD': {
                let SipContacts = this.state.deviceSettings.SipContacts
                //let channelNum = 1
                let sipContactIndex = 0
                if(SipContacts && SipContacts.length){
                    sipContactIndex = SipContacts.length
                }

                this.setState({sipContactEditor: {
                    action: 'add', //add|edit
                    index: sipContactIndex,
                    sipContact: {
                        SipName:'',
                        SipNumber:'' 
                    }
                }})
                break
            }

            case 'SIP_CONTACT_EDITOR_DELETE': {
                let deviceSettings = {...this.state.deviceSettings}
                const keyMappingIndex = action.index //getIndexByID(action.ID, deviceSettings.DeviceChannels)
                if(keyMappingIndex!==null){
                    deviceSettings.CustomKeys.splice(keyMappingIndex, 1)
                    this.setState({
                        changed: true,
                        deviceSettings: deviceSettings
                    })
                }
                break
            }

            case 'CHECK_PERMISSION':
                return this.props.onAction(action);
              
            default:
                alert(action.type)
        }
    }


    render(){
        const {t} = this.props

        const title = t('DeviceSettings')

        //const margin = {}//{margin: 0}

        return(
<Dialog
    scroll="body"
    fullWidth={this.props.fullWidth}
    maxWidth={this.props.maxWidth}
    open={this.props.open}
    onClose={this.handleClose.bind(this)}
    aria-labelledby="edit-dialog-title">
        
    <DialogTitle id="edit-dialog-title">{title}</DialogTitle>
    <DialogContent style={{minWidth:450}}>

        {/*<AppBar position="static" color="secondary">*/}{/**  */}
        <Paper square>
        <Tabs  value={this.state.tab} 
            variant="scrollable"
            scrollButtons="auto"
            onChange={this.handleTabChange} aria-label="simple tabs example"
            indicatorColor="primary">
            <Tab wrapped style={{minWidth:80}} label={t("General")} id="tab-0" aria-controls="tabpanel-0" />
            <Tab wrapped style={{minWidth:80}} label={t("Accounts")} id="tab-1" aria-controls="tabpanel-1" />
            <Tab wrapped style={{minWidth:80}} label={t("ChannelSelector")} id="tab-2" aria-controls="tabpanel-2" />
            <Tab wrapped style={{minWidth:80}} label={t("KeyMapping")} id="tab-3" aria-controls="tabpanel-3" />
            <Tab wrapped style={{minWidth:80}} label={t("SipContacts")} id="tab-4" aria-controls="tabpanel-4" />
        </Tabs>
        </Paper>
        {/*</AppBar>*/}
        <TabPanel value={this.state.tab} index={0}>
            {this.tabGeneral()}
        </TabPanel>
        <TabPanel value={this.state.tab} index={1}>
            {this.tabAccounts()}
        </TabPanel>
        <TabPanel value={this.state.tab} index={2}>
            {this.tabChannels()}
        </TabPanel>
        <TabPanel value={this.state.tab} index={3}>
            {this.tabKeyMapping()}
        </TabPanel>
        <TabPanel value={this.state.tab} index={4}>
            {this.tabSipContacts()}
        </TabPanel>

    </DialogContent>
    <DialogActions>
        <Button onClick={this.handleSave.bind(this)} variant="contained" color="primary">
            {t('Apply')}
        </Button>
        <Button onClick={this.handleClose.bind(this)} variant="contained" color="inherit">
            {t('Cancel')}
        </Button>
    </DialogActions>

</Dialog>
        );

    }

}


export const EditDeviceSettingsDialog = withTranslation()(EditDeviceSettingsDialog_);