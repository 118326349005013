import { base64ToUint8Array } from "../../api/helpers"


/**
 * record structure:
 */
const fields = [
    'id', //autoincrement not reqired
    'name',
    'type', //0 - preview; 1 - file
    'state', //0 - empty chunks; 1  - load in progress; 2 - full chunks 
    'job_id', //indexed
    'size',
    'mime_type',
    'network_id'
]

const chunk_fields = [
    'id', //autoincrement not reqired
    'file_id', //indexed
    'num', // chunk number
    'data', //base64 encoded binary string
    'last' //last chunk
]


export default class FileStorage{

    constructor(indexedDB){

        this.db = null 

        this.indexedDB = indexedDB

        this.indexedDB.addUpgradeListener(this.onUpgradeNeeded.bind(this))
        this.indexedDB.addConnectListener(this.onConnect.bind(this))

    }

    onConnect(db){
        this.db = db
    }

    onUpgradeNeeded(event){

        console.log('FileStorage onUpgradeNeeded')
        

        let transaction = event.target.transaction;
        this.db = event.target.result;

        //File {id, name, type, job_id, size}
        let fileStore
        if (!this.db.objectStoreNames.contains('File')) {
            console.log('create File store')
            fileStore = this.db.createObjectStore("File", { keyPath : "id", autoIncrement: true  })
        }else{
            console.log('exist store File')
        }

        if(!fileStore){
            fileStore = transaction.objectStore('File')
        } 


        try {
            fileStore.index("job_id_idx")
            console.log('exist index File.job_id_idx')
        } catch (error) {
            if(error.name==='NotFoundError'){
                console.log('create File.job_id_idx')
                //dateIndex = 
                fileStore.createIndex('job_id_idx', 'job_id')
            }
        }


        //FileChunks {id, file_id, num, data} 
        let fileChunksStore
        if (!this.db.objectStoreNames.contains('FileChunks')) {
            console.log('create FileChunks store')
            fileChunksStore = this.db.createObjectStore("FileChunks", { keyPath : "id", autoIncrement: true  })
        }else{
            console.log('exist store FileChunks')
        }

        if(!fileChunksStore){
            fileChunksStore = transaction.objectStore('FileChunks')
        } 



        //if(event.oldVersion<15) {
        //    console.log('fileChunksStore.deleteIndex')
        //    fileChunksStore.deleteIndex('file_id_idx')
        //}
        try {
            fileChunksStore.index("file_id_idx")
            console.log('exist index FileChunks.file_id_idx')
        } catch (error) {
            if(error.name==='NotFoundError'){
                console.log('create FileChunks.file_id_idx')
                //dateIndex = 
                fileChunksStore.createIndex('file_id_idx', ['file_id', 'num'])
            }
        }

    }

    connected(){
        return Boolean(this.db)
    }

    addFile(file, oncomplete){
        if(!this.connected()) return

        //console.log('Add file')
        //console.log(file)
        if(!this.checkFile(file)) return   

        const transaction = this.db.transaction(["File"],"readwrite");
        transaction.oncomplete = function() {
            //console.log("Success");
        };

        transaction.onerror = function() {
            //console.log("Error");
        };  
        const fileStore = transaction.objectStore("File");
        const request = fileStore.add(file);
        request.onsuccess = function (e) {
            console.log('request.onsuccess')
            console.log(e.target.result)
            const id = e.target.result
            //alert(id)
            oncomplete(id)
        };
    }

    checkFile(file){
        //check for required fields
        if(file.name === undefined){
            alert('File: file.name is not defined')
            return false
        }
        if(file.job_id === undefined){
            alert('File: file.job_id is not defined')
            return false
        }
        if(file.type === undefined){
            alert('File: file.type is not defined')
            return false
        }

        //console.log(Object.keys(event))
        Object.keys(file).forEach(item => {
            //console.log('item ' + item)
            if(fields.indexOf(item)===-1){
                alert("File: unexpected field "+item)
                return false
            }
        });

        return true
    }

    addChunk(chunk, oncomplete, onerror){
        if(!this.connected()) return

        //console.log('Add file')
        //console.log(file)
        if(!this.checkChunk(chunk)) return   

        const transaction = this.db.transaction(["FileChunks"], "readwrite");
        transaction.oncomplete = function(event) {
            //console.log("Success");
            if(oncomplete) oncomplete(event)
        };

        transaction.onerror = function(event) {
            if(onerror) onerror(event)
            //console.log("Error");
        };  
        const fileChunksStore = transaction.objectStore("FileChunks");
        fileChunksStore.add(chunk);
    }

    checkChunk(chunk){
        //check for required fields
        if(chunk.file_id === undefined){
            alert('FileChunks: chunk.file_id is not defined')
            return false
        }else if(!chunk.file_id){
            alert('FileChunks: chunk.file_id is not empty')
            return false
        }
        if(chunk.num === undefined){
            alert('FileChunks: chunk.num is not defined')
            return false
        }
        if(chunk.data === undefined){
            alert('FileChunks: chunk.data is not defined')
            return false
        }

        //console.log(Object.keys(event))
        Object.keys(chunk).forEach(item => {
            //console.log('item ' + item)
            if(chunk_fields.indexOf(item)===-1){
                alert("FileChunks: unexpected field "+item)
                return false
            }
        });

        return true
    }


    queryFiles(filter, onsuccess, onerror){
        //select events from db
        if(!this.connected()) return

        console.log('queryFiles')

        const transaction = this.db.transaction(["File"], "readonly")
        const fileStore = transaction.objectStore("File")
        let cursor
        let useFilter = true

        console.log('filter')
        console.log(filter)

        //let request
        let range
        if(filter.job_id && !Array.isArray(filter.job_id)){

            console.log('use job_id_idx')
            const jobIdIndex = fileStore.index("job_id_idx");
            range = filter.job_id  
            cursor = jobIdIndex.openCursor(range)

            useFilter = true

        }else{
            console.log('use default cursor')
            cursor = fileStore.openCursor()
        }
        

        let result = []
        let count = 0
        cursor.onsuccess = event => {
            let cursor = event.target.result;
            if(cursor) {
                //console.log('cursor.value')
                //console.log(cursor.value)
                if(!useFilter || fileItemFilter(cursor.value, filter)){
                    result.push(cursor.value)
                }
                count ++
                cursor.continue();
            }else{
                //console.log('cursor.complete '+ result.length)
                //todo need sort?
                //if(useSort){
                //    result = sort(result)
                //}
                console.log('files count = ' + count)
                onsuccess(result)
            }
        }
       
        cursor.onerror = event => {
            if(onerror) onerror(event)
        }

        /*
        request.onsuccess = ()=>{
            alert('success')
            onsuccess(request.result)
        }
        request.onerror = ()=>{
            alert('error')
            onerror()
        }
        */

    }


    queryChunks(file_id, onsuccess, onerror){
        //select events from db
        if(!this.connected()) return

        console.log('queryChunks')

        const transaction = this.db.transaction(["FileChunks"], "readonly")
        const fileStore = transaction.objectStore("FileChunks")
        let cursor

        console.log('queryChunks file_id=' + file_id)

        console.log('use file_id_idx')
        const fileIdIndex = fileStore.index("file_id_idx");

        const lowerBound = [file_id];
        const upperBound = [file_id, []];
        const range = IDBKeyRange.bound(lowerBound, upperBound);

        cursor = fileIdIndex.openCursor(range)


        let result = []
        let count = 0
        cursor.onsuccess = event => {
            let cursor = event.target.result;
            if(cursor) {
                //console.log('cursor.value')
                //console.log(cursor.value)
                //if(!useFilter || fileItemFilter(cursor.value, filter)){
                    result.push(cursor.value)
                //}
                count ++
                cursor.continue();
            }else{
                //console.log('cursor.complete '+ result.length)
                //todo need sort?
                //if(useSort){
                //    result = sort(result)
                //}
                console.log('chunks count = ' + count)
                onsuccess(result)
            }
        }
       
        cursor.onerror = event => {
            if(onerror) onerror(event)
        }

    }

    readFile(jobID, fileType, networkID, onsuccess, onerror){
        const filter = {
            type: fileType,
            job_id: jobID
        }
        this.queryFiles(filter, (result)=>{
            if(result && result.length===1){
                console.log('queryFiles')
                console.log(result)
                const file = result[0]
                console.log('readFile file')
                console.log(file)
                const name = file.name

                if(file.size && (file.state===2 || file.type===0)){
                    /*
                    if(file.state!==undefined && file.type===1){
                        alert('file.state='+file.state)
                        if(file.state===1){
                            onerror(jobID, fileType, 1, file.id, networkID, onsuccess)
                        }
                    }
                    */
                    this.queryChunks(file.id, result => {
                        console.log('queryChunks result')
                        console.log(result)
                        if(result.length){
                            const arr = result.map(item => base64ToUint8Array(item.data))
                            const b = new Blob(arr, {type: file.mime_type, name: name});//'application/octet-stream'
                            if(onsuccess) onsuccess(URL.createObjectURL(b))
                            //window.open(URL.createObjectURL(b))
                        }else{
                            if(onerror){
                                // 1 - errCode file is empty
                                onerror(jobID, fileType, 1, file.id, networkID, onsuccess)
                            }
                        }
                        
                    })
                }else if(file.state===undefined || file.state===0 || file.state===1){
                    if(onerror){
                        // 1 - errCode file is empty or not complete
                        onerror(jobID, fileType, 1, file.id, networkID, onsuccess)
                    }
                }else{
                    if(onsuccess) onsuccess(null)
                }

            }else{
                // 2 - errCode file is absent (not yet uploaded)
                onerror(jobID, fileType, 2, null, networkID, onsuccess)
            }
            
        }, ()=>{
            
            console.error('FileStorage.readFile error')
    
        })
    }

    getFileID(jobID, fileType, onsuccess){
        const filter = {
            type: fileType,
            job_id: jobID
        }
        this.queryFiles(filter, (result)=>{
            if(result && result.length===1){
                console.log('queryFiles')
                console.log(result)
                const file = result[0]
                console.log('readFile file')
                console.log(file)

                if(onsuccess) onsuccess(file.id)

            }else{
                // 2 - errCode file is absent (not yet uploaded)
                //onerror(jobID, fileType, 2, null, networkID, onsuccess)
            }
            
        }, ()=>{
            
            console.error('FileStorage.readFile error')
    
        })
    }

    delete(jobID){
        //alert('delete '+ jobID)
        //return

        console.log('file storage cleanup ' + jobID)

        const transaction = this.db.transaction(["File", "FileChunks"], "readwrite");
        const fileStore = transaction.objectStore("File")
        const fileChunksStore = transaction.objectStore("FileChunks")

        const jobIdIndex = fileStore.index("job_id_idx");
        const fileIdIndex = fileChunksStore.index("file_id_idx");

        const cursor = jobIdIndex.openCursor(jobID)

        cursor.onsuccess = event => {
            const cursor = event.target.result
            if(cursor) {
                //console.log('cursor.value')
                //console.log(cursor.value)
                //eventsLogStore.delete(cursor.value.id)
                console.log('delete file ' + cursor.value.id)
                console.log(cursor.value)
                const file_id = cursor.value.id

                const lowerBound = [file_id];
                const upperBound = [file_id, []];
                const range = IDBKeyRange.bound(lowerBound, upperBound);
        
                const chunkCursor = fileIdIndex.openCursor(range)
                chunkCursor.onsuccess = event => {
                    const cursor = event.target.result
                    if(cursor){
                        console.log('delete chunk') //v+ cursor.value.id
                        //console.log(cursor.value)

                        const chunk_id = cursor.value.id
                        fileChunksStore.delete(chunk_id)

                        cursor.continue();
                    }
                }

                fileStore.delete(file_id)

                cursor.continue();
            }else{
                console.log('delete complete')
            }
        }

    }


    deleteChunks(jobID, type, onsuccess){
        //alert('delete '+ jobID)
        //return

        console.log('delete file chunks type='+type+'  cleanup ' + jobID)

        const transaction = this.db.transaction(["File", "FileChunks"], "readwrite");
        const fileStore = transaction.objectStore("File")
        const fileChunksStore = transaction.objectStore("FileChunks")

        const jobIdIndex = fileStore.index("job_id_idx");
        const fileIdIndex = fileChunksStore.index("file_id_idx");

        let cursorCount = 0

        const cursor = jobIdIndex.openCursor(jobID)
        cursorCount ++

        cursor.onsuccess = event => {
            console.log('file onsuccess')
            const result = event.target.result
            if(result) {
                if(result.value.type===type){
                    //console.log('result.value')
                    //console.log(result.value)
                    //eventsLogStore.delete(result.value.id)
                    console.log('delete file ' + result.value.id)
                    console.log(result.value)
                    const file_id = result.value.id

                    const lowerBound = [file_id];
                    const upperBound = [file_id, []];
                    const range = IDBKeyRange.bound(lowerBound, upperBound);
            
                    const chunkCursor = fileIdIndex.openCursor(range)
                    cursorCount ++
                    chunkCursor.onsuccess = event => {
                        //console.log('filechunks onsuccess')
                        const cursor = event.target.result
                        if(cursor){
                            console.log('delete chunk')// ' + cursor.value.id)
                            //console.log(cursor.value)

                            const chunk_id = cursor.value.id
                            fileChunksStore.delete(chunk_id)

                            cursor.continue();
                        }else{
                            cursorCount --
                            if(cursorCount===0){
                                console.log('delete chunks complete 1')
                                if(onsuccess) onsuccess(file_id)
                            } 
                        }
                    }

                    //fileStore.delete(file_id)
                }
                result.continue();
            }else{
                cursorCount --
                //console.log('delete complete')
                if(cursorCount===0){
                    console.log('delete chunks complete 0')
                    //empty 
                    if(onsuccess) onsuccess()
                } 
            }
        }

    }

    //update file status
    update(id, updatedFields){
        console.log('updateFile')
        const transaction = this.db.transaction(["File"], "readwrite")
        const fileStore = transaction.objectStore("File")
        //const refIndex = fileStore.index("job_id_idx")

        if(id){
            const request = fileStore.get(id)
            //const request = refIndex.get(refID)
            request.onsuccess = function() {
                if (request.result !== undefined) {
                    console.log('request.result')
                    console.log(request.result)
                    let file = {...request.result, ...updatedFields}

                    transaction.oncomplete = function(event) {
                        console.log("Success update file "+id)
                    }
            
                    transaction.onerror = function(event) {
                        console.log("Error update file "+id)
                    }
                    //const log = transaction.objectStore("EventsLog");
                    fileStore.put(file)
                    console.log('put.file')
                    console.log(file)

                }
            }
        }

    }

}


function fileItemFilter(item, filter){
    if(filter.type!==undefined){
        if(Array.isArray(filter.type)){
            if(!filter.type.includes(item.type)) return false
        }else{
            //console.log('expr = ' + (filter.type!==item.type) + '; '+filter.type + ':'+ item.type)
            if(filter.type!==item.type) return false
        }
    }

    if(filter.network_id && filter.network_id!==item.network_id) return false
    //todo array filter
    if(filter.job_id && filter.job_id!==item.job_id) return false

    return true
}